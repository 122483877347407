<form [formGroup]="addItemForm">
  <div formGroupName="paymentDetails">
    <ul class="checkbox-list checkbox-list--detailed">
      <!-- accept offers -->
      <li
        (click)="
          addItemForm
            .get('paymentDetails')
            .get('acceptOffers')
            .patchValue(!addItemForm.get('paymentDetails').get('acceptOffers').value)
        "
        class="checkbox-list__item"
      >
        <div>
          <p class="highlight-text">{{ 'add-item.payment-details.purchase-method.accept-offers' | translate }}</p>
          <p>{{ 'add-item.payment-details.purchase-method.accept-offers-desc' | translate }}</p>
        </div>
        <pc-checkmark-icon [checked]="addItemForm.get('paymentDetails').get('acceptOffers').value" [size]="32">
        </pc-checkmark-icon>
      </li>

      <div class="separator"></div>

      <!-- buy now -->
      <li
        (click)="
          addItemForm
            .get('paymentDetails')
            .get('acceptBuyNow')
            .patchValue(!addItemForm.get('paymentDetails').get('acceptBuyNow').value)
        "
        class="checkbox-list__item"
      >
        <div>
          <p class="highlight-text">{{ 'add-item.payment-details.purchase-method.buy-now' | translate }}</p>
          <p>{{ 'add-item.payment-details.purchase-method.buy-now-desc' | translate }}</p>
        </div>
        <pc-checkmark-icon [checked]="addItemForm.get('paymentDetails').get('acceptBuyNow').value" [size]="32">
        </pc-checkmark-icon>
      </li>
    </ul>
  </div>
</form>
