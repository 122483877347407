import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@paperclip/core/app-state.service';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
import { TitleService } from '@paperclip/core/title.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { AppState } from '@paperclip/models/AppState';
import { ComponentUpdate } from '@paperclip/models/component-update';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { NotificationsByDate } from '@paperclip/models/Notification';
import { UserNotifications } from '@paperclip/models/user/UserNotifications';
import { UserNotificationsService } from '@paperclip/page/user-notifications/user-notifications.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ConfirmationMessageService } from '../../../../core/confirmation-message.service';
import { UsersService } from '../../../../user/users.service';

@Component({
  selector: 'pc-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() insideMenu: boolean;
  @Input() menuOpened: boolean;
  loggedInUserId = this.authService.getAuthedUser().detail?.id || '';
  reloadRouteSubscription: Subscription;
  appState: AppState = this.appStateService.appState;

  /*-- loading & load more --*/
  userNotifications: NotificationsByDate;
  userNotificationsLoading: boolean;
  userNotificationsLoadingMore: boolean;
  userNotificationsLoadingSkipValue: number;
  windowSize: string;
  windowWidthResizeSubscription: Subscription;
  updateServiceSubscription: Subscription;
  allMarkedAsSeen: boolean;

  // check for links or buttons in notification message innerHtml
  // and transform / react appropriately
  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'BUTTON' && !event.target.className.includes('mat-menu-trigger')) {
      this.followUser(event.target.getAttribute('userId'));
    } else {
      return;
    }
  }

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private userNotificationsService: UserNotificationsService,
    private coreService: CoreService,
    private windowResizeService: WindowResizeService,
    private userService: UsersService,
    private confirmationMessageService: ConfirmationMessageService,
    private titleService: TitleService,
    public appStateService: AppStateService,
    private updateService: UpdatesService
  ) {
    this.windowWidthResizeSubscription = this.windowResizeService
      .windowWidthDidChange()
      .subscribe((updatedWidth: number) => {
        this.windowSize = updatedWidth < 1200 ? 'responsive' : 'desktop';
      });

    this.updateServiceSubscription = this.updateService.getUpdateData().subscribe((updateData: ComponentUpdate) => {
      if (updateData.action === 'mark-all-notifications-as-read') {
        this.markAllNotificationsAsSeen();
      }

      if (updateData.action === 'deleted-all-notifications') {
        this.getUserNotifications();
        this.getUnseenNotificationsCount();
      }
    });

    // check if route is navigated to whilst already on it and if so reload the component
    this.reloadRouteSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: any) => {
        if (event.url.includes('notifications')) {
          this.ngOnInit();
        }
      });
  }

  ngOnInit() {
    this.coreService.trackPaperclipEvent({ name: 'NOTIFICATIONS_VIEWED', data: null }).subscribe();
    this.userNotificationsLoadingSkipValue = 0;
    this.windowSize = window.innerWidth < 1200 ? 'responsive' : 'desktop';
    this.getUserNotifications();
    this.getUnseenNotificationsCount();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.menuOpened?.currentValue) {
      this.getUserNotifications();
    }
  }

  ngOnDestroy(): void {
    this.reloadRouteSubscription.unsubscribe();
    this.windowWidthResizeSubscription.unsubscribe();
    this.updateServiceSubscription.unsubscribe();
  }

  getUserNotifications(loadMore = false) {
    if (!this.insideMenu) {
      this.titleService.setTitle(`Notifications`);
      if (loadMore) {
        this.userNotificationsLoadingMore = true;
        this.userNotificationsLoadingSkipValue += 30;
      } else {
        this.userNotificationsLoading = true;
        this.userNotificationsLoadingMore = false;
        this.userNotificationsLoadingSkipValue = 0;
      }
    } else {
      this.userNotificationsLoading = true;
    }

    this.userNotificationsService
      .getUserNotifications(this.userNotificationsLoadingSkipValue, this.insideMenu ? 3 : 0)
      .subscribe(
        ({ code, data, message }: PcApiResponse) => {
          this.userNotificationsLoading = false;
          this.userNotificationsLoadingMore = false;

          if (code === 1) {
            const notificationsByDate = new NotificationsByDate(
              data,
              this.authService.getAuthedUser().detail.id,
              this.translateService.getBrowserCultureLang()
            );

            if (loadMore) {
              this.userNotifications.all.push(...notificationsByDate.all);
            } else {
              this.userNotifications = notificationsByDate;
            }
          } else {
            this.coreService.handleError({ code, message });
          }
        },
        (error) => console.error(error)
      );
  }

  getUnseenNotificationsCount() {
    let userNotifications: UserNotifications = { unseenNotifications: 0, unseenMessages: 0, totalUnseenCount: 0 };
    this.userNotificationsService.getUnseenUserNotificationsCounts().subscribe((userNotificationsApi: any[]) => {
      const unseenNotifications: PcApiResponse = userNotificationsApi[0];
      const unseenMessages: PcApiResponse = userNotificationsApi[1];

      if (unseenNotifications.code === 1 && unseenMessages.code === 1) {
        userNotifications = {
          unseenNotifications: unseenNotifications.data.count,
          unseenMessages: unseenMessages.data.count,
          totalUnseenCount: unseenNotifications.data.count + unseenMessages.data.count
        };
        this.appStateService.setNotificationsState(userNotifications);
      } else {
        if (unseenNotifications.code) {
          this.coreService.handleError({ code: unseenNotifications.code });
        }
      }
    });
  }

  markAllNotificationsAsSeen() {
    this.userNotificationsService.markAllNotificationsAsSeen().subscribe(
      ({ code, message }: PcApiResponse) => {
        if (code === 1) {
          this.allMarkedAsSeen = true;
          this.getUnseenNotificationsCount();
          // this.confirmationMessageService.showConfirmationMessage('notifications-marked-as-read');
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  followUser(userId: string) {
    this.userService.followUser(userId, this.loggedInUserId).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('followed');
          for (let i = 0; i < this.userNotifications.today.length; i++) {
            if (userId === this.userNotifications.today[i].sender.detail.id) {
              this.userNotifications.today[i].sender.detail.isFollowed = true;
              this.userNotifications.today[i].detail.content.message = 'user-notifications.followed-you';
            }
          }

          for (let i = 0; i < this.userNotifications.previous.length; i++) {
            if (userId === this.userNotifications.previous[i].sender.detail.id) {
              this.userNotifications.previous[i].sender.detail.isFollowed = true;
              this.userNotifications.previous[i].detail.content.message = 'user-notifications.followed-you';
            }
          }

          for (let i = 0; i < this.userNotifications.all.length; i++) {
            if (userId === this.userNotifications.all[i].sender.detail.id) {
              this.userNotifications.all[i].sender.detail.isFollowed = true;
              this.userNotifications.all[i].detail.content.message = 'user-notifications.followed-you';
            }
          }
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }
}
