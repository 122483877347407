<div *ngIf="!itemOverviewConfig.loading" class="item-action-buttons item-action-buttons--{{ layout }}">
  <!-- buy now -->
  <button
    *ngIf="itemOverviewConfig.actions?.buyNow.visible"
    class="btn btn-large btn-large--fit-content btn-green"
    [ngClass]="{ 'btn--disabled': !this.itemOverviewConfig.actions?.buyNow?.enabled }"
    (click)="buyItem.emit()"
  >
    {{ 'buttons.buy-now' | translate }}
  </button>

  <!-- sell similar -->
  <a
    *ngIf="itemOverviewConfig.actions?.sellSimilar.visible"
    class="btn btn-large btn-large--fit-content"
    [routerLink]="['/sell-similar', itemOverviewConfig.item.detail.id]"
  >
    {{ 'item-overview.sell-similar' | translate }}
  </a>

  <!-- edit item -->
  <ng-container *ngIf="itemOverviewConfig.actions?.editItem.visible">
    <div
      *ngIf="!itemOverviewConfig.actions?.editItem.enabled"
      class="disabled-button-container"
      matTooltip="{{ 'tooltip.cannot-edit-item' | translate }}"
    >
      <button class="btn btn-large btn-large--fit-content btn-primary" disabled>
        {{ 'item-overview.edit-item' | translate }}
      </button>
    </div>

    <button
      *ngIf="itemOverviewConfig.actions?.editItem.enabled"
      class="btn btn-large btn-large--fit-content btn-primary"
      (click)="editItem.emit()"
    >
      {{ 'item-overview.edit-item' | translate }}
    </button>
  </ng-container>

  <!-- social inventory relist item -->
  <a
    *ngIf="itemOverviewConfig.actions?.relistItem.visible"
    class="btn btn-primary btn-large btn-large--fit-content"
    [routerLink]="['/relist-item', itemOverviewConfig.item.detail.id]"
  >
    {{ 'buttons.list-item' | translate }}
  </a>
</div>

<button *ngIf="itemOverviewConfig.loading" class="btn btn-large btn-large--fit-content"></button>
