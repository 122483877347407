import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-dispute-submitted-modal',
  templateUrl: './dispute-submitted-modal.component.html'
})
export class DisputeSubmittedModalComponent implements OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<DisputeSubmittedModalComponent>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { closed: () => {} }
  ) {}

  ngOnDestroy(): void {
    this.modalData.closed();
  }
}
