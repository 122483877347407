import { WalletDeliveryAddressMenuComponent } from './wallet-delivery-address-menu/wallet-delivery-address-menu.component';
import { WalletFilterTransactionsMenuComponent } from './wallet-filter-transactions-menu/wallet-filter-transactions-menu.component';
import { WalletHeaderMenuComponent } from './wallet-header-menu/wallet-header-menu.component';
import { WalletPaymentCardMenuComponent } from './wallet-payment-card-menu/wallet-payment-card-menu.component';

export * from './wallet-delivery-address-menu/wallet-delivery-address-menu.component';
export * from './wallet-filter-transactions-menu/wallet-filter-transactions-menu.component';
export * from './wallet-header-menu/wallet-header-menu.component';
export * from './wallet-payment-card-menu/wallet-payment-card-menu.component';

export const walletMenus = [
  WalletDeliveryAddressMenuComponent,
  WalletFilterTransactionsMenuComponent,
  WalletHeaderMenuComponent,
  WalletPaymentCardMenuComponent
];
