import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-payment-protection-modal',
  templateUrl: './payment-protection-modal.component.html',
  styleUrls: ['./payment-protection-modal.component.scss']
})
export class PaymentProtectionModalComponent implements OnInit {
  sellerProtectionInfo: { icon: string; heading: string; description: string }[];
  buyerProtectionInfo: { icon: string; heading: string; description: string }[];
  protectionInfo: { icon: string; heading: string; description: string }[];

  constructor(
    public dialogRef: MatDialogRef<PaymentProtectionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalPov: string
  ) {}

  ngOnInit() {
    const sellerTranslation = 'modals.payment-protection.seller.';
    this.sellerProtectionInfo = [
      {
        icon: '/assets/icons/item/payment.svg',
        heading: `${sellerTranslation}payment-processing`,
        description: `${sellerTranslation}payment-processing-desc`
      },
      {
        icon: '/assets/icons/payments/lock.svg',
        heading: `${sellerTranslation}money-protection`,
        description: `${sellerTranslation}money-protection-desc`
      },
      {
        icon: '/assets/icons/payments/help.svg',
        heading: `modals.payment-protection.help`,
        description: `${sellerTranslation}help-desc`
      }
    ];

    const buyerTranslation = 'modals.payment-protection.buyer.';
    this.buyerProtectionInfo = [
      {
        icon: '/assets/icons/item/payment.svg',
        heading: `${buyerTranslation}payments`,
        description: `${buyerTranslation}payments-desc`
      },
      {
        icon: '/assets/icons/item/delivery.svg',
        heading: `${buyerTranslation}safety`,
        description: `${buyerTranslation}safety-desc`
      },
      {
        icon: '/assets/icons/item/returns.svg',
        heading: `${buyerTranslation}money-back`,
        description: `${buyerTranslation}money-back-desc`
      },
      {
        icon: '/assets/icons/payments/help.svg',
        heading: `modals.payment-protection.help`,
        description: `${buyerTranslation}help-desc`
      }
    ];

    this.protectionInfo = this.modalPov === 'seller' ? this.sellerProtectionInfo : this.buyerProtectionInfo;
  }
}
