import { formatDate, relativeDate } from '@paperclip/functions/format-date';
export interface ApiTransaction {
  createdAt: string;
  type: 'startingBalance' | 'payment' | 'fee' | 'withdrawal' | 'pendingPayment';
  value: number;
  description: string;
  data?: {
    itemId: string;
    buyerUsername: string;
    expiryDate: string;
    creditCode: string;
    availableAt: string;
    sellerProtectionFee: number;
  };
}

export class Transaction {
  type: string;
  date: string;
  value: number;
  minusValue: boolean;
  description: string;
  itemId: string;
  buyerUsername: string;
  creditCode: string;
  creditCodeExpiryDate: string;
  sellerProtectionFee: number;

  constructor(transaction: ApiTransaction, locale = 'en-GB') {
    this.type = transaction.type;
    this.date = relativeDate(
      transaction.type === 'pendingPayment' ? transaction.data.availableAt : transaction.createdAt,
      locale,
      'L',
      true,
      false
    );
    this.minusValue = transaction.value < 0;
    this.value = this.minusValue ? Number(transaction.value.toString().replace('-', '')) : transaction.value;
    this.description = transaction.description;
    this.itemId = transaction.data?.itemId;
    this.buyerUsername = transaction.data?.buyerUsername;
    this.creditCode = transaction.data?.creditCode;
    this.creditCodeExpiryDate = formatDate(transaction.data?.expiryDate);
    this.sellerProtectionFee =
      transaction.type === 'payment' || transaction.type === 'pendingPayment'
        ? Number(transaction.data.sellerProtectionFee.toString().replace('-', ''))
        : null;
  }
}
