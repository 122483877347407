import { Component, Input } from '@angular/core';

@Component({
  selector: 'pc-social-icon',
  templateUrl: './social-icon.component.html'
})
export class SocialIconComponent {
  @Input() icon = 'facebook';
  @Input() socialColour = true;
}
