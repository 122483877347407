<li>
  <a [routerLink]="['/item', menuData.item?.detail?.id]">
    {{ 'buttons.view-item' | translate }}
  </a>
</li>

<li *ngIf="menuData.item?.detail?.sellerId !== authedUserId && !menuData.item?.detail?.offerMade">
  <a [routerLink]="['/make-offer']" [queryParams]="{ item: menuData.item?.detail?.id }">
    {{ 'buttons.make-offer' | translate }}
  </a>
</li>

<li *ngIf="!menuData.item?.detail?.isFavourite">
  <button class="btn-link" (click)="menuAction.emit({ action: 'save-item' })">
    {{ 'buttons.save-item' | translate }}
  </button>
</li>

<li *ngIf="menuData.item?.detail?.isFavourite">
  <button class="btn-link" (click)="menuAction.emit({ action: 'unsave-item' })">
    {{ 'buttons.unsave-item' | translate }}
  </button>
</li>

<li>
  <button class="btn-link" (click)="menuAction.emit({ action: 'share-item' })">
    {{ 'menus.item-search.share-item' | translate }}
  </button>
</li>

<li>
  <button class="btn-link" (click)="menuAction.emit({ action: 'report-item' })">
    {{ 'menus.item-search.report-item' | translate }}
  </button>
</li>

<li *ngIf="menuData.item?.detail?.sellerId !== authedUserId">
  <a [routerLink]="['/messages', menuData.item?.detail?.sellerId || '']">
    {{ 'buttons.message-seller' | translate }}
  </a>
</li>
