<div
  class="empty-state empty-state--{{ emptyState.size }}"
  [ngClass]="{ 'empty-state--responsive': emptyState.responsive }"
>
  <img
    *ngIf="emptyState.image"
    [src]="'/assets/illustrations/empty-state/' + emptyState.image + windowSizeImageModifier + '.svg'"
    alt=""
  />
  <h3>{{ 'empty-state.' + emptyState.page + '.title' | translate }}</h3>
  <p class="p-small" [innerHTML]="'empty-state.' + emptyState.page + '.message' | translate"></p>
  <button
    *ngIf="emptyState.button?.action"
    [class]="emptyState.button?.class || 'btn-primary'"
    (click)="buttonAction(emptyState.button?.action)"
    [disabled]="emptyState.button?.disabled"
  >
    {{ 'buttons.' + emptyState.button?.action | translate }}
  </button>
</div>
