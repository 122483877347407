import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GridViewService } from '@paperclip/components/views/grid-view/grid-view.service';
import { CoreService } from '@paperclip/core/core.service';
import { Item } from '@paperclip/models/item';
import { ApiItem } from '@paperclip/models/item/ApiItem';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { MessagesService } from '@paperclip/page/messages.service';

interface ItemTab {
  label: string;
  isSelected: boolean;
}

@Component({
  selector: 'pc-messages-add-item-modal',
  templateUrl: './messages-add-item-modal.component.html',
  styleUrls: ['./messages-add-item-modal.component.scss']
})
export class MessagesAddItemModalComponent implements OnInit {
  itemTabs: ItemTab[];
  selectedTab: ItemTab;
  items: Item[] = [];
  otherUserItems: Item[] = [];
  selectedItem: Item;
  dataLoading: boolean;
  loadingMore: boolean;
  loadMoreSkipValue = 0;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<MessagesAddItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: { authedUserId: string; otherUserId: string },
    private translateService: TranslateService,
    private messagesService: MessagesService,
    private gridViewService: GridViewService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.itemTabs = [
      { label: 'my-items', isSelected: true },
      { label: 'their-items', isSelected: false }
    ];
    this.selectedTab = this.itemTabs[0];
    this.getItems();
  }

  public getItems(loadMore = false) {
    if (loadMore) {
      this.loadMoreSkipValue += 30;
      this.loadingMore = true;
    } else {
      this.loadMoreSkipValue = 0;
      this.dataLoading = true;
      this.loadingMore = false;
    }

    const userId = this.selectedTab.label === 'my-items' ? this.modalData.authedUserId : this.modalData.otherUserId;
    this.gridViewService.getUserItems(userId, this.loadMoreSkipValue, 0, true).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.loadingMore = false;
        if (code === 1) {
          const items: Item[] = [];
          if (data.items.length > 0) {
            data.items.forEach((item: ApiItem) =>
              items.push(new Item(item, this.modalData.authedUserId, this.translateService.getBrowserCultureLang()))
            );
          }
          this.items = loadMore ? [...this.items, ...items] : items;
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  public selectItemTab(selectedTab: ItemTab) {
    this.selectedTab = selectedTab;
    this.itemTabs.forEach((tab: ItemTab) => (tab !== selectedTab ? (tab.isSelected = false) : (tab.isSelected = true)));
    this.selectedItem = null;
    this.getItems();
  }

  public selectItem(selectedItem: Item) {
    this.items.forEach((item: Item) => (item.isSelected = false));
    selectedItem.isSelected = true;
    this.selectedItem = this.items.filter((item: Item) => item.isSelected)[0];
  }

  public goToAddItem() {
    this.router.navigate(['/add-item']);
  }

  public addItemToMessage() {
    this.messagesService.sendMessageItem(this.selectedItem);
    this.dialogRef.close();
  }
}
