<pc-close-icon (click)="bottomSheetRef.dismiss()"></pc-close-icon>
<div class="add-item-bottomsheet">
  <h2>{{ 'add-item.bottomsheet.item-' + modalData.formControl | translate }}</h2>
  <!-- title -->
  <ng-container *ngIf="modalData.formControl === 'title'">
    <pc-item-details-item-title [addItemForm]="modalData.addItemForm" (clearItemDetail)="clearItemDetail($event)">
    </pc-item-details-item-title>
    <div class="add-item-bottomsheet__footer">
      <button class="btn-primary btn-large" (click)="bottomSheetRef.dismiss()"
        [disabled]="modalData.addItemForm.get('itemDetails').get('itemTitle').invalid">
        {{ 'buttons.done' | translate }}
      </button>
    </div>
  </ng-container>

  <!-- category -->
  <pc-item-details-item-category *ngIf="modalData.formControl === 'category'" [addItemForm]="modalData.addItemForm"
    [categoryTree]="modalData.categoryTree" (selectedCategoryTree)="updateCategoryTree($event)">
  </pc-item-details-item-category>

  <!-- condition -->
  <pc-item-details-item-condition *ngIf="modalData.formControl === 'condition'" [addItemForm]="modalData.addItemForm"
    [blockList]="true">
  </pc-item-details-item-condition>

  <!-- price -->
  <ng-container *ngIf="modalData.formControl === 'price'">
    <pc-payment-details-item-price [addItemForm]="modalData.addItemForm" [currency]="modalData.currency"
      [forceFullWidth]="true" (clearItemDetail)="clearItemDetail($event)">
    </pc-payment-details-item-price>
    <div class="add-item-bottomsheet__footer">
      <button class="btn-primary btn-large" (click)="bottomSheetRef.dismiss()"
        [disabled]="modalData.addItemForm.get('paymentDetails').get('itemPrice').invalid">
        {{ 'buttons.done' | translate }}
      </button>
    </div>
  </ng-container>

  <!-- purchase type -->
  <pc-payment-details-purchase-method *ngIf="modalData.formControl === 'purchase'"
    [addItemForm]="modalData.addItemForm"></pc-payment-details-purchase-method>

  <!-- delivery method -->
  <pc-delivery-details-delivery-method *ngIf="modalData.formControl === 'delivery'"
    [addItemForm]="modalData.addItemForm" [currency]="modalData.currency">
  </pc-delivery-details-delivery-method>

  <!-- description -->
  <pc-item-details-item-description *ngIf="modalData.formControl === 'description'"
    [addItemForm]="modalData.addItemForm"></pc-item-details-item-description>

  <!-- footer where needed -->
  <div *ngIf="
      modalData.formControl === 'purchase' ||
      modalData.formControl === 'payment' ||
      modalData.formControl === 'delivery' ||
      modalData.formControl === 'description'
    " class="add-item-bottomsheet__footer">
    <button class="btn-primary btn-large" (click)="closeBottomSheet()" [disabled]="
        modalData.formControl === 'description' &&
        modalData.addItemForm.get('itemDetails').get('itemDescription').invalid
      ">
      {{ 'buttons.done' | translate }}
    </button>
  </div>
</div>
