import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { AccountSettingsService } from '@paperclip/page/account-settings/account-settings.service';

@Component({
  selector: 'pc-remove-student-roost-modal',
  templateUrl: './remove-student-roost-modal.component.html'
})
export class RemoveStudentRoostModalComponent {
  activeRequest: boolean;
  constructor(
    public dialogRef: MatDialogRef<RemoveStudentRoostModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { studentRoostLocationId: string; complete: () => {} },
    private accountSettingsService: AccountSettingsService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService
  ) {}

  public removeStudentRoostLocation() {
    this.activeRequest = true;
    this.accountSettingsService.removeStudentRoostLocation(this.modalData.studentRoostLocationId).subscribe(
      ({ code, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.modalData.complete();
          this.confirmationMessageService.showConfirmationMessage('student-roost-removed');
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
