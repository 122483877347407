<form *ngIf="showUserProfileItemsSearch()" class="search-items-form" [formGroup]="searchItemsForm">
  <!-- search input -->
  <div class="search-input input-group input-group--btn-end">
    <input
      type="text"
      formControlName="term"
      [name]="searchInputRandomName"
      placeholder="{{ 'user-profile.item-search-placeholder' | translate }}"
      (focus)="searchItemsFormFocus = true"
      (blur)="searchItemsFormFocus = false"
      (keydown.enter)="searchItems($event, searchItemsForm.get('term').value)"
    />
    <pc-clear-input-btn
      *ngIf="searchItemsForm.get('term').value"
      class="input-group__btn input-group__btn--end"
      (click)="clearItemsSearch()"
    >
    </pc-clear-input-btn>
    <button
      type="button"
      class="search-input__search-button input-group__btn input-group__btn--end btn-no-styling"
      (click)="searchItems($event, searchItemsForm.get('term').value)"
    >
      <img src="/assets/icons/search/search-input-btn.svg" alt="" />
    </button>
  </div>

  <!-- cancel button to show only when search is active -->
  <button
    *ngIf="searchItemsForm.get('term').value"
    class="btn-large btn-stroked cancel-search-btn"
    (click)="clearItemsSearch()"
  >
    {{ 'buttons.cancel' | translate }}
  </button>
</form>

<div
  class="grid-view-new-container"
  [ngClass]="{ 'grid-view-new-container--social-inventory': gridViewConfig.dataToGet === 'profile-inactive' }"
  infiniteScroll
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="300"
  (scrolled)="getGridData(true)"
>
  <ng-container *ngIf="gridItemLink()">
    <a
      *ngFor="let item of !gridViewConfig.dataLoading ? items : gridViewConfig.loadingItems"
      class="grid-item grid-item--item"
      [ngClass]="{ 'loading-data': gridViewConfig.dataLoading }"
      [routerLink]="!gridViewConfig.dataLoading ? ['/item', item?.detail?.id] : []"
      [queryParams]="{ from: gridViewConfig.dataToGet }"
    >
      <pc-grid-view-new-item
        [gridViewConfig]="gridViewConfig"
        [item]="item"
        [isOwnProfile]="isOwnProfile"
        [windowSize]="windowSize"
      >
      </pc-grid-view-new-item>
    </a>
  </ng-container>

  <ng-container *ngIf="!gridItemLink()">
    <div
      *ngFor="let item of !gridViewConfig.dataLoading ? items : gridViewConfig.loadingItems"
      class="grid-item grid-item--item grid-item--no-link"
      [ngClass]="{ 'loading-data': gridViewConfig.dataLoading }"
    >
      <pc-grid-view-new-item
        [gridViewConfig]="gridViewConfig"
        [item]="item"
        [isOwnProfile]="isOwnProfile"
        [windowSize]="windowSize"
      >
      </pc-grid-view-new-item>
    </div>
  </ng-container>
</div>

<div *ngIf="!gridViewConfig.dataLoading && gridViewConfig.dataLoadingMore" class="loading-more-spinner"></div>
<pc-empty-state
  *ngIf="showEmptyState()"
  [page]="searchingItems ? gridViewConfig.dataToGet + '-searching' : gridViewConfig.dataToGet"
></pc-empty-state>
