<form [formGroup]="addItemForm">
  <div formGroupName="itemDetails" class="buttons-filter" [ngClass]="{ 'buttons-filter--block': blockList }">
    <button
      *ngFor="let condition of itemConditions"
      (click)="selectCondition(condition)"
      [ngClass]="{ 'btn-primary': condition.isSelected, 'btn-large btn-large--fluid': blockList }"
    >
      {{ condition.label }}
    </button>
  </div>
</form>
