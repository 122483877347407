<div class="modal search-filters-modal">
  <div class="modal-filters">
    <div class="modal-actions">
      <button class="btn-stroked" (click)="filtersModalAction('clear')">{{ 'buttons.clear' | translate }}</button>
      <pc-close-icon (click)="closeModal()"></pc-close-icon>
    </div>
    <pc-search-filters-all
      [searchState]="searchState"
      [insideFiltersModal]="true"
      [clearFilters]="clearFilters"
      [applyFilters]="applyFilters"
    >
    </pc-search-filters-all>
  </div>

  <div class="show-results">
    <button class="btn-large btn-primary" (click)="filtersModalAction('apply')">
      {{ 'modals.search-filters.show-results' | translate }}
    </button>
  </div>
</div>
