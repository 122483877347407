import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@paperclip/environments/environment';
import { MaintenanceBanner } from '@paperclip/models/misc/MaintenanceBanner';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';
import { DeepLinkData } from 'branch-sdk';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { PaperclipEvent } from '../models/PaperclipEvent';
import { AuthService } from './auth/auth.service';

interface KlaviyoEvent {
  name: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class CoreService implements OnDestroy {
  private errorMessage = new Subject<PcApiResponse>();
  public sessionId: any = uuidv4();
  private sessionIdSubscription: Subscription;
  private timeInSecondsSinceLastApiCall: number;
  authedUser: AuthedUser = this.authService.getAuthedUser();
  private paymentTransactionsEnabled = new Subject<boolean>();
  private maintenanceBanner = new Subject<MaintenanceBanner>();

  constructor(private http: HttpClient, private translateService: TranslateService, private authService: AuthService) {
    this.sessionIdTimer();
  }

  ngOnDestroy(): void {
    this.sessionIdSubscription.unsubscribe();
  }

  handleError(error: PcApiResponse) {
    this.errorMessage.next(error);
  }

  processError(): Observable<PcApiResponse> {
    return this.errorMessage.asObservable();
  }

  updatePaymentTransactionsEnabled(enabled: boolean) {
    this.paymentTransactionsEnabled.next(enabled);
  }

  getPaymentTransactionsEnabled(): Observable<boolean> {
    return this.paymentTransactionsEnabled.asObservable();
  }

  setMaintenanceBanner(banner: MaintenanceBanner) {
    this.maintenanceBanner.next(banner);
  }

  getMaintenanceBanner(): Observable<MaintenanceBanner> {
    return this.maintenanceBanner.asObservable();
  }

  public trackPaperclipEvent(event: PaperclipEvent): Observable<PcApiResponse> {
    const analyticsApi = environment.production ? 'https://api.paperclip.co/a' : 'http://localhost/a';

    event.data = {
      ...event.data,
      userToken: localStorage.getItem('userToken')
    };

    if (event.data.userToken === 'undefined') {
      event.data.userToken = '';
    }

    if (event.data.queryId) {
      event.name += '_AFTER_SEARCH';
    }

    if (!environment.production) {
      // console.log(`🚀 ~ trackPaperclipEvent\n`, event.name, `\n`, event.data);
    }
    return this.http.post(analyticsApi, event, { headers: { useApi: 'false' } });
  }

  public getSessionId(): any {
    if (this.timeInSecondsSinceLastApiCall >= 1800) {
      this.sessionId = uuidv4();
      this.sessionIdSubscription.unsubscribe();
      this.timeInSecondsSinceLastApiCall = 0;
      this.sessionIdTimer();
    }

    return this.sessionId;
  }

  private sessionIdTimer() {
    const source = interval(1000);
    this.sessionIdSubscription = source.subscribe(
      (timeInSeconds: number) => (this.timeInSecondsSinceLastApiCall = timeInSeconds)
    );
  }

  public getIp(): Observable<PcApiResponse> {
    return this.http.get('ip', { headers: { useApiVersionPrefix: 'false' } });
  }

  public getUserToken(): Observable<PcApiResponse> {
    return this.http.get('users/userToken');
  }

  public generateBranchDeepLink(branchDeepLinkOptions: DeepLinkData, includeTagline = true): Promise<string> {
    return new Promise((resolve) => {
      if (includeTagline) {
        branchDeepLinkOptions.data.$og_title += ` - ${this.translateService.instant('meta.tagline')}`;
      }
      if (
        branchDeepLinkOptions.data.$og_description === '' ||
        branchDeepLinkOptions.data.$og_description === 'items.no-description' ||
        branchDeepLinkOptions.data.$og_description === null ||
        branchDeepLinkOptions.data.$og_description === undefined
      ) {
        branchDeepLinkOptions.data.$og_description = this.translateService.instant('meta.description');
      }

      // add referralCode if necessary
      if (branchDeepLinkOptions.campaign === 'user-share' && this.authService.isLoggedIn()) {
        branchDeepLinkOptions.data.$desktop_url += `?referralCode=${this.authedUser?.detail?.referralCode}`;
      }

      window['branch'].disableTracking();
      window['branch'].link(branchDeepLinkOptions, (err: string, link: string) => {
        resolve(link);

        if (!environment.production) {
          // console.log(`🚀 ~ generateBranchDeepLink ~ branchDeepLinkOptions.data`);
          // console.table(JSON.parse(`${branchDeepLinkOptions.data}`));
          // console.log(`🚀 ~ generateBranchDeepLink`, link);
        }
        if (err) {
          console.error(`🚀 ~ openBranchUrl ~ err`, err);
        }
        resolve(link);
      });
    });
  }
}
