<form class="location-form" [ngClass]="{ 'location-form--full-width': needsFullWidth }" [formGroup]="locationForm">
  <!-- input -->
  <div class="search">
    <div class="input-group">
      <input
        #locationInput
        type="text"
        [ngClass]="{ inverted: inverted }"
        placeholder="{{ 'new-location-search.enter-manual-location' | translate }}"
        formControlName="locationName"
        value="locationForm.get('locationName').value"
        (focus)="locationInputFocus()"
        (keydown)="locationInputKeydown($event)"
      />
      <span
        *ngIf="locationForm.get('locationName').value !== null"
        class="input-group__icon"
        [inlineSVG]="'/assets/icons/misc/clear-input.svg'"
        [forceEvalStyles]="true"
        (click)="clearInput()"
      >
      </span>
    </div>

    <!-- use current location -->
    <div class="use-current-location" (click)="selectCurrentLocation()">
      <div class="left">
        <img src="assets/icons/search/current-location.svg" alt="use current location icon" />
        <p class="highlight-text">Use current location</p>
        <div *ngIf="loadingCurrentLocation" class="sk-fading-circle">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>
      </div>
      <div class="right">
        <div class="checkbox" [ngClass]="{ 'checkbox--checked': useCurrentLocation === true }">
          <input type="checkbox" id="free-item-checkbox" formControlName="useCurrentLocation" />
          <label for="free-item-checkbox"></label>
          <span
            class="checkbox__checked"
            [inlineSVG]="'/assets/icons/misc/checkbox-checked.svg'"
            [forceEvalStyles]="true"
          ></span>
        </div>
      </div>
    </div>

    <!-- results -->
    <ul
      *ngIf="locationInputHasFocus"
      class="search-suggestions"
      [ngClass]="{ 'search-suggestions--bordered': needsBorder }"
    >
      <!-- recent locations -->
      <ul *ngIf="showRecentLocations && recentLocations.length > 0" class="search-suggestions__section">
        <li class="search-suggestions__section__header">
          <p class="highlight-text">{{ 'location-search.recent-locations' | translate }}</p>
        </li>
        <li
          *ngFor="let location of recentLocations | slice: 0:3"
          class="search-suggestions__result search-suggestions__result--active"
          (mousedown)="selectLocation($event, location)"
        >
          <p>{{ location }}</p>
        </li>
      </ul>

      <!-- location suggestions -->
      <ul *ngIf="showLocationSuggestions && locationSuggestions.length > 0" class="search-suggestions__section">
        <li class="search-suggestions__section__header">
          <p class="highlight-text">{{ 'location-search.suggestions' | translate }}</p>
          <div *ngIf="locationSuggestionsLoading" class="sk-fading-circle">
            <div class="sk-circle1 sk-circle"></div>
            <div class="sk-circle2 sk-circle"></div>
            <div class="sk-circle3 sk-circle"></div>
            <div class="sk-circle4 sk-circle"></div>
            <div class="sk-circle5 sk-circle"></div>
            <div class="sk-circle6 sk-circle"></div>
            <div class="sk-circle7 sk-circle"></div>
            <div class="sk-circle8 sk-circle"></div>
            <div class="sk-circle9 sk-circle"></div>
            <div class="sk-circle10 sk-circle"></div>
            <div class="sk-circle11 sk-circle"></div>
            <div class="sk-circle12 sk-circle"></div>
          </div>
        </li>

        <li
          *ngFor="let location of locationSuggestions"
          class="search-suggestions__result"
          [ngClass]="{ 'search-suggestions__result--active': location !== 'no suggestions available' }"
          (mousedown)="selectLocation($event, location)"
        >
          <p>{{ location }}</p>
        </li>
      </ul>
    </ul>
  </div>
</form>
