import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Item } from '@paperclip/models/item';
import { ApiConversationMessage, ApiMessageSendData } from '@paperclip/models/messages/ConversationMessage';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private updateConversationsList = new Subject<boolean>();
  private updateMessagesWindow = new Subject<boolean>();
  private bottomSheetAction = new Subject<string>();
  private messageImages = new Subject<File[]>();
  private messageItem = new Subject<Item>();

  sendUpdateConversationsList(update: boolean) {
    this.updateConversationsList.next(update);
  }

  getUpdateConversationsList(): Observable<boolean> {
    return this.updateConversationsList.asObservable();
  }

  sendUpdateMessagesWindow(update: boolean) {
    this.updateMessagesWindow.next(update);
  }

  getUpdateMessagesWindow(): Observable<boolean> {
    return this.updateMessagesWindow.asObservable();
  }

  sendBottomSheetAction(action: string) {
    this.bottomSheetAction.next(action);
  }

  getBottomSheetAction(): Observable<string> {
    return this.bottomSheetAction.asObservable();
  }

  sendMessageImages(item: File[]) {
    this.messageImages.next(item);
  }

  getMessageImages(): Observable<File[]> {
    return this.messageImages.asObservable();
  }

  sendMessageItem(item: Item) {
    this.messageItem.next(item);
  }

  getMessageItem(): Observable<Item> {
    return this.messageItem.asObservable();
  }

  constructor(private http: HttpClient) {}

  getConversations(userId?: string, skip = 0, limit = 0): Observable<any> {
    return this.http.get(`conversations?userId=${userId}&skip=${skip}&limit=${limit}`);
  }

  getConversationMessages(userId: string, skip = 0): Observable<any> {
    return this.http.get(`messages?userId=${userId}&skip=${skip}`);
  }

  getCompatibleUserItems(userId: string, skip = 0): Observable<any> {
    return this.http.get(`items/compatible?userId=${userId}&skip=${skip}`);
  }

  closePaymentMessagePrompt(conversationId: string): Observable<any> {
    return this.http.post(`conversations/closeMessagePrompt`, { conversationId: conversationId });
  }

  dontShowFraudPromptsAgain(type: string, prompt: string, otherUserId: string): Observable<any> {
    return this.http.post(`messages/prompts/ignore`, { type, prompt, userId: otherUserId });
  }

  markMessagePromptAsSeen(type: string, prompt: string, otherUserId: string): Observable<any> {
    return this.http.post(`messages/prompts/seen`, { type, prompt, userId: otherUserId });
  }

  checkForMessagePrompts(otherUserId: string): Observable<any> {
    return this.http.get(`messages/prompts?userId=${otherUserId}`);
  }

  sendMessage({
    receiverId,
    message,
    attachmentType,
    attachmentImages,
    attachmentItemId,
    attachmentOfferId
  }: ApiConversationMessage): Observable<any> {
    const messageData: ApiMessageSendData = {
      receiverId: receiverId,
      message: message,
      attachmentType: attachmentType,
      'attachmentImages[0]': attachmentImages ? (attachmentImages[0] ? attachmentImages[0] : null) : null,
      'attachmentImages[1]': attachmentImages ? (attachmentImages[1] ? attachmentImages[1] : null) : null,
      'attachmentImages[2]': attachmentImages ? (attachmentImages[2] ? attachmentImages[2] : null) : null,
      'attachmentImages[3]': attachmentImages ? (attachmentImages[3] ? attachmentImages[3] : null) : null,
      attachmentItemId: attachmentItemId,
      attachmentOfferId: attachmentOfferId
    };

    const formData: FormData = new FormData();
    for (const key in messageData) {
      if (messageData[key] !== null) {
        formData.append(key, messageData[key]);
      }
    }

    // return null;
    return this.http.post(`messages`, formData);
  }

  deleteConversation(conversationId: string): Observable<any> {
    return this.http.post(`conversations/${conversationId}/delete`, null);
  }

  deleteAllConversations(): Observable<any> {
    return this.http.post(`conversations/deleteAll`, null);
  }

  markConversationAsSeen(userId: string): Observable<any> {
    return this.http.post(`conversations/seen`, { userId: userId });
  }

  markAllConversationsAsSeen(): Observable<any> {
    return this.http.post(`conversations/seenAll`, {});
  }

  getNewConversationFriend(skip: number): Observable<any> {
    return this.http.get(`conversations/eligibleUsers?skip=${skip}`);
  }
}
