import { Injectable } from '@angular/core';
import { AppConfig } from '@paperclip/core/app.config';
import { PartnerAd } from '@paperclip/models/misc/PartnerAds';

@Injectable({
  providedIn: 'root'
})
export class PartnerAdService {
  ads = AppConfig.app.ads;
  requiredAds: PartnerAd[];
  displayingAdIndex: number;

  getPartnerAdvertWithType(adType: string): PartnerAd[] {
    this.requiredAds = [];

    const matchingAds = this.ads[adType];
    // check if it exists first. It may not have been included in the ad bundle
    if (matchingAds) {
      for (const matchingAd of matchingAds) {
        const matchingPartnerAd: PartnerAd = {
          advertiserId: matchingAd['advertiserId'],
          created: null,
          id: matchingAd['id'],
          imageUrl: matchingAd['imageUrl'],
          linkUrl: matchingAd['linkUrl'],
          offerText: matchingAd['offerText'] != null ? matchingAd['offerText'] : null,
          title: matchingAd['title'] != null ? matchingAd['title'] : null,
          type: matchingAd['type'],
          updated: matchingAd['updated'] != null ? matchingAd['updated'] : null,
          urlClicksCount: matchingAd['urlClicksCount'] != null ? matchingAd['urlClicksCount'] : 0
        };

        this.requiredAds.push(matchingPartnerAd);
      }
    }

    return this.requiredAds;
  }
}
