import { formatDate } from '@paperclip/functions/format-date';
export interface ApiPaperclipCredit {
  id: string;
  valueAvailable: number;
  code: string;
  expiryDate: string;
}

export class PaperclipCredit {
  id: string;
  valueAvailable: string;
  code: string;
  expiryDate: string;
  isSelected?: boolean;
  disableSelection?: boolean;

  constructor(credit: ApiPaperclipCredit) {
    this.id = credit.id;
    this.valueAvailable =
      credit.valueAvailable % 1 === 0
        ? credit.valueAvailable.toLocaleString('en-GB') + '.00'
        : credit.valueAvailable.toLocaleString('en-GB');
    this.code = credit.code;
    this.expiryDate = formatDate(credit.expiryDate);
  }
}
