import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PcApiResponse } from '../../models/misc/pc-api-response';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsService {
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';

  constructor(private http: HttpClient, private authService: AuthService) {}

  getUserNotifications(skip = 0, limit = 0): Observable<PcApiResponse> {
    return this.http.get(`notifications/getByUser?userId=${this.authedUserId}&skip=${skip}&limit=${limit}`).pipe(
      map((response: PcApiResponse) => {
        return <PcApiResponse>response;
      })
    );
  }

  markNotificationAsSeen(notificationId: string): Observable<PcApiResponse> {
    return this.http.post(`notifications/seen?`, { notificationId: notificationId }).pipe(
      map((response: PcApiResponse) => {
        return <PcApiResponse>response;
      })
    );
  }

  markAllNotificationsAsSeen(): Observable<PcApiResponse> {
    return this.http.post(`notifications/markAsSeen?`, { userId: this.authedUserId }).pipe(
      map((response: PcApiResponse) => {
        return <PcApiResponse>response;
      })
    );
  }

  deleteAllNotifications(): Observable<PcApiResponse> {
    return this.http.post(`notifications/deleteAll?`, { currentUserId: this.authedUserId }).pipe(
      map((response: PcApiResponse) => {
        return <PcApiResponse>response;
      })
    );
  }

  getUnseenUserNotificationsCounts(): Observable<any> {
    return forkJoin([
      this.http.get(`unseenNotificationsCount?userId=${this.authedUserId}`),
      this.http.get(`messages/unseenCount`)
    ]);
  }
}
