import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { SearchState } from '@paperclip/models/search/SearchState';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-search-filters-modal',
  templateUrl: './search-filters-modal.component.html',
  styleUrls: ['./search-filters-modal.component.scss']
})
export class SearchFiltersModalComponent implements OnDestroy {
  clearFilters = false;
  applyFilters = false;
  windowResizeSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SearchFiltersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public searchState: SearchState,
    private windowResizeService: WindowResizeService
  ) {
    this.windowResizeSubscription = this.windowResizeService
      .windowWidthDidChange()
      .subscribe((updatedWidth: number) => {
        if (this.searchState) {
          const screenWidth = updatedWidth ? updatedWidth : window.innerWidth;
          const screenSize = screenWidth < 1200 ? 'mobile' : 'desktop';
          if (screenSize === 'desktop') {
            this.dialogRef.close();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.windowResizeSubscription.unsubscribe();
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public filtersModalAction(action: 'clear' | 'apply') {
    if (action === 'clear') {
      this.clearFilters = true;
    } else if (action === 'apply') {
      this.applyFilters = true;
    }
    this.dialogRef.close();
  }
}
