import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { AccountSettingsService } from '@paperclip/page/account-settings/account-settings.service';

@Component({
  selector: 'pc-social-inventory-privacy-modal',
  templateUrl: './social-inventory-privacy-modal.component.html'
})
export class SocialInventoryPrivacyModalComponent {
  userClickedNo: boolean;
  userClickedYes: boolean;

  constructor(
    public dialogRef: MatDialogRef<SocialInventoryPrivacyModalComponent>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { complete: (socialInventoryPrivacy: boolean) => {} },
    private accountSettingsService: AccountSettingsService,
    private coreService: CoreService
  ) {}

  setSocialInventoryPrivacy(socialInventoryPrivacy: boolean) {
    this.userClickedNo = socialInventoryPrivacy === true;
    this.userClickedYes = socialInventoryPrivacy === false;
    this.accountSettingsService.setSocialInventoryPrivacy(socialInventoryPrivacy).subscribe(
      ({ code, message }: PcApiResponse) => {
        this.userClickedNo = false;
        this.userClickedYes = false;
        if (code === 1) {
          this.modalData.complete(socialInventoryPrivacy);
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.userClickedNo = false;
        this.userClickedYes = false;
        this.coreService.handleError({});
      }
    );
  }
}
