import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TooltipBsComponent } from '@paperclip/bottomsheets/bottomsheets';
import { AppConfig } from '@paperclip/core/app.config';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { ModalService } from '@paperclip/modals/modal.service';
import { PaymentProtectionModalComponent } from '@paperclip/modals/payments/payments-modals';
import { ComponentUpdate } from '@paperclip/models/component-update';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { ApiTransaction, Transaction } from '@paperclip/models/wallet/Transaction';
import { Subscription } from 'rxjs';

import { WalletService } from '../../../../user/wallet/wallet.service';

@Component({
  selector: 'pc-wallet-transactions-list',
  templateUrl: './wallet-transactions-list.component.html',
  styleUrls: ['./wallet-transactions-list.component.scss']
})
export class WalletTransactionsListComponent implements OnInit, OnDestroy {
  @Input() windowSize: string;
  @Input() public pendingTransactionsModal = false;
  userCurrency: string = AppConfig.web.currency.symbol;
  transactionsList: Transaction[];
  transactionsListMaster: Transaction[];
  loadingMore: boolean;
  loadMoreSkipValue = 0;
  allLoaded: boolean;
  menuUpdateSubscription: Subscription;
  selectedFilterAction = 'all';

  constructor(
    private translateService: TranslateService,
    private walletService: WalletService,
    private coreService: CoreService,
    private modalService: ModalService,
    private updateService: UpdatesService
  ) {
    this.menuUpdateSubscription = this.updateService.getUpdateData().subscribe(({ action }: ComponentUpdate) => {
      this.selectedFilterAction = action;
      this.filterTransactions(action);
    });
  }

  ngOnInit() {
    this.getUserTransactions(this.pendingTransactionsModal);
  }

  ngOnDestroy() {
    this.menuUpdateSubscription.unsubscribe();
  }

  public getUserTransactions(getPendingTransactions: boolean, loadMore = false) {
    if (!this.allLoaded) {
      if (loadMore) {
        this.loadMoreSkipValue += 30;
        this.loadingMore = true;
      } else {
        this.loadMoreSkipValue = 0;
        this.loadingMore = false;
      }

      this.walletService.getUserTransactions(getPendingTransactions, this.loadMoreSkipValue).subscribe(
        ({ code, data, message }: PcApiResponse) => {
          this.loadingMore = false;
          if (code === 1) {
            const transactions: Transaction[] = [];
            data.forEach((transaction: ApiTransaction) =>
              transactions.push(new Transaction(transaction, this.translateService.getBrowserCultureLang()))
            );
            this.allLoaded = data.length < 30;
            this.transactionsList = loadMore ? [...this.transactionsList, ...transactions] : transactions;
            this.transactionsListMaster = this.transactionsList;

            if (this.selectedFilterAction !== 'all') {
              this.filterTransactions(this.selectedFilterAction);
            }
          } else {
            this.coreService.handleError({ code, message });
          }
        },
        () => {
          this.coreService.handleError({});
        }
      );
    }
  }

  public openSellerProtectionModal() {
    this.modalService.open(PaymentProtectionModalComponent, {
      type: 'centered',
      data: 'seller'
    });
  }

  public displayTooltipAsBottomSheet(tooltip: string) {
    if (this.windowSize !== 'desktop') {
      this.modalService.open(
        TooltipBsComponent,
        {
          needsAuth: false,
          data: tooltip
        },
        true
      );
    }
  }

  private filterTransactions(filter: string) {
    switch (filter) {
      case 'all':
        this.transactionsList = this.transactionsListMaster;
        break;

      case 'payments':
        this.transactionsList = this.transactionsListMaster.filter(
          (transaction: Transaction) => transaction.type === 'transfer'
        );
        break;

      case 'withdrawals':
        this.transactionsList = this.transactionsListMaster.filter(
          (transaction: Transaction) => transaction.type === 'pay-out'
        );
        break;

      case 'refunds':
        this.transactionsList = this.transactionsListMaster.filter(
          (transaction: Transaction) => transaction.type === 'transfer-refund'
        );
        break;

      case 'paperclip-credit':
        this.transactionsList = this.transactionsListMaster.filter(
          (transaction: Transaction) =>
            transaction.type === 'credit-code-redeemed' || transaction.type === 'credit-code-expired'
        );
        break;
    }
  }
}
