<div class="modal social-inventory-one-time-modal">
  <pc-close-icon (click)="closeModal()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.social-inventory-one-time.title' | translate }}</h1>
  </div>

  <div class="modal-body">
    <p>{{ 'modals.social-inventory-one-time.body-1' | translate }}</p>
    <img [src]="'assets/illustrations/si-onetime-modal/si-onetime-modal-' + windowSize + '.png'" alt="" />
    <p>{{ 'modals.social-inventory-one-time.body-2' | translate }}</p>
  </div>

  <div class="modal-footer multi-btn-container">
    <a class="btn btn-large" [href]="externalLinks.faq.socialInventory" target="_blank">
      {{ 'generic.learn-more' | translate }}
    </a>
    <a class="btn btn-large btn-primary" [routerLink]="['/u', authedUser.detail.username, 'inactive']">
      {{ 'modals.social-inventory-one-time.button' | translate }}
    </a>
  </div>
</div>
