import { Component, Input } from '@angular/core';

@Component({
  selector: 'pc-review-star-icon',
  templateUrl: './review-star-icon.svg',
  styleUrls: ['./review-star-icon.component.scss']
})
export class ReviewStarIconComponent {
  @Input() hover: boolean;
  @Input() selected: boolean;
}
