<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'menus.header-profile.logout' | translate }}</h1>
  </div>

  <p class="modal-body">
    {{ 'modals.sign-out.body' | translate }}
  </p>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large" (click)="dialogRef.close()">{{ 'buttons.cancel' | translate }}</button>
    <button class="btn-large btn-primary" (click)="signOut()">{{ 'menus.header-profile.logout' | translate }}</button>
  </div>
</div>
