<form [formGroup]="itemCheckout.form" class="billing-address">
  <div class="billing-address__header">
    <button
      *ngIf="
        itemCheckout?.config?.chosenDeliveryMethod === 'postage' &&
        itemCheckout.addAddressStep !== 'choose-billing-address'
      "
      class="btn-stroked"
      (click)="itemCheckout.addAddressStep = 'choose-billing-address'"
    >
      {{ 'buttons.cancel' | translate }}
    </button>
    <h2>{{ 'item-checkout.payment.billing-address.title' | translate }}</h2>
    <p>{{ 'item-checkout.payment.billing-address.billing-desc' | translate }}</p>
  </div>

  <!-- select new or same billing address -->
  <ng-container *ngIf="itemCheckout.addAddressStep === 'choose-billing-address'">
    <ul formGroupName="billingAddress" class="billing-address__options checkbox-list">
      <li (click)="setBillingOption('same-as-delivery')" class="checkbox-list__item">
        <p class="highlight-text">{{ 'item-checkout.payment.billing-address.same-as-delivery' | translate }}</p>
        <pc-checkmark-icon [checked]="itemCheckout.form.get('billingAddress').get('sameAsDelivery').value" [size]="32">
        </pc-checkmark-icon>
      </li>
      <li (click)="setBillingOption('new')" class="checkbox-list__item">
        <p class="highlight-text">{{ 'item-checkout.payment.billing-address.new-billing-address' | translate }}</p>
        <pc-checkmark-icon [checked]="newBillingAddress" [size]="32"> </pc-checkmark-icon>
      </li>
    </ul>

    <div class="checkout-form-group__form__footer">
      <button
        type="button"
        class="btn-primary"
        [ngClass]="{ 'btn-loading': itemCheckout.activeRequest }"
        (click)="goToBillingStep()"
        [disabled]="itemCheckout.form.get('paymentCard').invalid || !itemCheckout?.config?.chosenBillingOption"
      >
        {{ 'item-checkout.payment.add-payment-card.button' | translate }}
      </button>
    </div>
  </ng-container>

  <!-- find address -->
  <pc-find-payment-address
    *ngIf="itemCheckout.addAddressStep === 'find-billing-address'"
    [page]="page"
    [addressForm]="itemCheckout.form"
    addressType="billing"
  ></pc-find-payment-address>

  <!-- full address form -->
  <pc-add-payment-address
    *ngIf="itemCheckout.addAddressStep === 'billing-address-form'"
    [page]="page"
    [addressForm]="itemCheckout.form"
    [existingDeliveryAddresses]="itemCheckout.deliveryAddresses"
    [countriesList]="itemCheckout.countriesList"
    [activeRequest]="itemCheckout.activeRequest"
    addressType="billing"
  >
  </pc-add-payment-address>
</form>
