<div class="modal messages-add-images-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.messages.add-images.title' | translate }}</h1>
    <p>{{ 'image-uploader.max-4' | translate }}</p>
  </div>

  <div class="modal-body">
    <pc-image-dropzone
      [config]="{ showDropzone: windowSize === 'desktop', enableReordering: windowSize === 'desktop' }"
      (imageFilesSuccess)="imageFilesSuccess($event)"
    >
    </pc-image-dropzone>
  </div>

  <div class="modal-footer" [ngClass]="{ 'multi-btn-container': windowSize === 'desktop' }">
    <button class="btn-large" (click)="dialogRef.close()">{{ 'buttons.cancel' | translate }}</button>
    <button class="btn-primary btn-large" (click)="addImagesToMessage()" [disabled]="messageImages.length === 0">
      {{ 'modals.messages.add-images.add-images' | translate }}
    </button>
  </div>
</div>
