import { Component, Input, OnInit } from '@angular/core';
import { ItemCheckout } from '@paperclip/models/item/item-checkout';
import { PaymentAddress } from '@paperclip/models/payments/payment-address';

import { PaymentsService } from '../payments.service';

@Component({
  selector: 'pc-payment-card-billing-address',
  templateUrl: './payment-card-billing-address.component.html',
  styleUrls: [
    './payment-card-billing-address.component.scss',
    '../../../../item/item-checkout/item-checkout-form-groups.scss'
  ]
})
export class PaymentCardBillingAddressComponent implements OnInit {
  @Input() page: 'wallet' | 'checkout' = 'wallet';
  @Input() itemCheckout: ItemCheckout;
  newBillingAddress = false;

  constructor(private paymentsService: PaymentsService) {}

  ngOnInit() {
    this.itemCheckout.addAddressStep =
      this.itemCheckout?.config?.chosenDeliveryMethod === 'postage' ? 'choose-billing-address' : 'find-billing-address';
  }

  setBillingOption(option: string) {
    this.itemCheckout.config.chosenBillingOption = option;
    if (option === 'same-as-delivery') {
      this.newBillingAddress = false;
      this.itemCheckout.form.get('billingAddress').get('sameAsDelivery').patchValue(true);
    } else {
      this.newBillingAddress = true;
      this.itemCheckout.form.get('billingAddress').get('sameAsDelivery').patchValue(false);
    }
  }

  goToBillingStep() {
    if (!this.newBillingAddress) {
      const billingAddress: PaymentAddress = this.itemCheckout.form.get('deliveryAddress').value;
      this.paymentsService.paymentsActionCompleted({
        action: 'add-payment-card',
        data: { paymentCard: this.itemCheckout.form.get('paymentCard').value, billingAddress: billingAddress }
      });
    } else {
      if (this.itemCheckout.addAddressStep === 'choose-billing-address') {
        this.itemCheckout.addAddressStep = 'find-billing-address';
      } else if (this.itemCheckout.addAddressStep === 'find-billing-address') {
        this.itemCheckout.addAddressStep = 'billing-address-form';
      }
    }
  }
}
