<pc-close-icon (click)="bottomSheetRef.dismiss()"></pc-close-icon>
<div class="bottomsheet-options">
  <button class="btn-large btn-large--fit-content" (click)="messageAction('add-image')">
    {{ 'messages.add-image' | translate }}
  </button>
  <button class="btn-large btn-large--fit-content" (click)="messageAction('add-item')">
    {{ 'messages.add-item' | translate }}
  </button>
  <!-- todo: make offer from message -->
  <!-- <button class="btn-large btn-large--fit-content" (click)="messageAction('make-offer')">
    {{ 'messages.make-offer' | translate }}
  </button> -->
</div>
