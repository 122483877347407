<li>
  <a [routerLink]="['/u', authedUser.detail.username]">
    {{ 'menus.header-profile.view-profile' | translate }}
  </a>
</li>
<li>
  <a class="balance" routerLink="/wallet">
    <span>{{ 'menus.header-profile.balance' | translate }}</span>
    <span class="colour-secondary">{{ userCurrency }}{{ walletBalance?.available | number: '1.2-2' }}</span>
  </a>
</li>
<li>
  <a routerLink="/account">
    {{ 'menus.header-profile.account-settings' | translate }}
  </a>
</li>
<li>
  <button class="btn-link" (click)="menuAction.emit({ action: 'invite-friends' })">
    {{ 'menus.header-profile.invite-friends' | translate }}
  </button>
</li>
<li>
  <a target="_blank" [href]="externalLinks.company.helpCentre">
    {{ 'menus.header-info.help-centre' | translate }}
  </a>
</li>
<li>
  <button class="btn-link" (click)="menuAction.emit({ action: 'logout' })">
    {{ 'menus.header-profile.logout' | translate }}
  </button>
</li>
