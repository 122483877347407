import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@paperclip/models/AppState';
import { ConversationSummary } from '@paperclip/models/messages/ConversationSummary';
import { UserNotifications } from '@paperclip/models/user/UserNotifications';
import { forkJoin, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  appState: AppState = {
    notifications: {
      unseenNotifications: 0,
      unseenMessages: 0,
      totalUnseenCount: 0
    },
    getConversationsFromHeader: true,
    conversationsLoading: false,
    conversations: [],
    walletBalance: null,
    checkForMessagePrompts: true,
    messagePrompt: null,
    showHoverFraudPrompt: null
  };
  private notificationsStateCount = new Subject<void>();
  private checkForMessagesMobile = new Subject<void>();

  constructor(private http: HttpClient) {}

  public setNotificationsState({ unseenNotifications, unseenMessages, totalUnseenCount }: UserNotifications) {
    this.appState.notifications = {
      unseenNotifications: unseenNotifications,
      unseenMessages: unseenMessages,
      totalUnseenCount: totalUnseenCount
    };
    this.updateNotificationsStateCount();
  }

  public updateNotificationsState(property: 'notifications' | 'messages', count: number) {
    property === 'notifications'
      ? (this.appState.notifications.unseenNotifications += count)
      : (this.appState.notifications.unseenMessages += count);
    this.appState.notifications.totalUnseenCount += count;
    this.updateNotificationsStateCount();
  }

  public updateNotificationsStateCount() {
    this.notificationsStateCount.next();
  }

  public getUpdateNotificationsStateCount(): Observable<any> {
    return this.notificationsStateCount.asObservable();
  }

  public sendUpdateMessages() {
    this.checkForMessagesMobile.next();
  }

  public getUpdateMessages(): Observable<any> {
    return this.checkForMessagesMobile.asObservable();
  }

  public setConversationsState(conversations: ConversationSummary[]) {
    this.appState.conversations = conversations;
  }

  public getAppState(): AppState {
    return this.appState;
  }
}
