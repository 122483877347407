import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { clearAllBodyScrollLocks, disableBodyScroll } from '@paperclip/functions/bodyScrollLock';

@Directive({
  selector: '[pcBodyScrollLock]'
})
export class BodyScrollLockDirective implements OnInit, OnDestroy {
  @Input() pcBodyScrollLock: any;
  @Input() forceLock: boolean;
  @Input() canEnableBodyScrollLock = true;

  ngOnInit() {
    if (this.forceLock) {
      this.lockBodyScroll();
    }
  }

  ngOnDestroy() {
    clearAllBodyScrollLocks();
  }

  @HostListener('mouseover')
  @HostListener('touchstart')
  @HostListener('touchmove')
  lockBodyScroll() {
    if (this.canEnableBodyScrollLock) {
      disableBodyScroll(this.pcBodyScrollLock, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.classList.contains('smooth-scroll')) {
              return true;
            }
            el = el.parentNode;
          }
        }
      });
    }
  }

  @HostListener('mouseout')
  @HostListener('touchend')
  @HostListener('touchleave')
  unlockBodyScroll() {
    if (!this.forceLock) {
      clearAllBodyScrollLocks();
    }
  }
}
