import moment from 'moment';

import { DroppedImage } from '../image-dropzone/dropped-image';
import { ApiPaymentAccount } from '../payments/ApiPaymentAccount';
import { StudentRoostLocation } from './StudentRoostLocation';

export interface ApiAuthedUser {
  userId: string;
  firstName?: string;
  lastName?: string;
  name: string;
  email: string;
  nickname: string;
  location: {
    latitude: number;
    longitude: number;
    name: string;
  };
  created: string;
  phone: string;
  pictureUrl: string;
  favouriteCategories: number[];
  lastLoginDate: string;
  isNewAccount: boolean;
  api_token: string;
  onlyExternalAuthentication: boolean;
  verifiedByEmail: boolean;
  verifiedbyemail: boolean;
  verifiedByPhone: boolean;
  verifiedbyphone: boolean;
  waitingForVerifyEmail: boolean;
  waitingForVerifyPhone: boolean;
  myReferralCode: string;
  myReferralCodeUrl: string;
  paymentsAccount: ApiPaymentAccount;
  studentRoostLocation?: StudentRoostLocation;
  socialInventoryModalSeen: boolean;
  bio?: string;
  referralCodeUsed?: string;
  suspended?: boolean;
  userToken?: string;
}

export class AuthedUser {
  authToken?: string;
  detail?: {
    id?: string;
    userToken?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    username?: string;
    email?: string;
    bio?: string;
    picture?: string | DroppedImage;
    phone?: string;
    referralCode?: string;
    referralUrl?: string;
    isNewAccount?: boolean;
    onlyExternalAuthentication?: boolean;
    mpUserCategory?: 'PAYER' | 'OWNER' | 'UNKNOWN';
    location?: {
      latitude?: number;
      longitude?: number;
      name?: string;
    };
    favouriteCategories?: number[];
    referralCodeUsed?: string;
    accountAgeInDays?: number;
    isSuspended?: boolean;
  };
  verifications?: {
    emailVerified?: boolean;
    emailVerificationPending?: boolean;
    phoneVerified?: boolean;
    phoneVerificationPending?: boolean;
  };
  studentRoostLocation?: StudentRoostLocation;

  constructor(user: ApiAuthedUser) {
    this.authToken = user.api_token;
    this.detail = {
      id: user.userId,
      userToken: user.userToken,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.name,
      username: user.nickname,
      bio: user.bio,
      email: user.email,
      picture: user.pictureUrl,
      phone: user.phone,
      referralCode: user.myReferralCode,
      referralUrl: user.myReferralCodeUrl,
      isNewAccount: user.isNewAccount || false,
      onlyExternalAuthentication: user.onlyExternalAuthentication,
      mpUserCategory: user.paymentsAccount?.userCategory || null,
      location: {
        latitude: user.location.latitude,
        longitude: user.location.longitude,
        name: user.location.name
      },
      favouriteCategories: user.favouriteCategories,
      referralCodeUsed: user.referralCodeUsed || null,
      accountAgeInDays: accountAgeInDays(user.created),
      isSuspended: user.suspended
    };
    this.verifications = {
      emailVerified: user.verifiedByEmail || user.verifiedbyemail || false,
      emailVerificationPending: user.waitingForVerifyEmail || false,
      phoneVerified: user.verifiedbyphone || user.verifiedByPhone || !!user.phone || false,
      phoneVerificationPending: user.waitingForVerifyPhone || false
    };
    this.studentRoostLocation = user.studentRoostLocation
      ? {
          id: user.studentRoostLocation.id,
          city: user.studentRoostLocation.city,
          property: user.studentRoostLocation.property,
          fullLocationName: `${user.studentRoostLocation.city}, ${user.studentRoostLocation.property}`
        }
      : null;
  }
}

function accountAgeInDays(created: string): number {
  const dataDate = moment(created, 'YYYYMMDD');
  const currentDate = moment(new Date(), 'YYYYMMDD');
  const dateDifference = currentDate.diff(dataDate, 'days');
  return dateDifference;
}
