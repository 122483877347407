import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { Order, OrderState } from '@paperclip/models/order';

@Component({
  selector: 'pc-buying-menu',
  templateUrl: './buying-menu.component.html'
})
export class BuyingMenuComponent {
  @Input() menuData: { order: Order; showItemLink: boolean };
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();

  public showDeleteOption(): boolean {
    if (
      this.menuData.order.state === OrderState.Delivered ||
      this.menuData.order.state === OrderState.Refunded
    ) {
      return true;
    } else {
      return false;
    }
  }
}
