import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@paperclip/core/app.config';
import { DisableFilterAction } from '@paperclip/models/search/DisableFilterAction';
import { FilterItem } from '@paperclip/models/search/filter-item';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';

@Component({
  selector: 'pc-search-item-condition-filter',
  templateUrl: './search-item-condition-filter.component.html'
})
export class SearchItemConditionFilterComponent implements OnChanges {
  @Input() searchFilters: SearchFilters;
  @Input() applyFilter: boolean;
  @Input() clearFilter: boolean;
  @Input() insideFiltersModal = false;
  @Output() disableFilterButton: EventEmitter<DisableFilterAction> = new EventEmitter<DisableFilterAction>();
  itemConditions: FilterItem[] = [];
  selectedConditions: number[] = [];

  constructor(private router: Router) {}

  ngOnChanges(searchFiltersChanges: SimpleChanges): void {
    if (searchFiltersChanges) {
      if (searchFiltersChanges.searchFilters) {
        this.setItemConditions();
        this.setSelectedConditions();
      }

      if (searchFiltersChanges.applyFilter?.currentValue) {
        this.applyItemConditionFilter();
      }

      if (searchFiltersChanges.clearFilter?.currentValue) {
        this.clearItemConditionFilter();
      }
    }
  }

  private setItemConditions() {
    this.itemConditions = [];
    AppConfig.app['lists'].conditionType.forEach((condition: any) => {
      const itemCondition: FilterItem = {
        apiValue: condition.id,
        label: condition.name,
        isSelected: false
      };
      this.itemConditions.push(itemCondition);
    });
  }

  private setSelectedConditions() {
    if (this.searchFilters.conditionIds) {
      this.itemConditions.forEach((c: FilterItem) => {
        c.isSelected = this.searchFilters.conditionIds.includes(c.apiValue);
      });
    } else {
      // clear?
    }
  }

  public selectCondition(itemCondition: FilterItem) {
    this.disableFilterButton.emit({ button: 'clear', disable: false });
    this.disableFilterButton.emit({ button: 'apply', disable: false });
    itemCondition.isSelected = !itemCondition.isSelected;

    this.selectedConditions = this.itemConditions
      .filter((condition: FilterItem) => condition.isSelected)
      .map((condition: FilterItem) => condition.apiValue);

    if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
      this.setFilterOnAllComponent(this.selectedConditions);
    }
  }

  private applyItemConditionFilter() {
    this.router.navigate(['/search'], {
      queryParams: { conditionIds: this.selectedConditions },
      queryParamsHandling: 'merge'
    });
  }

  private clearItemConditionFilter() {
    this.router.navigate(['/search'], {
      queryParams: { conditionIds: null },
      queryParamsHandling: 'merge'
    });
  }

  private setFilterOnAllComponent(selectedConditions: number[]) {
    this.searchFilters.conditionIds = selectedConditions;
  }
}
