<div class="content-card offer-card"
  [ngClass]="{ 'offer-card--multiple-offers': false, 'loading-data': showLoading }">
  <ng-container *ngIf="showHeaderAndItem">
    <!-- header & menu -->
    <div class="offer-card__header">
      <h3>{{ 'trading.card.' + tradingPage + '.title' | translate }}</h3>
      <pc-menu *ngIf="windowSize !== 'desktop' && !showLoading"
        [menu]="this.menu" [menuData]="{
          order: order ? order : null,
          orderState: order?.state,
          itemId: order?.item.detail.id,
          item: order?.item,
          showItemLink: true,
          activeOrdersCount: order?.item?.detail.stats?.activeOrdersCount
        }">
      </pc-menu>
    </div>

    <!-- item -->
    <div class="offer-card__item">
      <pc-small-item [item]="order?.oldItemModel" [size]="windowSize === 'desktop' ? 'large' : 'default'" [showArrow]="true"
        [showTag]="false" metaDetail="price">
      </pc-small-item>
    </div>
  </ng-container>

  <!-- offer detail & status if only 1 offer -->
  <ng-container>
    <!-- offer detail -->
    <div class="offer-card__offer">
      <pc-order-detail [page]="tradingPage" [order]="order" [insideOrderCard]="true" [orderDetailLayout]="orderDetailLayout"
        [showLoading]="showLoading" (performOrderAction)="performOrderAction($event.action)">
      </pc-order-detail>
    </div>

    <!-- offer status - desktop only -->
    <div *ngIf="windowSize === 'desktop' && !showLoading" class="offer-card__state offer-card__state--{{ orderView.getColourPostfix() }} offer-card__state--{{
        orderView.getStatePostfix() }}" [ngClass]="{
        'offer-card__state--hide-status': false,
        'offer-card__state--disputed': order?.isDisputed(),
        'offer-card__state--payment-error': order?.lastPaymentFailed, 
        'offer-card__state--user-suspended': order?.isSuspended()
      }">
      <div class="offer-card__state__left">
        <span>
          <img src="/assets/icons/navigation/info.svg" alt="" />
          <p>{{ 'item-offers.card.status' | translate }}</p>
        </span>
        <p class="highlight-text" [innerHTML]="
            'item-offers.card.offer-state.' + tradingPage + '.' + orderView.getOrderText() | translate | safeHtml
          "></p>
      </div>

      <!-- actions -->
      <div class="offer-card__state__actions"
        [ngClass]="{ 'multi-btn-container': orderView.buttons?.length > 1 }">
        <button *ngIf="order?.isDisputable && !order?.isDisputed()"
          class="btn-white" (click)="disputeTrade()">
          {{ 'trading.card.state.disputable' | translate }}
        </button>

        <ng-container *ngFor="let button of orderView.buttons">
          <button *ngIf="button.action && !button.link" (click)="performOrderAction(button.action)"
            class="{{ button.class }}" [ngClass]="{ 'btn--disabled': button.disabled }">
            {{ 'buttons.' + button.action | translate }}
          </button>

          <a *ngIf="button.action && button.link" (click)="performOrderAction(button.action)"
            class="btn {{ button.class }}" [ngClass]="{ 'btn--disabled': button.disabled }"
            [routerLink]="getButtonLink(button.action)">
            {{ 'buttons.' + button.action | translate }}
          </a>
        </ng-container>
      </div>
    </div>
  </ng-container>
  
</div>
