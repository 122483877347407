<div
  *ngIf="notificationCount > 0 || !showCount"
  class="notification-dot notification-dot--{{ placement }} notification-dot--{{ userOS }} notification-dot--{{ size }}"
  [ngClass]="{ 'notification-dot--no-count': !showCount }"
>
  <ng-container *ngIf="showCount">
    <p *ngIf="notificationCount > 0 && notificationCount <= 9">
      {{ notificationCount }}
    </p>
    <p *ngIf="notificationCount > 9">
      {{ 'header.notifications-count+' | translate }}
    </p>
  </ng-container>
</div>
