<div class="header-notifications-messages-menu" pcClickStopPropagation>
  <div class="section-tabs">
    <button
      *ngFor="let tab of tabs"
      class="btn-no-styling p-large section-tabs__tab"
      [ngClass]="{ 'section-tabs__tab--selected': activeTab === tab }"
      (click)="changeSection(tab)"
    >
      {{ 'menus.header-notifications.' + tab | translate }}
      <pc-notification-dot
        placement="inline-right"
        [notificationCount]="
          tab === 'notifications' ? userNotificationsState.unseenNotifications : userNotificationsState.unseenMessages
        "
      >
      </pc-notification-dot>
    </button>
  </div>

  <div
    class="header-notifications-messages-menu__section"
    [ngClass]="{ 'header-notifications-messages-menu__section--messages': activeTab === 'messages' }"
  >
    <!-- messages -->
    <ng-container *ngIf="activeTab === 'messages'">
      <h3 *ngIf="conversations?.length > 0">
        {{ 'menus.header-notifications.latest-messages' | translate }}
      </h3>
      <div class="conversations-container">
        <pc-conversation
          *ngIf="conversationsLoading || conversations.length > 0"
          [conversations]="conversations"
          openConversationId=""
          [insideMenu]="true"
        ></pc-conversation>
        <div *ngIf="!conversationsLoading && conversations.length === 0" class="no-conversations">
          <h2>{{ 'messages.no-conversations-title' | translate }}</h2>
          <p>{{ 'messages.no-conversations-body' | translate }}</p>
          <button class="btn-secondary" (click)="openNewMessageModal()">
            {{ 'messages.new-message' | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <!-- notifications -->
    <pc-user-notifications
      *ngIf="activeTab === 'notifications'"
      [insideMenu]="true"
      [menuOpened]="menuOpened"
      (closeMenu)="closeMenu()"
    >
    </pc-user-notifications>

    <!-- view all -->
    <a class="btn" [routerLink]="activeTab === 'notifications' ? ['/notifications'] : ['/messages']">
      {{ 'menus.header-notifications.view-all' | translate }}
    </a>
  </div>
</div>
