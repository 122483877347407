import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-activate-payment-account-modal',
  templateUrl: './activate-payment-account-modal.component.html',
  styleUrls: ['./activate-payment-account-modal.component.scss']
})
export class ActivatePaymentAccountModalComponent implements OnInit {
  activateAccountInfo: { icon: string; heading: string; description: string }[];
  constructor(public dialogRef: MatDialogRef<ActivatePaymentAccountModalComponent>) {}

  ngOnInit(): void {
    this.activateAccountInfo = [
      {
        icon: '/assets/icons/item/payment.svg',
        heading: `modals.activate-payment-account.setup-bank-account`,
        description: `modals.activate-payment-account.setup-bank-account-desc`
      },
      {
        icon: '/assets/icons/profile/username.svg',
        heading: `modals.activate-payment-account.validate-identity`,
        description: `modals.activate-payment-account.validate-identity-desc`
      },
      {
        icon: '/assets/icons/payments/help.svg',
        heading: `modals.payment-protection.help`,
        description: `modals.activate-payment-account.help-desc`
      }
    ];
  }
}
