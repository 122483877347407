import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { PaymentCard } from '@paperclip/models/payments/payment-card';

@Component({
  selector: 'pc-wallet-payment-card-menu',
  templateUrl: './wallet-payment-card-menu.component.html'
})
export class WalletPaymentCardMenuComponent {
  @Input() menuData: { card: PaymentCard };
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
}
