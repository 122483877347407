<div class="modal following-followers-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ modalData.user.detail.username }}</h1>
  </div>
  <ul class="modal-tabs">
    <li class="modal-tabs__tab modal-tabs__tab--active">{{ 'user-profile.tab.reviews' | translate }}</li>
  </ul>

  <div class="modal-body">
    <ul
      *ngIf="userReviews.length > 0"
      class="scrollable-content user-reviews"
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="300"
      (scrolled)="loadMoreReviews()"
    >
      <li *ngFor="let review of userReviews" class="content-card review" [ngClass]="{ 'loading-data': dataLoading }">
        <div class="review-header">
          <!-- review stars -->
          <ul class="review-header__stars">
            <ng-container *ngIf="dataLoading">
              <li *ngFor="let star of [0, 1, 2, 3, 4]">
                <img src="/assets/icons/profile/review-star-empty.svg" alt="" />
              </li>
            </ng-container>
            <ng-container *ngIf="!dataLoading">
              <li *ngFor="let star of review?.reviewStars">
                <img src="/assets/icons/profile/review-star-{{ star }}.svg" alt="" />
              </li>
            </ng-container>
          </ul>

          <p class="highlight-text p-small">
            <!-- todo: need to ask backend guys to include date of trade accepted/declined
            https://paperclip.atlassian.net/browse/PP-1812
            {{ review?.item ? ('user-profile.reviews.' + review?.tradeType + '-on' | translate) : ('' | translate) }}
            {{ review?.date }}
          -->
            {{ review?.item ? ('user-profile.reviews.bought' | translate) : ('' | translate) }}{{ review?.date }}
          </p>
        </div>

        <div class="review-content">
          <p class="review-content__text">{{ review?.text }}</p>

          <div *ngIf="review?.item" class="review-content__item">
            <pc-small-item [item]="review?.item" metaDetail="price"></pc-small-item>
          </div>
        </div>

        <div class="review-footer">
          <pc-small-user [user]="review?.user"></pc-small-user>
          <button
            *ngIf="!dataLoading && modalData.ownProfile"
            class="btn-icon btn-icon--small report-review"
            (click)="reportReview(review)"
            [disabled]="dataLoading"
          >
            <img src="/assets/icons/misc/flag.svg" alt="report user" />
          </button>
        </div>
      </li>
      <div *ngIf="!dataLoading && dataLoadingMore" class="loading-more-spinner"></div>
    </ul>

    <!-- no data state -->
    <pc-empty-state
      *ngIf="!dataLoading && userReviews.length === 0"
      [page]="modalData.ownProfile ? 'user-reviews-own' : 'user-reviews'"
    >
    </pc-empty-state>
  </div>
</div>
