import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DisableFilterAction } from '@paperclip/models/search/DisableFilterAction';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

interface DistanceSliderConfig {
  value: number;
  min: number;
  max: number;
  step: number;
}

interface SearchLocationFormValue {
  useCurrentLocation: boolean;
  name: string;
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'pc-search-location-filter',
  templateUrl: './search-location-filter.component.html',
  styleUrls: ['./search-location-filter.component.scss']
})
export class SearchLocationFilterComponent implements OnChanges, OnDestroy {
  @Input() searchFilters: SearchFilters;
  @Input() applyFilter: boolean;
  @Input() clearFilter: boolean;
  @Input() insideFiltersModal = false;
  @Output() disableFilterButton: EventEmitter<DisableFilterAction> = new EventEmitter<DisableFilterAction>();
  locationForm: UntypedFormGroup = this.formBuilder.group({
    useCurrentLocation: false,
    distance: { value: 25, disabled: false },
    name: null,
    latitude: null,
    longitude: null
  });
  locationFormSubscription: Subscription;
  showSeparator: boolean;
  clearLocationSearch: boolean;
  distanceLabelTranslation: string;
  distanceSliderConfig: DistanceSliderConfig = {
    value: 25,
    min: 1,
    max: 25,
    step: 1
  };

  //
  insideSideFilters;
  insideModal;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    this.locationFormSubscription = this.locationForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((locationForm: SearchLocationFormValue) => {
        this.updateLocation(locationForm);
      });
  }

  ngOnChanges(searchFiltersChanges: SimpleChanges): void {
    if (searchFiltersChanges) {
      if (searchFiltersChanges.searchFilters) {
        this.initialiseLocationFilter();
      }

      if (searchFiltersChanges.applyFilter?.currentValue) {
        this.applyLocationFilter();
      }

      if (searchFiltersChanges.clearFilter?.currentValue) {
        this.clearLocationFilter();
      }
    }
  }

  ngOnDestroy(): void {
    this.locationFormSubscription.unsubscribe();
  }

  private initialiseLocationFilter() {
    this.locationForm.patchValue(
      {
        useCurrentLocation: this.searchFilters.useCurrentLocation || false,
        distance: this.searchFilters.distance || 25,
        name: this.searchFilters.location || null,
        latitude: this.searchFilters.latitude || null,
        longitude: this.searchFilters.longitude || null
      },
      { emitEvent: false }
    );
    this.changeDetector.detectChanges();

    this.disableFilterButtons(!this.searchFilters.location);
    this.setDistanceLabel(this.locationForm.get('distance').value);
    this.disableDistanceSlider();
  }

  public updateLocation(updatedLocation: SearchLocationFormValue) {
    this.disableFilterButtons(!updatedLocation.name);
    this.locationForm.patchValue(
      {
        useCurrentLocation: updatedLocation.useCurrentLocation,
        name: updatedLocation.name,
        latitude: updatedLocation.latitude,
        longitude: updatedLocation.longitude
      },
      { emitEvent: false }
    );

    this.disableDistanceSlider();
    if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
      this.setFilterOnAllComponent(this.locationForm);
    }
  }

  public distanceChanged({ value }: { value: number }) {
    this.locationForm.get('distance').patchValue(value, { emitEvent: false });
    this.disableFilterButtons();
    this.setDistanceLabel(value);
    if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
      this.setFilterOnAllComponent(this.locationForm);
    }
  }

  private setDistanceLabel(distance: number) {
    if (distance === 1) {
      this.distanceLabelTranslation = 'search.filters.location.distance-label.1-mile';
    } else {
      this.distanceLabelTranslation =
        distance === 25
          ? 'search.filters.location.distance-label.25+miles'
          : 'search.filters.location.distance-label.x-miles';
    }
  }

  private disableDistanceSlider() {
    setTimeout(() => {
      this.locationForm.get('name').value
        ? this.locationForm.get('distance').enable({ emitEvent: false })
        : this.locationForm.get('distance').disable({ emitEvent: false });
    }, 100);
  }

  private disableFilterButtons(disable = false) {
    this.disableFilterButton.emit({ button: 'clear', disable });
    this.disableFilterButton.emit({ button: 'apply', disable });
  }

  private applyLocationFilter() {
    this.router.navigate(['/search'], {
      queryParams: {
        useCurrentLocation: this.locationForm.get('useCurrentLocation').value,
        distance: this.locationForm.get('distance').value,
        location: this.locationForm.get('name').value,
        latitude: this.locationForm.get('latitude').value,
        longitude: this.locationForm.get('longitude').value
      },
      queryParamsHandling: 'merge'
    });
  }

  private clearLocationFilter() {
    this.router.navigate(['/search'], {
      queryParams: { useCurrentLocation: null, distance: null, location: null, latitude: null, longitude: null },
      queryParamsHandling: 'merge'
    });
  }

  private setFilterOnAllComponent(locationForm: UntypedFormGroup) {
    this.searchFilters.useCurrentLocation = locationForm.get('useCurrentLocation').value;
    this.searchFilters.distance = locationForm.get('name').value ? locationForm.get('distance').value : null;
    this.searchFilters.location = locationForm.get('name').value;
    this.searchFilters.latitude = locationForm.get('latitude').value;
    this.searchFilters.longitude = locationForm.get('longitude').value;
  }
}
