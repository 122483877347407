<div class="modal id-verification-modal" #modalDiv [pcBodyScrollLock]="modalDiv" [forceLock]="true">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h2>{{ 'modals.id-verification.title' | translate }}</h2>
    <div class="verification-options">
      <button
        *ngFor="let option of verificationOptions"
        class="btn-large"
        [ngClass]="{ 'btn-primary': selectedVerificationOption === option }"
        (click)="selectVerificationOption(option)"
      >
        {{ 'modals.id-verification.' + option | translate }}
      </button>
    </div>
    <p *ngIf="selectedVerificationOption">
      {{ 'modals.id-verification.' + selectedVerificationOption + '-subtitle' | translate }}
    </p>
  </div>

  <div class="modal-body" [ngClass]="{ 'modal-body--no-method-selected': !selectedVerificationOption }">
    <p *ngIf="!selectedVerificationOption">{{ 'modals.id-verification.select-method' | translate }}</p>
    <!-- dropzone -->
    <pc-image-dropzone
      [ngClass]="{ 'show-image-dropzone': selectedVerificationOption === 'drivers-licence' }"
      style="display: none"
      [config]="{
        showDropzone: windowSize === 'desktop',
        showPreviews: true,
        previewTemplate: 'id-verification',
        enableReordering: false,
        maxImages: 2
      }"
      (imageFilesSuccess)="imageFilesSuccess($event)"
    >
    </pc-image-dropzone>
    <pc-image-dropzone
      [ngClass]="{ 'show-image-dropzone': selectedVerificationOption === 'passport' }"
      style="display: none"
      [config]="{
        showDropzone: windowSize === 'desktop',
        showPreviews: true,
        previewTemplate: 'id-verification',
        enableReordering: false,
        maxImages: 1
      }"
      (imageFilesSuccess)="imageFilesSuccess($event)"
    >
    </pc-image-dropzone>
  </div>

  <div class="modal-footer" [ngClass]="{ 'multi-btn-container': windowSize === 'desktop' }">
    <button *ngIf="windowSize === 'desktop'" class="btn-large" (click)="dialogRef.close()">
      {{ 'buttons.cancel' | translate }}
    </button>
    <button
      class="btn-large btn-primary"
      [ngClass]="{ 'btn-loading': activeRequest }"
      (click)="saveIdVerification()"
      [disabled]="idVerification.length === 0"
    >
      {{ 'buttons.submit' | translate }}
    </button>
  </div>
</div>

<div class="tip" [ngClass]="{ 'tip--closed': closeTip }">
  <img src="assets/icons/misc/tip.svg" />
  <h2>{{ 'tips.title' | translate }}</h2>
  <p class="highlight-text">{{ 'tips.id-verification' | translate }}</p>
  <pc-clear-input-btn (click)="closeTip = true"></pc-clear-input-btn>
</div>
