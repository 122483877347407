<!-- own profile -->
<ng-container *ngIf="menuData?.ownProfile">
  <li *ngIf="!menuData?.onAccountSettings">
    <a routerLink="/account">
      {{ 'menus.header-profile.account-settings' | translate }}
    </a>
  </li>
  <li>
    <a target="_blank" [href]="externalLinks.company.helpCentre">
      {{ 'menus.header-info.help-centre' | translate }}
    </a>
  </li>
  <li>
    <a class="text-transform-none" target="_blank" href="https://paperclip.co">{{ 'footer.about' | translate }}</a>
  </li>
  <li>
    <button class="btn-link" (click)="menuAction.emit({ action: 'invite-friends' })">
      {{ 'menus.header-profile.invite-friends' | translate }}
    </button>
  </li>
  <li>
    <button class="btn-link" (click)="menuAction.emit({ action: 'logout' })">
      {{ 'menus.header-profile.logout' | translate }}
    </button>
  </li>
</ng-container>

<!-- other users profile -->
<ng-container *ngIf="!menuData?.ownProfile">
  <li>
    <a [routerLink]="menuData?.user?.detail?.id ? ['/messages', menuData?.user?.detail?.id] : []">
      {{ 'menus.user-profile.send-message' | translate }}
    </a>
  </li>
  <li>
    <button
      class="btn-link"
      (click)="
        menuData?.user?.detail?.isFollowed
          ? menuAction.emit({ action: 'unfollow-user', data: menuData?.user?.detail?.id })
          : menuAction.emit({ action: 'follow-user', data: menuData?.user?.detail?.id })
      "
    >
      {{
        menuData?.user?.detail?.isFollowed
          ? ('menus.user-profile.unfollow-user' | translate)
          : ('menus.user-profile.follow-user' | translate)
      }}
    </button>
  </li>
  <li>
    <button
      class="btn-link btn-link--red"
      (click)="menuAction.emit({ action: 'report-user', data: menuData?.user?.detail?.id })"
    >
      {{ 'menus.user-profile.report-user' | translate }}
    </button>
  </li>
</ng-container>
