<div class="modal account-suspended-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.account-suspended.title' | translate }}</h1>
  </div>

  <p class="modal-body">
    {{ 'modals.account-suspended.body' | translate }}
  </p>

  <div class="modal-footer multi-btn-container">
    <a class="btn btn-large btn-stroked" [href]="externalLinks.faq.accountSuspension" target="_blank">{{
      'generic.learn-more' | translate
    }}</a>
    <button class="btn-large btn-primary" (click)="dialogRef.close()">{{ 'buttons.okay' | translate }}</button>
  </div>
</div>
