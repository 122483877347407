import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '@paperclip/core/app.config';

@Component({
  selector: 'pc-trading-two-day-delay-modal',
  templateUrl: './trading-two-day-delay-modal.component.html'
})
export class TradingTwoDayDelayModalComponent {
  externalLinks = AppConfig.externalLinks;

  constructor(
    public dialogRef: MatDialogRef<TradingTwoDayDelayModalComponent>,
    @Inject(MAT_DIALOG_DATA) public deliveryType: string
  ) {}
}
