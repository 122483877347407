import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { MessagesService } from '@paperclip/page/messages.service';
import { UserNotificationsService } from '@paperclip/page/user-notifications/user-notifications.service';

@Component({
  selector: 'pc-delete-all-notifications-messages-modal',
  templateUrl: './delete-all-notifications-messages-modal.component.html'
})
export class DeleteAllNotificationsMessagesModalComponent {
  activeRequest: boolean;

  constructor(
    public dialogRef: MatDialogRef<DeleteAllNotificationsMessagesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public contentType: 'notifications' | 'messages',
    private confirmationMessageService: ConfirmationMessageService,
    private updateService: UpdatesService,
    private coreService: CoreService,
    private userNotificationsService: UserNotificationsService,
    private messagesService: MessagesService
  ) {}

  deleteAll() {
    this.activeRequest = true;
    switch (this.contentType) {
      case 'notifications':
        this.deleteAllNotifications();
        break;

      default:
      case 'messages':
        this.deleteAllMessages();
        break;
    }
  }

  private deleteAllNotifications() {
    this.userNotificationsService.deleteAllNotifications().subscribe(
      ({ code, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          // this.confirmationMessageService.showConfirmationMessage('deleted-all-notifications');
          this.updateService.sendUpdateData({ action: 'deleted-all-notifications' });
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  private deleteAllMessages() {
    this.messagesService.deleteAllConversations().subscribe(
      ({ code, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          // this.confirmationMessageService.showConfirmationMessage('deleted-all-notifications');
          this.updateService.sendUpdateData({ action: 'deleted-all-messages' });
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
