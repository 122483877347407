<div class="modal edit-image-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.edit-image.title' | translate }}</h1>
    <p>{{ 'modals.edit-image.body' | translate }}</p>
  </div>

  <div class="modal-body">
    <image-cropper
      [imageFile]="modalData.image"
      format="png"
      [maintainAspectRatio]="false"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>

    <div class="rotate-buttons">
      <button class="btn-medium btn-left-icon" (click)="rotateImage('left')">
        <img src="assets/icons/misc/rotate-left-icon.svg" alt="" />
        {{ 'generic.left' | translate }}
      </button>
      <button class="btn-medium btn-right-icon" (click)="rotateImage('right')">
        {{ 'generic.right' | translate }}
        <img src="assets/icons/misc/rotate-right-icon.svg" alt="" />
      </button>
    </div>
  </div>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large" (click)="dialogRef.close()">
      {{ 'buttons.cancel' | translate }}
    </button>
    <button class="btn-large btn-primary" (click)="saveCroppedImage()">
      {{ 'buttons.done' | translate }}
    </button>
  </div>
</div>
