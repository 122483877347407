import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { ItemOverviewService } from '@paperclip/page/item-overview/item-overview.service';
import { UsersService } from '@paperclip/page/users.service';

interface ReportReason {
  reason: string;
  isSelected: boolean;
  id: number;
}

@Component({
  selector: 'pc-report-x-modal',
  templateUrl: './report-x-modal.component.html'
})
export class ReportXModalComponent implements OnInit {
  activeRequest: boolean;
  reportData: { type: 'user' | 'item'; id: string; reasons: ReportReason[]; selectedReason: ReportReason };

  constructor(
    public dialogRef: MatDialogRef<ReportXModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: { type: 'user' | 'item'; id: string },
    private coreService: CoreService,
    private confirmationMsgService: ConfirmationMessageService,
    private usersService: UsersService,
    private itemOverviewService: ItemOverviewService
  ) {}

  ngOnInit() {
    const type = this.modalData.type;
    this.reportData = {
      type: type,
      id: this.modalData.id,
      reasons: type === 'user' ? this.getUserReasons() : this.getItemReasons(),
      selectedReason: null
    };
  }

  private getUserReasons(): ReportReason[] {
    return [
      {
        reason: 'inappropriate-messages',
        isSelected: false,
        id: 0
      },
      {
        reason: 'inappropriate-photos',
        isSelected: false,
        id: 1
      },
      {
        reason: 'offline-behaviour',
        isSelected: false,
        id: 2
      },
      {
        reason: 'spam',
        isSelected: false,
        id: 3
      }
    ];
  }

  private getItemReasons(): ReportReason[] {
    return [
      {
        reason: 'inappropriate-photos',
        isSelected: false,
        id: 0
      },
      {
        reason: 'spam',
        isSelected: false,
        id: 1
      },
      {
        reason: 'other',
        isSelected: false,
        id: 2
      }
    ];
  }

  public setSelectedReason(reasonId: number) {
    this.reportData.selectedReason = this.reportData.reasons.filter(
      (reason: ReportReason) => reason.id === reasonId
    )[0];
    this.reportData.reasons
      .filter((reason: ReportReason) => reason !== this.reportData.selectedReason)
      .map((reason: ReportReason) => (reason.isSelected = false));
    this.reportData.reasons
      .filter((reason: ReportReason) => reason === this.reportData.selectedReason)
      .map((reason: ReportReason) => (reason.isSelected = true));
  }

  public processReport() {
    this.activeRequest = true;

    switch (this.reportData.type) {
      case 'user':
        this.reportUser();
        break;

      case 'item':
        this.reportItem();
        break;
    }
  }

  private reportUser() {
    this.usersService.reportUser(this.reportData.id, this.reportData.selectedReason.id).subscribe(
      ({ code, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.dialogRef.close();
          this.confirmationMsgService.showConfirmationMessage('reported-user');
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  private reportItem() {
    this.itemOverviewService.reportItem(this.reportData.id, this.reportData.selectedReason.id).subscribe(
      ({ code, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.dialogRef.close();
          this.confirmationMsgService.showConfirmationMessage('reported-item');
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
