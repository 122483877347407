<li *ngIf="menuData.showItemLink">
  <a [routerLink]="['/item', menuData.order?.oldItemModel?.detail.id]">
    {{ 'buttons.view-item' | translate }}
  </a>
</li>

<li *ngIf="!menuData.showItemLink">
  <a
    [routerLink]="['/offers']"
    [queryParams]="{
      itemId: menuData.order?.oldItemModel?.detail.id,
      offerId: menuData.order.id,
      type: 'buying',
      state: menuData.order.state
    }"
  >
    {{ 'buttons.view-offer' | translate }}
  </a>
</li>

<li>
  <a [routerLink]="['/messages', menuData.order?.seller?.id]">
    {{ 'buttons.message-seller' | translate }}
  </a>
</li>

<li>
  <button class="btn-link btn-link--red" (click)="menuAction.emit({ action: 'report-seller' })">
    {{ 'menus.item-offer.report-seller' | translate }}
  </button>
</li>

<li *ngIf="showDeleteOption()">
  <button class="btn-link btn-link--red" (click)="menuAction.emit({ action: 'delete-trade' })">
    {{ 'buttons.delete' | translate }}
  </button>
</li>
