<li
  *ngIf="
    menuData?.conversation?.user.detail.username &&
    !menuData?.conversation?.user.deleted &&
    !menuData?.conversation?.user.detail.isSuspended
  "
>
  <a [routerLink]="['/u', menuData?.conversation?.user.detail.username]">
    {{ 'menus.user-conversation.view-profile' | translate }}
  </a>
</li>
<li>
  <button
    *ngIf="menuData?.conversation?.id"
    class="btn-link"
    (click)="menuAction.emit({ action: 'delete-conversation', data: menuData?.conversation?.id })"
  >
    {{ 'menus.user-conversation.delete-conversation' | translate }}
  </button>
</li>
<li>
  <button
    class="btn-link btn-link--red"
    (click)="menuAction.emit({ action: 'report-user', data: menuData?.conversation?.user.detail.username })"
  >
    {{ 'menus.user-conversation.report-user' | translate }}
  </button>
</li>
