import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';

@Component({
  selector: 'pc-search-sort-filter-bs',
  templateUrl: './search-sort-filter-bs.component.html'
})
export class SearchSortFilterBsComponent {
  constructor(
    public bottomSheetRef: MatBottomSheetRef<SearchSortFilterBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public searchFilters: SearchFilters
  ) {}
}
