import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TitleService extends TitleStrategy {
  defaultTitle = 'Paperclip: buy & sell anything';
  constructor(private title: Title) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    title === undefined
      ? this.title.setTitle(this.defaultTitle)
      : this.title.setTitle(`${title} | ${this.defaultTitle}`);
  }

  public setTitle(title: string) {
    this.title.setTitle(`${title} | ${this.defaultTitle}`);
  }
}
