import { Component, Input } from '@angular/core';

@Component({
  selector: 'pc-checkmark-icon',
  templateUrl: './checkmark-icon.svg',
  styleUrls: ['./checkmark-icon.component.scss']
})
export class CheckmarkIconComponent {
  @Input() checked = false;
  @Input() interactive = true;
  @Input() size = 24;
}
