import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@paperclip/core/app.config';
import { FlatCategory } from '@paperclip/models/item/FlatCategory';
import { ItemCategory } from '@paperclip/models/item/ItemCategory';
import { DisableFilterAction } from '@paperclip/models/search/DisableFilterAction';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';

@Component({
  selector: 'pc-search-item-category-filter',
  templateUrl: './search-item-category-filter.component.html'
})
export class SearchItemCategoryFilterComponent implements OnChanges {
  @Input() searchFilters: SearchFilters;
  @Input() applyFilter: boolean;
  @Input() clearFilter: boolean;
  @Input() insideFiltersModal = false;
  @Output() disableFilterButton: EventEmitter<DisableFilterAction> = new EventEmitter<DisableFilterAction>();
  itemCategories: ItemCategory[];
  selectedCategory: ItemCategory;
  parentCategory: ItemCategory;
  parentCategoryChildCategories: ItemCategory[];
  childCategory: ItemCategory;
  childCategoryChildCategories: ItemCategory[];
  grandChildCategory: ItemCategory;

  constructor(private router: Router) {}

  ngOnChanges(searchFiltersChanges: SimpleChanges): void {
    this.itemCategories = AppConfig.categories;
    if (searchFiltersChanges) {
      if (searchFiltersChanges.searchFilters) {
        this.setSelectedCategory();
      }

      if (searchFiltersChanges.applyFilter?.currentValue) {
        this.applyItemCategoryFilter();
      }

      if (searchFiltersChanges.clearFilter?.currentValue) {
        this.clearItemCategoryFilter();
      }
    }
  }

  private setSelectedCategory() {
    if (this.searchFilters.categoryId) {
      const matchedCategory: FlatCategory = AppConfig.flatCategories.filter(
        (category: FlatCategory) => category.id === this.searchFilters.categoryId
      )[0];

      this.selectedCategory = matchedCategory;
      if (matchedCategory.categoryLevel === 1) {
        this.parentCategory = matchedCategory.level1Category;
        this.parentCategoryChildCategories = matchedCategory.level1Category.categories;
        this.childCategory = null;
        this.childCategoryChildCategories = null;
        this.grandChildCategory = null;
      } else if (matchedCategory.categoryLevel === 2) {
        this.parentCategory = matchedCategory.level1Category;
        this.parentCategoryChildCategories = matchedCategory.level1Category.categories;
        this.childCategory = matchedCategory.level2Category;
        this.childCategoryChildCategories = matchedCategory.level2Category.categories;
        this.grandChildCategory = null;
      } else if (matchedCategory.categoryLevel === 3) {
        this.parentCategory = matchedCategory.level1Category;
        this.parentCategoryChildCategories = matchedCategory.level1Category.categories;
        this.childCategory = matchedCategory.level2Category;
        this.childCategoryChildCategories = matchedCategory.level2Category.categories;
        this.grandChildCategory = matchedCategory.level3Category;
      }
    } else {
      this.clearParentCategory();
    }
  }

  public selectCategory(itemCategory: ItemCategory) {
    this.disableFilterButton.emit({ button: 'clear', disable: false });
    this.disableFilterButton.emit({ button: 'apply', disable: false });

    if (this.selectedCategory?.id !== itemCategory.id) {
      if (!itemCategory.parentId) {
        this.parentCategory = itemCategory;
        this.parentCategoryChildCategories = itemCategory.categories;
      } else if (itemCategory.parentId && itemCategory.categories) {
        this.childCategory = itemCategory;
        this.childCategoryChildCategories = itemCategory.categories;
      } else {
        this.grandChildCategory = itemCategory;
      }

      this.selectedCategory = itemCategory;
    }

    if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
      this.setFilterOnAllComponent(this.selectedCategory);
    }
  }

  clearParentCategory() {
    this.parentCategory = null;
    this.parentCategoryChildCategories = null;
    this.childCategory = null;
    this.childCategoryChildCategories = null;
    this.grandChildCategory = null;
    this.selectedCategory = null;
    this.disableFilterButton.emit({ button: 'clear', disable: true });
    this.disableFilterButton.emit({ button: 'apply', disable: true });
    if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
      this.setFilterOnAllComponent(this.selectedCategory);
    }
  }

  clearChildCategory() {
    this.childCategory = null;
    this.childCategoryChildCategories = null;
    this.grandChildCategory = null;
    this.selectedCategory = this.parentCategory;
    if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
      this.setFilterOnAllComponent(this.selectedCategory);
    }
  }

  clearGrandChildCategory() {
    this.grandChildCategory = null;
    this.selectedCategory = this.childCategory;
    if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
      this.setFilterOnAllComponent(this.selectedCategory);
    }
  }

  private applyItemCategoryFilter() {
    this.router.navigate(['/search'], {
      queryParams: { categoryId: this.selectedCategory.id },
      queryParamsHandling: 'merge'
    });
  }

  private clearItemCategoryFilter() {
    this.router.navigate(['/search'], {
      queryParams: { categoryId: null },
      queryParamsHandling: 'merge'
    });
  }

  private setFilterOnAllComponent(selectedCategory: ItemCategory) {
    this.searchFilters.categoryId = selectedCategory?.id;
  }
}
