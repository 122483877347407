import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@paperclip/core/core.service';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { DroppedImage } from '@paperclip/models/image-dropzone/dropped-image';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { WalletService } from '@paperclip/page/wallet/wallet.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-id-verification-modal',
  templateUrl: './id-verification-modal.component.html',
  styleUrls: ['./id-verification-modal.component.scss']
})
export class IdVerificationModalComponent implements OnDestroy {
  idVerification: DroppedImage[] = [];
  activeRequest: boolean;
  windowSize: string;
  windowResizeServiceSub: Subscription;
  closeTip: boolean;
  verificationOptions = ['drivers-licence', 'passport'];
  selectedVerificationOption: string;
  @ViewChild('modalDiv', { static: true }) modalDiv: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<IdVerificationModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { userId: string; complete: () => {} },
    private walletService: WalletService,
    private coreService: CoreService,
    private windowResizeService: WindowResizeService
  ) {
    this.windowResizeServiceSub = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 1200 ? 'mobile' : 'desktop';
    });
  }

  ngOnDestroy(): void {
    this.windowResizeServiceSub.unsubscribe();
  }

  public selectVerificationOption(option: string) {
    this.selectedVerificationOption = option;
  }

  public imageFilesSuccess(imageFiles: DroppedImage[] | any[]) {
    this.idVerification = imageFiles;
  }

  public saveIdVerification() {
    const file = { file1: this.idVerification[0], file2: this.idVerification[0] };
    const formData: FormData = new FormData();
    for (const key in file) {
      if (file[key] !== null) {
        formData.append(key, file[key]);
      }
    }
    this.activeRequest = true;
    this.walletService.uploadIdVerification(this.modalData.userId, formData).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.modalData.complete();
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      (error) => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
