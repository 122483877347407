<form [formGroup]="locationForm" class="search-location-input">
  <div class="current-location" (click)="selectCurrentLocation()">
    <div class="current-location__label">
      <img src="assets/icons/search/current-location.svg" alt="" />
      <p>{{ 'new-location-search.use-current-location' | translate }}</p>
      <div *ngIf="gettingCurrentLocation" class="loading-more-spinner"></div>
    </div>
    <pc-checkmark-icon [checked]="locationForm.get('useCurrentLocation').value" [size]="32"></pc-checkmark-icon>
  </div>

  <div class="input-group input-group--btn-end">
    <input
      #locationInputRef
      formControlName="name"
      type="text"
      placeholder="{{ 'new-location-search.enter-manual-location' | translate }}"
      value="locationForm.get('name').value"
      (focus)="locationInputFocus()"
      (blur)="locationInputBlur()"
      (keydown)="locationInputKeydown($event)"
      required
    />
    <pc-clear-input-btn
      *ngIf="locationForm.get('name').value"
      class="input-group__btn input-group__btn--end"
      (click)="clearInput()"
    >
    </pc-clear-input-btn>
  </div>

  <ul *ngIf="locationInputHasFocus" class="location-results">
    <ul *ngIf="enableRecentLocations && showRecentLocations && recentLocations.length > 0">
      <div class="location-results__header">
        <h3>{{ 'location-search.recent-locations' | translate }}</h3>
      </div>
      <li
        *ngFor="let location of recentLocations | slice: 0:3"
        class="location-results__result"
        (mousedown)="selectLocation($event, location)"
      >
        <p>{{ location }}</p>
      </li>
    </ul>

    <ul *ngIf="showLocationSuggestions && locationSuggestions.length > 0" class="location-results__suggested">
      <div *ngIf="locationSuggestionsLoading" class="location-results__header">
        <div class="loading-more-spinner"></div>
      </div>
      <li
        *ngFor="let location of locationSuggestions"
        class="location-results__result"
        [ngClass]="{ 'location-results__result--none': location === 'no suggestions available' }"
        (mousedown)="selectLocation($event, location)"
      >
        <p>{{ location }}</p>
      </li>
    </ul>
  </ul>
</form>
