<button
  class="btn-no-styling clear-input-btn clear-input-btn--{{size}}"
  [ngClass]="{'clear-input-btn--white': whiteButton}"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <clipPath id="clip-path">
        <rect width="16" height="16" fill="none" />
      </clipPath>
    </defs>
    <g id="clear_icon" data-name="clear icon" clip-path="url(#clip-path)">
      <circle id="Ellipse_1272" data-name="Ellipse 1272" cx="8" cy="8" r="8" fill="#e3e0e0" />
      <g id="Group_9086" data-name="Group 9086">
        <line
          id="Line_701"
          data-name="Line 701"
          x1="6"
          y1="6"
          transform="translate(5 5)"
          fill="none"
          stroke="#444141"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_702"
          data-name="Line 702"
          y1="6"
          x2="6"
          transform="translate(5 5)"
          fill="none"
          stroke="#444141"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
</button>
