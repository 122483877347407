<!-- without router link -->
<div
  *ngIf="!link"
  class="user-profile-picture"
  [ngClass]="{ 'user-profile-picture--linked': isOnFirstLogin, 'user-profile-picture--border': border }"
  [ngStyle]="{ width: width + 'px', height: height + 'px' }"
>
  <img
    [src]="picture?.dataUrl || picture"
    class="bg-image"
    [ngStyle]="{ width: width + 'px', height: height + 'px', 'border-radius': borderRadius }"
  />
  <div
    *ngIf="pictureLoading"
    class="picture-loading"
    [ngStyle]="{ width: width + 'px', height: height + 'px', 'border-radius': borderRadius }"
  >
    <!-- todo: replace this with loading more spinner / is it needed? <div class="sk-fading-circle">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div> -->
  </div>

  <!-- add new button (for first login modal) -->
  <ng-container *ngIf="isOnFirstLogin && picture == defaultPicture">
    <div
      class="button-container"
      [ngStyle]="{ width: width + 'px', height: height + 'px', 'border-radius': borderRadius }"
    >
      <button class="btn-red">{{ 'buttons.add-image' | translate }}</button>
    </div>
  </ng-container>
</div>

<!-- with router link -->
<div
  *ngIf="link"
  class="user-profile-picture user-profile-picture--linked"
  [ngClass]="{ 'user-profile-picture--border': border }"
  [ngStyle]="{ width: width + 'px', height: height + 'px' }"
>
  <img
    [src]="picture?.dataUrl || picture"
    class="bg-image"
    [ngStyle]="{ width: width + 'px', height: height + 'px', 'border-radius': borderRadius }"
    [routerLink]="link"
  />
  <div
    *ngIf="pictureLoading"
    class="picture-loading"
    [ngStyle]="{ width: width + 'px', height: height + 'px', 'border-radius': borderRadius }"
  >
    <!-- todo: replace this with loading more spinner / is it needed? <div class="sk-fading-circle">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div> -->
  </div>
</div>
