import { Component, OnDestroy } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { environment } from '@paperclip/environments/environment';
import { ModalService } from '@paperclip/modals/modal.service';
import { AccountSuspendedModalComponent } from '@paperclip/modals/user/user-modals';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Subscription } from 'rxjs';

import { customErrors } from './custom-errors';
import { paperclipErrors } from './paperclip-errors';

@Component({
  selector: 'pc-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss']
})
export class ErrorBannerComponent implements OnDestroy {
  public error: { code: number; message: string; apiErrorMessage: string };
  private routeSub: Subscription;
  private errorSub: Subscription;

  constructor(
    private router: Router,
    private coreService: CoreService,
    private authService: AuthService,
    private modalService: ModalService,
    private confirmationMsgService: ConfirmationMessageService
  ) {
    this.routeSub = this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.error = null;
      }
    });

    this.errorSub = this.coreService.processError().subscribe(({ code, customCode, message }: PcApiResponse) => {
      code = customCode ? customCode : code === undefined ? 999 : code;
      const errorMessage: string = customCode
        ? customErrors.find((error: { code: number }) => error.code === customCode)?.message || 'GENERAL_ERROR'
        : paperclipErrors.find((error: { code: number }) => error.code === code)?.message || 'GENERAL_ERROR';

      if (errorMessage === 'ACCOUNT_AUTOMATICALLY_SUSPENDED' || errorMessage === 'ACCOUNT_SUSPENDED') {
        this.handleSuspendedUser(errorMessage);
      } else {
        this.error = { code, message: errorMessage, apiErrorMessage: message };

        // log user out if any of these codes
        switch (code) {
          case -2:
          case -3:
          case -4:
          case -5:
            this.error = null;
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.authService.logout(this.authService.getAuthedUser().detail.id).subscribe(() => {});
            break;
        }
      }

      if (!environment.production) {
        console.error(this.error);
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
    this.errorSub.unsubscribe();
  }

  private handleSuspendedUser(errorMessage: string) {
    this.authService.setUserSuspended(true);
    if (errorMessage === 'ACCOUNT_AUTOMATICALLY_SUSPENDED') {
      this.modalService.open(AccountSuspendedModalComponent);
    }

    if (errorMessage === 'ACCOUNT_SUSPENDED') {
      this.confirmationMsgService.showConfirmationMessage('user-suspended');
    }
  }
}
