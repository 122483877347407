import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { Subscription } from 'rxjs';

import { emptyStates } from './empty-states';
import { EmptyState } from './EmptyState';

@Component({
  selector: 'pc-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnChanges, OnInit, OnDestroy {
  @Input() page = '404';
  @Input() buttonActionData: any;
  @Output() outputAction: EventEmitter<any> = new EventEmitter<any>();
  emptyState: EmptyState;
  windowResizeServiceSubscription: Subscription;
  windowSize: 'desktop' | 'tablet' | 'mobile' = 'desktop';
  windowSizeImageModifier = '';

  constructor(private router: Router, private windowResizeService: WindowResizeService) {
    this.windowResizeServiceSubscription = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 750 ? 'mobile' : updatedWidth < 1200 ? 'tablet' : 'desktop';
      if (this.emptyState?.responsive) {
        this.setEmptyState();
      }
    });
  }

  ngOnInit() {
    this.setEmptyState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.page.firstChange) {
      this.setEmptyState();
    }
  }

  ngOnDestroy(): void {
    this.windowResizeServiceSubscription.unsubscribe();
  }

  private setEmptyState() {
    this.emptyState = emptyStates.find((state: EmptyState) => state.page === this.page) || emptyStates[0];
    if (this.emptyState?.responsive) {
      this.windowSizeImageModifier = this.windowSize === 'desktop' ? '' : `-${this.windowSize}`;
    }
  }

  public buttonAction(action: string) {
    switch (action) {
      case 'add-item':
        this.router.navigateByUrl('/add-item');
        break;

      case 'request-join':
        this.outputAction.emit();
        break;

      case 'go-home':
      case 'browse-items':
      default:
        this.router.navigateByUrl('/');
        break;
    }
  }
}
