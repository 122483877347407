<div class="modal messaging-fraud-prevention">
  <pc-close-icon (click)="closeModal()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.messages.fraud-prevention.title' | translate }}</h1>
  </div>

  <p class="modal-body" [innerHtml]="'modals.messages.fraud-prevention.body' | translate"></p>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large btn-large--fit-content btn-primary" (click)="closeModal()">
      {{ 'buttons.okay' | translate }}
    </button>
    <button class="btn-link" (click)="dontShowAgain()">
      {{ 'modals.messages.fraud-prevention.button' | translate }}
    </button>
  </div>
</div>
