import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { forkJoin, Observable, Subject } from 'rxjs';

import { AppData } from './models/AppData';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  appData: AppData = this.authService.getAppData();
  userLatitude = this.appData?.currentLocation?.latitude || '';
  userLongitude = this.appData?.currentLocation?.longitude || '';
  private preventBackgroundScrollingState = new Subject<boolean>();
  private adminSettingsMenuMethodClickedState = new Subject<string>();

  constructor(private router: Router, private http: HttpClient, private authService: AuthService) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl || '';
  }

  updatePreventBackgroundScrollingState(preventBackgroundScrollingState: boolean) {
    this.preventBackgroundScrollingState.next(preventBackgroundScrollingState);
  }

  needsPreventBackgroundScrollingStateChange(): Observable<boolean> {
    return this.preventBackgroundScrollingState.asObservable();
  }

  adminSettingsMenuMethodClicked(methodToCall: string) {
    this.adminSettingsMenuMethodClickedState.next(methodToCall);
  }

  needsTriggerApiCall(): Observable<string> {
    return this.adminSettingsMenuMethodClickedState.asObservable();
  }

  /*====================
  stuff for footer dynamic links
  ====================*/
  getFooterDynamicData(): Observable<any> {
    return forkJoin([this.http.get(`feed/items/featured?skip=0&limit=4`), this.http.get(`categories/popular?limit=5`)]);
  }

  getPopularItems(): Observable<any> {
    return this.http.get(`items/popular?skip=0&limit=5&latitude=${this.userLatitude}&longitude=${this.userLongitude}`);
  }
}
