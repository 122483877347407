import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class GridViewNewService {
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';
  private siOnetimeTooltip = new Subject<any>();
  sendShowSiOnetimeTooltip(show: boolean) {
    this.siOnetimeTooltip.next(show);
  }

  getShowSiOnetimeTooltip(): Observable<boolean> {
    return this.siOnetimeTooltip.asObservable();
  }

  constructor(private http: HttpClient, private authService: AuthService) {}

  public getDynamicDataSection(query: string, queryParams: any, limit: number, skip = 0): Observable<any> {
    const queryString = Object.keys(queryParams)
      .map((key) => key + '=' + queryParams[key])
      .join('&');
    return this.http.get(`${query.substring(1)}?${queryString}?skip=${skip}&limit=${limit}`);
  }

  getUserProfileItems(profilePage: string, userId: string, skip = 0, term: string = null, limit = 0): Observable<any> {
    let apiEndPoint = '';
    switch (profilePage) {
      case 'profile-sold':
        apiEndPoint = 'items/sold';
        break;

      case 'profile-favourites':
        apiEndPoint = 'favourite';
        break;

      case 'profile-items':
      default:
        apiEndPoint = 'items/getByUserId';
        break;
    }

    return this.http.get(
      `${apiEndPoint}?userId=${userId}&currentUserId=${this.authedUserId}&skip=${skip}&limit=${limit}&term=${
        term ? term : ''
      }`
    );
  }

  getUsersSocialInventory(userId: string, offset = 0, term: string = null, limit = 0): Observable<any> {
    return this.http.get(
      `algolia/socialInventory?userId=${userId}&offset=${offset}&limit=${limit}&term=${term ? term : ''}`
    );
  }
}
