<div class="sr-location-filter" pcClickStopPropagation>
  <p>{{ 'search.filters.sr-location.description' | translate }}</p>

  <div class="sr-location-options">
    <div *ngFor="let option of srLocationOptions" class="sr-location-options__option" (click)="selectSROption(option)">
      <p class="highlight-text">{{ 'search.filters.sr-location.' + option.option | translate }}</p>
      <pc-checkmark-icon [checked]="option.isSelected" [size]="32"></pc-checkmark-icon>
    </div>
  </div>
</div>
