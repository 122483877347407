import * as formatDate from './format-date';
import { getCategoryFromOldCategoryId } from './modify-category';

/*====================
functions for modifying
item data
====================*/
/*-- call all modify methods --*/
export function modifyItemsData(items: any, decimalPipe?: any, loggedInUserId?: string) {
  setItemCategories(items);
  setItemPrices(items, decimalPipe);
  setItemDescriptions(items);
  setItemOwnItemTag(items, loggedInUserId);
}

/*-- convert category id's to category --*/
export function setItemCategories(items: any, isItemOverview = false) {
  // console.log(`🚀 ~ setItemCategories`);
  for (let i = 0; i < items.length; i++) {
    if (isItemOverview) {
      if (items[i].category === null) {
        items[i].category = getCategoryFromOldCategoryId(items[i].categoryId);
      }
    }
  }
}

export function setItemOwnItemTag(items: any, loggedInUserId: string) {
  if (loggedInUserId) {
    items.forEach((item) => {
      if (item.userId === loggedInUserId) {
        item.isOwnItem = true;
      }
    });
  }
}

/*-- set item prices with correct currency --*/
export function setItemPrices(items: any, decimalPipe: any, singleItem?: boolean) {
  if (singleItem) {
    items.price = decimalPipe.transform(items.price, '1.2-2');
  } else {
    for (let i = 0; i < items.length; i++) {
      items[i].price = decimalPipe.transform(items[i].price, '1.2-2');
    }
  }
}

/*-- set item descriptions if null --*/
export function setItemDescriptions(items: any, singleItem?: boolean) {
  if (singleItem) {
    if (items.description === null || items.description === '') {
      items.description = 'items.no-description';
    }
  } else {
    for (let i = 0; i < items.length; i++) {
      // or add currency symbol
      if (items[i].description === null || items[i].description === '') {
        items[i].description = 'items.no-description';
      }
    }
  }
}
