<div class="modal">
  <pc-close-icon (click)="closedWithoutComplete()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.payment-account.' + modalData.pov + '.title' | translate }}</h1>
    <p
      *ngIf="modalData.pov === 'seller'"
      class="highlight-text kyc-required"
      [innerHTML]="'modals.payment-account.seller.kyc-required' | translate: { faqLink: externalLinks.faq.wallet }"
    ></p>
    <p>{{ 'modals.payment-account.' + modalData.pov + '.body' | translate }}</p>
  </div>

  <div class="modal-body">
    <pc-payment-account-form
      [nationalitiesList]="nationalitiesList"
      [countriesList]="countriesList"
      (paymentAccountFormValue)="setPaymentAccountForm($event)"
      [ngClass]="{ 'loading-data': !countriesList }"
    >
    </pc-payment-account-form>
  </div>

  <div class="modal-footer multi-btn-container">
    <button
      class="btn-large btn-primary"
      (click)="savePaymentInformation(paymentAccountForm)"
      [ngClass]="{ 'btn-loading': activeRequest }"
      [disabled]="!paymentAccountFormValid"
    >
      {{ 'buttons.save' | translate }}
    </button>
  </div>
</div>
