<div class="modal verify-user-info-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'auth-onboarding.verify-info.' + verifyInfoType + '-title' | translate }}</h1>
    <p>{{ 'modals.verify-user-info.' + verifyInfoType + '-text' | translate: { signupMethod: '' } }}</p>
  </div>

  <div class="modal-body">
    <form [formGroup]="userInfoForm" class="onboarding-verify-info__form">
      <div *ngIf="verifyInfoType.includes('email')" class="input-group input-group--icon-start input-group--label-end">
        <img class="input-group__icon" src="/assets/icons/modals/auth/email.svg" alt="" />
        <input
          formControlName="emailAddress"
          type="email"
          placeholder="{{ 'auth-signup.normal.email-address' | translate }}"
          [ngClass]="{
            'input-valid': userInfoForm.get('emailAddress').valid && userInfoForm.get('emailAddress').dirty,
            'input-invalid': userInfoForm.get('emailAddress').invalid && userInfoForm.get('emailAddress').dirty
          }"
          required
        />
        <div *ngIf="userInfoForm.get('emailAddress').dirty" class="input-group__label input-group__label--end">
          <img *ngIf="userInfoForm.get('emailAddress').valid" src="/assets/icons/forms/input-valid.svg" />
          <img *ngIf="userInfoForm.get('emailAddress').invalid" src="/assets/icons/forms/input-invalid.svg" />
        </div>
      </div>

      <div
        *ngIf="userInfoForm.get('emailAddress').dirty && userInfoForm.get('emailAddress').invalid"
        class="validation-error-message"
      >
        <p *ngIf="userInfoForm.get('emailAddress').invalid">
          {{ 'validation-error.email-invalid' | translate }}
        </p>
      </div>

      <div *ngIf="verifyInfoType.includes('name')">
        <div class="input-group input-group--icon-start input-group--btn-end">
          <img class="input-group__icon" src="/assets/icons/navigation/profile.svg" alt="" />
          <input
            [ngClass]="{
              'input-valid': userInfoForm.get('firstName').valid && userInfoForm.get('firstName').dirty,
              'input-invalid': userInfoForm.get('firstName').invalid && userInfoForm.get('firstName').dirty
            }"
            formControlName="firstName"
            type="text"
            placeholder="{{ 'account-settings.placeholders.first-name' | translate }}"
            required
          />
          <pc-clear-input-btn
            *ngIf="!!userInfoForm.get('firstName').value"
            class="input-group__btn input-group__btn--end"
            (click)="userInfoForm.get('firstName').patchValue('')"
          >
          </pc-clear-input-btn>
        </div>

        <div
          *ngIf="userInfoForm.get('firstName').dirty && userInfoForm.get('firstName').invalid"
          class="validation-error-message"
        >
          <p *ngIf="userInfoForm.get('firstName').hasError('required')">
            {{ 'validation-error.first-name-empty' | translate }}
          </p>
        </div>

        <div class="input-group input-group--icon-start input-group--btn-end">
          <img class="input-group__icon" src="/assets/icons/navigation/profile.svg" alt="" />
          <input
            [ngClass]="{
              'input-valid': userInfoForm.get('lastName').valid && userInfoForm.get('lastName').dirty,
              'input-invalid': userInfoForm.get('lastName').invalid && userInfoForm.get('lastName').dirty
            }"
            formControlName="lastName"
            type="text"
            placeholder="{{ 'account-settings.placeholders.last-name' | translate }}"
            required
          />
          <pc-clear-input-btn
            *ngIf="!!userInfoForm.get('lastName').value"
            class="input-group__btn input-group__btn--end"
            (click)="userInfoForm.get('lastName').patchValue('')"
          >
          </pc-clear-input-btn>
        </div>

        <div
          *ngIf="userInfoForm.get('lastName').dirty && userInfoForm.get('lastName').invalid"
          class="validation-error-message"
        >
          <p *ngIf="userInfoForm.get('lastName').hasError('required')">
            {{ 'validation-error.last-name-empty' | translate }}
          </p>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer multi-btn-container">
    <button
      class="btn-large btn-primary"
      (click)="saveUserInfo()"
      [ngClass]="{ 'btn-loading': activeRequest }"
      [disabled]="disableSaveButton()"
    >
      {{ 'buttons.save' | translate }}
    </button>
  </div>
</div>
