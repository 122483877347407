<li *ngIf="menuData.showItemLink">
  <a [routerLink]="['/item', menuData.order?.oldItemModel?.detail.id]">
    {{ 'buttons.view-item' | translate }}
  </a>
</li>

<li>
  <a
    [routerLink]="['/offers']"
    [queryParams]="{
      itemId: menuData.order?.oldItemModel?.detail.id,
      offerId: menuData.order.id,
      type: 'selling',
      state: menuData.order.state
    }"
  >
    {{ 'buttons.view-offer' | translate }}
  </a>
</li>

<li>
  <a [routerLink]="['/messages', menuData.order?.buyer?.id]">
    {{ 'buttons.message-buyer' | translate }}
  </a>
</li>

<li
  *ngIf="canSellSimilar()"
>
  <a [routerLink]="['/sell-similar', menuData.order?.oldItemModel?.detail.id]">
    {{ 'item-overview.sell-similar' | translate }}
  </a>
</li>

<li >
  <button class="btn-link btn-link--red" (click)="menuAction.emit({ action: 'report-buyer' })">
    {{ 'menus.item-offer.report-buyer' | translate }}
  </button>
</li>

<li
  *ngIf="canDeleteTrade()"
>
  <button class="btn-link btn-link--red" (click)="menuAction.emit({ action: 'delete-trade' })">
    {{ 'buttons.delete' | translate }}
  </button>
</li>
