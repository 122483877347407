import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { ComponentUpdate } from '@paperclip/models/component-update';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { ApiUser, User } from '@paperclip/models/user';
import { Subscription } from 'rxjs';

import { GridViewService } from '../grid-view/grid-view.service';

@Component({
  selector: 'pc-user-list-view',
  templateUrl: './user-list-view.component.html',
  styleUrls: ['./user-list-view.component.scss']
})
export class UserListViewComponent implements OnChanges, OnDestroy {
  /*====================
  USER LIST VIEW INPUTS
  usersToGet: what users list to get
  showFollowingTag: whether to show the following tag or not, true by default
  listData: gives us any data required for API calls
  ownProfile: is this list on the users own profile? false by default
  ====================*/
  @Input() usersToGet: string;
  @Input() showFollowingTag = true;
  @Input() listData: any;
  @Input() ownProfile = false;
  @Input() needsTwoColumns = false;
  @Input() showVieWMoreButton = false;
  @Input() menu = 'user-list-menu';
  @Input() insideTabbedModal = false;

  /*====================
  USERS & LOADING
  ====================*/
  usersList: User[] = [];
  listUsers: any = [];
  dataLoading: boolean;
  dataLoadingMore: boolean;
  dataLoadingMoreSkip = 0;
  dataAllLoaded: boolean;
  manuallyLoadingMore: boolean;

  /*====================
  MISCELLANEOUS
  ====================*/
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';
  windowSize = window.innerWidth < 1200 ? 'mobile' : 'desktop';

  /*====================
  SUBSCRIPTIONS
  ====================*/
  windowWidthResizeSubscription: Subscription;
  updateServiceSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private gridViewService: GridViewService,
    private updateService: UpdatesService,
    private windowResizeService: WindowResizeService,
    private coreService: CoreService
  ) {
    // check for updates from other components
    this.updateServiceSubscription = this.updateService.getUpdateData().subscribe((updateData: ComponentUpdate) => {
      if (updateData.updateKeyValue) {
        if (updateData.action === 'follow-user' || updateData.action === 'unfollow-user') {
          if (
            this.ownProfile &&
            this.insideTabbedModal &&
            updateData.action === 'unfollow-user' &&
            this.usersToGet === 'user-following'
          ) {
            this.usersList = this.usersList.filter((user: User) => user.detail.id !== updateData.updateId);
          } else {
            this.usersList.forEach((user: User) => {
              if (updateData.updateId === user.detail.id) {
                user.detail.isFollowed = updateData.action === 'follow-user' ? true : false;
              }
            });
          }
        }
      }

      if (updateData.updateAll) {
        this.getUsers(this.listData, this.usersToGet, false, false);
      }
    });

    // window width resize
    this.windowWidthResizeSubscription = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 1200 ? 'mobile' : 'desktop';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.listData && changes.listData.currentValue.userProfileId !== undefined) {
      this.getUsers(this.listData, this.usersToGet, false);
    }
  }

  ngOnDestroy() {
    this.windowWidthResizeSubscription.unsubscribe();
    this.updateServiceSubscription.unsubscribe();
  }

  getUsers(listData: any, usersToGet: string, loadMore = false, showLoading = true) {
    this.dataLoading = loadMore ? false : showLoading;

    // load more controls
    if (loadMore) {
      this.dataLoadingMoreSkip += 30;
      this.dataLoadingMore = true;
    } else {
      this.dataLoadingMoreSkip = 0;
      this.dataLoading = showLoading;
      this.dataLoadingMore = false;
    }

    // if data is all loaded turn off loading more
    if (this.dataAllLoaded) {
      this.dataLoadingMore = false;
    }

    // load users
    switch (usersToGet) {
      case 'user-following':
        if (listData.userProfileId && listData.userProfileId.length !== 0) {
          this.getUserFollowing(loadMore, listData);
        }
        break;

      case 'user-followers':
        if (listData.userProfileId && listData.userProfileId.length !== 0) {
          this.getUserFollowers(loadMore, listData);
        }
        break;

      default:
        if (listData.userProfileId && listData.userProfileId.length !== 0) {
          this.getUserFollowing(loadMore, listData);
        }
        break;
    }
  }

  loadMoreItems() {
    // don't load more on the feed or if all data has been loaded
    if (!this.dataAllLoaded && !this.showVieWMoreButton) {
      this.getUsers(this.listData, this.usersToGet, true, false);
    }
  }

  manuallyLoadMore() {
    this.manuallyLoadingMore = true;
    this.getUsers(this.listData, this.usersToGet, true, false);
  }

  getUserFollowing(loadMore: boolean, { userProfileId }) {
    // check if own profile for no data state
    this.showFollowingTag = !this.ownProfile;

    this.gridViewService.getUsersFollowing(userProfileId, this.dataLoadingMoreSkip).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.manuallyLoadingMore = false;
        this.dataLoadingMore = false;
        if (code === 1) {
          this.modelAndSetUserData(data, loadMore);
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  getUserFollowers(loadMore: boolean, { userProfileId }) {
    this.gridViewService.getUsersFollowers(userProfileId, this.dataLoadingMoreSkip).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.manuallyLoadingMore = false;
        this.dataLoadingMore = false;
        if (code === 1) {
          this.modelAndSetUserData(data, loadMore);
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  modelAndSetUserData(data: ApiUser[], loadMore: boolean) {
    const users: User[] = [];
    data.forEach((user: ApiUser) => {
      users.push(new User(user));
    });

    if (loadMore) {
      // eslint-disable-next-line prefer-spread
      this.usersList.push.apply(this.usersList, users);
      // check if all items have been loaded
      this.dataAllLoaded = users.length < 30;
    } else {
      this.usersList = users;
      // check if all items have been loaded
      this.dataAllLoaded = users.length < 30;
    }
  }

  public emptyStatePage(): string {
    if (this.usersToGet === 'user-following' || this.usersToGet === 'user-followers') {
      return this.ownProfile ? `${this.usersToGet}-own` : this.usersToGet;
    }

    return this.usersToGet;
  }
}
