import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { CoreService } from '../core.service';

@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {
  constructor(private coreService: CoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jsonReq: HttpRequest<any> = req.clone({
      setHeaders: {
        Accept: 'application/json',
        'Pap-Source': 'Web',
        'Pap-ver': `3.2.0`,
        'Pap-sid': this.coreService.getSessionId()
      }
    });

    return next.handle(jsonReq);
  }
}
