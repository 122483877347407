import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'pc-tooltip-bs',
  templateUrl: './tooltip-bs.component.html',
  styles: [
    `
      div {
        margin-top: 24px !important;
      }

      h2 {
        margin-bottom: 24px;
      }
    `
  ]
})
export class TooltipBsComponent {
  constructor(
    public bottomSheetRef: MatBottomSheetRef<TooltipBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public tooltip: string
  ) {}
}
