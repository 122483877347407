<form [formGroup]="addressForm" class="add-address add-address--{{ page }}">
  <div [formGroupName]="addressType === 'delivery' ? 'deliveryAddress' : 'billingAddress'">
    <!-- full name -->
    <div class="form-row">
      <div class="form-group">
        <label for="full-name">{{
          'item-checkout.delivery.add-delivery-address.address-form.full-name' | translate
        }}</label>
        <input
          id="full-name"
          formControlName="fullName"
          type="text"
          placeholder="{{
            'item-checkout.delivery.add-delivery-address.address-form.full-name-placeholder' | translate
          }}"
          required
        />
      </div>
    </div>

    <!-- address line 1 -->
    <div class="form-row">
      <div class="form-group">
        <label for="address-line-1">{{
          'item-checkout.delivery.add-delivery-address.address-form.address-line-1' | translate
        }}</label>
        <input
          id="address-line-1"
          formControlName="addressLine1"
          type="text"
          placeholder="{{
            'item-checkout.delivery.add-delivery-address.address-form.address-line-1-placeholder' | translate
          }}"
          required
        />
      </div>
    </div>

    <!-- address line 2 -->
    <div class="form-row">
      <div class="form-group">
        <div class="form-group__labels">
          <label for="address-line-2">{{
            'item-checkout.delivery.add-delivery-address.address-form.address-line-2' | translate
          }}</label>
          <p>{{ 'generic.optional' | translate }}</p>
        </div>
        <input
          id="address-line-2"
          formControlName="addressLine2"
          type="text"
          placeholder="{{
            'item-checkout.delivery.add-delivery-address.address-form.address-line-2-placeholder' | translate
          }}"
        />
      </div>
    </div>

    <!-- city -->
    <div class="form-row">
      <div class="form-group">
        <label for="city">{{ 'item-checkout.delivery.add-delivery-address.address-form.city' | translate }}</label>
        <input
          id="city"
          formControlName="city"
          type="text"
          placeholder="{{ 'item-checkout.delivery.add-delivery-address.address-form.city-placeholder' | translate }}"
          required
        />
      </div>
    </div>

    <!-- county & postCode -->
    <div class="form-row form-row--group">
      <div class="form-group">
        <label for="county">{{ 'item-checkout.delivery.add-delivery-address.address-form.county' | translate }}</label>
        <input
          id="county"
          formControlName="county"
          type="text"
          placeholder="{{ 'item-checkout.delivery.add-delivery-address.address-form.county-placeholder' | translate }}"
          required
        />
      </div>

      <div class="form-group postcode">
        <label for="postCode">{{
          'item-checkout.delivery.add-delivery-address.address-form.post-code' | translate
        }}</label>
        <input
          id="postCode"
          formControlName="postCode"
          type="text"
          placeholder="{{
            'item-checkout.delivery.add-delivery-address.address-form.post-code-placeholder' | translate
          }}"
          required
        />
      </div>
    </div>

    <!-- country -->
    <div [formGroup]="countryFormGroup" class="form-row">
      <div class="form-group">
        <label for="country">{{
          'item-checkout.delivery.add-delivery-address.address-form.country' | translate
        }}</label>
        <span class="custom-select">
          <select id="country" formControlName="country" (change)="changeCountry()" required>
            <option *ngFor="let country of countriesList" [ngValue]="country">{{ country.name }}</option>
          </select>
        </span>
      </div>
    </div>

    <!-- isDefault -->
    <div *ngIf="showSetAsDefaultOption()" class="form-row">
      <div class="form-group form-group--checkbox" (click)="setAsDefaultAddress()">
        <pc-checkmark-icon
          [checked]="
            addressForm.get('deliveryAddress').get('isDefault').value || existingDeliveryAddresses?.length === 0
          "
          [size]="24"
        >
        </pc-checkmark-icon>
        <label for="isDefault">{{
          'item-checkout.delivery.add-delivery-address.address-form.set-default-address' | translate
        }}</label>
      </div>
    </div>

    <div class="checkout-form-group__form__footer">
      <button
        type="button"
        class="btn-primary"
        [ngClass]="{ 'btn-loading': activeRequest }"
        (click)="addAddress()"
        [disabled]="isAddressFormInvalid()"
      >
        {{ getFooterBtnLabel() | translate }}
      </button>
    </div>
  </div>
</form>
