import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemOverviewConfig } from '@paperclip/page/item-overview/ItemOverviewConfig';

@Component({
  selector: 'pc-item-overview-action-buttons',
  templateUrl: './item-overview-action-buttons.component.html',
  styleUrls: ['./item-overview-action-buttons.component.scss']
})
export class ItemOverviewActionButtonsComponent implements OnInit {
  @Input() itemOverviewConfig: ItemOverviewConfig;
  @Input() layout: 'horizontal' | 'vertical' = 'horizontal';
  @Output() editItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() buyItem: EventEmitter<any> = new EventEmitter<any>();
  queryId: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.queryId = this.activatedRoute.snapshot.queryParams?.queryId || null;
  }
}
