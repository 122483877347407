import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
import * as formatDate from '@paperclip/functions/format-date';
import { ModalService } from '@paperclip/modals/modal.service';
import { ReportXModalComponent } from '@paperclip/modals/shared/shared-modals';
import { Item } from '@paperclip/models/item';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { User } from '@paperclip/models/user';
import { UserReview } from '@paperclip/models/user/user-review';
import { UserProfileService } from '@paperclip/page/user-profile/user-profile.service';

@Component({
  selector: 'pc-user-reviews-modal',
  templateUrl: './user-reviews-modal.component.html',
  styleUrls: ['./user-reviews-modal.component.scss']
})
export class UserReviewsModalComponent implements OnInit {
  userReviews: UserReview[] = [];
  dataLoading = true;
  dataLoadingMore: boolean;
  dataLoadingMoreSkip = 0;
  dataAllLoaded: boolean;

  constructor(
    public dialogRef: MatDialogRef<UserReviewsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: { user: User; ownProfile: boolean },
    private authService: AuthService,
    private translateService: TranslateService,
    private userProfileService: UserProfileService,
    private modalService: ModalService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.userReviews = [
      {
        date: '',
        text: 'string',
        reviewStars: [''],
        item: null,
        tradeType: '',
        user: null
      },
      {
        date: '',
        text: 'string',
        reviewStars: [''],
        item: null,
        tradeType: '',
        user: null
      },
      {
        date: '',
        text: 'string',
        reviewStars: [''],
        item: null,
        tradeType: '',
        user: null
      }
    ];
    this.getUserReviews(this.modalData.user.detail.id);
  }

  getUserReviews(userId: string, loadMore = false) {
    if (userId) {
      this.dataLoading = loadMore ? false : true;

      // load more controls
      if (loadMore) {
        this.dataLoadingMoreSkip += 30;
        this.dataLoadingMore = true;
      } else {
        this.dataLoadingMoreSkip = 0;
        this.dataLoading = true;
        this.dataLoadingMore = false;
      }

      // if data is all loaded turn off loading more
      if (this.dataAllLoaded) {
        this.dataLoadingMore = false;
      }

      this.userProfileService.getUserReviews(userId, this.dataLoadingMoreSkip).subscribe(
        ({ code, data, message }: PcApiResponse) => {
          this.dataLoading = false;
          this.dataLoadingMore = false;

          if (code === 1) {
            // model reviews
            const userReviews: UserReview[] = [];
            data.forEach((dataReview: any) => {
              const review: UserReview = {
                date: formatDate.relativeDate(
                  dataReview.offer?.item.traded || dataReview.date,
                  this.translateService.getBrowserCultureLang()
                ),
                text: dataReview.text,
                reviewStars: [],
                item: dataReview.offer
                  ? new Item(
                      dataReview.offer.item,
                      this.authService.getAuthedUser().detail?.id || '',
                      this.translateService.getBrowserCultureLang()
                    )
                  : null,
                tradeType: dataReview.offer
                  ? dataReview.offer.seller.userId === this.modalData.user.detail.id
                    ? 'sold'
                    : 'bought'
                  : null,
                user: new User(dataReview)
              };

              review.reviewStars = [];
              for (let x = 0; x < dataReview.rating; x++) {
                review.reviewStars.push('filled');
              }

              for (let i = dataReview.rating; i < 5; i++) {
                review.reviewStars.push('empty');
              }

              userReviews.push(review);
            });

            if (loadMore) {
              // eslint-disable-next-line prefer-spread
              this.userReviews.push.apply(this.userReviews, userReviews);
              // check if all items have been loaded
              this.dataAllLoaded = userReviews.length < 30;
            } else {
              this.userReviews = userReviews;
              // check if all items have been loaded
              this.dataAllLoaded = userReviews.length < 30;
            }
          } else {
            this.coreService.handleError({ code, message });
          }
        },
        () => {
          this.coreService.handleError({});
        }
      );
    }
  }

  loadMoreReviews() {
    if (this.dataLoading) {
      return;
    }

    // don't load more on the feed or if all data has been loaded
    if (!this.dataAllLoaded) {
      this.getUserReviews(this.modalData.user.detail.id, true);
    }
  }

  reportReview(review: UserReview) {
    this.modalService.open(ReportXModalComponent, {
      type: 'centered',
      data: { type: 'user', id: review.user.detail.id }
    });
  }
}
