<!--
  initial loading state
-->
<div *ngIf="dataLoading" class="item item--loading item--user">
  <!-- item image & favourite item -->
  <div class="item__image">
    <img class="bg-image" src="assets/loading-img.png" />
  </div>

  <!-- user info -->
  <div class="item__info">
    <a class="user-name"></a>
    <p class="user-location"></p>
  </div>
</div>

<!--
  loaded
-->
<a
  *ngIf="!dataLoading"
  class="item item--user"
  [ngClass]="{ 'item--loading': dataLoading }"
  [routerLink]="['/u', gridItem.nickname]"
>
  <!-- item image -->
  <div class="item__image">
    <img class="bg-image" [src]="gridItem.pictureUrl" />
  </div>

  <!-- user info -->
  <div class="item__info">
    <p class="user-name faded-content">{{ gridItem.name }}</p>
    <p class="user-location faded-content">
      <!-- todo: remove the need for this check -->
      <ng-container *ngIf="gridItem.location">
        {{ gridItem.location.name }}
      </ng-container>
    </p>
    <ng-container *ngIf="gridItem.userId !== loggedInUserId">
      <button *ngIf="!gridItem.isFollowed" (click)="followUser(gridItem.userId)">
        {{ 'users.follow' | translate }}
      </button>
      <button class="btn-green" *ngIf="gridItem.isFollowed" (click)="unfollowUser(gridItem.userId)">
        {{ 'users.following' | translate }}
      </button>
    </ng-container>
  </div>
</a>
