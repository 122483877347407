import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { MessagesService } from '@paperclip/page/messages.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-messages-add-images-modal',
  templateUrl: './messages-add-images-modal.component.html',
  styleUrls: ['./messages-add-images-modal.component.scss']
})
export class MessagesAddImagesModalComponent implements OnDestroy {
  /*-- properties --*/
  windowSize: any = window.innerWidth < 1200 ? 'mobile' : 'desktop';
  windowWidthResizeSubscription: Subscription;
  messageImages: any = [];

  constructor(
    public dialogRef: MatDialogRef<MessagesAddImagesModalComponent>,
    public messagesService: MessagesService,
    private windowResizeService: WindowResizeService
  ) {
    this.windowWidthResizeSubscription = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 1200 ? 'mobile' : 'desktop';
    });
  }

  ngOnDestroy() {
    this.windowWidthResizeSubscription.unsubscribe();
  }

  imageFilesSuccess(messageImages: File[]) {
    this.messageImages = messageImages;
  }

  addImagesToMessage() {
    this.messagesService.sendMessageImages(this.messageImages);
    this.dialogRef.close();
  }
}
