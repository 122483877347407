<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.delete-account.title' | translate }}</h1>
  </div>

  <p class="modal-body">
    {{ 'modals.delete-account.body' | translate }}
  </p>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large" (click)="dialogRef.close()">{{ 'buttons.cancel' | translate }}</button>
    <button class="btn-large btn-primary" (click)="deleteAccount()" [ngClass]="{ 'btn-loading': activeRequest }">
      {{ 'modals.delete-account.delete' | translate }}
    </button>
  </div>
</div>
