import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { TradeFilters } from '@paperclip/models/TradeFilters';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuyingService {
  loggedInUserId: string = this.authService.getAuthedUser().detail?.id || '';

  constructor(private http: HttpClient, private authService: AuthService) {}

  getBuyingActiveCount(): Observable<any> {
    return this.http.get(`buying/activeCount`);
  }

  getBuyingActive(tradeStatusQuery: string, booleanTradeFilters: string, skip = 0, limit = 0): Observable<any> {
    return this.http.get(`buying/activeTrades?skip=${skip}&limit=${limit}${tradeStatusQuery}${booleanTradeFilters}`);
  }

  getBuyingHistory(tradeStatusQuery: string, booleanTradeFilters: string, skip = 0, limit = 0): Observable<any> {
    return this.http.get(`buying/history?skip=${skip}&limit=${limit}${tradeStatusQuery}${booleanTradeFilters}`);
  }

  /*-- actions --*/
  withdrawOffer(offerId: string): Observable<any> {
    return this.http.post(`offers/withdraw`, { offerId: offerId, userId: this.loggedInUserId });
  }

  deleteTrade(tradeId: string): Observable<any> {
    return this.http.post(`offers/retract`, { offerId: tradeId, userId: this.loggedInUserId });
  }
}
