import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, TitleStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from '@paperclip/core/core.module';
import { GlobalErrorHandler } from '@paperclip/core/errors/error-handler';
import { environment } from '@paperclip/environments/environment';
import { SharedModule } from '@paperclip/shared/shared.module';
import { Angulartics2Module } from 'angulartics2';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { FacebookModule } from 'ngx-facebook';
import { NgxHotjarModule } from 'ngx-hotjar';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { TitleService } from './core/title.service';
import { AuthedUser } from './models/user/AuthedUser';
import * as Sentry from "@sentry/angular";

// get auth token
export function tokenGetter() {
  const authedUser: AuthedUser = JSON.parse(localStorage.getItem('user'));
  const authToken = authedUser ? authedUser.authToken : null;
  return authToken;
}

// load i18n
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `./assets/i18n/en.json?v=${Date.now()}`);
}

// cookie consent config
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: ''
  },
  position: 'bottom-left',
  theme: 'classic',
  type: 'info',
  content: { href: 'https://hub.paperclip.co/cookies/' }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,

    // 3rd party
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
        // whitelistedDomains: ['localhost:4200']
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FacebookModule.forRoot(),
    PortalModule,
    OverlayModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearQueryParams: true
      }
    }),
    NgxHotjarModule.forRoot('1371862'),
    NgcCookieConsentModule.forRoot(cookieConfig),

    // paperclip
    AppRoutes,
    CoreModule,
    SharedModule
  ],
  providers: [
    { 
      provide: ErrorHandler, 
      useValue: Sentry.createErrorHandler({showDialog: false,}),      
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: TitleStrategy,
      useClass: TitleService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
