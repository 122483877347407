import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemOverviewService {
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

  /*-- get the item --*/
  getItemById(itemId: string): Observable<any> {
    return this.http.get(`items/${itemId}?userId=${this.authedUserId}`);
  }

  getSimilarItems(itemId: string, skip = 0, limit = 30): Observable<any> {
    return this.http.get(`items/similar?userId=${this.authedUserId}&itemId=${itemId}&skip=${skip}&limit=${limit}`);
  }

  /*====================
  item actions
  ====================*/
  reportItem(reportedItemId: string, reasonId: number): Observable<any> {
    return this.http.post(`items/reportItem`, {
      reportingUserId: this.authedUserId,
      reportedItemId: reportedItemId,
      reasonId: reasonId
    });
  }

  deleteItem(itemId: string, isInSocialInventory = false): Observable<any> {
    return this.http.post(isInSocialInventory ? `socialInventory/delete` : `items/delete`, { itemId: itemId });
  }

  favouriteItem(itemId: string): Observable<any> {
    if (this.authService.isLoggedIn()) {
      return this.http.post(`favourite`, { itemId: itemId, currentUserId: this.authedUserId });
    } else {
      this.router.navigate(['/signin']);
      return EMPTY;
    }
  }

  unfavouriteItem(itemId: string): Observable<any> {
    if (this.authService.isLoggedIn()) {
      return this.http.post(`unfavourite`, { itemId: itemId, currentUserId: this.authedUserId });
    } else {
      // show auth modal if the user isn't logged in and return an empty observable
      this.router.navigate(['/signin']);
      return EMPTY;
    }
  }

  markAsSold(itemId: string): Observable<any> {
    return this.http.post(`items/markSold`, { itemId: itemId, currentUserId: this.authedUserId });
  }

  relistItem(itemId: string): Observable<any> {
    return this.http.post(`items/relist`, { itemId: itemId, currentUserId: this.authedUserId });
  }
}
