import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-balance-withdrawn-modal',
  templateUrl: './balance-withdrawn-modal.component.html'
})
export class BalanceWithdrawnModalComponent {
  constructor(public dialogRef: MatDialogRef<BalanceWithdrawnModalComponent>) {}
}
