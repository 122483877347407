import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'pc-steps-progress-bar',
  templateUrl: './steps-progress-bar.component.html',
  styleUrls: ['./steps-progress-bar.component.scss']
})
export class StepsProgressBarComponent implements OnChanges {
  @Input() stepNumber: number;
  @Input() totalSteps: number;
  progressPercentage: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes && changes.stepNumber && changes.stepNumber.currentValue) ||
      (changes.totalSteps && changes.totalSteps.currentValue)
    ) {
      const progress = (100 / this.totalSteps) * this.stepNumber;
      this.progressPercentage = `${progress}%`;
    }
  }
}
