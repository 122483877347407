import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentAddress } from '@paperclip/models/payments/payment-address';
import { PaymentInfoList } from '@paperclip/models/payments/payment-info-list';

import { PaymentsService } from '../payments.service';

@Component({
  selector: 'pc-add-payment-address',
  templateUrl: './add-payment-address.component.html',
  // todo: move the shared styles somewhere better
  styleUrls: ['./add-payment-address.component.scss', '../../../../item/item-checkout/item-checkout-form-groups.scss']
})
export class AddPaymentAddressComponent implements OnInit {
  @Input() page: 'wallet' | 'checkout' = 'wallet';
  @Input() addressForm: UntypedFormGroup;
  @Input() existingDeliveryAddresses: PaymentAddress[];
  @Input() countriesList: PaymentInfoList[];
  @Input() activeRequest: boolean;
  @Input() addressType = 'delivery';
  @Input() editingAddress: boolean;
  defaultAddress = false;
  addressFormGroup: any;
  countryFormGroup: UntypedFormGroup = this.formBuilder.group({
    country: [null, Validators.required]
  });

  constructor(private formBuilder: UntypedFormBuilder, private paymentsService: PaymentsService) {}

  ngOnInit(): void {
    this.addressFormGroup =
      this.addressType === 'delivery'
        ? this.addressForm.get('deliveryAddress')
        : this.addressForm.get('billingAddress');

    if (!this.addressFormGroup.value.countryCode) {
      this.addressFormGroup.patchValue({ countryCode: 'GB' });
    } else {
      this.countryFormGroup.patchValue({
        country: this.countriesList.find(
          (country: PaymentInfoList) => country.shortcode === this.addressFormGroup.value.countryCode
        )
      });
    }
  }

  public showSetAsDefaultOption(): boolean {
    if (this.addressType === 'delivery') {
      if (this.editingAddress) {
        return this.addressForm.get('deliveryAddress').get('isDefault').value
          ? this.existingDeliveryAddresses?.length > 1
          : true;
      }

      return this.existingDeliveryAddresses?.length > 0;
    }

    return false;
  }

  public setAsDefaultAddress() {
    this.defaultAddress = !this.defaultAddress;
    this.addressForm.get('deliveryAddress').get('isDefault').patchValue(this.defaultAddress);
  }

  public addAddress() {
    if (this.addressType === 'delivery') {
      this.paymentsService.paymentsActionCompleted({
        action: this.editingAddress ? 'edit-address' : 'add-delivery-address',
        data: this.addressForm.get('deliveryAddress').value
      });
    } else {
      this.paymentsService.paymentsActionCompleted({
        action: 'add-payment-card',
        data: {
          paymentCard: this.addressForm.get('paymentCard').value,
          billingAddress: this.addressForm.get('billingAddress').value
        }
      });
    }
  }

  public isAddressFormInvalid(): boolean {
    return this.addressFormGroup.invalid;
  }

  public changeCountry() {
    const { shortcode, name } = this.countryFormGroup.value.country;
    this.addressFormGroup.patchValue({
      countryCode: shortcode,
      countryName: name
    });
  }

  public getFooterBtnLabel(): string {
    if (this.page === 'wallet') {
      return this.addressType === 'delivery' ? 'buttons.save-address' : 'buttons.save-card';
    }

    return this.addressType === 'delivery'
      ? 'item-checkout.button.select-delivery-address'
      : 'item-checkout.button.choose-payment-method';
  }
}
