import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./core/layouts/app-layout/app-layout.module').then((m) => m.AppLayoutModule)
  },
  {
    path: '',
    loadChildren: () => import('./core/layouts/steps-layout/steps-layout.module').then((m) => m.StepsLayoutModule)
  },
  {
    path: '',
    loadChildren: () => import('./core/layouts/auth-layout/auth-layout.module').then((m) => m.AuthLayoutModule)
  },
  { path: '**', redirectTo: '404' }
];

export const AppRoutes = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'enabled'
});
