<div *ngIf="item?.detail?.isSold || item?.detail?.isTraded" class="sold-item-overlay">
  <div class="sold-item-overlay sold-item-overlay--background"></div>
  <div class="sold-item-overlay sold-item-overlay--foreground">
    <p class="p-small sold-tag highlight-text">{{ 'tag.sold' | translate }}</p>
  </div>
</div>

<div *ngIf="showFavouriteItemAction(item)" class="grid-item__favourite">
  <button
    *ngIf="!item?.detail?.isFavourite"
    (click)="gridItemAction($event, 'like', item)"
    class="btn-icon btn-icon--small btn-opacity save-item"
  >
    <img
      src="/assets/icons/item/save.svg"
      alt="save item"
      matTooltip="{{ 'buttons.save-item' | translate }}"
      matTooltipPosition="above"
    />
  </button>
  <button
    *ngIf="item?.detail?.isFavourite"
    (click)="gridItemAction($event, 'unlike', item)"
    class="btn-icon btn-icon--small btn-opacity save-item"
  >
    <img
      src="/assets/icons/item/saved.svg"
      alt="saved item"
      matTooltip="{{ 'buttons.saved' | translate }}"
      matTooltipPosition="above"
    />
  </button>
</div>

<span *ngIf="item?.detail?.tag" class="grid-item__tag item-tag item-tag--{{ item?.detail?.tag }}">
  {{ 'tags.item.' + item?.detail?.tag | translate }}
</span>

<div class="grid-item__image">
  <img [src]="item?.detail?.thumbnail || 'assets/loading-img.png'" [alt]="item?.detail?.name || ''" />
</div>

<div class="grid-item__content">
  <div class="grid-item__meta">
    <p class="grid-item__meta__category no-text-overflow">{{ item?.detail?.category?.name }}</p>
    <p *ngIf="gridViewConfig.dataToGet !== 'profile-inactive'" class="grid-item__meta__price" class="colour-secondary">
      {{ userCurrency }}{{ item?.detail?.price | translate }}
    </p>
  </div>
  <p class="grid-item__name no-text-overflow">{{ item?.detail?.name }}</p>
  <button
    *ngIf="gridViewConfig.dataToGet === 'profile-inactive'"
    class="social-inventory-btn btn-large--fit-content"
    [ngClass]="{ 'btn-large': windowSize === 'desktop', 'btn-primary': isOwnProfile, 'btn-secondary': !isOwnProfile }"
    (click)="socialInventoryBtnAction($event, item)"
  >
    {{ isOwnProfile ? ('add-item.review-listing.list-item' | translate) : ('buttons.message' | translate) }}
  </button>
</div>
