import { Component, Input, OnChanges } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';

@Component({
  selector: 'pc-user-profile-picture',
  templateUrl: './user-profile-picture.component.html',
  styleUrls: ['./user-profile-picture.component.scss']
})
export class UserProfilePictureComponent implements OnChanges {
  constructor(public authService: AuthService) {}

  defaultPicture = '/assets/default-avatar.svg';

  @Input()
  picture: any = this.authService.getAuthedUser()
    ? this.authService.getAuthedUser().detail?.picture
    : this.defaultPicture;

  @Input()
  pictureLoading = false;

  @Input()
  link: string;

  @Input()
  width = 40;

  @Input()
  height = 40;

  @Input()
  borderRadius = '50%';

  @Input()
  isOnFirstLogin = false;

  @Input() border = true;

  ngOnChanges() {
    if (this.picture === null) {
      this.picture = this.defaultPicture;
    }
  }
}
