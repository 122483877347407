<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.report-data.' + reportData.type + '.title' | translate }}</h1>
    <p>{{ 'modals.report-data.' + reportData.type + '.body' | translate }}</p>
  </div>

  <div class="modal-body">
    <ul class="checkbox-list">
      <li *ngFor="let reason of reportData.reasons" class="checkbox-list__item" (click)="setSelectedReason(reason.id)">
        <p class="highlight-text">{{ 'modals.report-data.reasons.' + reason.reason | translate }}</p>
        <pc-checkmark-icon [checked]="reason.isSelected" [size]="32"></pc-checkmark-icon>
      </li>
    </ul>
  </div>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large" (click)="dialogRef.close()">{{ 'buttons.cancel' | translate }}</button>
    <button
      class="btn-large btn-primary"
      (click)="processReport()"
      [ngClass]="{ 'btn-loading': activeRequest }"
      [disabled]="!reportData.selectedReason"
    >
      {{ 'buttons.report' | translate }}
    </button>
  </div>
</div>
