import { Component, Input, OnChanges } from '@angular/core';
import { ModalService } from '@paperclip/modals/modal.service';
import { UserReviewsModalComponent } from '@paperclip/modals/user/user-modals';
import { User } from '@paperclip/models/user';

@Component({
  selector: 'pc-user-star-rating',
  templateUrl: './user-star-rating.component.html',
  styleUrls: ['./user-star-rating.component.scss']
})
export class UserStarRatingComponent implements OnChanges {
  @Input() showLoading: boolean;
  @Input() display: string;
  @Input() newUser: boolean;
  @Input() usernameLink: string;
  @Input() user: User;
  @Input() ownProfile: boolean;
  disableReviews: boolean;

  constructor(private modalService: ModalService) {}

  ngOnChanges() {
    if (this.user) {
      this.disableReviews = !this.ownProfile && this.user.detail.isSuspended;
    }
  }

  public openUserReviewsModal() {
    this.modalService.open(UserReviewsModalComponent, {
      type: 'tabbed',
      extraClass: 'modal-container--tabbed--reviews',
      needsAuth: false,
      data: {
        user: this.user,
        ownProfile: this.ownProfile
      }
    });
  }
}
