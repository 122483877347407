import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAction } from '@paperclip/models/component/menu-action';

@Component({
  selector: 'pc-wallet-filter-transactions-menu',
  templateUrl: './wallet-filter-transactions-menu.component.html'
})
export class WalletFilterTransactionsMenuComponent {
  @Input() menuOpened: boolean;
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
  filterOptions = ['all', 'payments', 'withdrawals', 'refunds', 'paperclip-credit'];

  public setTransactionFilter(filter: string) {
    filter = filter.replace(/\s/g, '-').toLowerCase();
    this.menuAction.emit({ action: 'filter-transaction-history', data: filter });
    this.menuAction.emit({ action: 'close-menu' });
  }
}
