<div class="modal remove-student-roost-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.remove-student-roost.title' | translate }}</h1>
  </div>

  <p class="modal-body" [innerHTML]="'modals.remove-student-roost.body' | translate"></p>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large" (click)="dialogRef.close()">{{ 'buttons.cancel' | translate }}</button>
    <button
      class="btn-large btn-primary"
      (click)="removeStudentRoostLocation()"
      [ngClass]="{ 'btn-loading': activeRequest }"
    >
      {{ 'buttons.confirm' | translate }}
    </button>
  </div>
</div>
