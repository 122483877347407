import { DeleteTradeModalComponent } from './delete-trade-modal/delete-trade-modal.component';
import { DisputeSubmittedModalComponent } from './dispute-submitted-modal/dispute-submitted-modal.component';
import { DisputeTradeModalComponent } from './dispute-trade-modal/dispute-trade-modal.component';
import { MarkDispatchedModalComponent } from './mark-dispatched-modal/mark-dispatched-modal.component';
import { MarkReceivedModalComponent } from './mark-received-modal/mark-received-modal.component';
import { ReviewTradeModalComponent } from './review-trade-modal/review-trade-modal.component';
import { SocialInventoryOneTimeModalComponent } from './social-inventory-one-time-modal/social-inventory-one-time-modal.component';
import { TradingTwoDayDelayModalComponent } from './trading-two-day-delay-modal/trading-two-day-delay-modal.component';
import { WithdrawOfferModalComponent } from './withdraw-offer-modal/withdraw-offer-modal.component';

export * from './delete-trade-modal/delete-trade-modal.component';
export * from './dispute-submitted-modal/dispute-submitted-modal.component';
export * from './dispute-trade-modal/dispute-trade-modal.component';
export * from './mark-dispatched-modal/mark-dispatched-modal.component';
export * from './mark-received-modal/mark-received-modal.component';
export * from './review-trade-modal/review-trade-modal.component';
export * from './social-inventory-one-time-modal/social-inventory-one-time-modal.component';
export * from './trading-two-day-delay-modal/trading-two-day-delay-modal.component';
export * from './withdraw-offer-modal/withdraw-offer-modal.component';

export const tradingModals = [
  DeleteTradeModalComponent,
  DisputeTradeModalComponent,
  DisputeSubmittedModalComponent,
  MarkDispatchedModalComponent,
  MarkReceivedModalComponent,
  ReviewTradeModalComponent,
  WithdrawOfferModalComponent,
  SocialInventoryOneTimeModalComponent,
  TradingTwoDayDelayModalComponent
];
