import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppConfig } from '@paperclip/core/app.config';
import { CoreService } from '@paperclip/core/core.service';
import { LocationService } from '@paperclip/core/location.service';
import { AppData } from '@paperclip/models/AppData';
import { LocationFormUpdate } from '@paperclip/models/component/location-form-update';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AuthService } from '../../../core/auth/auth.service';

interface LocationFormValue {
  useCurrentLocation: boolean | string;
  locationName: string;
  locationLat: number | string;
  locationLng: number | string;
}

@Component({
  selector: 'pc-new-location-search',
  templateUrl: './new-location-search.component.html',
  styleUrls: ['./new-location-search.component.scss']
})
export class NewLocationSearchComponent implements OnInit, OnChanges, OnDestroy {
  appData: AppData;
  @Input() locationFormValue: LocationFormValue;
  @Input() clearLocationSearch: boolean;
  @Input() insideSideFilters = false;
  @Input() forceFullWidth = false;
  @Output() updateLocationForm: EventEmitter<LocationFormUpdate> = new EventEmitter<LocationFormUpdate>();
  @Output() emitLocationInputFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  locationForm: UntypedFormGroup = this.formBuilder.group({
    useCurrentLocation: null,
    locationName: null,
    locationLat: null,
    locationLng: null
  });
  locationNameSubscription: Subscription;

  /*-- layout control  --*/
  @ViewChild('locationInputRef', { static: true }) locationInputRef: ElementRef;
  locationSelected: boolean;
  locationInputHasFocus: boolean;
  showRecentLocations: boolean;
  showLocationSuggestions: boolean;
  locationSuggestionsLoading: boolean;
  loadingCurrentLocation: boolean;
  useCurrentLocation = false;
  showClearSearchInputBtn: boolean;

  /*-- location data--*/
  recentLocations: string[] = [];
  locationSuggestions: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngZone: NgZone,
    private authService: AuthService,
    private locationService: LocationService,
    private coreService: CoreService
  ) {
    this.locationNameSubscription = this.locationForm
      .get('locationName')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((location: string) => {
        if (location) {
          this.showLocationSuggestions = true;
          this.locationSuggestionsLoading = true;
          this.showClearSearchInputBtn = true;
          this.showRecentLocations = false;
          this.getLocationSuggestions(location);
        } else {
          this.showLocationSuggestions = false;
          this.locationSuggestionsLoading = false;
          this.locationSuggestions = [];
          this.showClearSearchInputBtn = false;
          this.showRecentLocations = true;
        }
      });
  }

  ngOnInit() {
    this.appData = this.authService.getAppData();
    this.patchLocationForm(this.locationFormValue);
    this.recentLocations = this.appData ? (this.appData.recentLocations ? this.appData.recentLocations : []) : [];
    this.useCurrentLocation = String(this.locationForm.get('useCurrentLocation').value) === 'true';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.clearLocationSearch && !changes.clearLocationSearch.firstChange) {
        this.clearInput(!this.insideSideFilters);
      }
      if (changes?.locationFormValue?.currentValue) {
        this.patchLocationForm(this.locationFormValue);
      }
    }
  }

  ngOnDestroy(): void {
    this.locationNameSubscription.unsubscribe();
  }

  patchLocationForm(locationFormValue: LocationFormValue) {
    this.locationForm.patchValue({
      useCurrentLocation: String(locationFormValue.useCurrentLocation) === 'true',
      locationName: locationFormValue.locationName,
      locationLat: locationFormValue.locationLat,
      locationLng: locationFormValue.locationLng
    });
  }

  /*====================
  layout & form control
  ====================*/
  locationInputFocus() {
    // only show list if any of these are true
    const locationName = this.locationForm.get('locationName').value;
    if (locationName) {
      this.locationInputHasFocus = true;
    } else if (this.recentLocations.length > 0) {
      this.locationInputHasFocus = true;
    } else if (this.locationSuggestions.length > 0) {
      this.locationInputHasFocus = true;
    }

    this.emitLocationInputFocus.emit(this.locationInputHasFocus);

    // show suggestions if input isn't empty
    if (locationName) {
      this.showLocationSuggestions = true;
      this.showRecentLocations = false;
      this.getLocationSuggestions(locationName);
    }
  }

  locationInputBlur() {
    this.locationInputHasFocus = false;
    this.emitLocationInputFocus.emit(this.locationInputHasFocus);
  }

  locationInputKeydown(keyEvent: any) {
    this.locationInputHasFocus = true;
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  }

  clearInput(applyFilter = false) {
    // reset stuff
    this.useCurrentLocation = false;
    this.showLocationSuggestions = false;
    this.locationSuggestions = [];
    this.locationSuggestionsLoading = false;
    this.loadingCurrentLocation = false;

    // empty the value
    this.locationForm.patchValue({
      useCurrentLocation: false,
      locationName: null,
      locationLat: null,
      locationLng: null
    });
    this.locationSelected = false;

    // emit the value to the parent
    this.emitUpdateLocationForm(applyFilter);

    // if user has recentLocations show them
    // todo: ask steve about this
    // if (this.recentLocations.length > 0) {
    //   this.locationInputHasFocus = true;
    //   this.showRecentLocations = true;
    //   this.emitLocationInputFocus.emit(this.locationInputHasFocus);
    // }
  }

  /*====================
  location suggestions
  ====================*/
  getLocationSuggestions(location) {
    this.locationService.getLocationSuggestions(location).subscribe((locations) => {
      this.ngZone.run(() => {
        this.locationSuggestionsLoading = false;
        this.locationSuggestions = locations;
      });
    });
  }

  /*====================
  current location
  ====================*/
  selectCurrentLocation() {
    this.useCurrentLocation = !this.useCurrentLocation;

    /*-- current location selected --*/
    if (this.useCurrentLocation) {
      // reset the form
      this.locationForm.patchValue({
        locationName: ''
      });

      this.loadingCurrentLocation = true;
      this.locationService.getCurrentLocation().subscribe((locationCoordinates: any) => {
        /*-- we have access to location --*/
        if (locationCoordinates !== 'user denied location') {
          this.locationService
            .getLocation(locationCoordinates.latitude, locationCoordinates.longitude)
            .subscribe((locationName) => {
              // force template to update
              this.ngZone.run(() => {
                this.loadingCurrentLocation = false;
              });

              // patch form
              this.locationForm.patchValue({
                useCurrentLocation: true,
                locationName: locationName.formattedAddress,
                locationLat: locationCoordinates.latitude,
                locationLng: locationCoordinates.longitude
              });
              this.locationSelected = true;

              // emit the value to the parent
              this.emitUpdateLocationForm();
              this.locationForm.get('useCurrentLocation').setValue(true);

              const currentLocation = {
                name: locationName.formattedAddress,
                latitude: locationCoordinates.latitude,
                longitude: locationCoordinates.longitude
              };

              this.authService.updateAppData({ currentLocation: currentLocation });
            });

          /*-- we don't have access to location --*/
        } else {
          this.ngZone.run(() => {
            this.loadingCurrentLocation = false;
          });

          this.locationForm.patchValue({
            useCurrentLocation: true,
            locationName: AppConfig.app.location.name,
            locationLat: AppConfig.app.location.latitude,
            locationLng: AppConfig.app.location.longitude
          });

          this.emitUpdateLocationForm();
          this.coreService.handleError({ customCode: 995 });
        }
      });

      /*-- current location deselected --*/
    } else {
      this.loadingCurrentLocation = false;
      this.useCurrentLocation = false;
      this.locationForm.patchValue({
        useCurrentLocation: false,
        locationName: AppConfig.app.location.name,
        locationLat: AppConfig.app.location.latitude,
        locationLng: AppConfig.app.location.longitude
      });

      // emit the value to the parent
      this.emitUpdateLocationForm();
    }
  }

  /*====================
  select & search location
  ====================*/
  selectLocation(event: any, location: string) {
    // prevent blur from firing because otherwise this will all happen twice
    event.preventDefault();
    this.locationInputRef.nativeElement.blur();

    // patch location name from selected location
    this.locationForm.patchValue({
      locationName: location
    });

    // search location
    this.searchLocation(this.locationForm.get('locationName').value);
  }

  searchLocation(locationValue: string) {
    this.locationInputHasFocus = false;
    this.showLocationSuggestions = false;
    this.showRecentLocations = false;

    // add location to recent locations if not already in the array
    if (locationValue != null && locationValue.trim().length > 0) {
      if (this.recentLocations.length === 0) {
        this.recentLocations = [locationValue];
      } else {
        let duplicateLocation = false;
        this.recentLocations.map((recentLocation) => {
          if (recentLocation === locationValue) {
            duplicateLocation = true;
          }
        });
        if (!duplicateLocation) {
          if (locationValue !== '' && locationValue.trim().length > 0) {
            this.recentLocations = [locationValue, ...this.recentLocations];
          }
        }
      }

      this.recentLocations = this.recentLocations.slice(0, 3);
      this.authService.updateAppData({ recentLocations: this.recentLocations });
    }

    // get longitude and latitude for location
    if (locationValue !== 'no suggestions available') {
      this.locationService.getLatLng(locationValue).subscribe((locationCoordinates) => {
        this.locationForm.patchValue({
          useCurrentLocation: false,
          locationLat: locationCoordinates.lat,
          locationLng: locationCoordinates.lng
        });

        this.locationSelected = true;
        // emit the value to the parent
        this.emitUpdateLocationForm();
      });
    }
  }

  emitUpdateLocationForm(applyFilter?: boolean) {
    this.emitLocationInputFocus.emit(this.locationInputHasFocus);
    this.updateLocationForm.emit({
      applyFilter: applyFilter,
      locationSelected: this.locationSelected,
      useCurrentLocation: this.locationForm.get('useCurrentLocation').value,
      locationName: this.locationForm.get('locationName').value,
      locationLat: this.locationForm.get('locationLat').value,
      locationLng: this.locationForm.get('locationLng').value
    });
  }
}
