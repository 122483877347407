import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-cancel-make-offer-modal',
  templateUrl: './cancel-make-offer-modal.component.html'
})
export class CancelMakeOfferModalComponent {
  constructor(
    public dialogRef: MatDialogRef<CancelMakeOfferModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { cancelMakeOffer: () => {} }
  ) {}
}
