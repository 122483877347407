import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';
import { AccountSettingsService } from '@paperclip/page/account-settings/account-settings.service';

@Component({
  selector: 'pc-verify-user-info-modal',
  templateUrl: './verify-user-info-modal.component.html'
})
export class VerifyUserInfoModalComponent implements OnInit {
  authedUser: AuthedUser = this.authService.getAuthedUser();
  verifyInfoType: 'email' | 'name' | 'email-and-name';
  activeRequest: boolean;
  userInfoForm: UntypedFormGroup = this.formBuilder.group({
    emailAddress: [null, Validators.compose([Validators.required, Validators.email])],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required]
  });

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<VerifyUserInfoModalComponent>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { targetUrl: string; userInfoSaved: () => {} },
    private authService: AuthService,
    private accountSettingsService: AccountSettingsService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    if (!this.authedUser.detail.email && this.authedUser.detail.firstName) {
      this.verifyInfoType = 'email';
    } else if (this.authedUser.detail.email && !this.authedUser.detail.firstName) {
      this.verifyInfoType = 'name';
    } else {
      this.verifyInfoType = 'email-and-name';
    }
  }

  public saveUserInfo() {
    this.activeRequest = true;
    const userInfo = {
      email: this.userInfoForm.get('emailAddress').value || null,
      firstName: this.userInfoForm.get('firstName').value || null,
      lastName: this.userInfoForm.get('lastName').value || null
    };
    this.accountSettingsService.saveProfile(userInfo).subscribe(
      ({ code, message }: PcApiResponse) => {
        if (code === 1) {
          if (this.verifyInfoType === 'email-and-name') {
            this.emailAndNameUpdate(userInfo);
          } else if (this.verifyInfoType === 'email') {
            this.emailUpdate(userInfo);
          } else if (this.verifyInfoType === 'name') {
            this.nameUpdate(userInfo);
          }
        } else {
          this.activeRequest = false;
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  public disableSaveButton(): boolean {
    if (this.verifyInfoType === 'email') {
      return this.userInfoForm.get('emailAddress').invalid;
    } else if (this.verifyInfoType === 'name') {
      return this.userInfoForm.get('firstName').invalid || this.userInfoForm.get('lastName').invalid;
    } else if (this.verifyInfoType === 'email-and-name') {
      return (
        this.userInfoForm.get('emailAddress').invalid ||
        this.userInfoForm.get('firstName').invalid ||
        this.userInfoForm.get('lastName').invalid
      );
    }
  }

  private emailUpdate({ email }) {
    this.accountSettingsService.sendVerificationEmail(email).subscribe(
      ({ code, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('email-saved');
          this.authService.updateAuthedUser({ detail: { email: email } });
          this.closeAndRedirect();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  private nameUpdate({ firstName, lastName }) {
    this.authService.updateAuthedUser({ detail: { firstName: firstName, lastName: lastName } });
    this.confirmationMessageService.showConfirmationMessage('name-saved');
    this.closeAndRedirect();
  }

  private emailAndNameUpdate({ email, firstName, lastName }) {
    this.accountSettingsService.sendVerificationEmail(email).subscribe(
      ({ code, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.authService.updateAuthedUser({
            detail: { firstName: firstName, lastName: lastName }
          });
          this.confirmationMessageService.showConfirmationMessage('name-saved');
          this.confirmationMessageService.showConfirmationMessage('email-saved');
          this.authService.updateAuthedUser({ detail: { email: email } });
          this.closeAndRedirect();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  private closeAndRedirect() {
    if (this.modalData.targetUrl === 'add-item-images') {
      this.modalData.userInfoSaved();
      this.dialogRef.close();
    } else {
      this.router.navigateByUrl(this.modalData.targetUrl);
    }
  }
}
