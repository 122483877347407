import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-pending-transactions-modal',
  templateUrl: './pending-transactions-modal.component.html'
})
export class PendingTransactionsModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PendingTransactionsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: { pendingTransactions: number }
  ) {}
}
