import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@paperclip/models/user';

@Component({
  selector: 'pc-following-followers-modal',
  templateUrl: './following-followers-modal.component.html'
})
export class FollowingFollowersModalComponent implements OnInit {
  modalTabs: string[] = ['following', 'followers'];
  activeModalTab: string;

  constructor(
    public dialogRef: MatDialogRef<FollowingFollowersModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: { user: User; ownProfile: boolean; activeModalTab: string }
  ) {}

  ngOnInit() {
    this.activeModalTab = this.modalData.activeModalTab;
  }

  public switchActiveTab(tab: string) {
    this.activeModalTab = tab;
  }
}
