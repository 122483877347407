<div pcClickStopPropagation class="buttons-filter">
  <button
    pcClickStopPropagation
    *ngFor="let condition of itemConditions"
    (click)="selectCondition(condition)"
    [ngClass]="{ 'btn-primary': condition.isSelected }"
  >
    {{ condition.label }}
  </button>
</div>
