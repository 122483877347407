import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from '@paperclip/core/app.config';
import { ItemCategory } from '@paperclip/models/item/ItemCategory';

@Component({
  selector: 'pc-item-details-item-category',
  templateUrl: './item-details-item-category.component.html'
})
export class ItemDetailsItemCategoryComponent implements OnInit {
  @Input() addItemForm: UntypedFormGroup;
  @Input() categoryTree: ItemCategory[];
  @Output() selectedCategoryTree: EventEmitter<ItemCategory[]> = new EventEmitter<ItemCategory[]>();
  @Output() setFocusedSection: EventEmitter<string> = new EventEmitter<string>();
  itemCategories = AppConfig.categories;
  parentCategory: ItemCategory;
  parentCategoryChildCategories: ItemCategory[] = [];
  childCategory: ItemCategory;
  childCategoryChildCategories: ItemCategory[] = [];
  grandChildCategory: ItemCategory;

  ngOnInit(): void {
    // set selected category if already set
    if (this.categoryTree) {
      this.parentCategory = this.categoryTree[0] || null;
      this.childCategory = this.categoryTree[1] || null;
      this.grandChildCategory = this.categoryTree[2] || null;

      // get parent category child categories
      this.parentCategoryChildCategories =
        this.itemCategories.filter((category: ItemCategory) => category.id === this.parentCategory?.id)[0]
          ?.categories || null;

      // get child category grandchild categories
      if (this.childCategory) {
        this.childCategoryChildCategories =
          this.parentCategoryChildCategories.filter(
            (category: ItemCategory) => category.id === this.childCategory.id
          )[0].categories || null;
      }
    }
  }

  selectCategory(category: any) {
    // set whether this category is a parent, child or 2nd child category
    if (!category.parentId) {
      this.parentCategory = category;
      this.parentCategoryChildCategories = category.categories;
    } else if (category.parentId && category.categories) {
      this.childCategory = category;
      this.childCategoryChildCategories = category.categories;
    } else {
      this.grandChildCategory = category;
    }

    // set the category
    this.patchItemCategoryValue(category);
  }

  clearParentCategory() {
    this.parentCategory = null;
    this.parentCategoryChildCategories = null;
    this.childCategory = null;
    this.childCategoryChildCategories = null;
    this.grandChildCategory = null;
    this.patchItemCategoryValue(null);
  }

  clearChildCategory() {
    this.childCategory = null;
    this.childCategoryChildCategories = null;
    this.grandChildCategory = null;
    this.patchItemCategoryValue(this.parentCategory);
  }

  clearGrandChildCategory() {
    this.grandChildCategory = null;
    this.patchItemCategoryValue(this.childCategory);
  }

  patchItemCategoryValue(selectedCategory: ItemCategory) {
    this.setFocusedSection.emit('item-category');
    this.addItemForm.get('itemDetails').patchValue({ itemCategory: selectedCategory });

    // this is for use on the review listing step
    this.selectedCategoryTree.emit(
      [
        this.parentCategory ? this.parentCategory : null,
        this.childCategory ? this.childCategory : null,
        this.grandChildCategory ? this.grandChildCategory : null
      ].filter((category: any) => category !== null)
    );
  }
}
