<!-- loading and data states -->
<ng-container *ngIf="dataLoading">
  <ul class="users-list" [ngClass]="{
      'loading-data': dataLoading,
      'users-list--two-columns': needsTwoColumns || insideTabbedModal,
      'scrollable-content users-list--tabbed-modal': insideTabbedModal
    }">
    <li *ngFor="let user of [null, null, null]" class="content-card content-card--user">
      <pc-small-user [user]="user"></pc-small-user>
    </li>
  </ul>
</ng-container>

<!-- loaded -->
<div *ngIf="usersList && usersList.length > 0" class="user-list-view">
  <ul class="users-list" [ngClass]="{
      'loading-data': dataLoading,
      'users-list--two-columns': needsTwoColumns || insideTabbedModal,
      'scrollable-content users-list--tabbed-modal': insideTabbedModal
    }" infiniteScroll [scrollWindow]="!insideTabbedModal" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="300"
    (scrolled)="loadMoreItems()">
    <li *ngFor="let user of usersList" class="content-card content-card--user">
      <pc-small-user [user]="user" [menu]="{
          name: menu,
          data: {
            user: user,
            userId: user?.detail.id,
            isFollowed: user?.detail.isFollowed,
            loggedInUserId: authedUserId
          }
        }" [showTag]="showFollowingTag">
      </pc-small-user>
    </li>
    <div *ngIf="insideTabbedModal && !showVieWMoreButton && !dataLoading && dataLoadingMore"
      class="loading-more-spinner-container">
      <div class="loading-more-spinner"></div>
    </div>
  </ul>

  <div class="user-list-view__footer">
    <button *ngIf="showVieWMoreButton && !dataAllLoaded" class="btn-stroked"
      [ngClass]="{ 'btn-loading': manuallyLoadingMore }" (click)="manuallyLoadMore()">
      {{ 'buttons.view-more' | translate }}
    </button>
  </div>

  <div *ngIf="!insideTabbedModal && !showVieWMoreButton && !dataLoading && dataLoadingMore"
    class="loading-more-spinner"></div>
</div>

<div [ngClass]="{ 'empty-state-tabbed-modal': insideTabbedModal }">
  <pc-empty-state *ngIf="!dataLoading && usersList && usersList.length === 0" [page]="emptyStatePage()">
  </pc-empty-state>
</div>
