<li>
  <a target="_blank" [href]="externalLinks.company.helpCentre">
    {{ 'menus.header-info.help-centre' | translate }}
  </a>
</li>
<li>
  <a target="_blank" [href]="externalLinks.company.home">
    {{ 'menus.header-info.about-paperclip' | translate }}
  </a>
</li>
