/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '@paperclip/core/core.service';
import { Item } from '@paperclip/models/item';
import { ApiItem } from '@paperclip/models/item/ApiItem';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { MessagesService } from '@paperclip/page/messages.service';

@Component({
  selector: 'pc-messages-make-offer-modal',
  templateUrl: './messages-make-offer-modal.component.html',
  styleUrls: ['./messages-make-offer-modal.component.scss']
})
export class MessagesMakeOfferModalComponent implements OnInit {
  items: Item[] = [];
  selectedItem: Item;
  dataLoading: boolean;
  loadingMore: boolean;
  loadMoreSkipValue = 0;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<MessagesMakeOfferModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: { offerType: 'make-offer' | 'buy-now'; authedUserId: string; otherUserId: string },
    private translateService: TranslateService,
    private messagesService: MessagesService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.getCompatibleUserItems();
  }

  public getCompatibleUserItems(loadMore = false) {
    if (loadMore) {
      this.loadMoreSkipValue += 30;
      this.loadingMore = true;
    } else {
      this.loadMoreSkipValue = 0;
      this.dataLoading = true;
      this.loadingMore = false;
    }

    this.messagesService.getCompatibleUserItems(this.modalData.otherUserId, this.loadMoreSkipValue).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.loadingMore = false;
        if (code === 1) {
          const items: Item[] = [];
          if (data.length > 0) {
            data.forEach((item: ApiItem) =>
              items.push(new Item(item, this.modalData.authedUserId, this.translateService.getBrowserCultureLang()))
            );
          }
          this.items = loadMore ? [...this.items, ...items] : items;
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      (error) => {
        this.coreService.handleError({});
      }
    );
  }

  public selectItem(selectedItem: Item) {
    this.items.forEach((item: Item) => (item.isSelected = false));
    selectedItem.isSelected = true;
    this.selectedItem = this.items.filter((item: Item) => item.isSelected)[0];
  }

  public makeOfferOrBuy() {
    if (this.modalData.offerType === 'make-offer') {
      this.router.navigate(['/make-offer'], { queryParams: { item: this.selectedItem.detail.id } });
    } else {
      this.goToItemCheckout();
    }
  }

  noDataClick() {}

  private goToItemCheckout(offerId = '') {
    this.router.navigate(['/checkout'], {
      queryParams: { item: this.selectedItem.detail.id, offerId: offerId }
    });
  }
}
