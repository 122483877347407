import { ActivatePaymentAccountModalComponent } from './activate-payment-account-modal/activate-payment-account-modal.component';
import { IdVerificationModalComponent } from './id-verification-modal/id-verification-modal.component';
import { IdVerificationUploadedModalComponent } from './id-verification-uploaded-modal/id-verification-uploaded-modal.component';
import { Payment3dsModalComponent } from './payment-3ds-modal/payment-3ds-modal.component';
import { PaymentAccountModalComponent } from './payment-account-modal/payment-account-modal.component';
import { PaymentProtectionModalComponent } from './payment-protection-modal/payment-protection-modal.component';
import { PendingBalanceExplainedModalComponent } from './pending-balance-explained-modal/pending-balance-explained-modal.component';
import { SelectPaperclipCreditModalComponent } from './select-paperclip-credit-modal/select-paperclip-credit-modal.component';

export * from './activate-payment-account-modal/activate-payment-account-modal.component';
export * from './id-verification-modal/id-verification-modal.component';
export * from './id-verification-uploaded-modal/id-verification-uploaded-modal.component';
export * from './payment-3ds-modal/payment-3ds-modal.component';
export * from './payment-account-modal/payment-account-modal.component';
export * from './payment-protection-modal/payment-protection-modal.component';
export * from './pending-balance-explained-modal/pending-balance-explained-modal.component';
export * from './select-paperclip-credit-modal/select-paperclip-credit-modal.component';

export const paymentsModals = [
  ActivatePaymentAccountModalComponent,
  PaymentAccountModalComponent,
  IdVerificationModalComponent,
  IdVerificationUploadedModalComponent,
  PaymentProtectionModalComponent,
  Payment3dsModalComponent,
  SelectPaperclipCreditModalComponent,
  PendingBalanceExplainedModalComponent
];
