import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { User } from '@paperclip/models/user';
import { NewProfileData } from '@paperclip/models/user/NewProfileData';
import { Observable, Subject } from 'rxjs';

import { PcApiResponse } from '../../models/misc/pc-api-response';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingsService {
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';

  /*-- update user data from any of the account setting components --*/
  private userData = new Subject<any>();
  private authedUser = new Subject<any>();

  updateUserData(user: User) {
    this.userData.next(user);
  }

  getUserData(): Observable<User> {
    return this.userData.asObservable();
  }

  sendUpdateAuthedUser(update: boolean) {
    this.authedUser.next(update);
  }

  getUpdateAuthedUser(): Observable<boolean> {
    return this.authedUser.asObservable();
  }

  /*-- update email verified status--*/
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private emailVerified = new Subject<any>();

  updateEmailVerified(verified: boolean) {
    this.emailVerified.next(verified);
  }

  getEmailVerified(): Observable<any> {
    return this.emailVerified.asObservable();
  }

  constructor(private http: HttpClient, private authService: AuthService) {}

  /*-- save profile --*/
  saveProfile(newProfileInfo: NewProfileData): Observable<any> {
    const formData: FormData = new FormData();
    for (const key in newProfileInfo) {
      if (newProfileInfo[key] !== null) {
        formData.append(key, newProfileInfo[key]);
      }
    }

    return this.http.post(`users/updateProfile`, formData);
  }

  /*-- check if email is available --*/
  checkEmailAvailability(email: string): Observable<any> {
    return this.http.get(`users/isEmailAvailable?email=${email}`);
  }

  /*-- check if username is available --*/
  checkUsernameAvailability(username: string): Observable<any> {
    return this.http.get(`users/isUsernameAvailable?username=${username}`);
  }

  /*-- set favourite categories --*/
  setFavouriteCategories(categories: any): Observable<any> {
    return this.http.post(`users/editMiscellaneousUserInfo`, { favouriteCategories: categories.toString() });
  }

  setSocialInventoryPrivacy(socialInventoryPrivacy: boolean): Observable<any> {
    return this.http.post(`users/editMiscellaneousUserInfo`, { privateSocialInventory: socialInventoryPrivacy });
  }

  setSocialInventoryTooltipSeen(): Observable<any> {
    return this.http.post(`users/editMiscellaneousUserInfo`, { socialInventoryTooltipSeen: true });
  }

  setSocialInventoryModalSeen(): Observable<any> {
    return this.http.post(`users/editMiscellaneousUserInfo`, { socialInventoryModalSeen: true });
  }

  /*-- verifications --*/
  sendVerificationEmail(email: string): Observable<any> {
    return this.http.post(`users/verifyEmail`, { userId: this.authedUserId, email: email });
  }

  /*-- email notification preferences --*/
  getEmailNotificationPreferences(): Observable<any> {
    return this.http.get(`users/emailing?userId=${this.authedUserId}`);
  }

  updateEmailNotificationPreferences({ emailPromotions, emailOffersAndReview }): Observable<any> {
    const params = {
      userId: this.authedUserId,
      emailPromotions: emailPromotions,
      emailOffersAndReview: emailOffersAndReview
    };

    const formData: FormData = new FormData();
    // eslint-disable-next-line guard-for-in
    for (const key in params) {
      formData.append(key, params[key]);
    }

    return this.http.post('users/updateEmailing', formData);
  }

  /*-- blocked users
    getBlockedUsers(skip: number): Observable<any> {
      return this.http.get(`users/blocked?userId=${this.authedUserId}&skip=${skip}`);
    }
  --*/

  /*-- change password-- */
  changePassword(currentPw: string, newPw: string, confirmPw: string): Observable<any> {
    return this.http.post(`users/updateProfile`, {
      oldPassword: currentPw,
      newPassword: newPw,
      newPasswordConfirm: confirmPw
    });
  }

  saveReferralCode(code: string): Observable<PcApiResponse> {
    return this.http.post(`users/referralCode`, { referralCode: code });
  }

  getStudentRoostLocations(term = '', skip = 0, limit = 0): Observable<any> {
    return this.http.get(`studentRoost/locations?term=${term}&skip=${skip}&limit=${limit}`);
  }

  saveStudentRoostLocation(locationId: string): Observable<PcApiResponse> {
    return this.http.post(`studentRoost/updateLocation`, { locationId });
  }

  removeStudentRoostLocation(locationId: string): Observable<PcApiResponse> {
    return this.http.post(`studentRoost/removeLocation`, { locationId });
  }
}
