import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-id-verification-uploaded-modal',
  templateUrl: './id-verification-uploaded-modal.component.html'
})
export class IdVerificationUploadedModalComponent {
  constructor(public dialogRef: MatDialogRef<IdVerificationUploadedModalComponent>) {}
}
