<div class="category-scroll" [ngClass]="{ 'category-scroll--w-title': exploreTitle }">
  <h3 *ngIf="exploreTitle">{{ 'home-feed.explore' | translate }}</h3>
  <div class="category-scroll__container faded-content">
    <div
      #scrollDivRef
      class="category-scroll__container__scroll smooth-scroll hide-scrollbar"
      (mousewheel)="getScrollPosition()"
    >
      <a *ngIf="showEverything" class="btn" [routerLink]="['/search']" [queryParams]="{ type: categoriesType }">{{
        'buttons.everything' | translate
      }}</a>
      <a
        class="btn"
        *ngFor="let category of categories"
        [routerLink]="['/search']"
        [queryParams]="{ type: categoriesType, categoryId: category.id }"
        queryParamsHandling="merge"
        >{{ category.name }}</a
      >
    </div>
  </div>
  <div class="category-scroll__controls">
    <pc-arrow-icon (click)="scrollGridLeft()" [disabled]="disableScrollLeft || disableButtons"></pc-arrow-icon>
    <pc-arrow-icon
      (click)="scrollGridRight()"
      orientation="right"
      [disabled]="disableScrollRight || disableButtons"
    ></pc-arrow-icon>
  </div>
</div>
