<div pcClickStopPropagation class="view-type-filter">
  <h2>{{ 'search.filters.view.title' | translate }}</h2>
  <ul class="checkbox-list">
    <li pcClickStopPropagation *ngFor="let type of viewTypes" (click)="selectView(type)" class="checkbox-list__item">
      <div class="checkbox-list__left">
        <img
          [src]="'/assets/icons/search/filters/view-' + (type.label === 'list' ? 'list-light.svg' : 'grid-light.svg')"
          alt=""
        />
        <p class="highlight-text">{{ 'search.filters.view.' + type.label | translate }}</p>
      </div>
      <pc-checkmark-icon [checked]="type.isSelected" [size]="32"></pc-checkmark-icon>
    </li>
  </ul>
</div>
