import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '@paperclip/core/auth/auth.service';

export interface ModalConfig extends MatDialogConfig {
  type?: 'generic' | 'centered' | 'left' | 'image-viewer' | 'item-image-viewer' | 'tabbed' | 'edit-image';
  scrollableContent?: boolean;
  extraClass?: string;
  needsAuth?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private defaultModalConfig: ModalConfig = {
    type: 'generic',
    panelClass: 'modal-container',
    hasBackdrop: true,
    backdropClass: 'modal-backdrop',
    closeOnNavigation: true,
    autoFocus: false,
    disableClose: false,
    scrollStrategy: this.overlay.scrollStrategies.block(),
    needsAuth: true
  };

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private overlay: Overlay,
    private authService: AuthService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.dialog.closeAll();
        this.bottomSheet.dismiss();
      }
    });
  }

  public open(component: ComponentType<any>, config?: ModalConfig, bottomSheet = false): void {
    const modalConfig = { ...this.defaultModalConfig, ...config };

    if (modalConfig.needsAuth && !this.authService.isLoggedIn()) {
      this.router.navigate(['/signin']);
    } else {
      if (bottomSheet) {
        this.openBottomSheet(component, modalConfig);
      } else {
        this.openModal(component, modalConfig);
      }
    }
  }

  private openModal(modal: ComponentType<any>, modalConfig?: ModalConfig): void {
    const modalRef = this.dialog.open(modal, modalConfig);
    modalRef.addPanelClass(`modal-container--${modalConfig.type}`);

    if (modalConfig.extraClass) {
      modalRef.addPanelClass(`${modalConfig.extraClass}`);
    }

    if (modalConfig.scrollableContent) {
      modalRef.addPanelClass(`modal-container--scrollable`);
    }

    modalRef.afterClosed().subscribe((closeAction: string) => {
      if (closeAction) {
        switch (closeAction) {
          case 'goto-signup':
            this.router.navigate(['/signup']);
            break;
        }
      }
    });
  }

  private openBottomSheet(modal: any, config?: any): void {
    if (config.extraClass) {
      config.panelClass = `${config.extraClass}`;
    }

    const bottomSheetRef = this.bottomSheet.open(modal, config);

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    bottomSheetRef.afterDismissed().subscribe(() => {});
  }
}
