import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'pc-buyer-address-bs',
  templateUrl: './buyer-address-bs.component.html',
  styleUrls: ['./buyer-address-bs.component.scss']
})
export class BuyerAddressBsComponent {
  constructor(
    public bottomSheetRef: MatBottomSheetRef<BuyerAddressBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public address: string
  ) {}
}
