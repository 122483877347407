import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { Order, OrderState } from '@paperclip/models/order';

@Component({
  selector: 'pc-selling-menu',
  templateUrl: './selling-menu.component.html'
})
export class SellingMenuComponent {
  @Input() menuData: { order: Order; showItemLink: boolean };
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();

  canSellSimilar() {
    return true;
  }
  canDeleteTrade() {
    return this.menuData.order.state === OrderState.Delivered ||
    this.menuData.order.state === OrderState.Refunded 
  }
}
