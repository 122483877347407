import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadApiConfig } from '@paperclip/core/app.config';
import { environment } from '@paperclip/environments/environment';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { SentryErrorHandler } from '@sentry/angular';

if (!window.location.hostname.includes('localhost')) {
  Sentry.init({
    dsn: "https://c83057ac507da9adf64bf6e5b47f5f24@o4508408074534912.ingest.de.sentry.io/4508413296181328",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost",  "https://api.paperclip.co", "https://staging-api.paperclip.co"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Profiling
    profilesSampleRate: 1.0, // Capture 100% of the profiles
  });
}


loadApiConfig()
  .then(() => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .then(() => {
        if ('serviceWorker' in navigator && environment.production) {
          navigator.serviceWorker.register('ngsw-worker.js');
        }
      })
      .catch((err) => console.log(err));
  })
  .catch((err) => console.log(err));
