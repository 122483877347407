<div class="modal messages-add-item-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>
      {{ modalData.offerType === 'make-offer' ? ('buttons.make-offer' | translate) : ('buttons.buy-now' | translate) }}
    </h1>
    <p>{{ 'modals.messages-make-offer.' + modalData.offerType + '-subtitle' | translate }}</p>
  </div>

  <div class="modal-body">
    <div class="list-title">
      <h3>{{ 'modals.messages-make-offer.their-items' | translate }}</h3>
    </div>
    <div class="data-list">
      <ul class="checkbox-list scrollable-content" infiniteScroll [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300" [scrollWindow]="false" (scrolled)="getCompatibleUserItems(true)">
        <ng-container *ngIf="dataLoading">
          <li *ngFor="let data of [0, 1, 2]" class="checkbox-list__item">
            <pc-small-item [item]="null" [link]="false"></pc-small-item>
          </li>
        </ng-container>
        <ng-container *ngIf="!dataLoading && items.length > 0">
          <li *ngFor="let item of items" (click)="selectItem(item)" class="checkbox-list__item">
            <pc-small-item [item]="item" [link]="false"></pc-small-item>
            <pc-checkmark-icon [checked]="item.isSelected" [size]="32"></pc-checkmark-icon>
          </li>
        </ng-container>
        <div *ngIf="!dataLoading && loadingMore" class="loading-more-spinner"></div>
      </ul>
    </div>
  </div>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large btn-primary" (click)="makeOfferOrBuy()"
      [disabled]="dataLoading || items?.length === 0 || !selectedItem">
      {{ modalData.offerType === 'make-offer' ? ('buttons.make-offer' | translate) : ('buttons.buy-now' | translate) }}
    </button>
  </div>
</div>
