import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AppStateService } from '@paperclip/core/app-state.service';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { NewMessageModalComponent } from '@paperclip/modals/messaging/messaging-modals';
import { ModalService } from '@paperclip/modals/modal.service';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { ConversationSummary } from '@paperclip/models/messages/ConversationSummary';
import { UserNotifications } from '@paperclip/models/user/UserNotifications';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-header-notifications-messages-menu',
  templateUrl: './header-notifications-messages-menu.component.html',
  styleUrls: ['./header-notifications-messages-menu.component.scss']
})
export class HeaderNotificationsMenuComponent implements OnInit, OnChanges {
  @Input() menuOpened: boolean;
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();

  /*-- properties --*/
  tabs: string[] = ['messages', 'notifications'];
  activeTab: string;
  conversations: ConversationSummary[] = [];
  conversationsLoading: boolean;
  loggedInUserId: string = this.authService.getAuthedUser().detail.id;
  updateConversationsListIntervalSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private appStateService: AppStateService
  ) {}

  ngOnInit() {
    this.activeTab = 'messages';
  }

  ngOnChanges() {
    this.getConversations();
  }

  changeSection(section: string) {
    this.activeTab = section;
    this.getConversations();
  }

  closeMenu() {
    this.menuAction.emit({ action: 'close-menu' });
  }

  private getConversations() {
    this.conversations = this.appStateService.appState.conversations.slice(0, 3);
  }

  public openNewMessageModal() {
    this.closeMenu();
    this.modalService.open(NewMessageModalComponent, {
      type: 'centered',
      scrollableContent: true
    });
  }

  get userNotificationsState(): UserNotifications {
    return this.appStateService.appState.notifications;
  }
}
