import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAction } from '@paperclip/models/component/menu-action';

@Component({
  selector: 'pc-clear-delete-all-menu',
  templateUrl: './clear-delete-all-menu.component.html'
})
export class ClearDeleteAllMenuComponent {
  @Input() menuData: { contentType: 'notifications' | 'messages'; dataCount: number };
  @Input() menuOpened: boolean;
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
}
