<form [formGroup]="addItemForm">
  <div formGroupName="paymentDetails" [ngClass]="{ 'force-full-width': forceFullWidth }">
    <div
      class="input-group input-group--label-start input-group--btn-end"
      [ngClass]="{
        'input-group--large': largeInput
      }"
    >
      <p class="highlight-text input-group__label">{{ currency.symbol }}</p>
      <input
        pcCurrencyInputPadding
        formControlName="itemPrice"
        class="colour-secondary"
        type="text"
        placeholder="0.00"
        [textMask]="{ mask: currency.mask }"
        (keydown.enter)="$event.preventDefault()"
      />
      <pc-clear-input-btn
        *ngIf="addItemForm.get('paymentDetails').get('itemPrice').value"
        class="input-group__btn input-group__btn--end"
        (click)="clearItemDetail.emit('itemPrice')"
      >
      </pc-clear-input-btn>
    </div>
    <div
      *ngIf="
        addItemForm.get('paymentDetails').get('itemPrice').dirty &&
        addItemForm.get('paymentDetails').get('itemPrice').hasError('min')
      "
      class="validation-error-message"
    >
      <p>{{ 'validation-error.minimum-amount' | translate }}</p>
    </div>
  </div>
</form>
