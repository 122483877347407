import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationMessageService {
  private readonly notifier: NotifierService;
  constructor(private notifierService: NotifierService, private translateService: TranslateService) {
    this.notifier = this.notifierService;
  }

  showConfirmationMessage(notificationText: string, type: 'default' | 'error' | 'success' | 'warning' = 'default') {
    this.notifier.notify(type, this.translateService.instant('confirmation-message.' + notificationText));
  }
}
