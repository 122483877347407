import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private profileUserId = new Subject<any>();

  /*-- pass userId from parent profile component to child components --*/
  setProfileUserId(userId: string) {
    this.profileUserId.next(userId);
  }

  getProfileUserId() {
    return this.profileUserId.asObservable();
  }

  constructor(private http: HttpClient) {}

  getUserByUsername(username: string): Observable<any> {
    return this.http.get(`users/byUsername/${username}`);
  }

  getUserById(userId: string): Observable<any> {
    return this.http.get(`users/getUser?user1Id=${userId}`);
  }

  getUserReviews(userId: string, skip: number): Observable<any> {
    return this.http.get(`reviews/getByUser?userId=${userId}&skip=${skip}`);
  }
}
