import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { DroppedImage } from '@paperclip/models/image-dropzone/dropped-image';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddItemService {
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';
  public itemImages: DroppedImage[];

  constructor(private http: HttpClient, private authService: AuthService) {}

  public storeItemImages(images: DroppedImage[]) {
    this.itemImages = images;
  }

  public getItemImages(): DroppedImage[] {
    return this.itemImages;
  }

  public addItem(formData: FormData): Observable<any> {
    return this.http.post<any>(`items`, formData);
  }

  public editItem(formData: FormData, itemId: string): Observable<any> {
    return this.http.post<any>(`items/${itemId}`, formData);
  }

  public getDraftItem(): Observable<any> {
    return this.http.get(`drafts/user?userId=${this.authedUserId}`);
  }

  public saveDraftItem(formData: FormData): Observable<any> {
    return this.http.post<any>(`drafts`, formData);
  }
}
