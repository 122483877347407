import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
/*-- model --*/
import { PartnerAd } from '@paperclip/models/misc/PartnerAds';
/* -- interval observable -- */
import { interval, Subscription } from 'rxjs';

/*-- services --*/
import { PartnerAdService } from './partner-ads.service';

@Component({
  selector: 'pc-partner-ad',
  templateUrl: './partner-ads.component.html',
  styleUrls: ['./partner-ads.component.scss']
})
export class PartnerAdsComponent implements OnChanges, OnDestroy {
  /*-- inputs --*/
  @Input()
  adSize = 'rectangle';

  @Input() isSmallSize = false;

  /* -- handle ad display -- */
  ads: PartnerAd[] = [];
  intervalObserver: Subscription;
  currentAdIndex: number;

  constructor(private partnerAdsService: PartnerAdService) {}

  ngOnChanges() {
    this.ads = this.partnerAdsService.getPartnerAdvertWithType(this.adSize);
    if (this.ads.length > 0) {
      this.getRandomizedAdvertStartingIndex();
    }
  }

  getRandomizedAdvertStartingIndex() {
    if (this.ads.length > 1) {
      this.currentAdIndex = Math.floor(Math.random() * this.ads.length);
      this.intervalObserver = interval(10000).subscribe(() => {
        this.incrementCurrentAdIndex();
      });
    } else {
      this.currentAdIndex = 0;
    }
  }

  incrementCurrentAdIndex() {
    // increment the index if it is lower than the index of the last advert
    // if it is the last advert, show the first one again
    if (this.currentAdIndex === this.ads.length - 1) {
      this.currentAdIndex = 0;
    } else {
      this.currentAdIndex += 1;
    }
  }

  openLinkInNewTab() {
    if (this.ads[this.currentAdIndex].linkUrl !== '#' && this.ads[this.currentAdIndex].linkUrl !== null) {
      window.open(this.ads[this.currentAdIndex].linkUrl, '_blank');
    }
  }

  ngOnDestroy() {
    // check if we have instantiated this subscription, because
    // if there were no ads then we wouldn't have needed to
    if (this.intervalObserver) {
      this.intervalObserver.unsubscribe();
    }
  }
}
