import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { ItemCategory } from '@paperclip/models/item/ItemCategory';

@Component({
  selector: 'pc-category-scroll',
  templateUrl: './category-scroll.component.html',
  styleUrls: ['./category-scroll.component.scss']
})
export class CategoryScrollComponent implements OnChanges {
  @Input() categories: ItemCategory[];
  @Input() categoriesType = 'items';
  @Input() exploreTitle = true;
  @Input() showEverything = true;
  @Input() disableButtons = false;
  @ViewChild('scrollDivRef') scrollDivRef: ElementRef;
  scrollLeftValue: number;
  disableScrollLeft = true;
  disableScrollRight: boolean;
  scrollDistance = 300;

  ngOnChanges() {
    setTimeout(() => {
      this.getScrollPosition();
    }, 100);
  }

  public scrollGridLeft() {
    if (!this.disableScrollLeft && !this.disableButtons) {
      this.scrollDivRef.nativeElement.scrollLeft -= this.scrollDistance;
      this.disableScrollRight = false;

      // disable scroll left?
      setTimeout(() => {
        const scrollLeftValue = this.scrollDivRef.nativeElement.scrollLeft;
        this.disableScrollLeft = scrollLeftValue <= 0 ? true : false;
      }, 500);
    }
  }

  public scrollGridRight() {
    if (!this.disableScrollRight && !this.disableButtons) {
      this.scrollDivRef.nativeElement.scrollLeft += this.scrollDistance;
      this.disableScrollLeft = false;

      // disable scroll right?
      const scrollWidth = this.scrollDivRef.nativeElement.scrollWidth;
      const offsetWidth = this.scrollDivRef.nativeElement.offsetWidth;
      const totalScrollWidth = scrollWidth - offsetWidth - 1;

      setTimeout(() => {
        const scrollLeftValue = this.scrollDivRef.nativeElement.scrollLeft;
        this.disableScrollRight = scrollLeftValue >= totalScrollWidth ? true : false;
      }, 500);
    }
  }

  // disable / enable swipeGrid navigation when using mouse to scroll
  public getScrollPosition() {
    const scrollWidth = this.scrollDivRef.nativeElement.scrollWidth;
    const offsetWidth = this.scrollDivRef.nativeElement.offsetWidth;
    const totalScrollWidth = scrollWidth - offsetWidth - 1;

    this.disableScrollLeft = this.scrollDivRef.nativeElement.scrollLeft <= 0 ? true : false;
    this.disableScrollRight = this.scrollDivRef.nativeElement.scrollLeft >= totalScrollWidth ? true : false;
  }
}
