<div
  class="small-data-container"
  [ngClass]="{
    'loading-data': userLoading,
    'small-data-container--star-rating-bottom': starRating?.show && starRating?.position === 'bottom'
  }"
>
  <a
    class="small-data small-data--user"
    [ngClass]="{ 'small-data--link': link }"
    [routerLink]="link && user?.detail ? ['/u', user?.detail.username] : []"
  >
    <div
      class="small-data__thumbnail"
      [ngStyle]="{
        width: thumbnailSize + 'px',
        height: thumbnailSize + 'px',
        'margin-right': thumbnailSize === 40 ? '8px' : '16px'
      }"
    >
      <img class="bg-image data-item__picture" [src]="user?.detail?.picture" [alt]="user?.detail?.fullName" />
    </div>

    <div class="small-data__detail" [ngClass]="{ 'small-data__detail--no-meta-detail': metaDetail === 'none' }">
      <p class="highlight-text faded-content">
        {{ user?.detail?.fullName
        }}<img
          *ngIf="user?.detail?.verifiedBadge"
          class="verified-badge"
          [src]="user?.detail?.verifiedBadge"
          alt="{{ 'users.' + user?.detail?.verifiedBadgeAlt | translate }}"
        />
      </p>
      <div class="small-data__detail__meta">
        <p *ngIf="showSuspendedTag">{{ 'tags.user.suspended' | translate }}</p>
        <p #metaTextRef *ngIf="!showSuspendedTag && metaDetail !== 'none'" class="p-small meta-detail">
          {{ metaDetail === 'location' ? user?.detail?.location : '@' + user?.detail?.username }}
        </p>
        <span
          #tagRef
          *ngIf="showTag && user?.detail?.isFollowed"
          class="user-tag user-tag--following"
          [ngClass]="{ 'user-tag--following--wrapped': tagWrapped }"
          >{{ 'tags.user.following' | translate }}</span
        >
        <span
          #studentRoostTagRef
          *ngIf="showStudentRoostTag && user?.studentRoostLocation?.city"
          class="user-tag user-tag--student-roost"
          [ngClass]="{ 'user-tag--student-roost-wrapped': studentRoostTagWrapped }"
          >{{ 'user-profile.header.student-roost-resident' | translate }}</span
        >
      </div>
    </div>
  </a>

  <pc-menu *ngIf="!userLoading && menu && authedUserId !== user?.detail.id" [menu]="menu?.name" [menuData]="menu?.data">
  </pc-menu>

  <pc-user-star-rating
    *ngIf="starRating?.show"
    [showLoading]="userLoading"
    [user]="user"
    [display]="starRating?.position === 'bottom' ? 'inline' : null"
    [usernameLink]="linkReviews ? user?.detail?.username : null"
  >
  </pc-user-star-rating>
</div>
