import { environment } from '@paperclip/environments/environment';
import { ApiConfig, ApiConfigApi, ApiConfigApp, ApiConfigMangoPay, ApiConfigWeb } from '@paperclip/models/ApiConfig';
import { ApiFeedSection } from '@paperclip/models/feed/NewApiFeedSection';
import { FlatCategory } from '@paperclip/models/item/FlatCategory';
import { ItemCategory } from '@paperclip/models/item/ItemCategory';

import { ApiConfigExternalLinks } from '../models/ApiConfig';
import { of } from 'rxjs';

export class AppConfig {
  static app: ApiConfigApp = null;
  static api: ApiConfigApi = null;
  static web: ApiConfigWeb = null;
  static mangoPay: ApiConfigMangoPay = null;
  static externalLinks: ApiConfigExternalLinks = null;
  static share: {
    site: string;
    item: string;
  };
  static categories: ItemCategory[] = [];
  static flatCategories: FlatCategory[] = [];
  static feedSections: ApiFeedSection[] = [];
}

export function loadApiConfig() {
  let configUrl = environment.production
    ? 'https://api.paperclip.co/web/configuration'
    : 'https://staging-api.paperclip.co/web/configuration';

  if(environment.local) 
    configUrl = 'http://localhost:5000/web/configuration'

  return new Promise<void>((resolve, reject) => {
    fetch(configUrl)
      .then((res) => res.json())
      .then((config: ApiConfig) => {
        AppConfig.app = config.app;
        AppConfig.api = { ...config.api, images: config.api.url + config.api.images };
        AppConfig.mangoPay = config.mangoPay;        
        AppConfig.web = config.web;
        AppConfig.externalLinks = config.externalLinks;
        setShareLinks(config.api);
        // loadFeedSections()
        //   .then(() => resolve())
        //   .catch((error) => reject(error));
        loadCategories()
          .then(() => resolve())
          .catch((error) => reject(error));
      })
      .catch((error: any) => reject(error));
  });
}

function setShareLinks(configAapi: ApiConfigApi) {
  AppConfig.share = {
    site: `${configAapi.url}sp`,
    item: `${configAapi.url}si`
  };
}

export function loadFeedSections() {
  let userTokenQuery = '';
  const userToken = localStorage.getItem('userToken');
  if (userToken !== 'undefined' && userToken !== 'null' && userToken != null) {
    userTokenQuery = `&userToken=${localStorage.getItem('userToken') || ''}`;
  }

  return new Promise<void>((resolve, reject) => {
    fetch(AppConfig.api.url + AppConfig.api.version + `/feed?${userTokenQuery}`)
      .then((res) => res.json())
      .then(({ code, data }) => {
        if (code === 1) {
          AppConfig.feedSections = data;
          resolve();
        } else {
          reject(code);
        }
      })
      .catch((error) => reject(error));
  });
}

export function loadCategories() {
  return new Promise<void>((resolve, reject) => {
    fetch(AppConfig.api.url + AppConfig.api.version + `/categories/tree`)
      .then((res) => res.json())
      .then(({ code, data }) => {
        if (code === 1) {
          AppConfig.categories = data;
          setFlatCategories();
          resolve();
        } else {
          reject(code);
        }
      })
      .catch((error) => reject(error));
  });
}

function setFlatCategories() {
  AppConfig.categories.forEach((level1Category: ItemCategory) => {
    AppConfig.flatCategories.push({
      categoryLevel: 1,
      id: level1Category.id,
      name: level1Category.name,
      level1Category: level1Category,
      level2Category: null,
      level3Category: null
    });

    level1Category.categories.forEach((level2Category: ItemCategory) => {
      AppConfig.flatCategories.push({
        categoryLevel: 2,
        id: level2Category.id,
        name: level2Category.name,
        level1Category: level1Category,
        level2Category: level2Category,
        level3Category: null
      });

      if (level2Category.categories) {
        level2Category.categories.forEach((level3Category: ItemCategory) => {
          AppConfig.flatCategories.push({
            categoryLevel: 3,
            id: level3Category.id,
            name: level3Category.name,
            level1Category: level1Category,
            level2Category: level2Category,
            level3Category: level3Category
          });
        });
      }
    });
  });
}
