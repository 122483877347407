import { EmptyState } from './EmptyState';

export const emptyStates: EmptyState[] = [
  {
    page: '404',
    size: 'large',
    image: 'page-not-found',
    button: {
      action: 'go-home'
    }
  },
  {
    page: 'search',
    size: 'large',
    image: 'no-search-results'
  },
  {
    page: 'buying-active-all',
    size: 'large',
    image: 'buying/buying-active',
    button: {
      action: 'browse-items'
    },
    responsive: true
  },
  {
    page: 'buying-active-waiting-for-response',
    size: 'large',
    image: 'buying/waiting-for-response',
    responsive: true
  },
  {
    page: 'buying-active-awaiting-payment',
    size: 'large',
    image: 'buying/awaiting-payment',
    responsive: true
  },
  {
    page: 'buying-active-awaiting-dispatch',
    size: 'large',
    image: 'buying/awaiting-dispatch',
    responsive: true
  },
  {
    page: 'buying-active-awaiting-delivery',
    size: 'large',
    image: 'buying/awaiting-delivery',
    responsive: true
  },
  {
    page: 'buying-active-requires-collection',
    size: 'large',
    image: 'buying/requires-collection',
    responsive: true
  },
  {
    page: 'buying-active-payment-failed',
    size: 'large',
    image: 'buying/payment-failed',
    responsive: true
  },
  {
    page: 'buying-active-active-dispute',
    size: 'large',
    image: 'buying/active-dispute',
    responsive: true
  },
  {
    page: 'buying-history-all',
    size: 'large',
    image: 'buying/buying-history',
    button: {
      action: 'browse-items'
    },
    responsive: true
  },
  {
    page: 'buying-history-completed',
    size: 'large',
    image: 'buying/completed',
    responsive: true
  },
  {
    page: 'buying-history-declined',
    size: 'large',
    image: 'buying/declined',
    responsive: true
  },
  {
    page: 'buying-history-refunded',
    size: 'large',
    image: 'buying/refunded',
    responsive: true
  },
  {
    page: 'buying-history-dispute-resolved',
    size: 'large',
    image: 'buying/dispute-resolved',
    responsive: true
  },
  {
    page: 'selling-active-all',
    size: 'large',
    image: 'selling/selling-active',
    button: {
      action: 'add-item'
    },
    responsive: true
  },
  {
    page: 'selling-active-waiting-for-response',
    size: 'large',
    image: 'selling/waiting-for-response',
    responsive: true
  },
  {
    page: 'selling-active-awaiting-payment',
    size: 'large',
    image: 'selling/awaiting-payment',
    responsive: true
  },
  {
    page: 'selling-active-awaiting-dispatch',
    size: 'large',
    image: 'selling/awaiting-dispatch',
    responsive: true
  },
  {
    page: 'selling-active-awaiting-delivery',
    size: 'large',
    image: 'selling/awaiting-delivery',
    responsive: true
  },
  {
    page: 'selling-active-requires-collection',
    size: 'large',
    image: 'selling/requires-collection',
    responsive: true
  },
  {
    page: 'selling-active-payment-failed',
    size: 'large',
    image: 'selling/payment-failed',
    responsive: true
  },
  {
    page: 'selling-active-active-dispute',
    size: 'large',
    image: 'selling/active-dispute',
    responsive: true
  },
  {
    page: 'selling-active-mark-as-completed',
    size: 'large',
    image: 'selling/mark-as-completed',
    responsive: true
  },
  {
    page: 'selling-history-all',
    size: 'large',
    image: 'selling/selling-history',
    button: {
      action: 'add-item'
    },
    responsive: true
  },
  {
    page: 'selling-history-completed',
    size: 'large',
    image: 'selling/completed',
    responsive: true
  },
  {
    page: 'selling-history-declined',
    size: 'large',
    image: 'selling/declined',
    responsive: true
  },
  {
    page: 'selling-history-refunded',
    size: 'large',
    image: 'selling/refunded',
    responsive: true
  },
  {
    page: 'selling-history-dispute-resolved',
    size: 'large',
    image: 'selling/resolved-dispute',
    responsive: true
  },
  {
    page: 'feed-featured-items',
    size: 'x-small'
  },
  {
    page: 'feed-default',
    size: 'x-small'
  },
  {
    page: 'profile-items',
    size: 'large',
    image: 'profile/no-items',
    responsive: true
  },
  {
    page: 'profile-items-own',
    size: 'large',
    image: 'profile/no-items',
    button: {
      action: 'add-item'
    },
    responsive: true
  },
  {
    page: 'profile-items-own-searching',
    size: 'large',
    image: 'profile/no-items',
    responsive: true
  },
  {
    page: 'profile-favourites',
    size: 'large',
    image: 'profile/no-favourites',
    responsive: true
  },
  {
    page: 'profile-sold',
    size: 'large',
    image: 'profile/profile-sold',
    responsive: true
  },
  {
    page: 'profile-inactive',
    size: 'large',
    image: 'profile/profile-inactive',
    responsive: true
  },
  {
    page: 'profile-inactive-searching',
    size: 'large',
    image: 'profile/profile-inactive',
    responsive: true
  },
  {
    page: 'user-following',
    size: 'modal',
    image: 'no-users'
  },
  {
    page: 'user-followers',
    size: 'modal',
    image: 'no-users'
  },
  {
    page: 'user-following-own',
    size: 'modal',
    image: 'no-users'
  },
  {
    page: 'user-followers-own',
    size: 'modal',
    image: 'no-users'
  },
  {
    page: 'user-reviews',
    size: 'modal',
    image: 'profile/no-reviews'
  },
  {
    page: 'user-reviews-own',
    size: 'modal',
    image: 'profile/no-reviews'
  },
  {
    page: 'suspended-profile',
    size: 'small'
  }
];
