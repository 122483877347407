/*====================
functions for payment card
validation and formatting

taken from (modified a little and converted to TS)
https://github.com/jessepollak/payment
but only the functions we need and kept as
local code as a security concern
====================*/

import { cards, defaultMask } from './cards';

/*====================
helper functions
====================*/
function getCardFromNumber(cardNumber: string) {
  cardNumber = cardNumber.replace(/\D/g, '');
  for (const card of cards) {
    if (card.pattern.test(cardNumber)) {
      return card;
    }
  }
}

function getCardFromType(type: string) {
  for (const card of cards) {
    if (card.type === type) {
      return card;
    }
  }
}

function luhnCheck(num: string) {
  let odd = true;
  let sum = 0;

  const digits: any[] = (num + '').split('').reverse();

  for (let digit of digits) {
    digit = parseInt(digit, 10);
    if ((odd = !odd)) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }

  return sum % 10 === 0;
}

/*====================
formatting
====================*/
export function getCardMask(cardNumber: string): (string | RegExp)[] {
  return !cardNumber ? defaultMask : getCardFromNumber(cardNumber)?.mask || defaultMask;
}

/*====================
validation functions
====================*/
export function cardType(cardNumber: string) {
  return !cardNumber ? null : getCardFromNumber(cardNumber)?.type || null;
}

export function validateCardNumber(cardNumber: string) {
  cardNumber = cardNumber.replace(/\D/g, '');
  if (!/^\d+$/.test(cardNumber)) {
    return false;
  }

  const card = getCardFromNumber(cardNumber);
  if (!card) {
    return false;
  }

  return card.length.includes(cardNumber.length) && (card.luhn === false || luhnCheck(cardNumber));
}

export function validateCardExpiry(monthYear: string) {
  let cardExpiryMonth: any = monthYear.split('/')[0];
  let cardExpiryYear: any = monthYear.split('/')[1];

  if (!/^\d+$/.test(cardExpiryMonth)) {
    return false;
  }
  if (!/^\d+$/.test(cardExpiryYear)) {
    return false;
  }

  cardExpiryMonth = parseInt(cardExpiryMonth, 10);

  if (!cardExpiryMonth || !(cardExpiryMonth <= 12)) {
    return false;
  }

  if (cardExpiryYear.toString().length === 2) {
    let prefix: any = new Date().getFullYear();
    prefix = prefix.toString().slice(0, 2);
    cardExpiryYear = prefix + cardExpiryYear;
  }

  const expiry = new Date(cardExpiryYear, cardExpiryMonth);
  const currentTime = new Date();

  // Months start from 0 in JavaScript
  expiry.setMonth(expiry.getMonth() - 1);

  // The cc expires at the end of the month,
  // so we need to make the expiry the first day
  // of the month after
  expiry.setMonth(expiry.getMonth() + 1, 1);

  return expiry > currentTime;
}

export function validateCardCVC(cvc: string, type?: string) {
  if (!/^\d+$/.test(cvc)) {
    return false;
  }

  if (type && getCardFromType(type)) {
    // Check against an explicit card type
    return cvc.length === getCardFromType(type).cvcLength;
  } else {
    // Check against all types
    return cvc.length >= 3 && cvc.length <= 4;
  }
}
