import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FilterItem } from '@paperclip/models/search/filter-item';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';

@Component({
  selector: 'pc-search-view-filter',
  templateUrl: './search-view-filter.component.html',
  styleUrls: ['./search-view-filter.component.scss']
})
export class SearchViewFilterComponent implements OnChanges {
  @Input() searchFilters: SearchFilters;
  viewTypes: FilterItem[];
  selectedView: FilterItem;

  constructor(private router: Router) {}

  ngOnChanges(searchFiltersChanges: SimpleChanges): void {
    if (searchFiltersChanges) {
      this.initialiseViewFilter();
    }
  }

  private initialiseViewFilter() {
    this.viewTypes = [
      { label: 'grid', isSelected: this.searchFilters.view === 'grid' },
      { label: 'list', isSelected: this.searchFilters.view === 'list' }
    ];

    this.selectedView = this.viewTypes.filter((type: FilterItem) => type.isSelected)[0];
    this.selectedView.isSelected = true;
  }

  public selectView(view: FilterItem) {
    if (view.label !== this.selectedView.label) {
      this.viewTypes.forEach((type: FilterItem) => {
        type.isSelected = type.label === view.label;
      });
      this.selectedView = this.viewTypes.filter((type: FilterItem) => type.isSelected)[0];
      this.router.navigate(['/search'], {
        queryParams: { view: this.selectedView.label },
        queryParamsHandling: 'merge'
      });
    }
  }
}
