import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { User } from '@paperclip/models/user';

@Component({
  selector: 'pc-small-user',
  templateUrl: './small-user.component.html'
})
export class SmallUserComponent implements OnChanges, AfterViewChecked {
  @Input() user: User;
  @Input() link = true;
  @Input() linkReviews = false;
  @Input() metaDetail: 'location' | 'username' | 'none' = 'location';
  @Input() showTag = true;
  @Input() menu: { name: string; data: any };
  @Input() starRating: { show: boolean; position: 'bottom' | 'right' } = { show: false, position: 'bottom' };
  @Input() showStudentRoostTag = false;
  @Input() showSuspendedTag = false;
  @ViewChild('metaTextRef') metaTextRef: ElementRef;
  @ViewChild('tagRef') tagRef: ElementRef;
  @ViewChild('studentRoostTagRef') studentRoostTagRef: ElementRef;
  authedUserId = this.authService.getAuthedUser()?.detail?.id || '';
  thumbnailSize = 40;
  userLoading: boolean;
  tagWrapped = false;
  studentRoostTagWrapped = false;

  constructor(private authService: AuthService, private cdref: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.userLoading = changes.user && !changes.user.currentValue;
    }
  }

  ngAfterViewChecked(): void {
    const metaTextRefTop = this.metaTextRef?.nativeElement.getBoundingClientRect().top;
    const tagRefTop = this.tagRef?.nativeElement.getBoundingClientRect().top;
    const studentRoostTagRefTop = this.studentRoostTagRef?.nativeElement.getBoundingClientRect().top;
    this.tagWrapped = tagRefTop > metaTextRefTop;
    this.studentRoostTagWrapped = studentRoostTagRefTop > metaTextRefTop;
    this.cdref.detectChanges();
  }
}
