import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '@paperclip/core/app.config';

@Component({
  selector: 'pc-account-suspended-modal',
  templateUrl: './account-suspended-modal.component.html',
  styleUrls: ['./account-suspended-modal.component.scss']
})
export class AccountSuspendedModalComponent {
  externalLinks = AppConfig.externalLinks;

  constructor(public dialogRef: MatDialogRef<AccountSuspendedModalComponent>) {}
}
