import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@paperclip/core/core.service';
import { ItemCheckout } from '@paperclip/models/item/item-checkout';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { ApiPaperclipCredit, PaperclipCredit } from '@paperclip/models/payments/PaperclipCredit';
import { WalletService } from '@paperclip/page/wallet/wallet.service';

@Component({
  selector: 'pc-select-paperclip-credit-modal',
  templateUrl: './select-paperclip-credit-modal.component.html',
  styleUrls: ['./select-paperclip-credit-modal.component.scss']
})
export class SelectPaperclipCreditModalComponent implements OnInit, OnDestroy {
  allCreditsLoaded: boolean;
  loadingMoreCredits: boolean;
  selectedCredits: PaperclipCredit[] = [];
  totalSelectedCredit = 0;
  orderTotal: number;

  constructor(
    public dialogRef: MatDialogRef<SelectPaperclipCreditModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { itemCheckout: ItemCheckout; complete: () => {} },
    private walletService: WalletService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.allCreditsLoaded = this.modalData.itemCheckout.userAvailableCredits.length < 30;
    this.orderTotal =
      this.modalData.itemCheckout.config?.chosenDeliveryMethod === 'postage' ||
      this.modalData.itemCheckout.config?.chosenDeliveryMethod === null
        ? this.modalData.itemCheckout.itemAmount + this.modalData.itemCheckout.deliveryAmount
        : this.modalData.itemCheckout.itemAmount;

    // mark previously selected credits
    this.selectedCredits =
      this.modalData.itemCheckout.selectedCredits.length > 0 ? this.modalData.itemCheckout.selectedCredits : [];
    this.selectedCredits.forEach((credit: PaperclipCredit) => this.selectCredit(credit));
  }

  ngOnDestroy(): void {
    this.selectedCredits = [];
    this.modalData.itemCheckout.userAvailableCredits.forEach((userCredit: PaperclipCredit) => {
      userCredit.isSelected = false;
      userCredit.disableSelection = false;
    });
  }

  public loadMoreUserPaperclipCredit() {
    if (!this.allCreditsLoaded) {
      this.loadingMoreCredits = true;
      this.walletService.getUserCredits().subscribe(
        ({ code, data, message }: PcApiResponse) => {
          this.loadingMoreCredits = false;
          if (code === 1) {
            this.allCreditsLoaded = data.length < 30;
            data.forEach((credit: ApiPaperclipCredit) => {
              this.modalData.itemCheckout.userAvailableCredits.push(new PaperclipCredit(credit));
            });
          } else {
            this.coreService.handleError({ code, message });
          }
        },
        () => {
          this.coreService.handleError({});
        }
      );
    }
  }

  public selectCredit(credit: PaperclipCredit) {
    if (!credit.disableSelection) {
      credit.isSelected = !credit.isSelected;
      this.totalSelectedCredit = credit.isSelected
        ? this.totalSelectedCredit + Number(credit.valueAvailable)
        : this.totalSelectedCredit - Number(credit.valueAvailable);

      this.selectedCredits = this.modalData.itemCheckout.userAvailableCredits.filter(
        (userCredit: PaperclipCredit) => userCredit.isSelected
      );

      this.modalData.itemCheckout.userAvailableCredits.forEach((userCredit: PaperclipCredit) => {
        if (!userCredit.isSelected) {
          userCredit.disableSelection = this.totalSelectedCredit >= this.orderTotal;
        }
      });
    }
  }

  public setSelectedCredits() {
    this.modalData.itemCheckout.selectedCredits = this.selectedCredits.filter(
      (credit: PaperclipCredit) => !credit.disableSelection
    );
    this.modalData.complete();
    this.dialogRef.close();
  }

  public getOrderTotal(): number {
    return this.orderTotal - this.totalSelectedCredit;
  }
}
