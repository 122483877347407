import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { IPAddressInfo } from '@paperclip/models/IPAddressInfo';
import { Observable } from 'rxjs';
declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(private http: HttpClient, private geocoder: MapGeocoder) {}

  getLatLng(location: string): Observable<{ lat?: number; lng?: number }> {
    return new Observable((observer) => {
      this.geocoder.geocode({ address: location }).subscribe(({ results, status }) => {
        if (status === google.maps.GeocoderStatus.OK) {
          observer.next({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          });
        } else {
          console.error('Error - ', results, ' & Status - ', status);
          observer.next({});
        }
        observer.complete();
      });
    });
  }

  getLocation(lat: number, lng: number): Observable<{ formattedAddress?: string }> {
    const latlng = { lat: lat, lng: lng };
    let formattedAddress;
    return new Observable((observer) => {
      this.geocoder.geocode({ location: latlng }).subscribe(({ results, status }) => {
        if (status === google.maps.GeocoderStatus.OK) {
          for (const result of results) {
            if (result.types[0] === 'administrative_area_level_2') {
              formattedAddress = result.formatted_address;
            }
          }

          observer.next({
            formattedAddress: formattedAddress
          });
        } else {
          console.error('Error - ', results, ' & Status - ', status);
          observer.next({});
        }
        observer.complete();
      });
    });
  }

  getLocationSuggestions(location: string): Observable<string[]> {
    return new Observable((observer) => {
      const displaySuggestions = function (predictions, status) {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          observer.next(['no suggestions available']);
          return;
        }

        const locations = [];
        for (let i = 0; i < predictions.length; i++) {
          locations.push(predictions[i].description);
        }

        observer.next(locations);
        observer.complete();
      };

      const service = new google.maps.places.AutocompleteService();
      return service.getPlacePredictions(
        {
          input: location,
          types: ['(cities)']
        },
        displaySuggestions
      );
    });
  }

  getCurrentLocation(): Observable<GeolocationCoordinates | string> {
    return new Observable((observer) => {
      if (navigator.geolocation) {
        return navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next(position.coords);
            observer.complete();
          },
          (error) => {
            console.error(error);
            observer.next('user denied location');
            observer.complete();
          },
          {
            timeout: 10000
          }
        );
      }
    });
  }

  getGeoLocationByIP(ipAddress = ''): Observable<{
    code?: number;
    data?: IPAddressInfo;
    message?: string;
  }> {
    return this.http.get(
      `geolocation/getByIp?ip=${ipAddress}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    );
  }
}
