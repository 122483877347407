<img
  *ngIf="ads[currentAdIndex]"
  class="feed-banner"
  [ngClass]="{
    'feed-banner--small': isSmallSize,
    'feed-banner--nolink': ads[currentAdIndex].linkUrl === '#' || ads[currentAdIndex].linkUrl == null
  }"
  [src]="ads[currentAdIndex].imageUrl"
  [alt]="ads[currentAdIndex].linkUrl"
  (click)="openLinkInNewTab()"
/>
