<div class="modal">
  <pc-close-icon (click)="closeModal()"></pc-close-icon>
  <ng-container *ngIf="!tradeCompleted">
    <div class="modal-header">
      <h1>
        {{ 'modals.mark-received.title-' + modalData.action | translate }}
      </h1>
    </div>

    <p class="modal-body">
      {{ 'modals.mark-received.body' | translate }}
    </p>

    <div class="modal-footer multi-btn-container">
      <button class="btn-large" (click)="dialogRef.close()">{{ 'buttons.cancel' | translate }}</button>
      <button class="btn-large btn-primary" (click)="markAsReceived()" [ngClass]="{ 'btn-loading': activeRequest }">
        {{ 'buttons.confirm' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="tradeCompleted">
    <div class="item-image">
      <img class="item-image__checkmark" src="/assets/icons/misc/checkbox-checked.svg" />
      <img class="bg-image" [src]="modalData.order.item.detail.thumbnail" alt="" />
    </div>

    <div class="modal-header">
      <h1>{{ 'generic.congratulations' | translate }}</h1>
    </div>

    <p class="modal-body">
      {{ 'modals.mark-received.body-complete' | translate }}
    </p>

    <div class="modal-footer multi-btn-container">
      <button class="btn-large btn-secondary" (click)="leaveReview()">{{ 'buttons.leave-review' | translate }}</button>
    </div>
  </ng-container>
</div>
