<div class="modal messages-new-message-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.messages-new-message.title' | translate }}</h1>
    <p>{{ 'modals.messages-new-message.subtitle' | translate }}</p>
  </div>

  <div class="modal-body">
    <h3>{{ 'modals.messages-new-message.your-friends' | translate }}</h3>
    <div class="data-list">
      <ul
        class="checkbox-list scrollable-content"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300"
        [scrollWindow]="false"
        (scrolled)="getUsers(true)"
      >
        <ng-container *ngIf="dataLoading">
          <li *ngFor="let data of [0, 1, 2]" class="checkbox-list__item">
            <pc-small-item [item]="null" [link]="false"></pc-small-item>
          </li>
        </ng-container>
        <ng-container *ngIf="!dataLoading && users.length > 0">
          <li
            *ngFor="let user of users"
            (click)="selectUser(user)"
            class="checkbox-list__item"
            [ngClass]="{ 'checkbox-list__item--disabled': user.detail.isSuspended }"
          >
            <pc-small-user
              [user]="user"
              [link]="false"
              [showTag]="false"
              [showSuspendedTag]="user.detail.isSuspended"
            ></pc-small-user>
            <pc-checkmark-icon
              *ngIf="!user.detail.isSuspended"
              [checked]="user.isSelected"
              [size]="32"
            ></pc-checkmark-icon>
          </li>
        </ng-container>
        <pc-no-data-view
          *ngIf="!dataLoading && users.length === 0"
          pageToShow="your-following"
          [isInDropdown]="false"
          [showImage]="false"
        >
        </pc-no-data-view>
        <div *ngIf="!dataLoading && loadingMore" class="loading-more-spinner"></div>
      </ul>
    </div>
  </div>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large btn-primary" (click)="startMessage()" [disabled]="!selectedUser">
      {{ 'modals.messages-new-message.button' | translate }}
    </button>
  </div>
</div>
