/* eslint-disable @typescript-eslint/ban-types */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { ModalService } from '@paperclip/modals/modal.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Order } from '@paperclip/models/order';
import { TradingService } from '@paperclip/page/trading.service';
import { TradingTwoDayDelayModalComponent } from '../trading-modals';

@Component({
  selector: 'pc-mark-received-modal',
  templateUrl: './mark-received-modal.component.html',
  styleUrls: ['./mark-received-modal.component.scss']
})
export class MarkReceivedModalComponent {
  activeRequest: boolean;
  tradeCompleted: boolean;
  socialInventoryModalSeen: boolean;

  constructor(
    public dialogRef: MatDialogRef<MarkReceivedModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: {
      order: Order;
      action: string;
      confirmed: (data: Order) => {};
      leaveReview: (socialInventoryModalSeen: boolean) => {};
      modalClosed: (socialInventoryModalSeen: boolean) => {};
    },
    private tradingService: TradingService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService,
    private modalService: ModalService
  ) {}

  markAsReceived() {
    this.activeRequest = true;
    this.tradingService.markAsReceived(this.modalData.order.id).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.socialInventoryModalSeen = data.order.buyer.socialInventoryModalSeen;
          this.tradeCompleted = true;
          this.confirmationMessageService.showConfirmationMessage(this.modalData.action);
          this.modalData.confirmed(data);
        } else if (code === -210) {
          this.modalService.open(TradingTwoDayDelayModalComponent, { data: this.modalData.action });
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  leaveReview() {
    this.dialogRef.close();
    this.modalData.leaveReview(this.socialInventoryModalSeen);
  }

  public closeModal() {
    this.modalData.modalClosed(this.tradeCompleted ? this.socialInventoryModalSeen : true);
    this.dialogRef.close();
  }
}
