import { ClearDeleteAllMenuComponent } from './clear-delete-all-menu/clear-delete-all-menu.component';
import { ConversationMenuComponent } from './conversation-menu/conversation-menu.component';
import { UserListMenuComponent } from './user-list-menu/user-list-menu.component';
import { UserProfileMenuComponent } from './user-profile-menu/user-profile-menu.component';

export * from './clear-delete-all-menu/clear-delete-all-menu.component';
export * from './conversation-menu/conversation-menu.component';
export * from './user-list-menu/user-list-menu.component';
export * from './user-profile-menu/user-profile-menu.component';

export const userMenus = [
  ConversationMenuComponent,
  UserListMenuComponent,
  UserProfileMenuComponent,
  ClearDeleteAllMenuComponent
];
