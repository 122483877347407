import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AddIconComponent } from './add-icon/add-icon.component';
import { ArrowIconComponent } from './arrow-icon/arrow-icon.component';
import { CheckmarkIconComponent } from './checkmark-icon/checkmark-icon.component';
import { ClearInputBtnComponent } from './clear-input-btn/clear-input-btn.component';
/*-- components --*/
import { CloseIconComponent } from './close-icon/close-icon.component';
import { DeleteIconComponent } from './delete-icon/delete-icon.component';
import { MessageIconBtnComponent } from './message-icon-btn/message-icon-btn.component';
import { ReviewStarIconComponent } from './review-star-icon/review-star-icon.component';
import { SocialIconComponent } from './social-icon/social-icon.component';

const iconButtonComponents = [
  CloseIconComponent,
  ArrowIconComponent,
  AddIconComponent,
  ClearInputBtnComponent,
  DeleteIconComponent,
  ReviewStarIconComponent,
  MessageIconBtnComponent,
  SocialIconComponent,
  CheckmarkIconComponent
];

@NgModule({
  imports: [CommonModule],
  declarations: iconButtonComponents,
  exports: iconButtonComponents
})
export class IconButtonsModule {}
