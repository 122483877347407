import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { PaymentAddress } from '@paperclip/models/payments/payment-address';

@Component({
  selector: 'pc-wallet-delivery-address-menu',
  templateUrl: './wallet-delivery-address-menu.component.html'
})
export class WalletDeliveryAddressMenuComponent {
  @Input() menuData: { address: PaymentAddress };
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
}
