<div class="modal invite-friends-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <img src="assets/illustrations/invite-friends.svg" />
    <h1>{{ 'modals.invite-friends.title' | translate }}</h1>
  </div>

  <div class="modal-body">
    <div class="invite-friends-modal__email">
      <p>{{ 'modals.invite-friends.body' | translate }}</p>
      <button
        class="btn-large btn-large--fit-content btn-primary"
        [ngClass]="{ 'btn-loading': branchDeepLinkLoading }"
        (click)="shareViaEmail()"
      >
        {{ 'modals.invite-friends.send-email' | translate }}
      </button>
    </div>
    <div class="word-separator">
      <span></span>
      <p class="p-small">{{ 'generic.or' | translate }}</p>
      <span></span>
    </div>
    <div class="invite-friends-modal__share">
      <p class="p-large highlight-text">{{ 'modals.invite-friends.share' | translate }}</p>
      <input type="text" [value]="branchDeepLink" readonly (click)="copyToClipboard()" />
      <div class="invite-friends-modal__share__buttons">
        <button
          class="btn-large"
          [ngClass]="{ 'btn-loading': branchDeepLinkLoading }"
          [ngxClipboard]
          [cbContent]="branchDeepLink"
          (cbOnSuccess)="copyToClipboard()"
        >
          {{ copiedText | translate }}
        </button>
        <button
          class="btn btn-facebook btn-icon"
          [ngClass]="{ 'btn-loading': branchDeepLinkLoading }"
          (click)="shareToFacebook()"
        >
          <pc-social-icon icon="facebook" [socialColour]="true"></pc-social-icon>
        </button>
        <button
          class="btn btn-messenger btn-icon"
          [ngClass]="{ 'btn-loading': branchDeepLinkLoading }"
          (click)="shareToMessenger()"
        >
          <pc-social-icon icon="messenger" [socialColour]="true"></pc-social-icon>
        </button>
        <button
          class="btn btn-twitter btn-icon"
          [ngClass]="{ 'btn-loading': branchDeepLinkLoading }"
          (click)="shareToTwitter()"
        >
          <pc-social-icon icon="twitter" [socialColour]="true"></pc-social-icon>
        </button>
      </div>
    </div>
  </div>
</div>
