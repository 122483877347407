<form [formGroup]="addItemForm">
  <div formGroupName="itemDetails">
    <div class="input-group input-group--btn-end">
      <input
        id="item-title"
        type="text"
        formControlName="itemTitle"
        placeholder="{{ 'add-item.item-details.item-title.input-placeholder' | translate }}"
        (keydown.enter)="$event.preventDefault()"
      />
      <pc-clear-input-btn
        *ngIf="addItemForm.get('itemDetails').get('itemTitle').value"
        class="input-group__btn input-group__btn--end"
        (click)="clearItemDetail.emit('itemTitle')"
      >
      </pc-clear-input-btn>
    </div>
    <pc-character-counter
      [currentCount]="addItemForm.get('itemDetails').get('itemTitle').value?.length"
      [countLimit]="70"
    >
    </pc-character-counter>
  </div>
</form>
