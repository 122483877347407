import { AppConfig } from "@paperclip/core/app.config";
import { Item } from "./item";
import { ApiOfferDeliveryAddress, ApiOrder } from "./order/ApiOrder";
export enum OrderState {
    PaymentPending = 'payment-pending',
    PaymentFailed = 'payment-failed',
    Paid = 'paid',
    Dispatched = 'dispatched',
    Delivered = 'delivered',
    Refunded = 'refunded',
    PaidOut = 'paid-out'
}

export class Order implements ApiOrder {
    id?: string;
    created?: string;
    cash?: number;
    priceItem: number;
    priceDelivery: number;
    priceFee: number;
    priceTotal: number;
    totalOrdersCount?: number;
    isReviewed?: boolean;
    item?: any;    
    toItem?: any;
    state?: OrderState;
    type?: "offer" | "buyNow";
    paidAt?: string;
    lastPaymentFailed?: boolean;
    dispatchedAt?: string;
    deliveredAt?: string;
    refundedAt?: string;
    isDisputable?: boolean;
    disputedAt?: string;
    disputedBy?: string;
    deliveryAddressId?: string;

    seller?: { id?: string; name?: string; username?: string; suspended?: boolean; };
    buyer?: { id?: string; username?: string; socialInventoryModalSeen?: boolean; suspended?: boolean; };
    deliveryAddress?: ApiOfferDeliveryAddress;
    fromUser?: { userId?: string; name?: string; username?: string; }
    toUser?: { userId?: string; name?: string; username?: string; }

    oldItemModel?: Item;

    constructor(data?: Partial<Order>) {
      if (data) {
        Object.assign(this, data);
      }
    }
    
    public initOldStructures(loggedInUserId: string, locale = 'en-GB', dontShowTag: 'ownItem' | 'featuredItem' | 'newItem' = null) {
      this.oldItemModel = new Item(this.item, loggedInUserId, locale, dontShowTag)
    }

    isSuspended() {
        return this.seller.suspended || this.buyer.suspended
    }

    isDisputed() {
        return !!this.disputedAt;
    }


    getFormattedDeliveryAddress() {
        if(!this.deliveryAddress) return null;

        return `${this.deliveryAddress?.fullName}<br>
        ${this.deliveryAddress?.addressLine1}<br>
        ${this.deliveryAddress?.addressLine2}<br>
        ${this.deliveryAddress?.city}<br>
        ${this.deliveryAddress?.county}<br>
        ${this.deliveryAddress?.postCode}<br>
        ${this.deliveryAddress?.countryName}`;
    }

    

}

export class OrderView {
    buttons: any[];
    constructor(private order: Order, private page: 'buying' | 'selling', private currentUserId: string) {
        this.buttons = this.getButtons();
    }

    public getPriceForUser() {
      if(this.currentUserId === this.order.buyer.id) {        
        return this.order?.priceTotal
      } else if(this.currentUserId === this.order.seller.id) {
        return this.order?.priceItem + this.order?.priceDelivery;
      }      
    }

    getColourPostfix() {
        if(
          this.order.isSuspended() 
          || this.order.lastPaymentFailed 
          || this.order.isDisputed()      
        ) { return 'red'; }
    
        if(this.order.state == OrderState.Refunded || this.order.state == OrderState.PaidOut)
          return 'yellow';
          
        return 'green';
      }
      getStatePostfix() {
        if(!this.order)
          return null;
        //TODO check css based on state
        const s = this.order.state;
        if(s == OrderState.Paid)
          return 'acceptedDeliveryPost'
        else if(s == OrderState.Dispatched)
          return 'acceptedDeliveryPostDispatched'
        else if(s == OrderState.Delivered)
          return 'completed'
      }
    
      getOrderText() {    
    
        const user = this.page === 'buying' ? this.order.buyer : this.order.seller;
        if(user.id === this.currentUserId && user.suspended) {
          return 'account-under-review'
        } else {
          if(this.order.buyer.suspended)
            return 'buyer-under-review';  
          if(this.order.seller.suspended)
            return 'seller-under-review';
        }
    
        if(
          this.order.lastPaymentFailed
        ) return 'payment-error'
    
        if(this.order.state == OrderState.Refunded)
          return 'payment-refunded'

        if(this.order.state == OrderState.PaidOut)
          return 'payment-paidout'
    
        if(this.order.state == OrderState.Paid)
          return 'awaiting-dispatch'
    
        if(this.order.state == OrderState.Dispatched)
          return 'awaiting-delivery'
    
        if(this.order.state == OrderState.Delivered)
          return 'completed'
    
    
        return "accepted-offer"
      }
    
      private getButtons() {   
        if(this.page === 'buying') 
          return this.getButtonsForBuying();;;
        return this.getButtonsForSelling();
      } 
    
      private getButtonsForBuying() {
        if(!this.order) {
          return [{
            action: 'buy-now',
            class: 'btn-green',
            link: false,
            disabled: false
          }]
        }
    
        if(this.order.state == OrderState.PaymentFailed) {
          return [{
            action: 'resend-payment',
            class: 'btn-primary',
            link: false,
            disabled: false
          }]
        }
    
        if(this.order.state == OrderState.Refunded) {
          return [{
            action: 'dispute-resolved',
            class: 'btn-primary',
            link: false,
            disabled: true
          }]
        }

        if(this.order.state == OrderState.PaidOut) {
          return [{
            action: 'dispute-resolved',
            class: 'btn-primary',
            link: false,
            disabled: true
          }]
        }

        if(this.order.isDisputed()) {
          return [{
            action: 'reviewing-dispute',
            class: 'btn-primary',
            link: false,
            disabled: true
          }]
        }
    
        if(this.order.state == OrderState.Paid) {
          return [
            {
              action: 'send-reminder',
              class: 'btn-secondary',
              link: true,
              disabled: false
            }
          ]
        }
    
        if(this.order.state == OrderState.Dispatched) {
          return [
            {
              action: 'mark-delivered',
              class: 'btn-primary',
              link: false,
              disabled: false
            }
          ]
        }
    
        if(this.order.state == OrderState.Delivered) {
          if(this.order.isReviewed) {
            return [
              {
                action: 'reviewed',
                class: 'btn-secondary',
                link: false,
                disabled: true
              }
            ]
          } else {
            return [
              {
                action: 'leave-review',
                class: 'btn-secondary',
                link: false,
                disabled: false
              }
            ]
          }
        }
    
        return [];
      }
      private getButtonsForSelling() {
        const s = this.order?.state;
        if(!s) return []
       
        if(s === OrderState.Refunded) {
          return [
            {
              action: 'dispute-resolved',
              class: 'btn-primary',
              link: false,
              disabled: true
            }
          ]
        }

        if(s === OrderState.PaidOut) {
          return [
            {
              action: 'dispute-resolved',
              class: 'btn-primary',
              link: false,
              disabled: true
            }
          ]
        }

        if(this.order.isDisputed()) {
          return [{
            action: 'reviewing-dispute',
            class: 'btn-primary',
            link: false,
            disabled: true
          }]
        }
    
        if(s === OrderState.Paid) {
          return [
            {
              action: 'mark-dispatched',
              class: 'btn-primary',
              link: false,
              disabled: false
            }
          ]
        }
    
        if(s === OrderState.Dispatched){
          return [
            {
              action: 'pending',
              class: 'btn-secondary',
              link: false,
              disabled: true
            }
          ]
        }
    
        if(this.order.state == OrderState.Delivered) {
          if(this.order.isReviewed) {
            return [
              {
                action: 'reviewed',
                class: 'btn-secondary',
                link: false,
                disabled: true
              }
            ]
          } else {
            return [
              {
                action: 'leave-review',
                class: 'btn-secondary',
                link: false,
                disabled: false
              }
            ]
          }
        }
    
    
      }

}