<li>
  <a [routerLink]="['/messages', menuData?.userId]">
    {{ 'menus.user-profile.send-message' | translate }}
  </a>
</li>
<li>
  <button class="btn-link" (click)="
        menuData?.isFollowed
          ? menuAction.emit({ action: 'unfollow-user', data: menuData?.userId })
          : menuAction.emit({ action: 'follow-user', data: menuData?.userId })
      ">
    {{
    menuData?.isFollowed
    ? ('menus.user-profile.unfollow-user' | translate)
    : ('menus.user-profile.follow-user' | translate)
    }}
  </button>
</li>
<li>
  <button class="btn-link btn-link--red" (click)="
        menuAction.emit({
          action: 'report-user',
          data: { userId: menuData?.userId }
        })
      ">
    {{ 'menus.user-profile.report-user' | translate }}
  </button>
</li>
