import { Component, Input } from '@angular/core';

@Component({
  selector: 'pc-password-strength-meter',
  templateUrl: './password-strength-meter.component.html',
  styleUrls: ['./password-strength-meter.component.scss']
})
export class PasswordStrengthMeterComponent {
  @Input() passwordScore = 0;
}
