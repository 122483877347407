<pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
<form [formGroup]="withdrawBalanceForm" class="modal withdraw-balance-modal" (ngSubmit)="withdrawBalance()">
  <div class="modal-header">
    <h1>{{ 'modals.withdraw-balance.title' | translate }}</h1>
  </div>

  <div class="modal-body">
    <p>{{ 'modals.withdraw-balance.body' | translate: { accountNumber: modalData.accountNumber } }}</p>
    <p class="highlight-text">{{ 'modals.withdraw-balance.available' | translate }}</p>
    <p class="colour-secondary">{{ modalData.userCurrency }}{{ modalData.availableBalance | number: '1.2-2' }}</p>

    <div class="withdraw-form">
      <div class="input-group input-group--label-start">
        <p class="highlight-text input-group__label">{{ modalData.userCurrency }}</p>
        <input
          pcCurrencyInputPadding
          formControlName="amount"
          class="colour-secondary"
          type="text"
          [textMask]="{ mask: modalData.currencyMask }"
        />
      </div>
      <button
        type="submit"
        class="btn-large btn-primary"
        [disabled]="disableButton()"
        [ngClass]="{ 'btn-loading': activeRequest }"
      >
        {{ 'wallet.balance.withdraw' | translate }}
      </button>
    </div>
  </div>
</form>

<div class="modal-footer">
  <button
    type="submit"
    class="btn-large btn-primary"
    (click)="withdrawBalance()"
    [disabled]="disableButton()"
    [ngClass]="{ 'btn-loading': activeRequest }"
  >
    {{ 'wallet.balance.withdraw' | translate }}
  </button>
</div>
