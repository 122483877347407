import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pc-delivery-details-delivery-method',
  templateUrl: './delivery-details-delivery-method.component.html',
  styleUrls: ['./delivery-details-delivery-method.component.scss']
})
export class DeliveryDetailsDeliveryMethodComponent {
  @Input() addItemForm: UntypedFormGroup;
  @Input() currency: { symbol: string; mask: any };

  enablePostage() {
    this.addItemForm
      .get('deliveryDetails')
      .get('postageAvailable')
      .patchValue(!this.addItemForm.get('deliveryDetails').get('postageAvailable').value);
  }

  enableMip() {
    this.addItemForm
      .get('deliveryDetails')
      .get('meetInPerson')
      .patchValue(!this.addItemForm.get('deliveryDetails').get('meetInPerson').value);
  }
}
