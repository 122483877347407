import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import FuzzySearch from 'fuzzy-search';
import * as glibphone from 'google-libphonenumber';
import { Subscription } from 'rxjs';

import { Country } from './Country';
import { PhoneNumberInputService } from './phone-number-input.service';
import { VerifyPhoneNumberService } from './verify-phone-number.service';

@Component({
  selector: 'pc-verify-phone-number-modal',
  templateUrl: './verify-phone-number-modal.component.html',
  styleUrls: ['./verify-phone-number-modal.component.scss', './assets/flags.scss']
})
export class VerifyPhoneNumberModalNewComponent implements OnInit, AfterViewInit, OnDestroy {
  activeRequest: boolean;
  disableButton = true;
  showEnterCodeForm = false;
  verifyPhoneForm: UntypedFormGroup = this.formBuilder.group({
    phoneNumber: ['', Validators.compose([Validators.required])]
  });
  verificationCodeForm: UntypedFormGroup = this.formBuilder.group({
    verificationCode: ['', Validators.compose([Validators.required])]
  });
  verificationCodeFormSubscription: Subscription;

  // phone input & country search
  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef;
  countries: Country[];
  commonCountries: Country[];
  selectedCountry: Country;
  defaultCountry = 'gb';
  countryDialCode: string;
  searchInputHasValue: boolean;
  searchCountriesForm: UntypedFormGroup = this.formBuilder.group({
    countryName: ''
  });
  countriesListCopy: Country[];
  verifyPhoneFormSubscription: Subscription;
  searchCountriesFormSubscription: Subscription;
  showCountrySelection = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<VerifyPhoneNumberModalNewComponent>,
    @Inject(MAT_DIALOG_DATA) public enterCodeForm = false,
    private verifyPhoneNumberService: VerifyPhoneNumberService,
    private confirmationMsgService: ConfirmationMessageService,
    private coreService: CoreService,
    private phoneNumberInputService: PhoneNumberInputService
  ) {
    this.verificationCodeFormSubscription = this.verificationCodeForm.valueChanges.subscribe(({ verificationCode }) => {
      this.disableButton = !verificationCode;
    });

    this.searchCountriesFormSubscription = this.searchCountriesForm.valueChanges.subscribe(({ countryName }) => {
      this.countries = this.countriesListCopy;
      this.searchInputHasValue = !!countryName;
      if (countryName) {
        const searcher = new FuzzySearch(this.countries, ['name']);
        this.countries = searcher.search(countryName);
      } else {
        this.countries = this.countriesListCopy;
      }
    });
  }

  ngOnInit() {
    document.body.style.overflow = 'hidden';
    this.showEnterCodeForm = this.enterCodeForm;

    this.countries = this.phoneNumberInputService
      .getCountries()
      .sort((a: Country, b: Country) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    this.commonCountries = this.countries.filter(
      (country: Country) => country.countryCode === 'gb' || country.countryCode === 'us'
    );
    this.countriesListCopy = this.countries;

    this.selectedCountry = this.countries.find((country: Country) => country.countryCode === this.defaultCountry);
    this.countryDialCode = this.selectedCountry.dialCode;
    this.setCountryDialCode(this.countryDialCode);
    this.verifyPhoneFormSubscription = this.verifyPhoneForm.valueChanges.subscribe(({ phoneNumber }) => {
      this.validatePhoneNumber(phoneNumber);
    });
  }

  ngAfterViewInit(): void {
    this.setInputPadding(this.selectedCountry.dialCode.length);
  }

  ngOnDestroy(): void {
    document.body.style.overflow = '';
    this.verificationCodeFormSubscription.unsubscribe();
    this.verifyPhoneFormSubscription.unsubscribe();
    this.searchCountriesFormSubscription.unsubscribe();
  }

  public switchForm() {
    this.showEnterCodeForm = !this.showEnterCodeForm;
    this.disableButton = true;
  }

  public setCountryDialCode(countryCode) {
    this.countryDialCode = countryCode;
  }

  public isValidNumber(validNumber?: boolean) {
    this.disableButton = !validNumber;
  }

  public verifyPhoneNumber() {
    !this.showEnterCodeForm ? this.sendVerificationCode() : this.checkVerificationCode();
  }

  private sendVerificationCode() {
    this.activeRequest = true;
    const phoneNumber = this.verifyPhoneForm.get('phoneNumber').value;
    this.verifyPhoneNumberService.sendVerificationTextMessage(phoneNumber, this.countryDialCode).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.showEnterCodeForm = true;
          this.confirmationMsgService.showConfirmationMessage('phone-verification-code-sent');
          this.verifyPhoneNumberService.verificationCodeSent(phoneNumber);
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  private checkVerificationCode() {
    this.activeRequest = true;
    this.verifyPhoneNumberService.verifyPhoneCode(this.verificationCodeForm.get('verificationCode').value).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.confirmationMsgService.showConfirmationMessage('phone-verified');
          this.verifyPhoneNumberService.setPhoneVerified(true);
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  public updateSelectedCountry(event: Event, countryCode: string) {
    event.preventDefault();
    this.showCountrySelection = false;
    this.countries = this.countriesListCopy;
    this.searchCountriesForm.reset();
    this.selectedCountry = null;
    this.selectedCountry = this.countries.find((country: Country) => country.countryCode === countryCode);

    if (!this.selectedCountry) {
      this.selectedCountry = {
        name: 'United Kingdom',
        dialCode: '44',
        countryCode: 'gb'
      };
    }
    this.countryDialCode = this.selectedCountry.dialCode;
    this.setCountryDialCode(this.countryDialCode);
    this.setInputPadding(this.countryDialCode.length);
  }

  private setInputPadding(dialCodeLength: number) {
    this.phoneNumberInput.nativeElement.style.paddingLeft = `${20 + dialCodeLength * 15}px`;
  }

  private validatePhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      const phoneUtil = glibphone.PhoneNumberUtil.getInstance();
      try {
        console.log('phone', phoneNumber);
        const fullNumber = `+${this.countryDialCode} ${phoneNumber}`;
        const phoneNumberParsed = phoneUtil.parse(fullNumber);
        console.log('phoneNumberParsed', phoneNumberParsed);
        const isValidNumber: boolean = phoneUtil.isValidNumber(phoneNumberParsed);
        console.log('isValidNumber', isValidNumber);
        this.isValidNumber(isValidNumber);
        // const countryCode: any = this.selectedCountry.countryCode.toUpperCase();
        // const asYouType = new AsYouType(countryCode).input(phoneNumber);
      } catch (ex) {
        this.isValidNumber(false);
      }
    } else {
      this.isValidNumber(false);
    }
  }
}
