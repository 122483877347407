<div class="search-filters-all" [ngClass]="{
    'search-filters-all--list-view': searchStateCopy.filters.view === 'list' && !insideFiltersModal,
    'search-filters-all--inside-modal': insideFiltersModal
  }">
  <div *ngIf="!insideFiltersModal" class="search-filters-all__actions">
    <button class="btn-stroked" (click)="clearAllFilters()">{{ 'buttons.clear' | translate }}</button>
    <button class="btn-primary" (click)="applyAllFilters()">{{ 'buttons.apply' | translate }}</button>
  </div>

  <div class="search-filters-all__filters"
    [ngClass]="{ 'scrollable-content': searchStateCopy.filters.view === 'list' && !insideFiltersModal }">
    <section class="grid-row--no-top-margin">
      <h2>{{ 'modals.search-filters.type.title' | translate }}</h2>
      <pc-search-search-type-filter [searchFilters]="searchStateCopy.filters" [insideFiltersModal]="insideFiltersModal">
      </pc-search-search-type-filter>
    </section>

    <section *ngIf="showFilter('student-roost-location')">
      <h2>{{ 'modals.search-filters.student-roost' | translate }}</h2>
      <pc-search-sr-location-filter [searchFilters]="searchStateCopy.filters" [insideFiltersModal]="insideFiltersModal">
      </pc-search-sr-location-filter>
    </section>

    <section *ngIf="showFilter('location')">
      <h2>{{ 'modals.search-filters.location' | translate }}</h2>
      <pc-search-location-filter [searchFilters]="searchStateCopy.filters" [insideFiltersModal]="insideFiltersModal">
      </pc-search-location-filter>
    </section>

    <section *ngIf="showFilter('item-category')" class="grid-row--no-padding">
      <h2>{{ 'modals.search-filters.category' | translate }}</h2>
      <pc-search-item-category-filter [searchFilters]="searchStateCopy.filters"
        [insideFiltersModal]="insideFiltersModal">
      </pc-search-item-category-filter>
    </section>

    <section *ngIf="showFilter('item-price')" class="grid-row--no-bottom-padding">
      <h2>{{ 'modals.search-filters.item-price' | translate }}</h2>
      <pc-search-item-price-filter [searchFilters]="searchStateCopy.filters" [insideFiltersModal]="insideFiltersModal">
      </pc-search-item-price-filter>
    </section>

    <section *ngIf="showFilter('item-condition')" class="grid-row--no-bottom-padding">
      <h2>{{ 'modals.search-filters.item-condition' | translate }}</h2>
      <pc-search-item-condition-filter [searchFilters]="searchStateCopy.filters"
        [insideFiltersModal]="insideFiltersModal">
      </pc-search-item-condition-filter>
    </section>
  </div>
</div>
