import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';

import { bottomsheets } from './bottomsheets/bottomsheets';
import { sharedComponents } from './components/_shared-components';
import { sharedDirectives } from './directives/_shared-directives';
import { formControls } from './form-controls/form-controls';
import { modals } from './modals/modals';
import { sharedModuleExports, sharedModuleImports } from './modules/_shared-modules';
import { SafeHtmlPipe } from './safe-html.pipe';

/*====================
providers
====================*/
const matBottomSheetDefaultConfig: MatBottomSheetConfig = {
  hasBackdrop: true
};

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close({ threshold: 0 });
}

/*====================
pull everything from
relevant files
====================*/
@NgModule({
  imports: [...sharedModuleImports],
  declarations: [...sharedComponents, ...sharedDirectives, ...modals, ...bottomsheets, ...formControls, SafeHtmlPipe],
  exports: [
    ...sharedModuleExports,
    ...sharedComponents,
    ...sharedDirectives,
    ...modals,
    ...bottomsheets,
    ...formControls,
    SafeHtmlPipe
  ],
  providers: [
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: matBottomSheetDefaultConfig },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
  ]
})
export class SharedModule {}
