import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';
/*-- services --*/
import { ItemOverviewService } from '@paperclip/page/item-overview/item-overview.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'pc-delete-item-modal',
  templateUrl: './delete-item-modal.component.html'
})
export class DeleteItemModalComponent {
  activeRequest: boolean;
  authedUser: AuthedUser = this.authService.getAuthedUser();

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DeleteItemModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: { itemId: string; activeOffersCount: number; openedFrom: string; isInSocialInventory: boolean },
    private itemOverviewService: ItemOverviewService,
    private updateService: UpdatesService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService,
    private authService: AuthService,
    private appService: AppService
  ) {}

  deleteItem() {
    this.activeRequest = true;
    this.itemOverviewService.deleteItem(this.modalData.itemId, this.modalData.isInSocialInventory).subscribe(
      ({ code, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('item-deleted');
          if (this.modalData.openedFrom === 'item-overview') {
            if (
              !this.appService.getPreviousUrl().includes(this.modalData.itemId) &&
              !this.appService.getPreviousUrl().includes('add-item') &&
              !this.appService.getPreviousUrl().includes('edit-item') &&
              !this.appService.getPreviousUrl().includes('relist-item') &&
              !this.appService.getPreviousUrl().includes('sell-similar')
            ) {
              this.router.navigate([this.appService.getPreviousUrl()]);
            } else {
              this.router.navigate([
                '/u',
                this.authedUser.detail.username,
                this.modalData.isInSocialInventory ? 'inactive' : 'items'
              ]);
            }
          } else if (this.modalData.openedFrom === 'selling') {
            this.updateService.sendUpdateData({
              action: 'delete-item',
              removeData: true,
              updateId: this.modalData.itemId
            });
            this.updateService.sendUpdateData({ action: 'refresh-trading-stats' });
          }
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
