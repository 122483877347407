<div pcClickStopPropagation class="buttons-filter">
  <button
    pcClickStopPropagation
    *ngFor="let type of searchTypes"
    (click)="selectSearchType(type)"
    [ngClass]="{ 'btn-primary': type.isSelected }"
  >
    {{ 'search.filters.search-type.' + type.label | translate }}
  </button>
</div>
