<button
  class="{{ menuButtonClass }}"
  [ngClass]="{ 'btn-icon': iconMenu }"
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"
  [disabled]="disabled"
>
  <div #ngContentRef [ngClass]="{ 'ng-content-ref': ngContentRef.hasChildNodes() }">
    <ng-content></ng-content>
  </div>
  <img *ngIf="!ngContentRef.hasChildNodes()" [src]="menuIcon" alt="" />
</button>

<!-- menu content -->
<mat-menu #menu="matMenu" xPosition="before" [class]="menuClass">
  <!-- list menu -->
  <ul *ngIf="menuType === 'list'" class="menu-list">
    <div #menuComponentContainerRef></div>
  </ul>

  <!-- custom menu -->
  <ng-container *ngIf="menuType !== 'list'">
    <div #menuComponentContainerRef></div>
  </ng-container>
</mat-menu>
