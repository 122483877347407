import { Component, EventEmitter, Output } from '@angular/core';
import { AppConfig } from '@paperclip/core/app.config';
import { MenuAction } from '@paperclip/models/component/menu-action';

@Component({
  selector: 'pc-wallet-header-menu',
  templateUrl: './wallet-header-menu.component.html'
})
export class WalletHeaderMenuComponent {
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
  externalLinks = AppConfig.externalLinks;
}
