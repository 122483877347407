<ng-container
  *ngFor="let conversation of conversations.length > 0 ? conversations : [null, null, null]; let last = last"
  ><a
    class="content-card conversation"
    [ngClass]="{
      'loading-data btn-no-click': !conversation,
      'loading-data--dark': onDesktop,
      'conversation--open': openConversationId === conversation?.user.detail.id,
      'conversation--inside-menu': insideMenu
    }"
    [routerLink]="conversation ? ['/messages', conversation?.user.detail.id] : []"
  >
    <div class="conversation__user">
      <pc-notification-dot *ngIf="!conversation?.detail.lastMessageRead" placement="profile" [showCount]="false">
      </pc-notification-dot>
      <pc-user-profile-picture [picture]="conversation ? conversation?.user.detail.picture : '/assets/loading-img.png'">
      </pc-user-profile-picture>
    </div>

    <div class="conversation__detail">
      <div class="conversation__detail__user">
        <p class="p-large highlight-text">{{ conversation?.user.detail.fullName }}</p>
        <img
          *ngIf="conversation?.user?.detail?.verifiedBadge"
          class="verified-badge"
          [src]="conversation?.user?.detail?.verifiedBadge"
          alt="{{ 'users.' + conversation?.user?.detail?.verifiedBadgeAlt | translate }}"
        />
      </div>
      <p class="p-small">
        {{ conversation?.detail.lastMessageText }}
      </p>
      <p class="sub-text">{{ conversation?.detail.lastMessageDate }}</p>
    </div>
  </a>
  <div *ngIf="!last && onDesktop" class="separator"></div>
</ng-container>
