import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { Conversation } from '@paperclip/models/messages/Conversation';

@Component({
  selector: 'pc-conversation-menu',
  templateUrl: './conversation-menu.component.html'
})
export class ConversationMenuComponent {
  @Input() menuData: { conversation: Conversation };
  @Input() menuOpened: boolean;
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
}
