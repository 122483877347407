/*-- angular imports --*/
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
/*-- app config --*/
import { AppConfig } from '@paperclip/core/app.config';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
/*-- services --*/
import { LocationService } from '@paperclip/core/location.service';
import { AppData } from '@paperclip/models/AppData';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';
/*-- rxjs imports --*/
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'pc-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchOLDSEARCHComponent implements OnChanges, OnDestroy {
  /*-- inputs --*/
  @Input() needsBorder = false;
  @Input() needsFullWidth = false;
  @Input() inverted = false;
  @Input() location: any;

  /*-- outputs --*/
  @Output() locationValueChanged: EventEmitter<any> = new EventEmitter<any>();

  /*-- form group --*/
  locationForm: UntypedFormGroup = this.formBuilder.group({
    useCurrentLocation: [null, Validators.required],
    locationDistance: null /*-- we don't need this, but needs inclusion --*/,
    locationName: [null, Validators.required],
    locationLat: [null, Validators.required],
    locationLng: [null, Validators.required]
  });

  /*-- form subscriptions --*/
  locationNameSubscription: Subscription;

  /*-- variables --*/
  authedUser: AuthedUser = this.authService.getAuthedUser();
  appData: AppData = this.authService.getAppData();
  @ViewChild('locationInput', { static: true })
  locationInput: ElementRef;
  locationSelected: boolean;
  locationInputHasFocus = false;
  recentLocations = this.appData ? (this.appData.recentLocations ? this.appData.recentLocations : []) : [];
  showRecentLocations = false;
  showLocationSuggestions = false;
  locationSuggestions: any = [];
  locationSuggestionsLoading = false;
  loadingCurrentLocation = false;
  useCurrentLocation = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private authService: AuthService,
    private ngZone: NgZone,
    private coreService: CoreService
  ) {}

  /*-- component lifecycle methods --*/
  ngOnChanges(): void {
    this.checkLocationInputValue();
    this.handleInputSubscriptions();
  }

  /*-- custom functionality --*/
  handleInputSubscriptions() {
    this.locationNameSubscription = this.locationForm
      .get('locationName')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((location: string) => {
        if (location && location.length > 0) {
          this.showLocationSuggestions = true;
          this.locationSuggestionsLoading = true;
          this.showRecentLocations = false;
          this.getLocationSuggestions(location);
        } else if (location === null) {
          this.showLocationSuggestions = false;
          this.showRecentLocations = true;
          this.locationSuggestions = [];
          this.locationSuggestionsLoading = false;
        } else if (location.length === 0) {
          this.showLocationSuggestions = false;
          this.showRecentLocations = true;
          this.locationSuggestions = [];
          this.locationSuggestionsLoading = false;
        }
      });
  }

  checkLocationInputValue() {
    // update the location
    if (this.location) {
      this.locationForm.patchValue({
        useCurrentLocation: this.location.useCurrentLocation,
        locationName: this.location.locationName,
        locationLat: this.location.locationLat,
        locationLng: this.location.locationLng
      });

      this.useCurrentLocation = this.location.useCurrentLocation;
    }

    // recent locations
    this.recentLocations = this.appData.recentLocations || [];
  }

  locationInputFocus() {
    // only show list if any of these are true
    const locationName = this.locationForm.get('locationName').value;
    if (locationName) {
      this.locationInputHasFocus = true;
    } else if (this.recentLocations.length > 0) {
      this.locationInputHasFocus = true;
    } else if (this.locationSuggestions.length > 0) {
      this.locationInputHasFocus = true;
    }

    // show suggestions if it's the first load and input isn't empty
    if (this.locationSuggestions.length === 0 && locationName) {
      this.showLocationSuggestions = true;
      this.showRecentLocations = false;
      this.getLocationSuggestions(locationName);
    }
  }

  getLocationSuggestions(location) {
    this.locationService.getLocationSuggestions(location).subscribe((locations) => {
      this.ngZone.run(() => {
        this.locationSuggestionsLoading = false;
        this.locationSuggestions = locations;
      });
    });
  }

  locationInputKeydown(keyEvent: any) {
    this.locationInputHasFocus = true;
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  }

  selectCurrentLocation() {
    this.useCurrentLocation = !this.useCurrentLocation;

    /*-- current location selected --*/
    if (this.useCurrentLocation) {
      // reset the form
      this.locationForm.patchValue({
        locationName: ''
      });

      this.loadingCurrentLocation = true;
      this.locationService.getCurrentLocation().subscribe((locationCoordinates: any) => {
        /*-- we have access to location --*/
        if (locationCoordinates !== 'user denied location') {
          this.locationService
            .getLocation(locationCoordinates.latitude, locationCoordinates.longitude)
            .subscribe((locationName) => {
              // force template to update
              this.ngZone.run(() => {
                this.loadingCurrentLocation = false;
              });

              // patch form
              this.locationForm.patchValue({
                useCurrentLocation: true,
                locationName: locationName.formattedAddress,
                locationLat: locationCoordinates.latitude,
                locationLng: locationCoordinates.longitude
              });

              this.locationSelected = true;

              // emit the value to the parent
              this.emitUpdatedLocationValue();

              const currentLocation = {
                name: locationName.formattedAddress,
                latitude: locationCoordinates.latitude,
                longitude: locationCoordinates.longitude
              };

              this.authService.updateAppData({ currentLocation: currentLocation });
            });

          /*-- we don't have access to location --*/
        } else {
          this.ngZone.run(() => {
            this.loadingCurrentLocation = false;
          });

          this.locationForm.patchValue({
            useCurrentLocation: true,
            locationName: AppConfig.app.location.name,
            locationLat: AppConfig.app.location.latitude,
            locationLng: AppConfig.app.location.longitude
          });

          this.emitUpdatedLocationValue();
          this.coreService.handleError({ customCode: 995 });
        }
      });

      /*-- current location deselected --*/
    } else {
      this.loadingCurrentLocation = false;
      this.locationForm.patchValue({
        useCurrentLocation: false,
        locationName: AppConfig.app.location.name,
        locationLat: AppConfig.app.location.latitude,
        locationLng: AppConfig.app.location.longitude
      });

      // emit the value to the parent
      this.emitUpdatedLocationValue();
    }
  }

  selectLocation(event: any, location: string) {
    // prevent blur from firing because otherwise this will all happen twice
    event.preventDefault();
    this.locationInput.nativeElement.blur();

    // patch location name from selected location
    this.locationForm.patchValue({
      locationName: location
    });

    // search location
    this.searchLocation(this.locationForm.get('locationName').value);
  }

  searchLocation(locationValue: string) {
    this.locationInputHasFocus = false;
    this.showLocationSuggestions = false;
    this.showRecentLocations = false;

    // add location to recent locations if not already in the array
    if (locationValue != null && locationValue.trim().length > 0) {
      if (this.recentLocations.length === 0) {
        this.recentLocations = [locationValue];
      } else {
        let duplicateLocation = false;
        this.recentLocations.map((recentLocation) => {
          if (recentLocation === locationValue) {
            duplicateLocation = true;
          }
        });
        if (!duplicateLocation) {
          if (locationValue !== '' && locationValue.trim().length > 0) {
            this.recentLocations = [locationValue, ...this.recentLocations];
          }
        }
      }

      this.recentLocations = this.recentLocations.slice(0, 3);
      this.authService.updateAppData({ recentLocations: this.recentLocations });
    }

    // get longitude and latitude for location
    if (locationValue !== 'no suggestions available') {
      this.locationService.getLatLng(locationValue).subscribe((locationCoordinates) => {
        this.locationForm.patchValue({
          useCurrentLocation: false,
          locationLat: locationCoordinates.lat,
          locationLng: locationCoordinates.lng
        });

        this.locationSelected = true;
        // emit the value to the parent
        this.emitUpdatedLocationValue();
      });
    }
  }

  clearInput() {
    // reset stuff
    this.useCurrentLocation = false;
    this.showLocationSuggestions = false;
    this.locationSuggestions = [];
    this.locationSuggestionsLoading = false;
    this.loadingCurrentLocation = false;

    // empty the value
    this.locationForm.patchValue({
      useCurrentLocation: false,
      locationName: null,
      locationLat: null,
      locationLng: null
    });
    this.locationSelected = false;

    // emit the value to the parent
    this.emitUpdatedLocationValue();

    // if user has recentLocations show them
    if (this.recentLocations.length > 0) {
      this.locationInputHasFocus = true;
      this.showRecentLocations = true;
    }
  }

  emitUpdatedLocationValue() {
    const updatedLocationValue = {
      locationSelected: this.locationSelected,
      useCurrentLocation: this.locationForm.get('useCurrentLocation').value,
      locationName: this.locationForm.get('locationName').value,
      locationLat: this.locationForm.get('locationLat').value,
      locationLng: this.locationForm.get('locationLng').value
    };
    this.locationValueChanged.emit(updatedLocationValue);
  }

  ngOnDestroy() {
    if (this.locationNameSubscription) {
      this.locationNameSubscription.unsubscribe();
    }
  }
}
