import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BuyerAddressBsComponent } from '@paperclip/bottomsheets/bottomsheets';
import { AppConfig } from '@paperclip/core/app.config';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { ModalService } from '@paperclip/modals/modal.service';
import { OrderDetailLayout } from '@paperclip/models/component/order-detail-Layout';
import { ComponentUpdate } from '@paperclip/models/component-update';
import { Order, OrderState, OrderView } from '@paperclip/models/order';
import { OrderAction } from '@paperclip/models/order/OrderAction';
import { ignoreElements, of, Subscription } from 'rxjs';
import { AuthService } from '@paperclip/core/auth/auth.service';

@Component({
  selector: 'pc-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit, OnDestroy, OnChanges {
  @Input() page: "buying" | "selling"
  @Input() order: Order;
  @Input() insideOrderCard = false;
  @Input() showLoading = false;
  @Input() orderDetailLayout: OrderDetailLayout = {
    headerTitle: true,
    showMenu: true,
    showOrderStatus: true,
    showOrderFooter: true
  };
  @Output() performOrderAction: EventEmitter<OrderAction> = new EventEmitter<OrderAction>();
  forceShowStatus = false;
  userCurrency = AppConfig.web.currency.symbol;
  windowSize: string;
  windowResizeSubscription: Subscription;
  updateServiceSubscription: Subscription;
  title: string;
  menu: 'selling-menu' | 'buying-menu';
  orderView: OrderView;

  constructor(
    private windowResizeService: WindowResizeService,
    private modalService: ModalService,
    private updateService: UpdatesService,
    private confirmationMessageService: ConfirmationMessageService,
    private authService: AuthService
  ) {
    this.windowSize = window.innerWidth < 750 ? 'mobile' : window.innerWidth < 1200 ? 'tablet' : 'desktop';
    this.windowResizeSubscription = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 750 ? 'mobile' : updatedWidth < 1200 ? 'tablet' : 'desktop';
    });

    this.updateServiceSubscription = this.updateService.getUpdateData().subscribe((updateData: ComponentUpdate) => {
      if (updateData.updateKeyValue) {
        if (updateData.action === 'decline-all-other-offers') {
          this.forceShowStatus = this.windowSize !== 'desktop';
        }
      }
    });

    this.title = this.createTitle();
    this.menu = this.page === 'buying' ? 'buying-menu' : 'selling-menu'
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.order) {
      this.orderView = new OrderView(this.order, this.page, this.authService.getAuthedUser().detail.id)
      this.title = this.createTitle();
      this.menu = this.page === 'buying' ? 'buying-menu' : 'selling-menu'

    }
  }

  ngOnInit() {
    if (!this.insideOrderCard) {
      this.orderDetailLayout.showOrderStatus = true
    }
  }

  ngOnDestroy(): void {
    this.windowResizeSubscription.unsubscribe();
    this.updateServiceSubscription.unsubscribe();
  }

  getButtonLink(action: string) {
    if (this.order.isSuspended()) {
      return;
    }

    switch (action) {
      case 'message-seller':
        return ['/messages', this.order.seller.id];

      case 'message-buyer':
        return ['/messages', this.order.buyer.id];

      case 'send-reminder':
        return ['/messages', this.page === 'buying' ? this.order.seller.id : this.order.buyer.id];
    }
  }

  orderAction(action: string) {
    if (this.order.isSuspended()) {
      this.confirmationMessageService.showConfirmationMessage('trade-suspended');
      return;
    }

    this.performOrderAction.emit({
      action: action,
      page: this.insideOrderCard ? 'selling' : 'item-overview',
      order: this.order
    });
  }

  disputeTrade() {
    if (this.order.isSuspended()) {
      this.confirmationMessageService.showConfirmationMessage('trade-suspended');
      return;
    }

    this.performOrderAction.emit({
      action: 'dispute-trade',
      page: this.insideOrderCard ? 'selling' : 'item-overview',
      order: this.order
    });
  }

  createTitle() {
    if(this.page === 'buying') return 'you-paid'
    else return 'buyer-paid'
  }

  public showBuyerAddress(): string {
    if (this.insideOrderCard) {
      return this.page === 'selling' && this.order?.state === OrderState.Paid
        ? this.windowSize === 'desktop'
          ? 'full-address'
          : 'view-address'
        : null;
    } else {
      return this.order?.state === OrderState.Paid ? 'full-address' : null;
    }
  }

  public showBuyersAddressModal() {
    this.modalService.open(
      BuyerAddressBsComponent,
      {
        needsAuth: false,
        data: this.order.getFormattedDeliveryAddress()
      },
      true
    );
  }
  
  
}