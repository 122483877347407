import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { AppConfig } from '../app.config';

@Injectable()
export class AddApiUrlInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only insert api url for requests that we make to the API
    let apiReq = req.clone({});
    const useApi = req.headers.get('useApi') === 'false' ? false : true;
    if (req.url.indexOf('assets') < 0 && useApi) {
      apiReq = req.clone({ url: `${AppConfig.api.url}${AppConfig.api.version}/${req.url}` });
    }

    const useApiVersionPrefix = req.headers.get('useApiVersionPrefix') === 'false' ? false : true;
    if (!useApiVersionPrefix) {
      apiReq = req.clone({ url: `${AppConfig.api.url}/${req.url}` });
    }

    return next.handle(apiReq);
  }
}
