import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConfig } from '@paperclip/core/app.config';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { ItemOverviewConfig } from '@paperclip/page/item-overview/ItemOverviewConfig';
import { NgxSwiperConfig } from 'ngx-image-swiper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-item-image-viewer-modal',
  templateUrl: './item-image-viewer-modal.component.html',
  styleUrls: ['./item-image-viewer-modal.component.scss']
})
export class ItemImageViewerModalComponent implements OnInit, OnDestroy {
  userCurrency = AppConfig.web.currency.symbol || '£';
  itemDescriptionExpanded: boolean;
  ngxSwiperConfig: NgxSwiperConfig = {
    navigation: false,
    imgBackgroundSize: 'contain'
  };
  paymentTransactionsEnabled: boolean = AppConfig.app.features.payments.enabled;
  paymentTransactionsEnabledSubscription: Subscription;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ItemImageViewerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public itemOverviewConfig: ItemOverviewConfig,
    private coreService: CoreService,
    private confirmationMessageService: ConfirmationMessageService
  ) {
    this.paymentTransactionsEnabledSubscription = this.coreService
      .getPaymentTransactionsEnabled()
      .subscribe((enabled: boolean) => {
        this.paymentTransactionsEnabled = enabled;
      });
  }

  ngOnInit() {
    this.paymentTransactionsEnabled = AppConfig.app.features.payments.enabled;
  }

  ngOnDestroy(): void {
    this.paymentTransactionsEnabledSubscription.unsubscribe();
  }

  public expandItemDescription() {
    this.itemDescriptionExpanded = !this.itemDescriptionExpanded;
  }

  public editItem() {
    if (
      this.itemOverviewConfig.item.detail.stats.activeOrdersCount === 0 &&
      !this.itemOverviewConfig.item.detail.isSold
    ) {
      this.dialogRef.close();
      this.router.navigate(['/edit-item', this.itemOverviewConfig.item.detail.id]);
    }
  }

  public buyItem() {
    if (this.paymentTransactionsEnabled) {
      this.goToItemCheckout();
    } else {
      this.confirmationMessageService.showConfirmationMessage('payments-disabled');
    }
  }

  goToItemCheckout(offerId?: string) {
    this.router.navigate(['/checkout'], {
      queryParams: { item: this.itemOverviewConfig.item.detail.id, offerId: offerId }
    });
  }
}
