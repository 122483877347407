<div
  class="small-data-container"
  [ngClass]="{ 'loading-data': itemLoading }"
  [ngStyle]="{ height: thumbnailSize + 'px' }"
>
  <a
    class="small-data"
    [ngClass]="{ 'small-data--large': size === 'large', 'small-data--link': link }"
    [routerLink]="link && item ? ['/item', item?.detail.id] : []"
  >
    <div
      class="small-data__thumbnail"
      [ngStyle]="{
        width: thumbnailSize + 'px',
        height: thumbnailSize + 'px',
        'margin-right': thumbnailSize === 40 ? '8px' : '16px'
      }"
    >
      <div *ngIf="item?.detail?.isSold || item?.detail?.isTraded" class="sold-item-overlay">
        <div class="sold-item-overlay sold-item-overlay--background"></div>
        <div class="sold-item-overlay sold-item-overlay--foreground">
          <p class="p-small sold-tag highlight-text">{{ 'tag.sold' | translate }}</p>
        </div>
      </div>

      <img class="bg-image data-item__picture" [src]="item?.detail?.thumbnail" />
    </div>

    <div class="small-data__detail">
      <p class="highlight-text faded-content">{{ item?.detail.name }}</p>
      <div class="small-data__detail__meta faded-content">

        <p *ngIf="metaDetail === 'price'" class="p-small price-breakdown" [ngClass]="item?.detail.priceClass">
          {{ userCurrency }}{{ item?.detail.priceItem | number: '1.2-2' }}

        <!-- delivery cost/info -->
        <span *ngIf="!!item" class="highlight-text item-title__meta__delivery p-small">
          {{ 'item-overview.+' | translate }}

          <!-- postage cost -->
          <span>
            <span *ngIf="item?.detail.priceDelivery" class="colour-secondary p-small">
              {{ userCurrency }}{{ item?.detail.priceDelivery | number: '1.2-2' }}
            </span>
            <span *ngIf="!item?.detail.priceDelivery" class="colour-primary p-small">
              {{ 'item-overview.delivery.free-delivery' | translate }}
            </span>
            {{ 'item-overview.delivery.delivery' | translate }}
          </span>

          {{ 'item-overview.+' | translate }}

          <span>
            <span class="colour-secondary p-small">
              {{ userCurrency }}{{ item?.detail.priceFee | number: '1.2-2' }}
            </span>
            {{ 'item-overview.price-fee' | translate }}
          </span>
          
        </span>

        

        </p>
        
        <p *ngIf="metaDetail === 'location'" class="p-small">
          item?.detail.location

          <span
            *ngIf="item?.detail?.isSold && !item?.detail?.isTraded"
            class="colour-secondary small-data__detail__meta__manually-sold"
          >
            <span class="middot">&middot;</span>{{ 'trading.card.state.manually-sold' | translate }}
          </span>
        </p>

        <!-- <p class="p-small" [ngClass]="metaDetail === 'price' ? item?.detail.priceClass : ''">
          {{ metaDetail === 'price' && item?.detail.priceClass === 'colour-secondary' ? userCurrency : ''}}
          {{ metaDetail === 'price' ? (item?.detail.price | translate) : item?.detail.location
          }}
          <span
            *ngIf="metaDetail === 'price' && item?.detail?.isSold && !item?.detail?.isTraded"
            class="colour-secondary small-data__detail__meta__manually-sold"
          >
            <span class="middot">&middot;</span>{{ 'trading.card.state.manually-sold' | translate }}
          </span>
        </p> -->

        <span *ngIf="showTag && item?.detail.tag" class="item-tag item-tag--{{ item?.detail.tag }}">{{
          'tags.item.' + item?.detail.tag | translate
        }}</span>
      </div>
    </div>

    <img *ngIf="showArrow" class="small-data__arrow" src="/assets/icons/misc/arrow-big.svg" />
  </a>

  <pc-menu *ngIf="!itemLoading && menu?.name" [menu]="menu?.name" [menuData]="menu?.data"></pc-menu>
</div>
