import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';

@Component({
  selector: 'pc-force-email-modal',
  templateUrl: './force-email-modal.component.html',
  styleUrls: ['./force-email-modal.component.scss']
})
export class ForceEmailModalComponent {
  public stepNumber = 1;
  public activeRequest: boolean;
  public emailAddressForm: UntypedFormGroup = this.formBuilder.group({
    emailAddress: ['', Validators.compose([Validators.required, Validators.email])]
  });

  constructor(
    public dialogRef: MatDialogRef<ForceEmailModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { complete: () => {} },
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private confirmationMsgService: ConfirmationMessageService,
    private coreService: CoreService
  ) {}

  addEmailToAccount() {
    const emailAddress = this.emailAddressForm.get('emailAddress').value;
    this.activeRequest = true;
    this.authService.sendVerificationEmail(this.authService.getAuthedUser().detail.id, emailAddress).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.modalData.complete();
          this.authService.updateAuthedUser({ detail: { email: emailAddress } });
          this.confirmationMsgService.showConfirmationMessage('verification-email-sent');
          this.stepNumber = 2;
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
