import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { UsersService } from '@paperclip/page/users.service';

@Component({
  selector: 'pc-block-user-modal',
  templateUrl: './block-user-modal.component.html',
  styleUrls: ['./block-user-modal.component.scss']
})
export class BlockUserModalComponent {
  constructor(
    public dialogRef: MatDialogRef<BlockUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public userProfileId: string,
    private usersService: UsersService,
    private confirmationMsgService: ConfirmationMessageService,
    private coreService: CoreService
  ) {}

  blockUser() {
    this.usersService.blockUser(this.userProfileId).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.dialogRef.close();
          this.confirmationMsgService.showConfirmationMessage('blocked-user');
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        // this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
