<div class="modal add-item-confirmation">
  <pc-close-icon (click)="closeModal()"></pc-close-icon>
  <div class="modal-body">
    <div class="item-image-container">
      <img [src]="item.detail.thumbnail" [alt]="item.detail.name" class="item-image-container__item-image bg-image" />
      <img src="/assets/icons/misc/checkmark.svg" alt="tick icon" class="item-image-container__checkmark" />
    </div>
    <h2>{{ 'modals.add-item-confirmation.title' | translate }}</h2>
    <p>{{ 'modals.add-item-confirmation.subtitle' | translate }}</p>
    <button class="btn-large btn-stroked" (click)="shareItem()">
      {{ 'modals.add-item-confirmation.share' | translate }}
    </button>
  </div>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large" (click)="closeModal()">
      {{ 'modals.add-item-confirmation.view-listing' | translate }}
    </button>
    <button class="btn-large btn-primary" (click)="addAnother()">
      {{ 'modals.add-item-confirmation.add-another' | translate }}
    </button>
  </div>
</div>
