import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  /*-- inputs --*/
  @Input() option: any;

  /*-- outputs --*/
  @Output() buttonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
}
