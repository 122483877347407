<form [formGroup]="priceForm" pcClickStopPropagation>
  <div class="price-form">
    <div class="price-input">
      <p class="price-input__label">{{ 'search.filters.price.min' | translate }}</p>
      <div class="input-group input-group--label-start input-group--btn-end">
        <p class="highlight-text input-group__label">{{ userCurrency }}</p>
        <input
          pcCurrencyInputPadding
          formControlName="priceMin"
          type="text"
          placeholder="0.00"
          [textMask]="{ mask: currencyMask }"
          (keydown.enter)="enterKeydown($event)"
          pcTabStopPropagation
        />
        <pc-clear-input-btn
          *ngIf="showClearPriceMinBtn"
          class="input-group__btn input-group__btn--end"
          (click)="clearFormControl('priceMin')"
          pcTabStopPropagation
        >
        </pc-clear-input-btn>
      </div>
    </div>

    <div class="price-input">
      <p class="price-input__label">{{ 'search.filters.price.max' | translate }}</p>
      <div class="input-group input-group--label-start input-group--btn-end">
        <p class="highlight-text input-group__label">{{ userCurrency }}</p>
        <input
          pcCurrencyInputPadding
          formControlName="priceMax"
          type="text"
          placeholder="0.00"
          [textMask]="{ mask: currencyMask }"
          (keydown.enter)="enterKeydown($event)"
          pcTabStopPropagation
        />
        <pc-clear-input-btn
          *ngIf="showClearPriceMaxBtn"
          class="input-group__btn input-group__btn--end"
          (click)="clearFormControl('priceMax')"
          pcTabStopPropagation
        >
        </pc-clear-input-btn>
      </div>
    </div>
  </div>
</form>
