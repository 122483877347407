<!-- without link -->
<div
  *ngIf="!usernameLink"
  class="user-star-rating"
  [ngClass]="{
    'user-star-rating--inline': display === 'inline',
    'loading-data': showLoading
  }"
>
  <ul *ngIf="!newUser" class="user-star-rating__stars">
    <li *ngFor="let star of user?.detail?.starRating">
      <img src="/assets/icons/profile/review-star-{{ star }}.svg" alt="" />
    </li>
  </ul>
  <p *ngIf="newUser" class="p-small user-star-rating__new-user">New to Paperclip</p>
  <p class="user-star-rating__review-count p-small">
    <span class="highlight-text">{{ user?.detail?.reviewsCount | number }}</span>
    {{
      user?.detail?.reviewsCount === 1 ? ('item-overview.review' | translate) : ('item-overview.reviews' | translate)
    }}
  </p>
</div>

<!-- with link -->
<div
  *ngIf="usernameLink"
  class="user-star-rating user-star-rating--link"
  [ngClass]="{
    'user-star-rating--inline': display === 'inline',
    'loading-data': showLoading,
    'user-star-rating--disabled': disableReviews
  }"
  (click)="openUserReviewsModal()"
>
  <ul *ngIf="!newUser" class="user-star-rating__stars">
    <li *ngFor="let star of user?.detail?.starRating">
      <img src="/assets/icons/profile/review-star-{{ star }}.svg" alt="" />
    </li>
  </ul>
  <p *ngIf="newUser" class="p-small user-star-rating__new-user">New to Paperclip</p>
  <p class="user-star-rating__review-count p-small">
    <span class="highlight-text">{{ user?.detail?.reviewsCount | number }}</span>
    {{
      user?.detail?.reviewsCount === 1 ? ('item-overview.review' | translate) : ('item-overview.reviews' | translate)
    }}
  </p>
</div>
