import { Directive, ElementRef, OnInit } from '@angular/core';
import { AppConfig } from '@paperclip/core/app.config';

@Directive({
  selector: '[pcCurrencyInputPadding]'
})
export class CurrencyInputPaddingDirective implements OnInit {
  userCurrency = AppConfig.web.currency.symbol || '£';

  constructor(private input: ElementRef) {}

  ngOnInit(): void {
    const largeInput = this.input.nativeElement.parentElement.classList.value.includes('input-group--large');
    this.input.nativeElement.style.paddingLeft = !largeInput
      ? `${20 + this.userCurrency.length * 10}px`
      : `${20 + this.userCurrency.length * 12}px`;
  }
}
