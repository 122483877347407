import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';

@Component({
  selector: 'pc-search-view-type-filter-bs',
  templateUrl: './search-view-type-filter-bs.component.html'
})
export class SearchViewTypeFilterBsComponent {
  constructor(
    public bottomSheetRef: MatBottomSheetRef<SearchViewTypeFilterBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public searchFilters: SearchFilters
  ) {}
}
