/* eslint-disable no-useless-escape */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { PaymentInfoList } from '@paperclip/models/payments/payment-info-list';
import { PaymentAccountForm } from '@paperclip/models/payments/PaymentAccountForm';
import { PaymentAccountFormOutput } from '@paperclip/models/payments/PaymentAccountFormOutput';
import { PaymentAccount } from '@paperclip/models/wallet/PaymentAccount';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

@Component({
  selector: 'pc-payment-account-form',
  templateUrl: './payment-account-form.component.html'
})
export class PaymentAccountFormComponent implements OnChanges, OnDestroy {
  @Input() paymentAccountLoading: boolean;
  @Input() mpUserCategory: 'PAYER' | 'OWNER' | 'UNKNOWN';
  @Input() paymentAccount: PaymentAccount;
  @Input() nationalitiesList: PaymentInfoList[];
  @Input() countriesList: PaymentInfoList[];
  @Output() paymentAccountFormValue: EventEmitter<PaymentAccountFormOutput> =
    new EventEmitter<PaymentAccountFormOutput>();
  paymentAccountForm: UntypedFormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    birthDate: ['', Validators.required],
    nationality: ['', Validators.required],
    countryOfResidence: ['', Validators.required]
  });
  nationalityFormGroup: UntypedFormGroup = this.formBuilder.group({ nationality: [null, Validators.required] });
  countryFormGroup: UntypedFormGroup = this.formBuilder.group({ country: [null, Validators.required] });
  apiBirthDate: string;
  paymentAccountForm$: Subscription;
  dobMask: Array<string | RegExp> = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  autoCorrectedDatePipe: any = createAutoCorrectedDatePipe('dd/mm/yyyy', {
    minYear: 1900,
    maxYear: new Date().getFullYear()
  });
  windowSize: string;
  windowSize$: Subscription;
  selectedNationality: PaymentInfoList;

  constructor(private formBuilder: UntypedFormBuilder, private windowResizeService: WindowResizeService) {
    this.paymentAccountForm$ = this.paymentAccountForm.valueChanges.subscribe(
      (paymentAccountForm: PaymentAccountForm) => {
        // validate birthDate against dd/mm/yyyy regex
        if (paymentAccountForm.birthDate) {
          const dateRegex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
          this.apiBirthDate = moment(paymentAccountForm.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

          if (paymentAccountForm.birthDate.length < 10) {
            this.paymentAccountForm.get('birthDate').setErrors({ required: true });
          } else {
            dateRegex.test(this.apiBirthDate)
              ? this.paymentAccountForm.setErrors(null)
              : this.paymentAccountForm.get('birthDate').setErrors({ required: true });
          }
        }

        this.paymentAccountFormValue.emit({
          paymentAccountForm: paymentAccountForm,
          isValid: this.paymentAccountForm.valid,
          apiBirthDate: this.apiBirthDate
        });
      }
    );

    this.windowSize$ = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 750 ? 'mobile' : updatedWidth < 1200 ? 'tablet' : 'desktop';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.paymentAccountLoading && changes?.paymentAccount && this.mpUserCategory === 'OWNER') {
      this.patchFormFromExistingData();
    }

    if (!changes?.paymentAccountLoading && !this.mpUserCategory && changes?.countriesList?.currentValue) {
      this.patchFormsNoExistingData();
    }
  }

  ngOnDestroy(): void {
    this.paymentAccountForm$.unsubscribe();
    this.windowSize$.unsubscribe();
  }

  private patchFormsNoExistingData() {
    const matchedNationality = this.nationalitiesList.find((nationality: PaymentInfoList) => nationality.isDefault);
    const matchedCountry = this.countriesList.find((country: PaymentInfoList) => country.isDefault);
    this.patchForms(matchedNationality, matchedCountry);
  }

  private patchFormFromExistingData() {
    const matchedNationality = this.nationalitiesList.find(
      (nationality: PaymentInfoList) => nationality.name === this.paymentAccount.nationality
    );
    const matchedCountry = this.countriesList.find(
      (country: PaymentInfoList) => country.name === this.paymentAccount.countryOfResidence
    );

    this.patchForms(matchedNationality, matchedCountry);
  }

  private patchForms(nationality: PaymentInfoList, country: PaymentInfoList) {
    this.nationalityFormGroup.patchValue({ nationality: nationality });
    this.countryFormGroup.patchValue({ country: country });
    this.paymentAccountForm.patchValue({
      nationality: nationality.name,
      countryOfResidence: country.name
    });

    if (this.paymentAccount) {
      this.paymentAccountForm.patchValue({
        firstName: this.paymentAccount.firstName,
        lastName: this.paymentAccount.lastName,
        birthDate: this.paymentAccount.birthDate
      });
    }
  }

  public changeNationality() {
    const { name } = this.nationalityFormGroup.value.nationality;
    this.paymentAccountForm.patchValue({ nationality: name });
  }

  public changeCountry() {
    const { name } = this.countryFormGroup.value.country;
    this.paymentAccountForm.patchValue({ countryOfResidence: name });
  }
}
