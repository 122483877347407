import { Component, Input } from '@angular/core';

@Component({
  selector: 'pc-grid-view-deal',
  templateUrl: './grid-view-deal.component.html'
})
export class GridViewDealComponent {
  @Input() dataLoading: boolean;
  @Input() dataLoadingMore: boolean;
  @Input() dataAllLoaded: boolean;
  @Input() numberOfItems: number;
  @Input() gridItem: any;

  imgLoaded(dealId: string) {
    if (this.gridItem.id === dealId) {
      this.gridItem.imgLoaded = true;
    }
  }
}
