import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';

import { UsersService } from '../../../../../user/users.service';

@Component({
  selector: 'pc-grid-view-user',
  templateUrl: './grid-view-user.component.html'
})
export class GridViewUserComponent implements OnInit {
  @Input() dataLoading: boolean;
  @Input() dataLoadingMore: boolean;
  @Input() dataAllLoaded: boolean;
  @Input() numberOfItems: number;
  @Input() gridItem: any;
  loggedInUserId: string;

  constructor(
    private authService: AuthService,
    private userService: UsersService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.loggedInUserId = this.authService.getAuthedUser().detail?.id || '';
  }

  /*====================
  utilities
  ====================*/
  imgLoaded(userId: string) {
    if (this.gridItem.userId === userId) {
      this.gridItem.imgLoaded = true;
    }
  }

  followUser(userId: string) {
    this.userService.followUser(userId, this.loggedInUserId).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('followed');
          this.changeFollowStatus(userId);
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  unfollowUser(userId: string) {
    this.userService.unfollowUser(userId, this.loggedInUserId).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('unfollowed');
          this.changeFollowStatus(userId);
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  changeFollowStatus(userId: string) {
    if (this.gridItem.userId === userId) {
      this.gridItem.isFollowed = !this.gridItem.isFollowed;
    }
  }
}
