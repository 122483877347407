<!--
  initial loading state
-->
<div *ngIf="dataLoading" class="item item--loading item--item">
  <!-- item image & favourite item -->
  <div class="item__image">
    <img class="bg-image" src="assets/loading-img.png" />
  </div>

  <!-- item info -->
  <div class="item__info">
    <!-- item meta: category & price -->
    <div class="item-meta">
      <p class="item-meta__left"></p>
      <p class="item-meta__right item-meta__price"></p>
    </div>

    <!-- item name -->
    <a class="item-name"></a>
  </div>
</div>

<!--
  loaded
-->
<a *ngIf="!dataLoading" class="item item--item" [ngClass]="{ 'item--loading': !gridItem.imgLoaded }">
  <!-- item image & favourite item -->
  <div class="item__image">
    <a [routerLink]="['/item', gridItem.id, gridItem.name]" [queryParams]="{ from: dataToGet }">
      <img class="bg-image" [src]="gridItem.thumbnails[0]" (load)="imgLoaded(gridItem.id)" />
    </a>

    <!-- favourite/unfavourite item -->
    <ng-container *ngIf="!gridItem.isOwnItem">
      <button
        *ngIf="!gridItem.isFavourite"
        (click)="favouriteItem(gridItem)"
        class="btn-icon btn-icon--small btn-opacity save-item"
      >
        <img src="/assets/icons/item/save.svg" alt="save item" />
      </button>
      <button
        *ngIf="gridItem.isFavourite"
        (click)="unfavouriteItem(gridItem)"
        class="btn-icon btn-icon--small btn-opacity save-item"
      >
        <img src="/assets/icons/item/saved.svg" alt="saved item" />
      </button>
    </ng-container>

    <!-- item tags: in order of priority -->
    <span *ngIf="showOwnItemTag" class="item-tag item-tag--own-item">
      {{ 'tags.item.own-item' | translate }}
    </span>
    <span *ngIf="showFeaturedItemTag" class="item-tag item-tag--featured-item">
      {{ 'tags.item.featured-item' | translate }}
    </span>
    <span *ngIf="showNewItemTag" class="item-tag item-tag--new-item">
      {{ 'tags.item.new-item' | translate }}
    </span>
  </div>

  <!-- item info -->
  <a class="item__info" [routerLink]="['/item', gridItem.id, gridItem.name]" [queryParams]="{ from: dataToGet }">
    <!-- item meta: category & price -->
    <div class="item-meta">
      <p class="item-meta__left faded-content">{{ gridItem.category.name }}</p>
      <p class="item-meta__right" class="colour-secondary">{{ userCurrency }}{{ gridItem.price | translate }}</p>
    </div>

    <!-- item name -->
    <p class="item-name faded-content">
      {{ gridItem.name }}
    </p>
  </a>
</a>
