import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@paperclip/core/app.config';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { PaperclipEvent } from '@paperclip/models/PaperclipEvent';
import { ItemOverviewService } from '@paperclip/page/item-overview/item-overview.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-grid-view-item',
  templateUrl: './grid-view-item.component.html'
})
export class GridViewItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dataLoading: boolean;
  @Input() dataLoadingMore: boolean;
  @Input() dataAllLoaded: boolean;
  @Input() numberOfItems: number;
  @Input() gridItem: any;
  @Input() showItemTags = { ownItem: true, featuredItem: true, newItem: true };
  @Input() dataToGet: string;

  menuActionsSubscription: Subscription;

  // item tags
  // tags: ItemTag[] = [
  //   new ItemTag({ type: TagType.Own, canShow: false, isBig: false }),
  //   new ItemTag({ type: TagType.Featured, canShow: false, isBig: false }),
  //   new ItemTag({ type: TagType.New, canShow: false, isBig: false })
  // ];
  showOwnItemTag = false;
  showFeaturedItemTag = false;
  showNewItemTag = false;

  // price stuff
  userCurrency = AppConfig.web.currency.symbol || '£';

  constructor(
    private itemOverviewService: ItemOverviewService,
    private confirmationMessageService: ConfirmationMessageService,
    private updatesService: UpdatesService,
    private coreService: CoreService,
    private activatedRoute: ActivatedRoute
  ) {
    // subscribe to actions performed from menus
    this.menuActionsSubscription = this.updatesService.getSearchItem().subscribe((item) => {
      if (item.id === this.gridItem.id) {
        this.gridItem = item;
      }
    });
  }

  ngOnInit() {
    // logic for showing item tags in priority order
    this.updateTagsStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      // logic for showing item tags in priority order
      this.updateTagsStatus();
    }
  }

  updateTagsStatus() {
    // this.tags.forEach((tag) => {
    //   const tagType: TagType = tag.getType();
    //   let canShowTag = false;
    //   if (!this.dataLoading) {
    //     switch (tagType) {
    //       case TagType.Own:
    //         canShowTag = this.showItemTags.ownItem && this.gridItem.isOwnItem;
    //         break;
    //       case TagType.Featured:
    //         canShowTag = !this.showItemTags.ownItem && this.showItemTags.featuredItem && this.gridItem.isFeaturedItem;
    //         break;
    //       case TagType.New:
    //         canShowTag = !this.showItemTags.featuredItem && this.showItemTags.newItem && this.gridItem.isNewItem;
    //         break;
    //       default:
    //         return false;
    //     }
    //   }
    //   tag.updateCanShow(canShowTag);
    // });
    if (!this.dataLoading) {
      // ownItem ON
      // featuredItem ON
      // newItem ON
      if (this.showItemTags.ownItem && this.showItemTags.featuredItem && this.showItemTags.newItem) {
        if (this.gridItem.isOwnItem) {
          this.showOwnItemTag = true;
        } else if (this.gridItem.isFeatured) {
          this.showFeaturedItemTag = true;
        } else if (this.gridItem.isNew) {
          this.showNewItemTag = true;
        }
      }

      // ownItem ON
      // featuredItem OFF
      // newItem ON
      // eslint-disable-next-line brace-style
      else if (this.showItemTags.ownItem && !this.showItemTags.featuredItem && this.showItemTags.newItem) {
        if (this.gridItem.isOwnItem) {
          this.showOwnItemTag = true;
        } else if (this.gridItem.isNew) {
          this.showNewItemTag = true;
        }
      }

      // ownItem ON
      // featuredItem ON
      // newItem OFF
      // eslint-disable-next-line brace-style
      else if (this.showItemTags.ownItem && this.showItemTags.featuredItem && !this.showItemTags.newItem) {
        if (this.gridItem.isOwnItem) {
          this.showOwnItemTag = true;
        } else if (this.gridItem.isFeatured) {
          this.showFeaturedItemTag = true;
        }
      }

      // ownItem ON
      // featuredItem OFF
      // newItem OFF
      // eslint-disable-next-line brace-style
      else if (this.showItemTags.ownItem && !this.showItemTags.featuredItem && !this.showItemTags.newItem) {
        if (this.gridItem.isOwnItem) {
          this.showOwnItemTag = true;
        }
      }

      // ownItem OFF
      // featuredItem ON
      // newItem ON
      // eslint-disable-next-line brace-style
      else if (!this.showItemTags.ownItem && this.showItemTags.featuredItem && this.showItemTags.newItem) {
        if (this.gridItem.isFeatured) {
          this.showFeaturedItemTag = true;
        } else if (this.gridItem.isNew) {
          this.showNewItemTag = true;
        }
      }

      // ownItem OFF
      // featuredItem ON
      // newItem OFF
      // eslint-disable-next-line brace-style
      else if (!this.showItemTags.ownItem && this.showItemTags.featuredItem && !this.showItemTags.newItem) {
        if (this.gridItem.isFeatured) {
          this.showFeaturedItemTag = true;
        }
      }

      // ownItem OFF
      // featuredItem OFF
      // newItem ON
      // eslint-disable-next-line brace-style
      else if (!this.showItemTags.ownItem && !this.showItemTags.featuredItem && this.showItemTags.newItem) {
        if (this.gridItem.isNew) {
          this.showNewItemTag = true;
        }
      }

      // ownItem OFF
      // featuredItem OFF
      // newItem OFF
      // eslint-disable-next-line brace-style
      else if (!this.showItemTags.ownItem && !this.showItemTags.featuredItem && !this.showItemTags.newItem) {
        this.showOwnItemTag = false;
        this.showFeaturedItemTag = false;
        this.showNewItemTag = false;
      }
    }
  }

  ngOnDestroy() {
    this.menuActionsSubscription.unsubscribe();
  }

  /*====================
  utilities
  ====================*/
  imgLoaded(itemId: string) {
    if (this.gridItem.id === itemId) {
      this.gridItem.imgLoaded = true;
    }
  }

  favouriteItem(item: any) {
    this.itemOverviewService.favouriteItem(item.id).subscribe(
      ({ code, message }: PcApiResponse) => {
        if (code === 1) {
          this.trackPaperclipEvent(this.dataToGet, 'save-item', item.id);
          this.confirmationMessageService.showConfirmationMessage('saved-item');
          item.isFavourite = true; // update item view
          this.updatesService.updateSearchItem(item);
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  unfavouriteItem(item: any) {
    this.itemOverviewService.unfavouriteItem(item.id).subscribe(
      ({ code, message }: PcApiResponse) => {
        if (code === 1) {
          this.trackPaperclipEvent(this.dataToGet, 'unsave-item', item.id);
          this.confirmationMessageService.showConfirmationMessage('unsaved-item');
          item.isFavourite = false; // update item view
          this.updatesService.updateSearchItem(item);
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  private trackPaperclipEvent(dataToGet: string, action: string, itemId: string) {
    const paperclipEvent: PaperclipEvent = { name: null, data: { objectIds: [itemId] } };
    if (dataToGet === 'similar-items') {
      switch (action) {
        case 'save-item':
          paperclipEvent.name = 'ITEM_SAVED';
          break;

        case 'unsave-item':
          paperclipEvent.name = 'ITEM_UNSAVED';
          break;

        default:
          console.error('unknown action');
          break;
      }
    } else if (dataToGet === 'user-items') {
      switch (action) {
        case 'save-item':
          paperclipEvent.name = 'ITEM_SAVED';
          break;

        case 'unsave-item':
          paperclipEvent.name = 'ITEM_UNSAVED';
          break;

        default:
          console.error('unknown action');
          break;
      }
    }

    if (this.activatedRoute.snapshot.queryParams?.queryId) {
      paperclipEvent.data.queryId = this.activatedRoute.snapshot.queryParams?.queryId || null;
    }

    this.coreService.trackPaperclipEvent({ name: paperclipEvent.name, data: paperclipEvent.data }).subscribe();
  }
}
