<form [formGroup]="addItemForm">
  <div formGroupName="deliveryDetails">
    <ul class="checkbox-list checkbox-list--detailed">
      <!-- post -->
      <li (click)="enablePostage()" class="checkbox-list__item">
        <div>
          <p class="highlight-text">{{ 'add-item.delivery-details.delivery-method.post' | translate }}</p>
          <p>{{ 'add-item.delivery-details.delivery-method.post-desc' | translate }}</p>
        </div>
        <pc-checkmark-icon [checked]="addItemForm.get('deliveryDetails').get('postageAvailable').value" [size]="32">
        </pc-checkmark-icon>
      </li>

      <!-- postage cost if selected -->
      <li
        *ngIf="addItemForm.get('deliveryDetails').get('postageAvailable').value"
        class="delivery-option-row delivery-option-row--postage checkbox-list__item"
      >
        <div>
          <p class="highlight-text">
            {{ 'add-item.delivery-details.delivery-method.delivery-price' | translate }}
          </p>
          <p>{{ 'add-item.delivery-details.delivery-method.delivery-price-desc' | translate }}</p>
        </div>
        <div class="input-group input-group--label-start input-group--btn-end">
          <p class="highlight-text input-group__label">{{ currency.symbol }}</p>
          <input
            pcCurrencyInputPadding
            formControlName="postageCost"
            class="colour-secondary"
            type="text"
            placeholder="0.00"
            [textMask]="{ mask: currency.mask }"
            (keydown.enter)="$event.preventDefault()"
          />
          <pc-clear-input-btn
            *ngIf="addItemForm.get('deliveryDetails').get('postageCost').value"
            class="input-group__btn input-group__btn--end"
            (click)="addItemForm.get('deliveryDetails').get('postageCost').patchValue(null)"
          >
          </pc-clear-input-btn>
        </div>
      </li>

      <div class="separator"></div>

      <!-- collection -->
      <li (click)="enableMip()" class="checkbox-list__item">
        <div>
          <p class="highlight-text">{{ 'add-item.delivery-details.delivery-method.mip' | translate }}</p>
          <p>{{ 'add-item.delivery-details.delivery-method.mip-desc' | translate }}</p>
        </div>
        <pc-checkmark-icon [checked]="addItemForm.get('deliveryDetails').get('meetInPerson').value" [size]="32">
        </pc-checkmark-icon>
      </li>
    </ul>
  </div>
</form>
