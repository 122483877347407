/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessagesService } from '@paperclip/page/messages.service';

@Component({
  selector: 'pc-fraud-prevention-modal',
  templateUrl: './fraud-prevention-modal.component.html',
  styleUrls: ['./fraud-prevention-modal.component.scss']
})
export class FraudPreventionModalComponent implements OnInit {
  activeRequest: boolean;

  constructor(
    public dialogRef: MatDialogRef<FraudPreventionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: { conversationId: string; openedFromHover: boolean; complete: () => {} },
    private messagesService: MessagesService
  ) {}

  ngOnInit() {
    if (!this.modalData.openedFromHover) {
      this.markFraudModalAsSeen();
    }
  }

  private markFraudModalAsSeen() {
    this.messagesService.markMessagePromptAsSeen('modal', 'fraud', this.modalData.conversationId).subscribe(() => {});
  }

  public dontShowAgain() {
    this.messagesService
      .dontShowFraudPromptsAgain('modal', 'fraud', this.modalData.conversationId)
      .subscribe(() => this.closeModal());
  }

  public closeModal() {
    this.dialogRef.close();
    this.modalData.complete();
  }
}
