import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerifyPhoneNumberService {
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';

  /*-- notify profile component of verification code sent and pass phone number --*/
  private verificationCode = new Subject<string>();
  verificationCodeSent(phoneNumber: string) {
    this.verificationCode.next(phoneNumber);
  }
  checkVerificationCodeSent(): Observable<any> {
    return this.verificationCode.asObservable();
  }

  /*-- notify profile component when phone number is verified --*/
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private phoneVerified = new Subject<boolean>();
  setPhoneVerified(verified: boolean) {
    this.phoneVerified.next(verified);
  }
  getPhoneVerified(): Observable<any> {
    return this.phoneVerified.asObservable();
  }

  constructor(private http: HttpClient, private authService: AuthService) {}

  sendVerificationTextMessage(phone: string, countryCode: string): Observable<any> {
    return this.http.post(`users/verifyPhoneNumber`, {
      userId: this.authedUserId,
      phone: phone,
      countryCode: countryCode
    });
  }

  verifyPhoneCode(code: string): Observable<any> {
    return this.http.post(`users/verifyPhoneCode`, { userId: this.authedUserId, code: code });
  }
}
