<div class="offer-detail">
  <div class="offer-detail__header">
    <h3 *ngIf="orderDetailLayout?.headerTitle">{{ 'item-offers.card.' + title | translate }}</h3>
    <p *ngIf="!orderDetailLayout?.headerTitle" class="highlight-text">
      {{ 'item-offers.card.' + title | translate }}
    </p>

    <div class="offer-detail__header__right">
      <p>{{ order?.created }}</p>
      <pc-menu
        *ngIf="orderDetailLayout?.showMenu && !showLoading"
        [menu]="this.menu"
        [menuData]="{ order: order, showItemLink: insideOrderCard }"
      >
      </pc-menu>
    </div>
  </div>

  <table class="table-data offer-detail__offer">
    <ng-container *ngIf="showLoading"></ng-container>
    <ng-container *ngIf="!showLoading">
      <!-- cash -->
      <tr>
        <td>
          <img src="/assets/icons/item/payment.svg" alt="" />
          <p class="highlight-text">{{ 'item-offers.card.offer-stats.amount' | translate }}</p>
        </td>
        <td>
          <p class="highlight-text colour-secondary">
            {{ userCurrency }}{{ orderView.getPriceForUser() | number: '1.2-2'}}
          </p>          
        </td>
      </tr>

      <div class="separator"></div>

      <!-- buyer or seller -->
      <tr>
        <td>
          <img src="/assets/icons/trading/buyer.svg" alt="" />
          <p>
            {{
              this.page === 'selling'
                ? ('item-offers.card.offer-stats.buyer' | translate)
                : ('item-offers.card.offer-stats.seller' | translate)
            }}
          </p>
        </td>
        <td class="offer-user">
          
          <button
            *ngIf="showBuyerAddress() === 'view-address'"
            class="btn-link btn-link--primary"
            (click)="showBuyersAddressModal()"
          >
            {{ 'item-offers.card.view-address' | translate }}
          </button>
          <a
            class="highlight-text"
            [routerLink]="[
              '/u',
              page === 'selling' ? order?.buyer.username : order?.seller.username
            ]"
          >
            {{ this.page === 'selling' ? order?.buyer?.username : order?.seller?.username }}
          </a>          
        </td>
      </tr>
    </ng-container>
  </table>

  <!-- offer status -->
  <div
    *ngIf="forceShowStatus || (orderDetailLayout?.showOrderStatus && !showLoading)"
    class="offer-detail__state offer-detail__state--{{ orderView.getColourPostfix() }} offer-detail__state--{{ orderView.getStatePostfix()}}"
    [ngClass]="{
      'offer-detail__state--disputed': order?.disputedAt,
      'offer-detail__state--payment-error': order?.lastPaymentFailed,
      'offer-detail__state--user-suspended': order?.isSuspended()
    }"
  >
    <span>
      <img src="/assets/icons/navigation/info.svg" alt="" />
      <p>{{ 'item-offers.card.status' | translate }}</p>
    </span>
    <p
      class="highlight-text"
      [innerHTML]="
        'item-offers.card.offer-state.' + this.page + '.' + orderView.getOrderText() | translate | safeHtml
      "
    ></p>
  </div>

  <!-- delivery address if applicable -->
  <div
    *ngIf="showBuyerAddress() === 'full-address' && !showLoading"
    class="offer-detail__delivery-address"
    [ngClass]="{ 'offer-detail__delivery-address--inside-offer-card': insideOrderCard }"
  >
    <p class="highlight-text">{{ 'item-offers.card.deliver-to' | translate }}</p>
    <p [innerHTML]="order?.getFormattedDeliveryAddress()"></p>
  </div>

  <!-- actions -->
  <div
    *ngIf="orderView?.buttons?.length > 0 && orderDetailLayout?.showOrderFooter && !showLoading"
    class="offer-detail__footer"
    [ngClass]="{ 'multi-btn-container': orderView?.buttons?.length > 1 }"
  >
    <button
      *ngIf="order?.isDisputable && !order?.isDisputed()"
      class="btn-white"
      (click)="disputeTrade()"
    >
      {{ 'trading.card.state.disputable' | translate }}
    </button>

    <ng-container *ngFor="let button of orderView.buttons">
      <button
        *ngIf="button.action && !button.link"
        (click)="orderAction(button.action)"
        class="{{ button.class }}"
        [ngClass]="{ 'btn--disabled': button.disabled }"
      >
        {{ 'buttons.' + button.action | translate }}
      </button>

      <a
        *ngIf="button.action && button.link"
        (click)="orderAction(button.action)"
        class="btn {{ button.class }}"
        [ngClass]="{ 'btn--disabled': button.disabled }"
        [routerLink]="getButtonLink(button.action)"
      >
        {{ 'buttons.' + button.action | translate }}
      </a>
    </ng-container>
  </div>
</div>