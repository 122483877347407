<pc-steps-progress-bar [stepNumber]="1" [totalSteps]="10"></pc-steps-progress-bar>
<div class="modal add-images-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <p class="highlight-text">
      {{ 'add-item.add-images.step-number' | translate: { totalSteps: 6 } }}
    </p>
    <h2>{{ 'add-item.add-images.title' | translate }}</h2>
    <p>{{ 'add-item.add-images.subtitle' | translate }}</p>
  </div>

  <div class="modal-body">
    <!-- dropzone -->
    <pc-image-dropzone
      [config]="{ showDropzone: windowSize === 'desktop', enableReordering: windowSize === 'desktop' }"
      (imageFilesSuccess)="imageFilesSuccess($event)"
    >
    </pc-image-dropzone>
  </div>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large" (click)="dialogRef.close()">{{ 'buttons.cancel' | translate }}</button>
    <button class="btn-large btn-primary" (click)="goToAddItem()" [disabled]="itemImages.length === 0">
      {{ 'buttons.next' | translate }}
    </button>
  </div>
</div>

<div class="tip" [ngClass]="{ 'tip--closed': closeTip }">
  <img src="assets/icons/misc/tip.svg" alt="" />
  <h2>{{ 'tips.title' | translate }}</h2>
  <p>{{ 'tips.add-item.add-images' | translate }}</p>
  <pc-clear-input-btn (click)="closeTip = true"></pc-clear-input-btn>
</div>
