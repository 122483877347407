import { Injectable } from '@angular/core';
import { environment } from '@paperclip/environments/environment';
import { ComponentUpdate } from '@paperclip/models/component-update';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdatesService {
  /*-- update search results after menu actions --*/
  private searchItem = new Subject<any>();

  updateSearchItem(item: any) {
    this.searchItem.next(item);
  }

  getSearchItem(): Observable<any> {
    return this.searchItem.asObservable();
  }

  /*-- update anything from anywhere --*/
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private updateComponentData = new Subject<ComponentUpdate>();

  sendUpdateData(updateData: ComponentUpdate) {
    if (!environment.production) {
      console.log('updateData', updateData);
    }
    this.updateComponentData.next(updateData);
  }

  getUpdateData(): Observable<any> {
    return this.updateComponentData.asObservable();
  }
}
