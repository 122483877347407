import { FilterItem } from '@paperclip/models/search/filter-item';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';

export function getSearchSortOptions({ type, sortId }: SearchFilters): FilterItem[] {
  let sortOptions: FilterItem[];

  if (type === 'items') {
    sortOptions = [
      {
        label: 'most-recent',
        isSelected: sortId === 1,
        apiValue: 1
      },
      {
        label: 'price-high-low',
        isSelected: sortId === 3,
        apiValue: 3
      },
      {
        label: 'price-low-high',
        isSelected: sortId === 4,
        apiValue: 4
      }
      // {
      //   label: 'relevant',
      //   isSelected: sortId === 5,
      //   apiValue: 5
      // }
    ];
  } else {
    sortOptions = [];
  }

  return sortOptions;
}
