import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'pc-character-counter',
  templateUrl: './character-counter.component.html',
  styleUrls: ['./character-counter.component.scss']
})
export class CharacterCounterComponent implements OnChanges {
  @Input() currentCount: number;
  @Input() countLimit: number;
  remainingCharacters: number;
  isCountLimitExceeded = false;

  ngOnChanges() {
    this.remainingCharacters = this.countLimit - this.currentCount;
    this.isCountLimitExceeded = this.remainingCharacters > -1 ? false : true;
  }
}
