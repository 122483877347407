<div class="modal following-followers-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ modalData.user.detail.username }}</h1>
  </div>
  <ul class="modal-tabs">
    <li
      *ngFor="let tab of modalTabs"
      class="modal-tabs__tab"
      [ngClass]="{ 'modal-tabs__tab--active': activeModalTab === tab }"
      (click)="switchActiveTab(tab)"
    >
      {{ 'user-profile.tab.' + tab | translate }}
    </li>
  </ul>

  <div class="modal-body">
    <pc-user-list-view
      *ngIf="activeModalTab === 'following'"
      usersToGet="user-following"
      [listData]="{ userProfileId: modalData.user.detail.id }"
      [ownProfile]="modalData.ownProfile"
      [insideTabbedModal]="true"
    >
    </pc-user-list-view>
    <pc-user-list-view
      *ngIf="activeModalTab === 'followers'"
      usersToGet="user-followers"
      [listData]="{ userProfileId: modalData.user.detail.id }"
      [ownProfile]="modalData.ownProfile"
      [insideTabbedModal]="true"
    >
    </pc-user-list-view>
  </div>
</div>
