import { AppConfig } from '@paperclip/core/app.config';
import { Category, CategoryTree } from '@paperclip/models/misc/category-tree';

export function getCategoryFromOldCategoryId(categoryId: number): Category {
  const category: Category = {
    id: categoryId,
    name: ''
  };

  if (categoryId === 0) {
    category.name = 'Electronics';
  } else if (categoryId === 1) {
    category.name = 'Sports & Outdoors';
  } else if (categoryId === 2) {
    category.name = 'Entertainment';
  } else if (categoryId === 3) {
    category.name = 'Home & Garden';
  } else if (categoryId === 4) {
    // remove category id so search goes to no category
    // as we can't match this to a new parent category
    category.id = null;
    category.name = 'Fashion & Beauty';
  } else if (categoryId === 5) {
    category.name = 'Cars & Motors';
  } else if (categoryId === 6) {
    category.name = 'Baby & Child';
  } else if (categoryId === 7 || categoryId === 8) {
    category.name = 'Other';
  } else {
    category.name = 'No category specified';
  }

  // match new category Id to old category id for similar items view all etc
  AppConfig.categories.forEach((parentCategory) => {
    if (category.name === parentCategory.name) {
      category.id = parentCategory.id;
    }
  });

  return category;
}
