<div
  class="checkbox"
  [ngClass]="{ 'checkbox--checked': option.isSelected }"
  (click)="buttonClicked.emit(option.isPublic)"
>
  <input type="checkbox" id="option.type" />
  <label [for]="option.type"></label>
  <span
    class="checkbox__checked"
    [inlineSVG]="'/assets/icons/misc/checkbox-checked.svg'"
    [forceEvalStyles]="true"
  ></span>
</div>
