import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from '@paperclip/core/app.config';
import { FilterItem } from '@paperclip/models/search/filter-item';

@Component({
  selector: 'pc-item-details-item-condition',
  templateUrl: './item-details-item-condition.component.html'
})
export class ItemDetailsItemConditionComponent implements OnInit {
  @Input() addItemForm: UntypedFormGroup;
  @Input() blockList: boolean;
  @Output() setFocusedSection: EventEmitter<string> = new EventEmitter<string>();
  itemConditions: FilterItem[] = [];
  selectedCondition: FilterItem;

  ngOnInit() {
    this.itemConditions = [];
    AppConfig.app['lists'].conditionType.forEach((condition: any) => {
      const itemCondition: FilterItem = {
        apiValue: condition.id,
        label: condition.name,
        isSelected: false
      };
      this.itemConditions.push(itemCondition);
    });

    // mark selected condition if it is set on the form
    const existingCondition: FilterItem = this.addItemForm.get('itemDetails').get('itemCondition').value;
    if (existingCondition) {
      this.selectedCondition = this.itemConditions.filter(
        (itemCondition: FilterItem) => itemCondition.apiValue === existingCondition.apiValue
      )[0];
      this.selectedCondition.isSelected = true;
    }
  }

  selectCondition(itemCondition: FilterItem) {
    this.setFocusedSection.emit('item-condition');
    this.itemConditions.forEach((condition: FilterItem) => {
      if (itemCondition.label === condition.label) {
        condition.isSelected = !condition.isSelected;
        this.selectedCondition = condition.isSelected ? condition : null;
      } else {
        condition.isSelected = false;
      }
    });
    this.addItemForm
      .get('itemDetails')
      .patchValue({ itemCondition: this.selectedCondition ? this.selectedCondition : null });
  }
}
