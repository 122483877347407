import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@paperclip/core/app.config';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';

@Injectable()
export class PostHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: check if we need to set content type for any api's
    const jsonReq: HttpRequest<any> = req.clone({
      // setHeaders: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

    return next.handle(jsonReq);
  }
}
