import { EditImageModalComponent } from './edit-image-modal/edit-image-modal.component';
import { ForceEmailModalComponent } from './force-email-modal/force-email-modal.component';
import { IeWarningModalComponent } from './ie-warning-modal/ie-warning-modal.component';
import { ImageViewerModalComponent } from './image-viewer-modal/image-viewer-modal.component';

export * from './edit-image-modal/edit-image-modal.component';
export * from './force-email-modal/force-email-modal.component';
export * from './ie-warning-modal/ie-warning-modal.component';
export * from './image-viewer-modal/image-viewer-modal.component';

export const miscModals = [
  ForceEmailModalComponent,
  IeWarningModalComponent,
  ImageViewerModalComponent,
  EditImageModalComponent
];
