import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ItemCategory } from '@paperclip/models/item/ItemCategory';

@Component({
  selector: 'pc-item-category-breadcrumb',
  templateUrl: './item-category-breadcrumb.component.html',
  styleUrls: ['./item-category-breadcrumb.component.scss']
})
export class ItemCategoryBreadcrumbComponent implements OnChanges {
  @Input() showLoading: boolean;
  @Input() categoryBreadcrumb: ItemCategory[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showLoading && changes.showLoading.currentValue === true) {
      this.categoryBreadcrumb = [{ name: '' }, { name: '' }, { name: '' }];
    }
  }
}
