import { Injectable } from "@angular/core";
import { CardInfoObject, MangopayVault, PreregistrationData, VaultSdk } from "@mangopay/vault-sdk";
import { AppConfig } from "@paperclip/core/app.config";
import { environment } from "@paperclip/environments/environment";
import { CardRegistrationResponse } from "@paperclip/models/payments/card-registration";
import { MangoPaymentCard } from "@paperclip/models/payments/payment-card";
import { AppComponent } from "src/app/app.component";

@Injectable({
    providedIn: 'root'
  })
export class MangoPayService {
    private vault?: VaultSdk;
    constructor(){
        this.initializeVault()        
    }
    initializeVault() {        
        this.vault = MangopayVault.initialize({
            clientId: AppConfig.mangoPay.clientId,
            environment: AppConfig.mangoPay.environment,
        });
    }

    async tokenizeCard(cardInfo: MangoPaymentCard, cardRegistration: CardRegistrationResponse) {
        try {
            const cardInfoObject : CardInfoObject = {
                cardHolderName: cardInfo.nameOnCard,
                cardNumber: cardInfo.cardNumber,
                cardExpirationDate: cardInfo.cardExpirationDate,
                cardCvx: cardInfo.cardCvx
            }
            const preregistrationData : PreregistrationData = {
                id: cardRegistration.mpCardRegistrationId,
                cardRegistrationURL: cardRegistration.cardRegistrationUrl,
                accessKeyRef: cardRegistration.accessKey,
                data: cardRegistration.preRegistrationData                
            } 
            const result = await this.vault?.tokenizePaymentMethod(cardInfoObject, preregistrationData)
            return new MpTokenizationResult(result.CardId);
        } catch(error) {
            console.error('Tokenization failed:', error)
            throw error
        }
    }
}
export class MpTokenizationResult {
    mpCardId?: string    
    constructor(mpCardId: string) {
        this.mpCardId = mpCardId;
    }
}