import { Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@paperclip/core/app.config';
import { AppStateService } from '@paperclip/core/app-state.service';
import { IntersectionStatus } from '@paperclip/directives/from-intersection-observer';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Notification } from '@paperclip/models/Notification';
import { UserNotificationsService } from '@paperclip/page/user-notifications/user-notifications.service';

@Component({
  selector: 'pc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnChanges {
  @Input() notificationsLoading: boolean;
  @Input() notification: Notification;
  @Input() windowSize: string;
  @Input() insideMenu: boolean;
  @Input() allMarkedAsSeen: boolean;
  notificationIsSeen: boolean;
  userCurrency = AppConfig.web.currency.symbol || '£';
  visibilityStatus: IntersectionStatus;
  intersectionStatus = IntersectionStatus;
  externalLinks = AppConfig.externalLinks;

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A' && !this.notification.detail.type.includes('ExternalLinkNotification')) {
      this.router.navigate([event.target.getAttribute('href')]);
      event.preventDefault();
    }
  }

  constructor(
    private router: Router,
    private userNotificationsService: UserNotificationsService,
    private appStateService: AppStateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.notificationsLoading && changes.allMarkedAsSeen?.currentValue) {
      this.notificationIsSeen = true;
      this.notification.detail.isRead = true;
    }
  }

  private markNotificationAsSeen() {
    if (!this.notification.detail.isRead) {
      this.userNotificationsService
        .markNotificationAsSeen(this.notification.detail.id)
        .subscribe(({ code }: PcApiResponse) => {
          if (code === 1) {
            this.notificationIsSeen = true;
            this.notification.detail.isRead = true;
            this.appStateService.updateNotificationsState('notifications', -1);
          }
        });
    }
  }

  onVisibilityChanged(status: IntersectionStatus) {
    this.visibilityStatus = status;
    if (!this.notificationsLoading && this.visibilityStatus === 'Visible' && !this.notification.detail.isRead) {
      this.markNotificationAsSeen();
    }
  }
}
