/* eslint-disable max-len */
import { AccountSuspendedModalComponent } from './account-suspended-modal/account-suspended-modal.component';
import { BlockUserModalComponent } from './block-user-modal/block-user-modal.component';
import { DeleteAccountModalComponent } from './delete-account-modal/delete-account-modal.component';
import { DeleteAllNotificationsMessagesModalComponent } from './delete-all-notifications-messages-modal/delete-all-notifications-messages-modal.component';
import { FollowingFollowersModalComponent } from './following-followers-modal/following-followers-modal.component';
import { InviteFriendsModalComponent } from './invite-friends/invite-friends-modal.component';
import { RemoveStudentRoostModalComponent } from './remove-student-roost-modal/remove-student-roost-modal.component';
import { SignOutModalComponent } from './sign-out-modal/sign-out-modal.component';
import { SocialInventoryPrivacyModalComponent } from './social-inventory-privacy-modal/social-inventory-privacy-modal.component';
import { UserReviewsModalComponent } from './user-reviews-modal/user-reviews-modal.component';
import { VerifiedBadgeModalComponent } from './verified-badge-modal/verified-badge-modal.component';
import { VerifyPhoneNumberModalNewComponent } from './verify-phone-number-modal/verify-phone-number-modal.component';
import { VerifyUserInfoModalComponent } from './verify-user-info-modal/verify-user-info-modal.component';

export * from './account-suspended-modal/account-suspended-modal.component';
export * from './block-user-modal/block-user-modal.component';
export * from './delete-account-modal/delete-account-modal.component';
export * from './delete-all-notifications-messages-modal/delete-all-notifications-messages-modal.component';
export * from './following-followers-modal/following-followers-modal.component';
export * from './invite-friends/invite-friends-modal.component';
export * from './remove-student-roost-modal/remove-student-roost-modal.component';
export * from './sign-out-modal/sign-out-modal.component';
export * from './social-inventory-privacy-modal/social-inventory-privacy-modal.component';
export * from './user-reviews-modal/user-reviews-modal.component';
export * from './verify-phone-number-modal/verify-phone-number-modal.component';
export * from './verify-user-info-modal/verify-user-info-modal.component';
export * from './verify-user-info-modal/verify-user-info-modal.component';

export const userModals = [
  BlockUserModalComponent,
  DeleteAccountModalComponent,
  InviteFriendsModalComponent,
  VerifyPhoneNumberModalNewComponent,
  DeleteAllNotificationsMessagesModalComponent,
  SocialInventoryPrivacyModalComponent,
  FollowingFollowersModalComponent,
  UserReviewsModalComponent,
  VerifyUserInfoModalComponent,
  RemoveStudentRoostModalComponent,
  SignOutModalComponent,
  AccountSuspendedModalComponent,
  VerifiedBadgeModalComponent
];
