import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfig } from '@paperclip/core/app.config';
import { DisableFilterAction } from '@paperclip/models/search/DisableFilterAction';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { createNumberMask } from 'text-mask-addons';

@Component({
  selector: 'pc-search-item-price-filter',
  templateUrl: './search-item-price-filter.component.html',
  styleUrls: ['./search-item-price-filter.component.scss']
})
export class SearchItemPriceFilterComponent implements OnChanges, OnDestroy {
  @Input() searchFilters: SearchFilters;
  @Input() applyFilter: boolean;
  @Input() clearFilter: boolean;
  @Input() insideFiltersModal = false;
  @Output() disableFilterButton: EventEmitter<DisableFilterAction> = new EventEmitter<DisableFilterAction>();
  priceForm: UntypedFormGroup = this.formBuilder.group({
    priceMin: null,
    priceMax: null
  });
  showClearPriceMinBtn: boolean;
  showClearPriceMaxBtn: boolean;
  userCurrency = AppConfig.web.currency.symbol || '£';
  currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    integerLimit: 9
  });
  priceFormValueChangesSubscription: Subscription;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder) {
    this.priceFormValueChangesSubscription = this.priceForm.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(100))
      .subscribe(({ priceMin, priceMax }) => {
        if (!!priceMin || !!priceMax) {
          this.disableFilterButton.emit({ button: 'clear', disable: false });
          this.disableFilterButton.emit({ button: 'apply', disable: false });
        }

        if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
          this.setFilterOnAllComponent(priceMin, priceMax);
        }

        this.showClearPriceMinBtn = !!priceMin;
        this.showClearPriceMaxBtn = !!priceMax;
      });
  }

  ngOnChanges(searchFiltersChanges: SimpleChanges): void {
    if (searchFiltersChanges) {
      if (searchFiltersChanges.searchFilters) {
        this.patchPriceForm();
      }

      if (searchFiltersChanges.applyFilter?.currentValue) {
        this.applyItemPriceFilter();
      }

      if (searchFiltersChanges.clearFilter?.currentValue) {
        this.clearItemPriceFilter();
      }
    }
  }

  ngOnDestroy(): void {
    this.priceFormValueChangesSubscription.unsubscribe();
  }

  private patchPriceForm() {
    this.priceForm.patchValue({
      priceMin: this.searchFilters.priceMin,
      priceMax: this.searchFilters.priceMax
    });
  }

  public clearFormControl(formControl: string) {
    switch (formControl) {
      case 'priceMin':
        this.priceForm.patchValue(
          { priceMin: null }
          // { emitEvent: false }
        );
        this.showClearPriceMinBtn = false;
        break;

      case 'priceMax':
      default:
        this.priceForm.patchValue(
          { priceMax: null }
          // { emitEvent: false }
        );
        this.showClearPriceMaxBtn = false;
        break;
    }
  }

  public enterKeydown(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  private applyItemPriceFilter() {
    this.router.navigate(['/search'], {
      queryParams: {
        priceMin: this.priceForm.get('priceMin').value?.replace(',', '') || null,
        priceMax: this.priceForm.get('priceMax').value?.replace(',', '') || null
      },
      queryParamsHandling: 'merge'
    });
  }

  private clearItemPriceFilter() {
    this.router.navigate(['/search'], {
      queryParams: { priceMin: null, priceMax: null },
      queryParamsHandling: 'merge'
    });
  }

  private setFilterOnAllComponent(priceMin: number, priceMax: number) {
    this.searchFilters.priceMin = priceMin || null;
    this.searchFilters.priceMax = priceMax || null;
  }
}
