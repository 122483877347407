import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfig } from '@paperclip/core/app.config';
import { Item } from '@paperclip/models/item';

@Component({
  selector: 'pc-small-item',
  templateUrl: './small-item.component.html'
})
export class SmallItemComponent implements OnChanges {
  @Input() item: Item | any;
  @Input() link = true;
  @Input() size: 'default' | 'large' = 'default';
  @Input() metaDetail: 'price' | 'location' = 'location';
  @Input() showTag = true;
  @Input() menu: { name: string; data: any };
  @Input() showArrow = false;
  thumbnailSize = 40;
  itemLoading: boolean;
  userCurrency = AppConfig.web.currency.symbol;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.thumbnailSize = this.size === 'default' ? 40 : 92;
      this.itemLoading = changes.item && !changes.item.currentValue;
    }
  }
}
