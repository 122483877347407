import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
import { LocationService } from '@paperclip/core/location.service';

@Component({
  selector: 'pc-search-location-input',
  templateUrl: './search-location-input.component.html',
  styleUrls: ['./search-location-input.component.scss']
})
export class SearchLocationInputComponent implements OnInit, OnChanges, DoCheck {
  @Input() locationForm: any;
  @Input() enableRecentLocations = true;
  @Output() emitLocationInputFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('locationInputRef', { static: true }) locationInputRef: ElementRef;
  locationFormDiffer: KeyValueDiffer<any, any>;
  gettingCurrentLocation: boolean;
  locationInputHasFocus: boolean;
  locationSuggestionsLoading: boolean;
  showRecentLocations: boolean;
  showLocationSuggestions: boolean;
  recentLocations: string[] = [];
  locationSuggestions: any[] = [];

  constructor(
    private differs: KeyValueDiffers,
    private authService: AuthService,
    private locationService: LocationService,
    private coreService: CoreService
  ) {
    this.locationFormDiffer = this.differs.find({}).create();
  }

  ngOnInit() {
    const appData = this.authService.getAppData();
    if (appData?.recentLocations) {
      this.recentLocations = appData.recentLocations;
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges) {
      // console.log(`🚀 ~ simpleChanges`, simpleChanges);
    }
  }

  ngDoCheck(): void {
    const locationFormChanges = this.locationFormDiffer.diff(this.locationForm);
    if (locationFormChanges) {
      this.manageLocationFormChanges();
    }
  }

  private manageLocationFormChanges() {
    if (this.locationForm.get('name').value) {
      this.showLocationSuggestions = true;
      this.locationSuggestionsLoading = true;
      this.showRecentLocations = false;
      this.getLocationSuggestions(this.locationForm.get('name').value);
    } else {
      this.showLocationSuggestions = false;
      this.locationSuggestionsLoading = false;
      this.locationSuggestions = [];
      this.showRecentLocations = true;
    }
  }

  public selectCurrentLocation() {
    this.locationForm.get('useCurrentLocation').patchValue(!this.locationForm.get('useCurrentLocation').value);

    if (!this.locationForm.get('useCurrentLocation').value) {
      this.gettingCurrentLocation = false;
      this.locationForm.get('useCurrentLocation').patchValue(false);
      this.locationForm.patchValue({
        useCurrentLocation: false,
        name: null,
        latitude: null,
        longitude: null
      });
    } else {
      this.gettingCurrentLocation = true;
      this.locationForm.patchValue({ name: '' });
      this.locationService.getCurrentLocation().subscribe((locationCoordinates: any) => {
        if (typeof locationCoordinates === 'string' && locationCoordinates.includes('denied location')) {
          this.currentLocationDenied();
        } else {
          this.currentLocationAllowed(locationCoordinates);
        }
      });
    }
  }

  private currentLocationAllowed(locationCoordinates: { latitude: number; longitude: number }) {
    this.locationService
      .getLocation(locationCoordinates.latitude, locationCoordinates.longitude)
      .subscribe((locationName) => {
        this.gettingCurrentLocation = false;
        this.locationForm.patchValue({
          useCurrentLocation: true,
          name: locationName.formattedAddress,
          latitude: locationCoordinates.latitude,
          longitude: locationCoordinates.longitude
        });

        const currentLocation = {
          name: locationName.formattedAddress,
          latitude: locationCoordinates.latitude,
          longitude: locationCoordinates.longitude
        };
        this.authService.updateAppData({ currentLocation: currentLocation });
        this.addLocationToRecents(locationName.formattedAddress);
      });
  }

  private currentLocationDenied() {
    this.gettingCurrentLocation = false;
    this.locationForm.patchValue({
      useCurrentLocation: false,
      name: null,
      latitude: null,
      longitude: null
    });

    this.coreService.handleError({ customCode: 995 });
  }

  public locationInputFocus() {
    const locationName = this.locationForm.get('locationName')?.value || null;
    this.locationInputHasFocus = locationName || this.recentLocations.length > 0 || this.locationSuggestions.length > 0;
    this.emitLocationInputFocus.emit(this.locationInputHasFocus);

    // show suggestions if input isn't empty
    if (locationName) {
      this.showLocationSuggestions = true;
      this.showRecentLocations = false;
      this.getLocationSuggestions(locationName);
    }
  }

  public locationInputBlur() {
    this.locationInputHasFocus = false;
    this.emitLocationInputFocus.emit(this.locationInputHasFocus);
  }

  public locationInputKeydown(keyEvent: KeyboardEvent) {
    this.locationInputHasFocus = true;
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  }

  public clearInput() {
    this.locationForm.get('useCurrentLocation').patchValue(false);
    this.showLocationSuggestions = false;
    this.locationSuggestions = [];
    this.locationSuggestionsLoading = false;
    this.gettingCurrentLocation = false;
    this.locationInputHasFocus = false;
    this.emitLocationInputFocus.emit(this.locationInputHasFocus);

    this.locationForm.patchValue({
      useCurrentLocation: false,
      name: null,
      latitude: null,
      longitude: null
    });
  }

  private getLocationSuggestions(locationName: string) {
    this.locationService.getLocationSuggestions(locationName).subscribe((locations) => {
      // this.ngZone.run(() => {
      this.locationSuggestionsLoading = false;
      this.locationSuggestions = locations;
      // });
    });
  }

  public selectLocation(event: any, location: string) {
    // prevent blur from firing because otherwise this will all happen twice
    event.preventDefault();
    this.locationInputRef.nativeElement.blur();

    this.locationForm.patchValue({ name: location });
    this.searchLocation(location);
  }

  private searchLocation(locationName: string) {
    this.locationInputHasFocus = false;
    this.emitLocationInputFocus.emit(this.locationInputHasFocus);
    this.showLocationSuggestions = false;
    this.showRecentLocations = false;

    // add location to recent locations if not already in the array
    this.addLocationToRecents(locationName);
    if (!locationName.includes('no suggestions')) {
      this.locationService.getLatLng(locationName).subscribe((locationCoordinates: { lat: number; lng: number }) => {
        this.locationForm.patchValue({
          useCurrentLocation: false,
          latitude: locationCoordinates.lat,
          longitude: locationCoordinates.lng
        });
      });
    }
  }

  private addLocationToRecents(location: string) {
    if (location) {
      if (this.recentLocations.length === 0) {
        this.recentLocations = [location];
      } else {
        const duplicateLocation = this.recentLocations.includes(location);
        if (!duplicateLocation && location !== '' && location.trim().length > 0) {
          this.recentLocations = [location, ...this.recentLocations];
        }
      }

      this.recentLocations = this.recentLocations.slice(0, 3);
      this.authService.updateAppData({ recentLocations: this.recentLocations });
    }
  }
}
