import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef
} from '@angular/core';

import { CustomTooltipComponent } from './custom-tooltip.component';

@Directive({
  selector: '[pcCustomTooltip]'
})
export class CustomTooltipDirective implements OnInit, OnDestroy {
  @Input() showToolTip = true;
  @Input() contentTemplate: TemplateRef<any>;
  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    if (!this.showToolTip) {
      return;
    }

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'top',
          offsetY: 15
        }
        // {
        //   originX: 'center',
        //   originY: 'top',
        //   overlayX: 'center',
        //   overlayY: 'top',
        //   offsetY: 15
        // }
      ])
      .withViewportMargin(15);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show() {
    // attach the component if it has not already attached to the overlay
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<CustomTooltipComponent> = this.overlayRef.attach(
        new ComponentPortal(CustomTooltipComponent)
      );
      tooltipRef.instance.contentTemplate = this.contentTemplate;
    }
  }

  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
