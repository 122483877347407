<!-- not inside menu -->
<ng-container *ngIf="!insideMenu">
  <!-- header -->
  <div
    class="basic-header notifications-header"
    [ngClass]="{ 'show-notification-count': appState?.notifications?.unseenNotifications > 0 }"
  >
    <div class="grid-container">
      <div class="basic-header__left">
        <h1>{{ 'user-notifications.user-notifications-title' | translate }}</h1>
        <pc-notification-dot
          *ngIf="appState?.notifications?.unseenNotifications > 0"
          placement="inline-right"
          [notificationCount]="appState?.notifications?.unseenNotifications"
          [size]="24"
        >
        </pc-notification-dot>
      </div>
      <pc-menu
        *ngIf="userNotifications?.all.length > 0"
        menu="clear-delete-all-menu"
        [iconMenu]="false"
        menuButtonClass="btn-stroked btn-medium--edit"
        [menuData]="{ contentType: 'notifications', dataCount: appState?.notifications?.unseenNotifications }"
        >{{ 'buttons.edit' | translate }}</pc-menu
      >
    </div>
  </div>

  <!-- notifications -->
  <div class="grid-container grid-container--thin">
    <section
      class="grid-row--no-margin grid-row--no-padding desktop-notifications"
      infiniteScroll
      [infiniteScrollDistance]="300"
      [infiniteScrollThrottle]="300"
      (scrolled)="getUserNotifications(true)"
    >
      <ng-container *ngIf="!userNotificationsLoading && userNotifications?.all.length > 0">
        <pc-notification
          *ngFor="let notification of userNotifications?.all"
          [notificationsLoading]="userNotificationsLoading"
          [notification]="notification"
          [windowSize]="windowSize"
          [allMarkedAsSeen]="allMarkedAsSeen"
        >
        </pc-notification>
        <div *ngIf="userNotificationsLoadingMore" class="loading-more-spinner"></div>
      </ng-container>

      <!-- initial loading -->
      <ng-container *ngIf="userNotificationsLoading">
        <pc-notification *ngFor="let notification of [0, 1, 2]" [notificationsLoading]="userNotificationsLoading">
        </pc-notification>
      </ng-container>

      <!-- no notifications -->
      <pc-no-data-view
        *ngIf="!userNotificationsLoading && !userNotificationsLoadingMore && userNotifications?.all.length === 0"
        [ngStyle]="{ width: '100%' }"
        pageToShow="notifications"
        [isOwnProfile]="true"
      >
      </pc-no-data-view>
    </section>
  </div>
</ng-container>

<!-- inside menu -->
<ng-container *ngIf="insideMenu">
  <!-- loading inside of menu -->
  <ng-container *ngIf="userNotificationsLoading">
    <pc-notification
      *ngFor="let notification of [0, 1, 2]"
      [notificationsLoading]="userNotificationsLoading"
      [insideMenu]="true"
    >
    </pc-notification>
  </ng-container>

  <!-- inside menu loaded -->
  <ng-container *ngIf="!userNotificationsLoading">
    <!-- todays -->
    <ng-container *ngIf="userNotifications?.today.length > 0">
      <h3 class="notification-group-title">{{ 'user-notifications.todays-notifications' | translate }}</h3>
      <pc-notification
        *ngFor="let notification of userNotifications?.today"
        [notification]="notification"
        [windowSize]="windowSize"
        [insideMenu]="true"
      ></pc-notification>
    </ng-container>

    <!-- previous -->
    <ng-container *ngIf="userNotifications?.previous.length > 0">
      <h3
        class="notification-group-title"
        [ngClass]="{ 'notification-group-title--border': userNotifications?.today.length > 0 }"
      >
        {{ 'user-notifications.previous-notifications' | translate }}
      </h3>
      <pc-notification
        *ngFor="let notification of userNotifications?.previous"
        [notification]="notification"
        [windowSize]="windowSize"
        [insideMenu]="true"
      ></pc-notification>
    </ng-container>
  </ng-container>

  <!-- no notifications -->
  <pc-no-data-view
    *ngIf="!userNotificationsLoading && !userNotificationsLoadingMore && userNotifications?.all.length === 0"
    [ngStyle]="{ width: '100%' }"
    [isInDropdown]="true"
    pageToShow="notifications"
    [isOwnProfile]="true"
  >
  </pc-no-data-view>
</ng-container>
