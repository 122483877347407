<pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
<div class="modal redeem-paperclip-credit-modal">
  <div class="modal-header">
    <h1>{{ 'modals.redeem-paperclip-credit.title-' + modalContent.title | translate }}</h1>
  </div>

  <div class="modal-body">
    <p>{{ 'modals.redeem-paperclip-credit.body-' + modalContent.body | translate }}</p>
  </div>
</div>

<div class="modal-footer">
  <button class="btn-large btn-large--fit-content btn-primary" (click)="dialogRef.close()">
    {{ 'buttons.okay' | translate }}
  </button>
</div>
