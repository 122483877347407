<!--
  initial loading state
-->
<div *ngIf="dataLoading" class="item item--loading item--deal">
  <!-- item image & favourite item -->
  <div class="item__image">
    <img class="bg-image" src="assets/loading-img.png" />
  </div>

  <!-- item info -->
  <div class="item__info">
    <!-- item meta: category & price -->
    <div class="item-meta">
      <p class="item-meta__left"></p>
    </div>

    <!-- item name -->
    <a class="item-name"></a>
  </div>
</div>

<!--
  loaded
-->
<a *ngIf="!dataLoading" class="item item--deal" [ngClass]="{ 'item--loading': !gridItem.imgLoaded }"
  [routerLink]="['/deal', gridItem.id]">
  <!-- item image -->
  <div class="item__image">
    <img class="bg-image" [src]="gridItem.imageUrl" (load)="imgLoaded(gridItem.id)" />
  </div>

  <!-- deal info -->
  <div class="item__info">
    <!-- deal meta: member count & privacy status -->
    <div class="item-meta">
      <p class="item-meta__left faded-content">{{ 'deals.expiration' | translate }} {{ gridItem.endDate }}</p>
    </div>

    <!-- item name -->
    <p class="item-name faded-content">{{ gridItem.name }}</p>
  </div>
</a>
