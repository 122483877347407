<pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
<div class="modal pending-transactions-modal">
  <div class="modal-header">
    <h1>
      {{
        modalData.pendingTransactions === 1
          ? ('modals.pending-transactions.title' | translate)
          : ('modals.pending-transactions.title-plural'
            | translate: { pendingTransactions: modalData.pendingTransactions })
      }}
    </h1>
  </div>

  <div class="modal-body">
    <pc-wallet-transactions-list [windowSize]="'desktop'" [pendingTransactionsModal]="true">
    </pc-wallet-transactions-list>
  </div>

  <div class="modal-footer">
    <button class="btn-large btn-large--fit-content btn-primary" (click)="dialogRef.close()">
      {{ 'buttons.okay' | translate }}
    </button>
  </div>
</div>
