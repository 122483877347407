import { Component, Input } from '@angular/core';
// import { ItemTag } from '@paperclip/models/item/item-tag';

@Component({
  selector: 'pc-tags-component',
  templateUrl: './tags-component.component.html',
  styleUrls: ['./tags-component.component.scss']
})
export class TagsComponent {
  // @Input() tags: ItemTag[];
}
