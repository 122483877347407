<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="item-images-modal">
    <div class="item-images-modal__images">
      <ngx-image-swiper [config]="ngxSwiperConfig" [images]="itemOverviewConfig.item.detail.images"></ngx-image-swiper>
    </div>

    <div class="item-images-modal__content">
      <div class="item-title">
        <h2>{{ itemOverviewConfig.item.detail.name }}</h2>
        <div class="item-title__meta">
          <p class="p-large {{ itemOverviewConfig.item?.detail.priceClass }}">
            <span>{{ userCurrency }}</span
            >{{ itemOverviewConfig.item?.detail.price | translate }}
          </p>
          <!-- delivery cost/info -->
          <p class="p-large highlight-text item-title__meta__delivery">
            {{ 'item-overview.+' | translate }}

            <!-- postage cost -->
            <span *ngIf="itemOverviewConfig.item?.detail.deliveryInformation.postageAvailable">
              <span *ngIf="itemOverviewConfig.item?.detail.deliveryInformation.postageCost" class="colour-secondary">
                {{ userCurrency
                }}{{ itemOverviewConfig.item?.detail.deliveryInformation.postageCost | number: '1.2-2' }}
              </span>
              <span *ngIf="!itemOverviewConfig.item?.detail.deliveryInformation.postageCost" class="colour-primary">
                {{ 'item-overview.delivery.free-delivery' | translate }}
              </span>
              {{ 'item-overview.delivery.delivery' | translate }}
            </span>

            <!-- or if both -->

            <span
              *ngIf="
                itemOverviewConfig.item?.detail.deliveryInformation.postageAvailable &&
                itemOverviewConfig.item?.detail.deliveryInformation.meetInPerson
              "
              >{{ 'item-overview.delivery.or' | translate }} {{ 'item-overview.delivery.collection' | translate }}</span
            >

            <!-- collection -->
            <span
              *ngIf="
                !itemOverviewConfig.item?.detail.deliveryInformation.postageAvailable &&
                itemOverviewConfig.item?.detail.deliveryInformation.meetInPerson
              "
            >
              {{ 'item-overview.delivery.collection-only' | translate }}
            </span>
          </p>
        </div>
      </div>

      <!-- item info: about -->
      <section class="grid-row grid-row--no-margin grid-row--tablet-extra item-about">
        <h3>{{ 'item-overview.about' | translate }}</h3>

        <!-- info table -->
        <table class="table-data">
          <tr *ngFor="let itemInfo of itemOverviewConfig.item?.detail.detailTable">
            <td>
              <img [src]="itemInfo.icon" alt="" />
              <p>{{ itemInfo.key | translate }}</p>
            </td>
            <td>
              <p class="highlight-text">{{ itemInfo.count | translate }}</p>
            </td>
          </tr>
        </table>

        <!-- description -->
        <div class="item-about__description">
          <p
            class="description-text"
            [ngClass]="{
              'description-text--truncated': itemOverviewConfig.item?.detail.description?.length > 250,
              'description-text--expanded': itemDescriptionExpanded,
              'faded-content': itemOverviewConfig.item?.detail.description?.length > 250 && !itemDescriptionExpanded
            }"
          >
            {{ itemOverviewConfig.item?.detail.description | translate }}
          </p>
          <button
            *ngIf="itemOverviewConfig.item?.detail.description?.length > 250"
            class="btn-no-styling btn-right-icon description-see-more"
            (click)="expandItemDescription()"
          >
            {{
              itemDescriptionExpanded ? ('item-overview.see-less' | translate) : ('item-overview.see-more' | translate)
            }}
            <img
              class="dropdown-arrow"
              [ngClass]="{ 'dropdown-arrow--rotate': itemDescriptionExpanded }"
              src="/assets/icons/misc/arrow-down-small.svg"
              alt=""
            />
          </button>
        </div>

        <!-- seller information -->
        <section
          *ngIf="itemOverviewConfig.loggedInUserId !== itemOverviewConfig.item?.seller.detail.id"
          class="grid-row grid-row--no-margin grid-row--tablet-extra seller-information"
        >
          <div class="item-info-section-header">
            <h3>{{ 'item-overview.seller-information' | translate }}</h3>
            <a
              *ngIf="itemOverviewConfig.item"
              class="btn btn-secondary"
              [routerLink]="['/messages', itemOverviewConfig.item?.seller.detail.id]"
            >
              {{ 'buttons.message' | translate }}
            </a>
          </div>

          <div *ngIf="itemOverviewConfig.item" class="mini-user-profile">
            <pc-small-user
              [user]="itemOverviewConfig.item?.seller"
              [starRating]="{ show: true, position: 'bottom' }"
              [linkReviews]="true"
            >
            </pc-small-user>
          </div>
        </section>
      </section>

      <div class="item-actions multi-btn-container">
        <pc-item-overview-action-buttons
          *ngIf="itemOverviewConfig.item"
          [itemOverviewConfig]="itemOverviewConfig"
          [layout]="itemOverviewConfig.windowSize === 'desktop' ? 'vertical' : 'horizontal'"
          (editItem)="editItem()"
          (buyItem)="buyItem()"
        >
        </pc-item-overview-action-buttons>
      </div>
    </div>
  </div>
</div>
