<header class="steps-layout-header-container">
  <div class="grid-container steps-layout-header">
    <div
      class="steps-layout-header__logo"
      [ngClass]="{ 'steps-layout-header__logo--paperclip': environmentName === 'paperclip' }"
    >
      <img [src]="environmentLogo" [alt]="environmentFriendlyName" />
    </div>
    <h1>{{ title | translate }}</h1>
    <p *ngIf="showStepCount" class="highlight-text">
      {{ 'step-progress' | translate: { currentStep: stepNumber, totalSteps: totalSteps } }}
    </p>
  </div>
</header>
<pc-close-icon (click)="closeButtonPressed.emit()"></pc-close-icon>
<pc-steps-progress-bar *ngIf="showProgressBar" [stepNumber]="stepNumber" [totalSteps]="totalSteps">
</pc-steps-progress-bar>
