<form [formGroup]="itemCheckout.form">
  <!-- payment card form -->
  <div formGroupName="paymentCard" class="add-payment-card">
    <div class="add-payment-card__security">
      <p class="highlight-text">
        <img src="/assets/icons/payments/lock.svg" alt="" />
        {{ 'item-checkout.payment.add-payment-card.security-desc' | translate }}
      </p>
      <div class="add-payment-card__security__cards">
        <img src="/assets/icons/payments/mastercard.svg" alt="" />
        <img src="/assets/icons/payments/visa.svg" alt="" />
        <img src="/assets/icons/payments/american-express.svg" alt="" />
      </div>
    </div>

    <!-- card number -->
    <div class="form-row">
      <div class="form-group">
        <label for="card-number">{{
          'item-checkout.payment.add-payment-card.card-form.card-number' | translate
        }}</label>
        <input
          id="card-number"
          formControlName="cardNumber"
          placeholder="{{ 'item-checkout.payment.add-payment-card.card-form.card-number-placeholder' | translate }}"
          [textMask]="{ mask: cardNumberMask, guide: false, keepCharPositions: true }"
          required
          autocomplete="cc-number"
        />
      </div>
    </div>

    <!-- name on card -->
    <div class="form-row">
      <div class="form-group">
        <label for="name-on-card">{{
          'item-checkout.payment.add-payment-card.card-form.name-on-card' | translate
        }}</label>
        <input
          id="card-name"
          formControlName="nameOnCard"
          type="text"
          placeholder="{{ 'item-checkout.payment.add-payment-card.card-form.name-on-card-placeholder' | translate }}"
          required
          autocomplete="cc-name"
        />
      </div>
    </div>

    <!-- expiry date & cvc -->
    <div class="form-row form-row--group">
      <div class="form-group form-group--force-inline card-expiration">
        <label for="expiry-date">{{
          'item-checkout.payment.add-payment-card.card-form.expiry-date' | translate
        }}</label>
        <input
          id="expiry-date"
          formControlName="cardExpiry"
          type="text"
          placeholder="{{ 'item-checkout.payment.add-payment-card.card-form.expiry-date-placeholder' | translate }}"
          [textMask]="{ mask: cardExpiryDateMask, guide: false, pipe: autoCorrectedDatePipe }"
          required
          autocomplete="cc-exp"
        />
      </div>

      <div class="form-group form-group--force-inline card-cvc">
        <label for="cvc">{{ 'item-checkout.payment.add-payment-card.card-form.cvc' | translate }}</label>
        <input
          id="cvc"
          formControlName="cardCVC"
          placeholder="{{ 'item-checkout.payment.add-payment-card.card-form.cvc-placeholder' | translate }}"
          [textMask]="{ mask: cardCVCMask, guide: false }"
          required
          autocomplete="cc-csc"
        />
      </div>
    </div>

    <!-- save card -->
    <div *ngIf="page === 'checkout'" class="form-row">
      <div class="form-group form-group--checkbox" (click)="savePaymentCard()">
        <pc-checkmark-icon [checked]="itemCheckout.form.get('paymentCard').get('storeCard').value" [size]="24">
        </pc-checkmark-icon>
        <label for="save-card">{{ 'item-checkout.payment.add-payment-card.card-form.save-card' | translate }}</label>
      </div>
    </div>

    <!-- isDefault -->
    <div *ngIf="showSetAsDefaultOption()" class="form-row">
      <div class="form-group form-group--checkbox" (click)="setAsDefaultCard()">
        <pc-checkmark-icon [checked]="itemCheckout.form.get('paymentCard').get('isDefault').value" [size]="24">
        </pc-checkmark-icon>
        <label for="isDefault">{{
          'item-checkout.payment.add-payment-card.card-form.set-default-card' | translate
        }}</label>
      </div>
    </div>
  </div>

  <div class="separator"></div>

  <!-- billing -->
  <pc-payment-card-billing-address [page]="page" [itemCheckout]="itemCheckout"> </pc-payment-card-billing-address>
</form>
