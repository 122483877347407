import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-ie-warning-modal',
  templateUrl: './ie-warning-modal.component.html',
  styleUrls: ['./ie-warning-modal.component.scss']
})
export class IeWarningModalComponent {
  constructor(public dialogRef: MatDialogRef<IeWarningModalComponent>) {}
}
