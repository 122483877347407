import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { MessagesService } from '@paperclip/page/messages.service';

@Component({
  selector: 'pc-delete-conversation-modal',
  templateUrl: './delete-conversation-modal.component.html'
})
export class DeleteConversationModalComponent {
  activeRequest: boolean;

  constructor(
    public dialogRef: MatDialogRef<DeleteConversationModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { messageId: string; complete: () => {} },
    private messagesService: MessagesService,
    private coreService: CoreService
  ) {}

  deleteConversation() {
    this.activeRequest = true;
    this.messagesService.deleteConversation(this.modalData.messageId).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.dialogRef.close();
          this.modalData.complete();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
