import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { AppData } from '@paperclip/models/AppData';
import { Observable, Subject } from 'rxjs';
/* eslint-disable brace-style */

@Injectable({
  providedIn: 'root'
})
export class GridViewService {
  appData: AppData = this.authService.getAppData();
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';
  userIdParam = '';
  locationParams = '';
  constructor(private http: HttpClient, private authService: AuthService) {
    this.userIdParam = `&userId=${this.authedUserId}`;
    // check if we have users current location
    if (this.appData) {
      if (this.appData.currentLocation) {
        this.locationParams = `&latitude=${this.appData.currentLocation.latitude}&longitude=${this.appData.currentLocation.longitude}`;
      }
    }
  }

  private forceUpload = new Subject<void>();

  sendForceUploadRequest() {
    this.forceUpload.next();
  }

  needsForceUploadRequestSent(): Observable<void> {
    return this.forceUpload.asObservable();
  }

  /*====================
  dynamic home page
  ====================*/
  getDynamicHomePageSection(query: string, queryParams: any, limit: number, skip = 0): Observable<any> {
    const updatedQuery = query.substring(1);
    const queryString = Object.keys(queryParams)
      .map((key) => key + '=' + queryParams[key])
      .join('&');
    return this.http.get(`${updatedQuery}?${queryString}&skip=${skip}&limit=${limit}`);
  }

  /*====================
  items
  ====================*/
  getFeaturedItems(skip = 0, limit = 4): Observable<any> {
    return this.http.get(`feed/items/featured?skip=${skip}&limit=${limit}${this.locationParams}`);
  }

  getSimilarItems(itemId: string, skip = 0, limit = 30): Observable<any> {
    return this.http.get(
      `items/similar?userId=${this.authedUserId}&itemId=${itemId}&skip=${skip}&limit=${limit}${this.locationParams}`
    );
  }

  getUserItems(userId: string, skip = 0, limit = 0, showSold = false): Observable<any> {
    return this.http.get(
      `items/getByUserId?userId=${userId}&currentUserId=${this.authedUserId}&skip=${skip}&limit=${limit}&showSold=${showSold}`
    );
  }

  getUserSoldItems(userId: string, skip = 0, limit = 0): Observable<any> {
    return this.http.get(`items/sold?userId=${userId}&userId=${userId}&skip=${skip}&limit=${limit}`);
  }

  getUserFavourites(userId: string, skip = 0, limit = 0): Observable<any> {
    return this.http.get(`favourite?userId=${userId}&currentUserId=${this.authedUserId}&skip=${skip}&limit=${limit}`);
  }

  getCategorisedItems(limit = 4): Observable<any> {
    return this.http.get(`feed/items/categorised?limit=${limit}${this.locationParams}`);
  }

  getUsersFollowers(userId: string, skip = 0): Observable<any> {
    if (userId) {
      this.userIdParam = `&userId=${userId}`;
    }
    return this.http.get(`followers?skip=${skip}${this.userIdParam}&currentUserId=${this.authedUserId}`);
  }

  getUsersFollowing(userId: string, skip = 0): Observable<any> {
    if (userId) {
      this.userIdParam = `&userId=${userId}`;
    }
    return this.http.get(`following?skip=${skip}${this.userIdParam}&currentUserId=${this.authedUserId}`);
  }

  /*====================
  deals
  ====================*/
  getDealsAndDiscounts(skip = 0, limit = 4): Observable<any> {
    return this.http.get(`deals?skip=${skip}&limit=${limit}`);
  }
}
