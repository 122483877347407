import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pc-payment-details-purchase-method',
  templateUrl: './payment-details-purchase-method.component.html',
  styleUrls: ['./payment-details-purchase-method.component.scss']
})
export class PaymentDetailsPurchaseMethodComponent {
  @Input() addItemForm: UntypedFormGroup;
}
