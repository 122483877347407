import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pc-item-details-item-description',
  templateUrl: './item-details-item-description.component.html',
  styleUrls: ['./item-details-item-description.component.scss']
})
export class ItemDetailsItemDescriptionComponent {
  @Input() addItemForm: UntypedFormGroup;
}
