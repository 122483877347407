import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Angulartics2Facebook, Angulartics2GoogleAnalytics } from 'angulartics2';
import * as Bowser from 'bowser';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { interval, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '../environments/environment';
import { AppConfig, loadApiConfig } from './core/app.config';
import { AuthService } from './core/auth/auth.service';
import { CoreService } from './core/core.service';
import { MaintenanceBanner } from './models/misc/MaintenanceBanner';
import { PaperclipEventData } from './models/PaperclipEvent';
import { AuthedUser } from './models/user/AuthedUser';
import { IeWarningModalComponent } from './shared/modals/misc/misc-modals';
import { ModalService } from './shared/modals/modal.service';
import { SwUpdateService } from './sw-update.service';

@Component({
  selector: 'pc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  authedUser: AuthedUser = this.authService.getAuthedUser();
  windowSize: string = window.innerWidth < 1200 ? 'mobile' : 'desktop';
  maintenanceBanner: MaintenanceBanner = { show: false, message: '' };
  paymentTransactionsEnabled: boolean = AppConfig.app.features.payments.enabled;
  paymentTransactionsEnabledIntervalSubscription: Subscription;
  userSuspendedSubscription: Subscription;
  userSuspendedIntervalSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private swUpdateService: SwUpdateService,
    private ccService: NgcCookieConsentService,
    private authService: AuthService,
    private modalService: ModalService,
    private coreService: CoreService,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    angulartics2Facebook: Angulartics2Facebook
  ) {
    angulartics2GoogleAnalytics.startTracking();
    angulartics2Facebook.startTracking();
    // check for service worker updates in background
    this.swUpdateService.checkForUpdates();

    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use('en');

    // track route changes in google analytics
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });

    // store referralCode if it exists for use on user signup
    this.activatedRoute.queryParams.subscribe((params: { referralCode: string }) => {
      if (params.referralCode && !this.authService.isLoggedIn()) {
        localStorage.setItem('referralCode', params.referralCode);
      }
    });

    this.userSuspendedSubscription = this.authService.getUserSuspended().subscribe((suspended: boolean) => {
      if (this.authService.isLoggedIn()) {
        this.authedUser.detail.isSuspended = suspended;
        this.authService.updateAuthedUser(this.authedUser);
        this.showMaintenanceBanner();
        this.startUserSuspendedStatusCheck();
      }
    });
  }

  ngOnInit() {
    this.trackAppOpened();
    this.handleCookieConsent();
    this.initBranch();
    this.setUserToken();
    this.checkForIE();
    setTimeout(() => {
      this.showMaintenanceBanner();
      if (this.authedUser?.detail?.isSuspended) {
        this.startUserSuspendedStatusCheck();
      }
    }, 100);
    this.paymentTransactionsEnabledIntervalSubscription = interval(300000).subscribe(() =>
      this.checkPaymentTransactionsEnabled()
    );
  }

  ngOnDestroy() {
    this.userSuspendedSubscription.unsubscribe();
    if (this.userSuspendedIntervalSubscription) {
      this.userSuspendedIntervalSubscription.unsubscribe();
    }
    if (this.paymentTransactionsEnabledIntervalSubscription) {
      this.paymentTransactionsEnabledIntervalSubscription.unsubscribe();
    }
  }

  private handleCookieConsent() {
    this.translateService //
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link'])
      .subscribe((data) => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.link = data['cookie.link'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

  private trackAppOpened() {
    const device = Bowser.getParser(window.navigator.userAgent).getResult();
    const eventData: PaperclipEventData = {
      platform: 'web',
      browser: device.browser.name,
      os: device.os.name,
      osVersion: device.os.version,
      deviceManufacturer: device.platform.vendor,
      deviceModel: device.platform.model || null,
      deviceType: device.platform.type
    };

    if (eventData.deviceModel === null) {
      delete eventData.deviceModel;
    }

    this.coreService.trackPaperclipEvent({ name: 'APP_OPENED', data: eventData }).subscribe();
  }

  private setUserToken() {
    if (this.authService.isLoggedIn()) {
      // get user token from user profile
      const userToken = this.authedUser.detail.userToken;
      localStorage.setItem('userToken', userToken);
      if (!userToken) {
        this.getUserToken();
      }
    } else {
      // get user token from local storage
      const userToken = localStorage.getItem('userToken');
      if (!userToken || userToken === 'undefined') {
        this.createUuidUserToken();
      }
    }
  }

  private getUserToken() {
    this.coreService.getUserToken().subscribe(({ code, data }) => {
      if (code === 1) {
        this.authedUser.detail.userToken = data;
        this.authService.updateAuthedUser(this.authedUser);
        localStorage.setItem('userToken', data);
      }
    });
  }

  private createUuidUserToken() {
    const userToken = uuidv4().replace(/-/g, '').toUpperCase();
    localStorage.setItem('userToken', userToken);
  }

  private initBranch() {
    const branchKey = environment.production
      ? 'key_live_jf4MYbRmNx62j6kq3ABbTgmaFCdl08qb'
      : 'key_test_joXL5mNeJy63d4ez4uqh9mfpBrnl28zw';
    window['branch'].init(branchKey, {}, (err, data) => {
      // console.log(`🚀 ~ branch.init ~ err`, err);
      // console.log(`🚀 ~ branch.init ~ data`, data);
    });
    // const userToken = this.isLoggedIn ? this.authedUser.detail.id : '';
    // window['branch'].setIdentity(userToken);
    window['branch'].disableTracking();
  }

  private startUserSuspendedStatusCheck() {
    this.userSuspendedIntervalSubscription = interval(60000).subscribe(() => this.checkUserIsSuspended());
  }

  private checkUserIsSuspended() {
    this.authService.checkUserIsSuspended().subscribe(({ code, data, message }) => {
      if (code === 1) {
        const suspendedStatus: 'yes' | 'no' | 'permanently-suspended' = data;
        const isSuspended = suspendedStatus === 'yes' || suspendedStatus === 'permanently-suspended' ? true : false;
        this.authedUser.detail.isSuspended = isSuspended;
        this.authService.updateAuthedUser(this.authedUser);
        this.showMaintenanceBanner();

        if (suspendedStatus === 'no') {
          this.userSuspendedIntervalSubscription.unsubscribe();
          return;
        }

        if (isSuspended && suspendedStatus === 'permanently-suspended') {
          this.userSuspendedIntervalSubscription.unsubscribe();
          this.authService.logout(this.authedUser.detail.id).subscribe();
        }
      } else {
        console.error(message);
      }
    });
  }

  private checkPaymentTransactionsEnabled() {
    loadApiConfig().then(() => {
      this.paymentTransactionsEnabled = AppConfig.app.features.payments.enabled;
      this.coreService.updatePaymentTransactionsEnabled(this.paymentTransactionsEnabled);
      this.showMaintenanceBanner();
    });
  }

  private showMaintenanceBanner() {
    this.maintenanceBanner = { show: false, message: '' };
    const userIsSuspended = this.authService.isLoggedIn() ? this.authedUser.detail.isSuspended : false;
    if (userIsSuspended) {
      this.maintenanceBanner.show = true;
      this.maintenanceBanner.message = 'maintenance-message.account-suspended';
    } else if (!this.paymentTransactionsEnabled) {
      this.maintenanceBanner.show = true;
      this.maintenanceBanner.message = 'maintenance-message.transactions-disabled';
    }

    setTimeout(() => {
      this.coreService.setMaintenanceBanner(this.maintenanceBanner);
    }, 100);
  }

  private checkForIE() {
    const ua = window.navigator.userAgent;
    const is_ie = /MSIE|Trident/.test(ua);

    if (is_ie) {
      setTimeout(() => {
        this.modalService.open(IeWarningModalComponent);
      }, 100);
    }
  }

  externalJwtLogin(jwt: string) {
    this.authService.externalJwtAuth(jwt).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.authService.setAuthedUser(data, '');
        } else {
          console.error(code, message);
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }
}
