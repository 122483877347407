import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentsService } from '@paperclip/components/payments/payments.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';

@Component({
  selector: 'pc-delete-payment-card-modal',
  templateUrl: './delete-payment-card-modal.component.html'
})
export class DeletePaymentCardModalComponent {
  activeRequest: boolean;

  constructor(
    public dialogRef: MatDialogRef<DeletePaymentCardModalComponent>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { cardId: string; complete: () => {} },
    private paymentsService: PaymentsService,
    private coreService: CoreService
  ) {}

  deleteAddress() {
    this.activeRequest = true;
    this.paymentsService.deletePaymentCard(this.modalData.cardId).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.dialogRef.close();
          this.modalData.complete();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
