<form
  [formGroup]="locationForm"
  class="location-search"
  [ngClass]="{ 'location-search--force-full-width': forceFullWidth }"
>
  <!-- current location checkbox -->
  <div class="current-location" (click)="selectCurrentLocation()">
    <div class="current-location__label">
      <img src="assets/icons/search/current-location.svg" alt="" />
      <p>{{ 'new-location-search.use-current-location' | translate }}</p>
      <div *ngIf="loadingCurrentLocation" class="loading-more-spinner"></div>
    </div>
    <div class="checkbox" [ngClass]="{ 'checkbox--checked': useCurrentLocation }">
      <input type="checkbox" id="current-location-checkbox" formControlName="useCurrentLocation" />
      <label for="current-location-checkbox"></label>
      <span
        class="checkbox__checked"
        [inlineSVG]="'/assets/icons/misc/checkbox-checked.svg'"
        [forceEvalStyles]="true"
      ></span>
    </div>
  </div>

  <!-- location input -->
  <div class="input-group input-group--btn-end">
    <input
      #locationInputRef
      formControlName="locationName"
      type="text"
      placeholder="{{ 'new-location-search.enter-manual-location' | translate }}"
      value="locationForm.get('locationName').value"
      (focus)="locationInputFocus()"
      (blur)="locationInputBlur()"
      (keydown)="locationInputKeydown($event)"
      required
    />
    <pc-clear-input-btn
      *ngIf="showClearSearchInputBtn"
      class="input-group__btn input-group__btn--end"
      (click)="clearInput()"
    >
    </pc-clear-input-btn>
  </div>

  <!-- recent locations and location suggestions -->
  <ul *ngIf="locationInputHasFocus" class="location-results">
    <!-- recent locations -->
    <ul *ngIf="showRecentLocations && recentLocations.length > 0">
      <div class="location-results__header">
        <h3>{{ 'location-search.recent-locations' | translate }}</h3>
      </div>
      <li
        *ngFor="let location of recentLocations | slice: 0:3"
        class="location-results__result"
        (mousedown)="selectLocation($event, location)"
      >
        <p>{{ location }}</p>
      </li>
    </ul>

    <!-- location suggestions -->
    <ul *ngIf="showLocationSuggestions && locationSuggestions.length > 0">
      <div class="location-results__header">
        <div *ngIf="locationSuggestionsLoading" class="loading-more-spinner"></div>
      </div>
      <li
        *ngFor="let location of locationSuggestions"
        class="location-results__result"
        [ngClass]="{ 'location-results__result--none': location === 'no suggestions available' }"
        (mousedown)="selectLocation($event, location)"
      >
        <p>{{ location }}</p>
      </li>
    </ul>
  </ul>
</form>
