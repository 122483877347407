import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-cancel-item-checkout-modal',
  templateUrl: './cancel-item-checkout-modal.component.html'
})
export class CancelItemCheckoutModalComponent {
  constructor(
    public dialogRef: MatDialogRef<CancelItemCheckoutModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { cancelCheckout: () => {} }
  ) {}
}
