import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwUpdateService {
  constructor(public updates: SwUpdate, public snackBar: MatSnackBar) {
    if (updates.isEnabled) {
      // poll the service worker to check for updates every 2 minutes
      interval(120000).subscribe(() => {
        updates.checkForUpdate().then(() => {
          console.log('u');
        });
      });
    }
  }

  // Called from app.components.ts constructor
  public checkForUpdates() {
    if (this.updates.isEnabled) {
      this.updates.available.subscribe((event) => {
        this.promptUser(event);
      });
    }
  }

  // If there is an update, display prompt to user for 5 minutes
  private promptUser(e): void {
    if (e.available) {
      const snackBarRef = this.snackBar.open('A shiny new version of Paperclip is available!', 'Refresh', {
        horizontalPosition: 'left',
        duration: 600000
      });
      snackBarRef.onAction().subscribe(() => document.location.reload());
    }
  }
}
