import { Component, Input } from '@angular/core';

@Component({
  selector: 'pc-clear-input-btn',
  templateUrl: './clear-input-btn.html',
  styleUrls: ['./clear-input-btn.component.scss']
})
export class ClearInputBtnComponent {
  @Input() whiteButton = false;
  @Input() size = 16;
}
