<!-- swipe grid -->
<div *ngIf="swipeGrid" class="swipe-grid">
  <section class="grid-row grid-row--no-margin swipe-grid__header">
    <h1 *ngIf="swipeGridConfig.titleStyle === 'h1'">{{ swipeGridConfig.title | translate }}</h1>
    <h2 *ngIf="swipeGridConfig.titleStyle === 'h2'">{{ swipeGridConfig.title | translate }}</h2>
    <h3 *ngIf="!swipeGridConfig.titleStyle">{{ swipeGridConfig.title | translate }}</h3>
    <div *ngIf="gridItems.length > 0" class="swipe-grid__header__buttons">
      <a
        *ngIf="swipeGridConfig.routerLink"
        class="btn btn-stroked"
        [routerLink]="swipeGridConfig.routerLink"
        [queryParams]="swipeGridConfig.queryParams"
      >
        {{ 'header-search.view-all' | translate }}
      </a>
      <pc-arrow-icon (click)="scrollGridLeft()" [disabled]="disableScrollLeft"></pc-arrow-icon>
      <pc-arrow-icon
        (click)="scrollGridRight()"
        orientation="right"
        [disabled]="disableScrollRight || gridItems?.length === 0"
      ></pc-arrow-icon>
    </div>
  </section>

  <!-- loading -->
  <div
    *ngIf="dataLoading"
    class="grid-view grid-view--loading grid-view--rows-{{ gridRows }}"
    [ngClass]="{ 'grid-view--feed': gridLocation === 'feed' }"
  >
    <div
      *ngFor="let gridItem of loadingItems"
      class="grid-view__item-container"
      [ngClass]="{ 'grid-view__item-container--user': gridType === 'users' }"
    >
      <!-- items -->
      <pc-grid-view-item *ngIf="gridType === 'items'" [gridItem]="gridItem" [dataLoading]="dataLoading">
      </pc-grid-view-item>

      <!-- users -->
      <pc-grid-view-user *ngIf="gridType === 'users'" [gridItem]="gridItem" [dataLoading]="dataLoading">
      </pc-grid-view-user>

      <!-- deals -->
      <pc-grid-view-deal *ngIf="gridType === 'deals'" [gridItem]="gridItem" [dataLoading]="dataLoading">
      </pc-grid-view-deal>
    </div>
  </div>

  <!-- loaded -->
  <div
    #gridElementRef
    *ngIf="!showNoDataState()"
    class="grid-view grid-view--rows-{{ gridRows }} smooth-scroll"
    [ngClass]="{ 'grid-view--feed': gridLocation === 'feed' }"
    (mousewheel)="getScrollPosition()"
  >
    <!-- items -->
    <div
      *ngFor="let gridItem of gridItems"
      class="grid-view__item-container"
      [ngClass]="{ 'grid-view__item-container--user': gridType === 'users' }"
    >
      <!-- items -->
      <pc-grid-view-item
        *ngIf="gridType === 'items'"
        [gridItem]="gridItem"
        [numberOfItems]="numberOfItems"
        [showItemTags]="showItemTags"
        [dataToGet]="dataToGet"
      ></pc-grid-view-item>

      <!-- users -->
      <pc-grid-view-user *ngIf="gridType === 'users'" [gridItem]="gridItem" [numberOfItems]="numberOfItems">
      </pc-grid-view-user>

      <!-- deals -->
      <pc-grid-view-deal *ngIf="gridType === 'deals'" [gridItem]="gridItem" [numberOfItems]="numberOfItems">
      </pc-grid-view-deal>
    </div>
  </div>
</div>

<!-- normal grid -->
<ng-container *ngIf="!swipeGrid">
  <!--
    loading states
  -->
  <div
    *ngIf="dataLoading"
    class="grid-view grid-view--loading grid-view--rows-{{ gridRows }}"
    [ngClass]="{ 'grid-view--feed': gridLocation === 'feed' }"
  >
    <div
      *ngFor="let gridItem of loadingItems"
      class="grid-view__item-container"
      [ngClass]="{ 'grid-view__item-container--user': gridType === 'users' }"
    >
      <!-- items -->
      <pc-grid-view-item *ngIf="gridType === 'items'" [gridItem]="gridItem" [dataLoading]="dataLoading">
      </pc-grid-view-item>

      <!-- users -->
      <pc-grid-view-user *ngIf="gridType === 'users'" [gridItem]="gridItem" [dataLoading]="dataLoading">
      </pc-grid-view-user>

      <!-- deals -->
      <pc-grid-view-deal *ngIf="gridType === 'deals'" [gridItem]="gridItem" [dataLoading]="dataLoading">
      </pc-grid-view-deal>
    </div>
  </div>

  <!--
    loaded
  -->
  <div
    #gridElementRef
    *ngIf="!showNoDataState()"
    class="grid-view grid-view--rows-{{ gridRows }}"
    [ngClass]="{ 'grid-view--feed': gridLocation === 'feed' }"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="300"
    (scrolled)="loadMoreItems()"
  >
    <!-- items -->
    <div
      *ngFor="let gridItem of gridItems"
      class="grid-view__item-container"
      [ngClass]="{ 'grid-view__item-container--user': gridType === 'users' }"
    >
      <!-- items -->
      <pc-grid-view-item
        *ngIf="gridType === 'items'"
        [gridItem]="gridItem"
        [numberOfItems]="numberOfItems"
        [showItemTags]="showItemTags"
        [dataToGet]="dataToGet"
      ></pc-grid-view-item>

      <!-- users -->
      <pc-grid-view-user *ngIf="gridType === 'users'" [gridItem]="gridItem" [numberOfItems]="numberOfItems">
      </pc-grid-view-user>

      <!-- deals -->
      <pc-grid-view-deal *ngIf="gridType === 'deals'" [gridItem]="gridItem" [numberOfItems]="numberOfItems">
      </pc-grid-view-deal>
    </div>
  </div>

  <!--
  loading more
  -->
  <div class="grid-view-load-more">
    <div *ngIf="!dataLoading && dataLoadingMore" class="loading-more-spinner"></div>
  </div>
</ng-container>

<!--
  no data states
-->
<div *ngIf="showNoDataState()" class="grid-view grid-view-no-data">
  <pc-no-data-view
    [ngStyle]="{ width: '100%' }"
    [pageToShow]="getCorrectNoDataPageToShow()"
    [isInDropdown]="false"
    [isOwnProfile]="isOwnProfile"
    [isFriend]="isFriend"
    (buttonWasClicked)="noDataButtonWasClicked($event)"
  >
  </pc-no-data-view>
</div>
