/* eslint-disable @typescript-eslint/ban-types */
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Order } from '@paperclip/models/order';
import { TradingService } from '@paperclip/page/trading.service';

@Component({
  selector: 'pc-dispute-trade-modal',
  templateUrl: './dispute-trade-modal.component.html',
  styleUrls: ['./dispute-trade-modal.component.scss']
})
export class DisputeTradeModalComponent {
  activeRequest: boolean;
  disputeForm: UntypedFormGroup = this.formBuilder.group({
    disputeText: ['', Validators.compose([Validators.required, Validators.maxLength(500)])]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DisputeTradeModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: { order: Order; confirmed: (data: Order) => {} },
    private tradingService: TradingService,
    private coreService: CoreService
  ) {}

  disputeOrder() {
    this.activeRequest = true;
    this.tradingService
      .disputeOrder(this.modalData.order.id, this.disputeForm.get('disputeText').value)
      .subscribe(
        ({ code, data, message }: PcApiResponse) => {
          this.activeRequest = false;
          if (code === 1) {
            this.modalData.confirmed(data);
            this.dialogRef.close();
          } else {
            this.coreService.handleError({ code, message });
          }
        },
        () => {
          this.activeRequest = false;
          this.coreService.handleError({});
        }
      );
  }
}
