<li *ngIf="!menuData?.card?.isDefault">
  <button class="btn-link" (click)="menuAction.emit({ action: 'make-payment-card-default' })">
    {{ 'menus.wallet-delivery-address.make-default' | translate }}
  </button>
</li>
<li>
  <button class="btn-link btn-link--red" (click)="menuAction.emit({ action: 'delete-payment-card' })">
    {{ 'buttons.delete-payment-card' | translate }}
  </button>
</li>
