import { Component, Input, OnInit } from '@angular/core';
import { getCardMask } from '@paperclip/functions/card-functions/card-functions';
import { ItemCheckout } from '@paperclip/models/item/item-checkout';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

@Component({
  selector: 'pc-add-payment-card',
  templateUrl: './add-payment-card.component.html',
  styleUrls: ['./add-payment-card.component.scss', '../../../../item/item-checkout/item-checkout-form-groups.scss']
})
export class AddPaymentCardComponent implements OnInit {
  @Input() page: 'wallet' | 'checkout' = 'wallet';
  @Input() itemCheckout: ItemCheckout;
  @Input() showSetDefaultOption = true;
  cardNumberMask: Array<string | RegExp>;
  cardExpiryDateMask: Array<string | RegExp> = [/\d/, /\d/, '/', /\d/, /\d/];
  cardCVCMask: Array<string | RegExp> = [/\d/, /\d/, /\d/, /\d/];
  autoCorrectedDatePipe: any = createAutoCorrectedDatePipe('mm/yy');
  defaultCard = false;
  storeCard = false;

  ngOnInit() {
    this.cardNumberMask = getCardMask(null);
    this.itemCheckout.form
      .get('paymentCard')
      .get('cardNumber')
      .valueChanges.subscribe((cardNumber: string) => {
        this.cardNumberMask = getCardMask(cardNumber);
      });
  }

  setAsDefaultCard() {
    this.defaultCard = !this.defaultCard;
    this.storeCard = true;
    this.itemCheckout.form.get('paymentCard').get('isDefault').patchValue(this.defaultCard);
    this.itemCheckout.form.get('paymentCard').get('storeCard').patchValue(this.storeCard);
  }

  savePaymentCard() {
    this.storeCard = !this.storeCard;
    this.itemCheckout.form.get('paymentCard').get('storeCard').patchValue(this.storeCard);
    if (!this.storeCard) {
      this.defaultCard = false;
      this.itemCheckout.form.get('paymentCard').get('isDefault').patchValue(false);
    }
  }

  public showSetAsDefaultOption(): boolean {
    if (this.page === 'wallet') {
      return this.showSetDefaultOption;
    } else {
      return (
        this.itemCheckout.paymentCards.length > 0 && this.itemCheckout.form.get('paymentCard').get('storeCard').value
      );
    }
  }
}
