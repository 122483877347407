<div pcClickStopPropagation class="sort-results-filter">
  <h2>{{ 'search.filters.sort.title-' + searchFilters.type | translate }}</h2>
  <ul class="checkbox-list">
    <li
      pcClickStopPropagation
      *ngFor="let sortOption of sortOptions"
      (click)="selectSortOption(sortOption)"
      class="checkbox-list__item"
    >
      <p class="highlight-text">{{ 'search.filters.sort.' + sortOption.label | translate }}</p>
      <div class="checkbox" [ngClass]="{ 'checkbox--checked': sortOption.isSelected }">
        <input type="checkbox" id="reason-checkbox" />
        <label for="reason-checkbox"></label>
        <span
          class="checkbox__checked"
          [inlineSVG]="'/assets/icons/misc/checkbox-checked.svg'"
          [forceEvalStyles]="true"
        ></span>
      </div>
    </li>
  </ul>
</div>
