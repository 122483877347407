/*==================================================
standardise all dates using momentjs to avoid issues
with different browsers formatting dates differently!
===================================================*/

import * as moment from 'moment';

export function formatSingleDateByLocale(date: string, locale: any = 'en-GB', formatString = 'LL') {
  // tell moment that the date is UTC format and use .local() to transform into correct local date from time
  // e.g. for GMT would add 1 hour on (important e.g. if time is 23:30) and then finally convert to
  // locale date string with correct format
  return moment.utc(date).local().locale(locale).format(formatString);
}

export function formatDate(date: string) {
  return moment(date).format('DD/MM/YYYY');
}

export function relativeDate(date: string, locale: any = 'en-GB', formatString = 'L', utc = true, calendarDate = true) {
  const dataDate = moment(date, 'YYYYMMDD');
  const currentDate = moment(new Date(), 'YYYYMMDD');
  const dateDifference = currentDate.diff(dataDate, 'days');
  const fromNow = moment(date).fromNow();

  let formattedDate = utc
    ? moment.utc(date).local().locale(locale).calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: formatString
      })
    : moment(date).local().locale(locale).calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: formatString
      });

  if (calendarDate) {
    formattedDate =
      dateDifference >= 30 ? '30+ days ago' : dateDifference >= 7 && dateDifference < 30 ? fromNow : formattedDate;
  }

  return formattedDate;
}

export function timestamp(date: string, locale: any = 'en-GB', utc = true) {
  return utc
    ? parseInt(moment.utc(date).local().locale(locale).format('x'), null)
    : parseInt(moment(date).local().locale(locale).format('x'), null);
}
