import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '@paperclip/core/app.config';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { environment } from '@paperclip/environments/environment';
import { Item, setItemDeepLink } from '@paperclip/models/item';
import { PaperclipEvent } from '@paperclip/models/PaperclipEvent';
import * as Bowser from 'bowser';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'pc-share-x-modal',
  templateUrl: './share-x-modal.component.html',
  styleUrls: ['./share-x-modal.component.scss']
})
export class ShareXModalComponent implements OnInit {
  branchDeepLinkLoading: boolean;
  branchDeepLink: string;
  copiedText = 'invite-friends.copy-link';
  device: Bowser.Parser.ParsedResult;

  constructor(
    public dialogRef: MatDialogRef<ShareXModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: { type: 'item'; item: Item; showTags?: boolean },
    private clipboardService: ClipboardService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.branchDeepLink = await this.getBranchDeepLink();
    this.branchDeepLinkLoading = false;
    this.device = Bowser.getParser(window.navigator.userAgent).getResult();
    this.modalData.showTags = this.modalData.showTags !== null ? this.modalData.showTags : true;
  }

  private async getBranchDeepLink(): Promise<string> {
    if (this.modalData.type === 'item') {
      if (this.modalData.item.detail.deepLink) {
        return this.modalData.item.detail.deepLink;
      } else {
        this.branchDeepLinkLoading = true;
        return await setItemDeepLink(this.modalData.item, this.coreService);
      }
    }
  }

  public shareToFacebook() {
    const fbAppId = environment.production ? AppConfig.app.fbAppId : '237645557053105';
    const display: 'async' | 'iframe' | 'page' | 'popup' | 'touch' = 'popup';
    const redirectUri = environment.production
      ? 'https://marketplace.paperclip.co/close-redirect'
      : 'https://marketplace.papercliptesting.co.uk/close-redirect';
    window.open(
      `https://www.facebook.com/dialog/share?app_id=${fbAppId}&display=${display}&href=${this.branchDeepLink}&redirect_uri=${redirectUri}`,
      '_blank'
    );
    this.trackPaperclipEvent();
  }

  public shareToMessenger() {
    const fbAppId = environment.production ? AppConfig.app.fbAppId : '237645557053105';
    if (this.device.platform.type !== 'desktop') {
      window.open(
        'fb-messenger://share?link=' +
          encodeURIComponent(this.branchDeepLink) +
          '&app_id=' +
          encodeURIComponent(fbAppId)
      );
    } else {
      const redirectUri = environment.production
        ? 'https://marketplace.paperclip.co/close-redirect'
        : 'https://marketplace.papercliptesting.co.uk/close-redirect';
      window.open(
        `https://www.facebook.com/dialog/send?app_id=${fbAppId}&link=${this.branchDeepLink}&redirect_uri=${redirectUri}`,
        '_blank'
      );
    }
    this.trackPaperclipEvent();
  }

  public shareToTwitter() {
    const tweet = `Look at this item I found on Paperclip!`;
    /* eslint-disable max-len */
    window.open(
      `https://twitter.com/intent/tweet?url=${this.branchDeepLink}&via=PaperclipHQ&text=${tweet}&hashtags=paperclip,marketplace`,
      '_blank'
    );
    this.trackPaperclipEvent();
  }

  public shareByEmail() {
    const itemName = this.modalData.item.detail.name;
    const emailBody = `
      I thought you might be interested in this ${this.modalData.type} I found on Paperclip: ${this.modalData.item.detail.name}
      \n\n
      Check it out here: ${this.branchDeepLink}.
    `;
    const emailSubject = `${itemName} -  - ${this.translateService.instant('meta.tagline')}`;
    const mailTo = `mailto:?Subject=${emailSubject}&body=${encodeURIComponent(emailBody)}`;
    setTimeout(() => {
      window.open(mailTo, '_self');
    }, 100);
    this.trackPaperclipEvent();
  }

  public copyLink() {
    this.clipboardService.copyFromContent(this.branchDeepLink);
    this.confirmationMessageService.showConfirmationMessage('link-copied');
    this.copiedText = 'invite-friends.copied';
    setTimeout(() => {
      this.copiedText = 'invite-friends.copy-link';
    }, 3000);
    this.trackPaperclipEvent();
  }

  private trackPaperclipEvent() {
    if (this.modalData.type === 'item') {
      const paperclipEvent: PaperclipEvent = {
        name: 'ITEM_SHARED',
        data: { objectIds: [this.modalData.item.detail.id] }
      };
      this.coreService.trackPaperclipEvent({ name: paperclipEvent.name, data: paperclipEvent.data }).subscribe();
    }
  }
}
