import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
/*-- services --*/
import { ItemOverviewService } from '@paperclip/page/item-overview/item-overview.service';

@Component({
  selector: 'pc-mark-as-sold-modal',
  templateUrl: './mark-as-sold-modal.component.html'
})
export class MarkAsSoldModalComponent {
  /*-- properties --*/
  activeRequest: boolean;

  constructor(
    public dialogRef: MatDialogRef<MarkAsSoldModalComponent>,
    @Inject(MAT_DIALOG_DATA) public itemId: string,
    private itemOverviewService: ItemOverviewService,
    private updateService: UpdatesService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService
  ) {}

  markAsSold() {
    this.activeRequest = true;
    this.itemOverviewService.markAsSold(this.itemId).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('marked-as-sold');
          this.updateService.sendUpdateData({
            action: 'mark-as-sold',
            updateKeyValue: true,
            updateId: this.itemId,
            keyValueData: {
              key: 'isSold',
              value: true
            }
          });
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
