import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '@paperclip/core/app.config';

@Component({
  selector: 'pc-pending-balance-explained-modal',
  templateUrl: './pending-balance-explained-modal.component.html'
})
export class PendingBalanceExplainedModalComponent {
  externalLinks = AppConfig.externalLinks;
  constructor(public dialogRef: MatDialogRef<PendingBalanceExplainedModalComponent>) {}
}
