import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
/*-- services --*/
import { UsersService } from '@paperclip/page/users.service';

@Component({
  selector: 'pc-delete-account-modal',
  templateUrl: './delete-account-modal.component.html'
})
export class DeleteAccountModalComponent {
  /*-- properties --*/
  activeRequest: boolean;

  constructor(
    public dialogRef: MatDialogRef<DeleteAccountModalComponent>,
    private usersService: UsersService,
    private coreService: CoreService
  ) {}

  deleteAccount() {
    this.activeRequest = true;
    this.usersService.deleteAccount().subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.dialogRef.close();
          localStorage.removeItem('user');
          window.location.replace('/');
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
