import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pc-item-details-item-title',
  templateUrl: './item-details-item-title.component.html'
})
export class ItemDetailsItemTitleComponent {
  @Input() addItemForm: UntypedFormGroup;
  @Output() clearItemDetail: EventEmitter<string> = new EventEmitter<string>();
}
