import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { NoDataType } from '@paperclip/constants/no-data-type';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-no-data-view',
  templateUrl: './no-data-view.component.html',
  styleUrls: ['./no-data-view.component.scss']
})
export class NoDataViewComponent implements OnChanges, OnDestroy {
  /*-- inputs --*/
  @Input() pageToShow = 'user-items';
  @Input() isInDropdown = false;
  @Input() isOwnProfile = false;
  @Input() isFriend = false;
  @Input() showImage = true;

  /*-- outputs --*/
  @Output()
  buttonWasClicked: EventEmitter<NoDataType> = new EventEmitter<NoDataType>();

  /*-- noDataType --*/
  noDataType: NoDataType = NoDataType.NotYetSpecified;
  needsDisplayImage = true;
  needsMessage = true;
  needsButton = true;
  isButtonBlue = false;
  disableButton = false;

  /*-- get window size and also the image width and height --*/
  windowSize = window.innerWidth < 1200 ? 'mobile' : 'desktop';
  windowWidthResizeSubscription: Subscription;
  imageWidth;
  imageHeight;

  constructor(private windowResizeService: WindowResizeService) {
    this.windowWidthResizeSubscription = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 1200 ? 'mobile' : 'desktop';
    });
  }

  ngOnChanges() {
    this.updateImageSize();
    this.updateEmptyStateContent();
  }

  ngOnDestroy() {
    this.windowWidthResizeSubscription.unsubscribe();
  }

  updateImageSize() {
    this.windowSize = window.innerWidth < 1200 ? 'mobile' : 'desktop';
    this.imageWidth = '{{ `no-data-1.${this.noDataType}.image.width--${this.windowSize}` | translate }}';
    this.imageHeight = '{{ `no-data-1.${this.noDataType}.image.width--${this.windowSize}` | translate }}';
  }

  updateEmptyStateContent() {
    /*-- working with new grid system --*/
    switch (this.pageToShow) {
      /*==================
              ITEMS
      ==================*/
      /*-- user items --*/
      case 'user-items':
        this.noDataType = this.isOwnProfile ? NoDataType.SellingItems : NoDataType.UserProfileItems;
        this.needsButton = this.isOwnProfile;
        break;

      case 'your-following':
        this.noDataType = NoDataType.YourFollowing;
        this.needsButton = false;
        break;

      /*-- user favourites --*/
      case 'user-favourites':
        this.noDataType = NoDataType.YourFavourites;
        this.needsButton = false;
        break;

      /*-- item overview: similar items --*/
      case 'similar-items':
        this.noDataType = NoDataType.SimilarItems;
        this.needsDisplayImage = false;
        this.needsButton = false;
        break;

      /*-- feed: featured items --*/
      case 'featured-items':
        (this.noDataType = NoDataType.FeaturedItems), (this.needsDisplayImage = false);
        this.needsButton = false;
        break;

      /*-- feed: categorized items --*/
      case 'feed-categorised':
        this.noDataType = NoDataType.FeedCategorised;
        this.needsDisplayImage = false;
        this.needsButton = false;
        break;

      /*-- search items --*/
      case 'search-items':
        this.noDataType = NoDataType.MissingItem;
        this.needsButton = true;
        break;

      /*-- deleted item --*/
      case 'deleted-item':
        // do some text here
        break;

      /*-- user reviews --*/
      case 'user-reviews':
        this.noDataType = this.isOwnProfile ? NoDataType.YourReview : NoDataType.UserProfileReviews;
        this.needsButton = false;
        break;

      /*==================
            USERS
      ==================*/
      /*-- followers --*/
      case 'user-followers':
        this.noDataType = this.isOwnProfile ? NoDataType.YourFollowers : NoDataType.UserProfileFollowers;
        this.needsButton = false;
        break;

      /*-- following --*/
      case 'user-following':
        this.noDataType = this.isOwnProfile ? NoDataType.YourFollowing : NoDataType.UserProfileFollowing;
        this.needsButton = false;
        break;

      /*-- friends (followed and following back) --*/
      case 'user-friends':
        this.noDataType = NoDataType.YourFriends;
        this.needsButton = false;
        break;

      /*-- search users --*/
      case 'search-users':
        this.noDataType = NoDataType.MissingItem;
        this.needsButton = true;
        break;

      /*==================
            TRADING
      ==================*/
      case 'buying-active':
        this.noDataType = NoDataType.BuyingActive;
        break;

      case 'buying-won':
        this.noDataType = NoDataType.BuyingWon;
        this.needsButton = false;
        break;

      case 'buying-lost':
        this.noDataType = NoDataType.BuyingDidntWin;
        this.needsButton = false;
        break;

      case 'buying-completed':
        this.noDataType = NoDataType.BuyingCompleted;
        this.needsButton = false;
        break;

      case 'selling-items':
        this.noDataType = NoDataType.SellingItems;
        break;

      case 'selling-offers':
        this.noDataType = NoDataType.SellingOffers;
        break;

      case 'selling-offers--multiple':
        this.noDataType = NoDataType.SellingOffersMultiple;
        break;

      case 'selling-accepted':
        this.noDataType = NoDataType.SellingAccepted;
        break;

      case 'selling-declined':
        this.noDataType = NoDataType.SellingDeclined;
        break;

      case 'selling-sold':
        this.noDataType = NoDataType.SellingSold;
        break;

      case 'selling-declined--multiple':
        this.noDataType = NoDataType.SellingDeclinedMultiple;
        break;

      case 'buying-lost--multiple':
        this.noDataType = NoDataType.BuyingDeclinedMultiple;
        break;

      /*==================
            DEALS
      ==================*/
      case 'all-deals':
        this.noDataType = NoDataType.FeedAllDeals;
        this.needsDisplayImage = false;
        this.needsButton = false;
        break;

      /*==================
          COLLECTIONS
      ==================*/

      /*==================
              MISC
      ==================*/
      case 'page-not-found':
        this.noDataType = NoDataType.PageNotFound;
        this.needsButton = true;
        break;

      case 'notifications':
        this.noDataType = NoDataType.Notifications;
        this.needsButton = false;
        this.needsDisplayImage = !this.isInDropdown;
        this.needsMessage = !this.isInDropdown;
        break;

      case 'messages':
        this.noDataType = NoDataType.Messages;
        this.needsDisplayImage = !this.isInDropdown;
        this.needsMessage = !this.isInDropdown;
        this.needsButton = false;
        break;

      default:
        // 404
        this.noDataType = NoDataType.PageNotFound;
        this.needsButton = true;
        break;
    }
  }

  getAppropriateNoDataContent(): string {
    return 'no-data.' + this.noDataType + (this.isInDropdown ? '.title' : '.message');
  }

  handleClick() {
    this.buttonWasClicked.emit(this.noDataType);
  }
}
