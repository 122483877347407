<div pcIntersectionObserver [intersectionDebounce]="1000" (visibilityChange)="onVisibilityChanged($event)"
  class="content-card notification" [ngClass]="{
    'notification--inside-menu': insideMenu,
    'loading-data': notificationsLoading,
    'notification--seen': notificationIsSeen
  }">
  <pc-notification-dot *ngIf="!notification?.detail.isRead"
    [placement]="insideMenu ? 'notifications-inside-menu' : 'notifications'" [showCount]="false">
  </pc-notification-dot>
  <pc-small-user *ngIf="!insideMenu" [user]="notification?.sender" [link]="notification?.linkUser" metaDetail="none">
  </pc-small-user>
  <a *ngIf="insideMenu && notification" class="notification__user-picture"
    [routerLink]="['/u', notification?.sender.detail.username]">
    <img class="bg-image" [src]="notification ? notification?.sender.detail.picture : 'assets/loading-img.png'"
      alt="" /></a>
  <p class="notification__message" [ngClass]="{ 'highlight-text': insideMenu }" [innerHTML]="
      notification?.detail?.content.message
        | translate
          : {
              user: insideMenu ? notification?.fromUser?.detail?.fullName + ' ' : '',
              linkId: notification?.detail?.content.linkId,
              linkText: notification?.detail?.content.linkText,
              cardAlias: notification?.cardAlias,
              creditExpiring: notification?.creditExpiring,
              userCurrency: userCurrency,
              userName: notification?.fromUser?.detail?.fullName,
              itemName: notification?.item?.detail?.name
            }
        | safeHtml
    "></p>
  <div *ngIf="!insideMenu" class="notification__date">
    <p class="sub-text">{{ notification?.detail?.date }}</p>
  </div>
</div>
