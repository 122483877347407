import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { EMPTY, Observable } from 'rxjs';

import { PaymentAccountForm } from '../models/payments/PaymentAccountForm';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  /*-- properties --*/
  isLoggedIn = this.authService.isLoggedIn();
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

  public checkForPaymentAccount(userId: string): Observable<any> {
    return this.http.get<any>(`paymentsAccounts/${userId}`);
  }

  public savePaymentAccount(paymentAccount: PaymentAccountForm): Observable<any> {
    return this.http.post<any>(`paymentsAccounts`, paymentAccount);
  }

  /*-- follow user --*/
  followUser(userIdToFollow: string, currentUserId: string): Observable<any> {
    if (this.isLoggedIn) {
      return this.http.post(`follow`, {
        userId: userIdToFollow,
        currentUserId: currentUserId
      });
    } else {
      this.showAuthModal();
      return EMPTY;
    }
  }

  /*-- unfollow user --*/
  unfollowUser(userIdToUnFollow: string, currentUserId: string): Observable<any> {
    if (this.isLoggedIn) {
      return this.http.post(`unfollow`, { userId: userIdToUnFollow, currentUserId: currentUserId });
    } else {
      this.showAuthModal();
      return EMPTY;
    }
  }

  blockUser(userId: string): Observable<any> {
    if (this.isLoggedIn) {
      return this.http.post(`users/block`, { userId: userId, currentUserId: this.authedUserId });
    } else {
      this.showAuthModal();
      return EMPTY;
    }
  }

  unblockUser(userId: string): Observable<any> {
    if (this.isLoggedIn) {
      return this.http.post(`users/unblock`, { userId: userId, currentUserId: this.authedUserId });
    } else {
      this.showAuthModal();
      return EMPTY;
    }
  }

  reportUser(userId: string, reasonId: number): Observable<any> {
    if (this.isLoggedIn) {
      return this.http.post(`users/reportUser`, {
        reportedUserId: userId,
        reportingUserId: this.authedUserId,
        reasonId: reasonId
      });
    } else {
      this.showAuthModal();
      return EMPTY;
    }
  }

  showAuthModal() {
    this.router.navigate(['/signin']);
  }

  deleteAccount(): Observable<any> {
    return this.http.post(`users/delete`, { userId: this.authedUserId });
  }
}
