import { Component, Input } from '@angular/core';
import { ConversationSummary } from '@paperclip/models/messages/ConversationSummary';

@Component({
  selector: 'pc-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent {
  @Input() conversations: ConversationSummary[];
  @Input() openConversationId: string;
  @Input() onDesktop = false;
  @Input() insideMenu = false;
}
