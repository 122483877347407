<form
  [formGroup]="addressForm"
  class="find-address find-address--{{ page }}"
  [ngClass]="{ 'find-address__show-matches': showPostCodeMatches }"
>
  <div formGroupName="findAddress" class="form-row">
    <div class="form-group">
      <label for="postCode">{{
        page === 'wallet'
          ? ('item-checkout.delivery.add-delivery-address.enter-new-address' | translate)
          : ('item-checkout.delivery.add-delivery-address.find-address.enter-post-code' | translate)
      }}</label>
      <div class="postcode-search">
        <!-- input -->
        <div class="input-group input-group--btn-end">
          <input
            #postCodeInput
            id="postCode"
            formControlName="postCodeSuggestion"
            type="text"
            placeholder="{{ 'item-checkout.delivery.add-delivery-address.find-address.enter-post-code' | translate }}"
            (focus)="inputFocus()"
            (blur)="showPostCodeMatches = false"
            (keydown.enter)="enterKeydown($event)"
            required
          />
          <pc-clear-input-btn
            *ngIf="addressForm.get('findAddress').get('postCodeSuggestion').valid"
            class="input-group__btn input-group__btn--end"
            (click)="clearInput()"
          >
          </pc-clear-input-btn>
        </div>

        <!-- results -->
        <div *ngIf="showPostCodeMatches" class="postcode-matches">
          <div *ngIf="loadingAddressSuggestions" class="loading-more-spinner"></div>
          <ng-container *ngIf="!loadingAddressSuggestions && postCodeMatches.length > 0">
            <p
              *ngFor="let match of postCodeMatches"
              class="highlight-text p-large"
              (mousedown)="preventDefault($event)"
              (click)="selectPostCodeMatch(match)"
            >
              {{ match.suggestion }}
            </p>
          </ng-container>

          <!-- no matches -->
          <ng-container *ngIf="!loadingAddressSuggestions && postCodeMatches.length === 0">
            <p>{{ 'item-checkout.delivery.add-delivery-address.find-address.no-matches' | translate }}</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <p class="enter-manually">
    {{ 'generic.or' | translate }}
    <button type="button" class="btn-link btn-link--inline" (click)="enterAddressManually()">
      {{ 'item-checkout.delivery.add-delivery-address.find-address.enter-manually' | translate }}
    </button>
  </p>

  <div class="checkout-form-group__form__footer">
    <button type="button" class="btn-primary" (click)="useAddress()" [disabled]="useAddressButtonDisabled()">
      {{ getButtonLabel() | translate }}
    </button>
  </div>
</form>
