import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NotifierModule } from 'angular-notifier';
import { TextMaskModule } from 'angular2-text-mask';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CustomFormsModule } from 'ng2-validation';
import { ClipboardModule } from 'ngx-clipboard';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxImageSwiperModule } from 'ngx-image-swiper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgPipesModule } from 'ngx-pipes';

import { IconButtonsModule } from './icon-buttons/icons.module';

export const sharedModuleImports = [
  // angular
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  GoogleMapsModule,

  // 3rd party
  TranslateModule,
  NgPipesModule,
  InlineSVGModule.forRoot(),
  TextMaskModule,
  InfiniteScrollModule,
  ImageCropperModule,
  CustomFormsModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatMenuModule,
  MatDialogModule,
  MatBottomSheetModule,
  MatSliderModule,
  NotifierModule.withConfig({
    position: {
      horizontal: {
        position: 'right',
        distance: 12
      },
      vertical: {
        position: 'top',
        distance: 12,
        gap: 10
      }
    },
    theme: 'material',
    behaviour: {
      autoHide: 3000,
      onClick: 'hide',
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
    },
    animations: {
      enabled: true,
      show: {
        preset: 'slide',
        speed: 300,
        easing: 'ease'
      },
      hide: {
        preset: 'fade',
        speed: 300,
        easing: 'ease',
        offset: 50
      },
      shift: {
        speed: 300,
        easing: 'ease'
      },
      overlap: 150
    }
  }),
  ClipboardModule,
  NgxImageSwiperModule,
  DragDropModule,

  // paperclip
  IconButtonsModule
];

export const sharedModuleExports = [
  // angular
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,

  // 3rd party
  TranslateModule,
  NgPipesModule,
  InlineSVGModule,
  TextMaskModule,
  InfiniteScrollModule,
  ImageCropperModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatMenuModule,
  MatDialogModule,
  MatBottomSheetModule,
  MatSliderModule,
  NotifierModule,
  ClipboardModule,
  NgxImageSwiperModule,
  DragDropModule,

  // paperclip
  IconButtonsModule
];
