<li>
  <a [href]="externalLinks.company.helpCentre" target="_blank">
    {{ 'menus.header-info.help-centre' | translate }}
  </a>
</li>
<li>
  <button class="btn-link" (click)="menuAction.emit({ action: 'invite-friends' })">
    {{ 'menus.header-profile.invite-friends' | translate }}
  </button>
</li>
<li>
  <a [routerLink]="['/wallet/payment-setup']">
    {{ 'wallet.tab.payment-setup' | translate }}
  </a>
</li>
