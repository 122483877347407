<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'tooltip.wallet-pending-balance.title' | translate }}</h1>
  </div>

  <div class="modal-body">
    <p>{{ 'tooltip.wallet-pending-balance.text' | translate }}</p>
  </div>

  <div class="modal-footer multi-btn-container">
    <a class="btn btn-large" [href]="externalLinks.faq.funds" target="_blank">{{ 'generic.learn-more' | translate }}</a>
    <button class="btn btn-primary btn-large" (click)="dialogRef.close()">{{ 'buttons.okay' | translate }}</button>
  </div>
</div>
