<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.social-inventory-privacy.title' | translate }}</h1>
  </div>

  <p class="modal-body">
    {{ 'modals.social-inventory-privacy.body' | translate }}
  </p>

  <div class="modal-footer multi-btn-container">
    <button
      class="btn-large"
      (click)="setSocialInventoryPrivacy(true)"
      [ngClass]="{ 'btn-loading': userClickedNo }"
      [disabled]="userClickedYes"
    >
      {{ 'generic.no' | translate }}
    </button>
    <button
      class="btn-large btn-primary"
      (click)="setSocialInventoryPrivacy(false)"
      [ngClass]="{ 'btn-loading': userClickedYes }"
      [disabled]="userClickedNo"
    >
      {{ 'generic.yes' | translate }}
    </button>
  </div>
</div>
