<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.dispute-trade.dispute-submitted.title' | translate }}</h1>
  </div>

  <p class="modal-body">
    {{ 'modals.dispute-trade.dispute-submitted.body' | translate }}
  </p>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large btn-large--fit-content btn-primary" (click)="dialogRef.close()">
      {{ 'modals.dispute-trade.dispute-submitted.button' | translate }}
    </button>
  </div>
</div>
