import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { Item } from '@paperclip/models/item';

@Component({
  selector: 'pc-item-search-menu',
  templateUrl: './item-search-menu.component.html'
})
export class ItemSearchMenuComponent {
  @Input() menuData: { item: Item };
  @Input() menuOpened: boolean;
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';

  constructor(private authService: AuthService) {}
}
