/* eslint-disable max-len */
import { ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { environment } from '@paperclip/environments/environment';
import { DeleteItemModalComponent, MarkAsSoldModalComponent } from '@paperclip/modals/item/item-modals';
import { DeleteConversationModalComponent } from '@paperclip/modals/messaging/messaging-modals';
import { ModalConfig, ModalService } from '@paperclip/modals/modal.service';
import { ReportXModalComponent, ShareXModalComponent } from '@paperclip/modals/shared/shared-modals';
import {
  DeleteTradeModalComponent,
  ReviewTradeModalComponent,
  WithdrawOfferModalComponent
} from '@paperclip/modals/trading/trading-modals';
import {
  DeleteAllNotificationsMessagesModalComponent,
  InviteFriendsModalComponent
} from '@paperclip/modals/user/user-modals';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { ComponentUpdate } from '@paperclip/models/component-update';
import { setItemDeepLink } from '@paperclip/models/item';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';
import { ItemOverviewService } from '@paperclip/page/item-overview/item-overview.service';
import { UsersService } from '@paperclip/page/users.service';
import { Subscription } from 'rxjs';

import { SignOutModalComponent } from '../modals/user/sign-out-modal/sign-out-modal.component';
import {
  HeaderInfoMenuComponent,
  HeaderNotificationsMenuComponent,
  HeaderProfileMenuComponent
} from './menus/header/header-menus';
import { ItemSearchMenuComponent } from './menus/search/search-menus';
import { BuyingMenuComponent, FilterTradesMenuComponent, SellingMenuComponent } from './menus/trading/trading-menus';
import {
  ClearDeleteAllMenuComponent,
  ConversationMenuComponent,
  UserListMenuComponent,
  UserProfileMenuComponent
} from './menus/user/user-menus';
import {
  WalletDeliveryAddressMenuComponent,
  WalletFilterTransactionsMenuComponent,
  WalletHeaderMenuComponent,
  WalletPaymentCardMenuComponent
} from './menus/wallet/wallet-menus';

interface Menu {
  name: string;
  component: ComponentType<any>;
}

interface MenuMethod {
  name: string;
  method: any;
}

@Component({
  selector: 'pc-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {
  /*-- inputs --*/
  @Input() menu: string;
  @Input() iconMenu = true;
  @Input() menuType = 'list';
  @Input() menuData: any;
  @Input() menuIcon: any = '/assets/icons/misc/menu.svg';
  @Input() menuClass = 'edit-menu';
  @Input() menuButtonClass = 'menu-button btn-icon--small btn-icon--fill';
  @Input() disabled: boolean;
  @Output() emitMenuOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  /*-- view child --*/
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('menuComponentContainerRef', { read: ViewContainerRef })

  /*-- properties --*/
  menuComponentContainerRef: ViewContainerRef;
  componentRef: ComponentRef<any>;
  routerEventsSubscription: Subscription;
  authedUser: AuthedUser = this.authService.getAuthedUser();

  constructor(
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: ModalService,
    private authService: AuthService,
    private usersService: UsersService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService,
    private updateService: UpdatesService,
    private itemOverviewService: ItemOverviewService
  ) {
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.menuTrigger.closeMenu();
      }
    });
  }

  ngOnInit() {
    const menuComponent: ComponentType<any> = this.getMenuComponent(this.menu);

    // run change detection so the DOM change in the
    // template is complete after the ngIf condition
    this.changeDetectorRef.detectChanges();

    menuComponent ? this.createMenu(menuComponent) : console.error('please provide a menu component for', this.menu);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.menuData) {
      if (this.componentRef) {
        this.componentRef.instance.menuData = this.menuData;
      }
    }

    if (changes?.menu) {
      this.ngOnInit();
    }
  }

  private getMenuComponent(menuName: string): ComponentType<any> {
    const menus: Menu[] = [
      { name: 'header-info-menu', component: HeaderInfoMenuComponent },
      { name: 'header-profile-menu', component: HeaderProfileMenuComponent },
      { name: 'header-notifications-messages-menu', component: HeaderNotificationsMenuComponent },
      { name: 'user-profile', component: UserProfileMenuComponent },
      { name: 'user-list-menu', component: UserListMenuComponent },
      { name: 'conversation-menu', component: ConversationMenuComponent },
      { name: 'buying-menu', component: BuyingMenuComponent },
      { name: 'selling-menu', component: SellingMenuComponent },
      { name: 'filter-trades-menu', component: FilterTradesMenuComponent },
      { name: 'items-search-menu', component: ItemSearchMenuComponent },
      { name: 'wallet-header-menu', component: WalletHeaderMenuComponent },
      { name: 'filter-transactions-menu', component: WalletFilterTransactionsMenuComponent },
      { name: 'wallet-delivery-address-menu', component: WalletDeliveryAddressMenuComponent },
      { name: 'wallet-payment-card-menu', component: WalletPaymentCardMenuComponent },
      { name: 'clear-delete-all-menu', component: ClearDeleteAllMenuComponent }
    ];

    const matchedMenu: Menu = menus.find((menu: Menu) => menu.name === menuName);

    if (!matchedMenu) {
      return null;
    }

    return matchedMenu.component;
  }

  private createMenu(menuComponent: ComponentType<any>) {
    this.menuComponentContainerRef.clear();
    const factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(menuComponent);
    this.componentRef = this.menuComponentContainerRef.createComponent(factory);
    this.componentRef.instance.menuData = this.menuData;

    // subscribe to menu action emitter and process
    if (this.componentRef.instance && this.componentRef.instance.menuAction) {
      this.componentRef.instance.menuAction.subscribe(({ action, data }: MenuAction) => {
        if (!environment.production) {
          console.log(`menuAction`, action, data);
        }

        const menuMethods: MenuMethod[] = [
          { name: 'close-menu', method: () => this.menuTrigger.closeMenu() },
          { name: 'report-user', method: () => this.reportUser(data) },
          { name: 'report-buyer', method: () => this.reportUser(null, 'selling') },
          { name: 'report-seller', method: () => this.reportUser(null, 'buying') },
          { name: 'review-trade', method: () => this.reviewTrade() },
          { name: 'withdraw-offer', method: () => this.withdrawOffer() },
          { name: 'delete-trade', method: () => this.deleteTrade() },
          { name: 'delete-item', method: () => this.deleteItem() },
          { name: 'share-item', method: () => this.shareItem() },
          { name: 'mark-sold', method: () => this.markAsSold() },
          { name: 'invite-friends', method: () => this.inviteFriends() },
          { name: 'follow-user', method: () => this.followUser(data) },
          { name: 'unfollow-user', method: () => this.unfollowUser(data) },
          { name: 'delete-conversation', method: () => this.deleteConversation(data) },
          { name: 'logout', method: () => this.logout() },
          { name: 'save-item', method: () => this.saveItem() },
          { name: 'unsave-item', method: () => this.unsaveItem() },
          { name: 'report-item', method: () => this.reportItem() },
          { name: 'make-address-default', method: () => this.addressAction('make-address-default') },
          { name: 'edit-address', method: () => this.addressAction('edit-address') },
          { name: 'delete-address', method: () => this.addressAction('delete-address') },
          { name: 'make-payment-card-default', method: () => this.paymentCardAction('make-payment-card-default') },
          { name: 'delete-payment-card', method: () => this.paymentCardAction('delete-payment-card') },
          { name: 'filter-transaction-history', method: () => this.filterTransactionHistory(data) },
          { name: 'mark-all-as-read', method: () => this.markAllAsRead(data) },
          { name: 'delete-all', method: () => this.deleteAll(data) }
        ];

        menuMethods.find((menuMethod: MenuMethod) => menuMethod.name === action).method();
      });
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    this.routerEventsSubscription.unsubscribe();
  }

  menuOpened() {
    if (!environment.production) {
      console.log(this.menu, this.menuData);
    }
    this.componentRef.instance.menuOpened = true;
    if (this.menu === 'header-profile-menu' || this.menu === 'header-notifications-messages-menu') {
      this.componentRef.instance.ngOnChanges();
    }
    this.emitMenuOpened.emit(this.componentRef.instance.menuOpened);
  }

  menuClosed() {
    this.componentRef.instance.menuOpened = false;
    if (this.menu === 'header-profile-menu' || this.menu === 'header-notifications-messages-menu') {
      this.componentRef.instance.ngOnChanges();
    }
    this.emitMenuOpened.emit(this.componentRef.instance.menuOpened);
  }

  /*====================
  MENU ACTIONS
  ====================*/
  private addressAction(action: string) {
    let updateData: ComponentUpdate = {
      action: action
    };
    updateData =
      action === 'edit-address'
        ? { ...updateData, updateObject: this.menuData.address }
        : { ...updateData, updateId: this.menuData.address.id };
    this.updateService.sendUpdateData(updateData);
  }

  private paymentCardAction(action: string) {
    this.updateService.sendUpdateData({
      action: action,
      updateId: this.menuData.card.id
    });
  }

  reportUser(actionData: any, offerType?: string) {
    let userId = '';
    if (offerType) {
      userId = offerType === 'selling' ? this.menuData.offer.detail.buyer.id : this.menuData.offer.detail.seller.id;
    } else {
      userId = this.menuData.userId;
      if (actionData !== null && typeof actionData === 'object') {
        userId = actionData.userId;
      } else {
        userId = actionData ? actionData : userId;
      }
    }

    this.modalService.open(ReportXModalComponent, {
      type: 'centered',
      data: { type: 'user', id: userId }
    });
  }

  reviewTrade() {
    this.modalService.open(ReviewTradeModalComponent, {
      type: 'centered',
      data: {
        order: this.menuData.order,
        confirmed: () => {
          this.menuData.offer.detail.isReviewed = true;
          this.menuData.offer.detail.stateConfig = {
            ...this.menuData.offer.detail.stateConfig,
            buttons: [
              {
                action: 'reviewed',
                class: 'btn-secondary',
                link: false,
                disabled: true
              }
            ]
          };
        }
      }
    });
  }

  withdrawOffer() {
    this.openModal({ modalComponent: WithdrawOfferModalComponent, data: this.menuData.offer.detail.id });
  }

  deleteTrade() {
    this.openModal({ modalComponent: DeleteTradeModalComponent, data: this.menuData.offer.detail.id });
  }

  deleteItem() {
    const data = {
      itemId: this.menuData.item.detail.id,
      activeOffersCount: this.menuData.activeOffersCount,
      openedFrom: 'selling'
    };
    this.openModal({ modalComponent: DeleteItemModalComponent, data: data });
  }

  async shareItem() {
    this.menuData.item.detail.deepLink = await setItemDeepLink(this.menuData.item, this.coreService);
    this.modalService.open(ShareXModalComponent, {
      type: 'left',
      data: { type: 'item', item: this.menuData.item },
      needsAuth: false
    });
  }

  markAsSold() {
    this.openModal({ modalComponent: MarkAsSoldModalComponent, data: this.menuData.item.detail.id });
  }

  inviteFriends() {
    this.openModal({ modalComponent: InviteFriendsModalComponent, type: 'left' });
  }

  followUser(userId: string) {
    this.usersService.followUser(userId, this.authedUser.detail.id).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('followed');
          this.updateService.sendUpdateData({
            action: 'follow-user',
            updateKeyValue: true,
            updateId: userId,
            keyValueData: {
              key: 'isFollowed',
              value: true
            }
          });
          this.updateService.sendUpdateData({
            action: 'increment-following-count'
          });
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  unfollowUser(userId: string) {
    this.usersService.unfollowUser(userId, this.authedUser.detail.id).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('unfollowed');
          this.updateService.sendUpdateData({
            action: 'unfollow-user',
            updateKeyValue: true,
            updateId: userId,
            keyValueData: {
              key: 'isFollowed',
              value: false
            }
          });
          this.updateService.sendUpdateData({
            action: 'decrement-following-count'
          });
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  deleteConversation(conversationId: string) {
    this.openModal({
      modalComponent: DeleteConversationModalComponent,
      data: { messageId: conversationId, complete: () => this.router.navigate(['/messages']) }
    });
  }

  openModal({
    modalComponent,
    data = {},
    scrollableContent = false,
    type = 'generic'
  }: {
    modalComponent: any;
    data?: any;
    scrollableContent?: boolean;
    type?: ModalConfig['type'];
  }): void {
    this.modalService.open(modalComponent, {
      type: type,
      scrollableContent: scrollableContent,
      data: data
    });
  }

  logout() {
    this.modalService.open(SignOutModalComponent);
  }

  saveItem() {
    this.itemOverviewService.favouriteItem(this.menuData.item.detail.id).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('saved-item');
          this.updateService.sendUpdateData({
            action: 'save-item',
            updateId: this.menuData.item.detail.id
          });
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  unsaveItem() {
    this.itemOverviewService.unfavouriteItem(this.menuData.item.detail.id).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('unsaved-item');
          this.updateService.sendUpdateData({
            action: 'unsave-item',
            updateId: this.menuData.item.detail.id
          });
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  reportItem() {
    this.modalService.open(ReportXModalComponent, {
      type: 'centered',
      data: { type: 'item', id: this.menuData.item.detail.id }
    });
  }

  private filterTransactionHistory(filter: string) {
    this.updateService.sendUpdateData({ action: filter });
  }

  private markAllAsRead(contentType: 'notifications' | 'messages') {
    this.updateService.sendUpdateData({ action: `mark-all-${contentType}-as-read` });
  }

  private deleteAll(contentType: 'notifications' | 'messages') {
    this.openModal({
      modalComponent: DeleteAllNotificationsMessagesModalComponent,
      data: contentType
    });
  }
}
