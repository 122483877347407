<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>
      {{ 'modals.verified-badge.title-' + verifiedType | translate
      }}<img
        class="verified-badge"
        [src]="verification.verifiedBadge"
        alt="{{ 'users.' + verification.verifiedBadgeAlt | translate }}"
      />
    </h1>
  </div>

  <p class="modal-body">
    {{ 'modals.verified-badge.body-' + verifiedType | translate }}
  </p>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large btn-primary" (click)="dialogRef.close()">{{ 'buttons.okay' | translate }}</button>
  </div>
</div>
