import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { DisableFilterAction } from '@paperclip/models/search/DisableFilterAction';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';

interface StudentRoostLocationOption {
  option: string;
  isSelected: boolean;
}

@Component({
  selector: 'pc-search-sr-location-filter',
  templateUrl: './search-sr-location-filter.component.html',
  styleUrls: ['./search-sr-location-filter.component.scss']
})
export class SearchSRLocationFilterComponent implements OnChanges {
  @Input() searchFilters: SearchFilters;
  @Input() applyFilter: boolean;
  @Input() clearFilter: boolean;
  @Input() insideFiltersModal = false;
  @Output() disableFilterButton: EventEmitter<DisableFilterAction> = new EventEmitter<DisableFilterAction>();
  authedUser: AuthedUser = this.authService.getAuthedUser();
  srLocationOptions: StudentRoostLocationOption[] = [
    { option: 'all-roosts', isSelected: false },
    { option: 'my-roost', isSelected: false }
  ];
  selectedSrOption: string;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnChanges(searchFiltersChanges: SimpleChanges): void {
    if (searchFiltersChanges) {
      this.setSelectedOption();

      if (searchFiltersChanges.applyFilter?.currentValue) {
        this.applySRLocationFilter();
      }

      if (searchFiltersChanges.clearFilter?.currentValue) {
        this.clearSRLocationFilter();
      }
    }
  }

  private setSelectedOption() {
    if (this.searchFilters.studentRoostLocation === 'all') {
      this.srLocationOptions[0].isSelected = true;
      this.srLocationOptions[1].isSelected = false;
    } else if (this.searchFilters.studentRoostLocation != null) {
      this.srLocationOptions[0].isSelected = false;
      this.srLocationOptions[1].isSelected = true;
    } else {
      this.srLocationOptions[0].isSelected = false;
      this.srLocationOptions[1].isSelected = false;
    }
  }

  public selectSROption(srOption: StudentRoostLocationOption) {
    this.disableFilterButton.emit({ button: 'clear', disable: false });
    this.disableFilterButton.emit({ button: 'apply', disable: false });

    if (srOption.option === 'all-roosts') {
      srOption.isSelected = !srOption.isSelected;
      this.srLocationOptions[1].isSelected = false;
    } else if (srOption.option === 'my-roost') {
      srOption.isSelected = !srOption.isSelected;
      this.srLocationOptions[0].isSelected = false;
    }

    this.selectedSrOption = srOption.isSelected ? srOption.option : null;

    if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
      this.setFilterOnAllComponent();
    }
  }

  private applySRLocationFilter() {
    let srLocationFilter = this.selectedSrOption === 'all-roosts' ? 'all' : this.authedUser?.studentRoostLocation?.id;
    if (!this.selectedSrOption) {
      srLocationFilter = null;
    }
    this.router.navigate(['/search'], {
      queryParams: { studentRoostLocation: srLocationFilter },
      queryParamsHandling: 'merge'
    });
  }

  private clearSRLocationFilter() {
    this.router.navigate(['/search'], {
      queryParams: { studentRoostLocation: null },
      queryParamsHandling: 'merge'
    });
  }

  private setFilterOnAllComponent() {
    let srLocationFilter = this.selectedSrOption === 'all-roosts' ? 'all' : this.authedUser?.studentRoostLocation?.id;
    if (!this.selectedSrOption) {
      srLocationFilter = null;
    }
    this.searchFilters.studentRoostLocation = srLocationFilter;
  }
}
