import { Component } from '@angular/core';
import { AppConfig } from '@paperclip/core/app.config';

@Component({
  selector: 'pc-header-info-menu',
  templateUrl: './header-info-menu.component.html'
})
export class HeaderInfoMenuComponent {
  externalLinks = AppConfig.externalLinks;
}
