import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentsService } from '@paperclip/components/payments/payments.service';
import { AppConfig } from '@paperclip/core/app.config';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { PaymentInfoList } from '@paperclip/models/payments/payment-info-list';
import { PaymentAccountForm } from '@paperclip/models/payments/PaymentAccountForm';
import { PaymentAccountFormOutput } from '@paperclip/models/payments/PaymentAccountFormOutput';
import { UsersService } from '@paperclip/page/users.service';

@Component({
  selector: 'pc-payment-account-modal',
  templateUrl: './payment-account-modal.component.html',
  styleUrls: ['./payment-account-modal.component.scss']
})
export class PaymentAccountModalComponent implements OnInit {
  paymentAccountForm: PaymentAccountForm;
  paymentAccountFormValid: boolean;
  apiBirthDate: string;
  nationalitiesList: PaymentInfoList[];
  countriesList: PaymentInfoList[];
  activeRequest: boolean;
  externalLinks = AppConfig.externalLinks;

  constructor(
    public dialogRef: MatDialogRef<PaymentAccountModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { pov: string; complete: () => {}; closedWithoutComplete: () => {} },
    private usersService: UsersService,
    private paymentsService: PaymentsService,
    private coreService: CoreService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.nationalitiesList = await this.getNationalitiesList();
    this.countriesList = await this.getCountriesList();
  }

  private getNationalitiesList(): Promise<PaymentInfoList[]> {
    return new Promise((resolve, reject) => {
      this.paymentsService.getPaymentInfoList('nationalities').subscribe(
        ({ code, data, message }: PcApiResponse) => {
          if (code === 1) {
            resolve(data);
          } else {
            this.coreService.handleError({ code, message });
            reject();
          }
        },
        (error) => {
          reject();
          console.error('error:', error);
        }
      );
    });
  }

  private getCountriesList(): Promise<PaymentInfoList[]> {
    return new Promise((resolve, reject) => {
      this.paymentsService.getPaymentInfoList('countries').subscribe(
        ({ code, data, message }: PcApiResponse) => {
          if (code === 1) {
            resolve(data);
          } else {
            this.coreService.handleError({ code, message });
            reject();
          }
        },
        (error) => {
          reject();
          console.error('error:', error);
        }
      );
    });
  }

  public savePaymentInformation(paymentAccount: PaymentAccountForm) {
    this.activeRequest = true;
    // set nationality and countryOfResidence to shortcode values
    paymentAccount.nationality = this.nationalitiesList.filter(
      (nationality: PaymentInfoList) => nationality.name === paymentAccount.nationality
    )[0].shortcode;
    paymentAccount.countryOfResidence = this.countriesList.filter(
      (country: PaymentInfoList) => country.name === paymentAccount.countryOfResidence
    )[0].shortcode;

    // format date for api
    paymentAccount.birthDate = this.apiBirthDate;

    this.usersService.savePaymentAccount(paymentAccount).subscribe(
      ({ code, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.authService.updateAuthedUser({ detail: { mpUserCategory: 'OWNER' } });
          this.dialogRef.close();
          this.modalData.complete();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  public setPaymentAccountForm({ paymentAccountForm, isValid, apiBirthDate }: PaymentAccountFormOutput) {
    this.paymentAccountForm = paymentAccountForm;
    this.paymentAccountFormValid = isValid;
    this.apiBirthDate = apiBirthDate;
  }

  closedWithoutComplete() {
    this.modalData.closedWithoutComplete();
    this.dialogRef.close();
  }
}
