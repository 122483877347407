import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConfig } from '@paperclip/core/app.config';

@Component({
  selector: 'pc-steps-layout-header',
  templateUrl: './steps-layout-header.component.html',
  styleUrls: ['./steps-layout-header.component.scss']
})
export class StepsLayoutHeaderComponent {
  environmentLogo = AppConfig.app.logo;
  environmentName = AppConfig.app.name;
  environmentFriendlyName = AppConfig.app.friendlyName;
  @Input() title: string;
  @Input() stepNumber: number;
  @Input() totalSteps: number;
  @Input() showStepCount = true;
  @Input() showProgressBar = true;
  @Output() closeButtonPressed: EventEmitter<any> = new EventEmitter<any>();
}
