import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'pc-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {
  activeRequest = false;
  windowSize: string;
  windowSizeSubscription: Subscription;

  /*-- forms --*/
  resetPasswordForm: UntypedFormGroup = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ForgotPasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public email: string,
    private authService: AuthService,
    private confirmationMsgService: ConfirmationMessageService,
    private coreService: CoreService,
    private windowResizeService: WindowResizeService
  ) {
    this.windowSizeSubscription = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 750 ? 'mobile' : updatedWidth < 1200 ? 'tablet' : 'desktop';
    });

    // check if valid email address
    this.resetPasswordForm
      .get('email')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((emailAddress: string) => {
        // eslint-disable-next-line max-len
        const emailValidator =
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // check if the email is valid
        if (emailValidator.test(emailAddress)) {
          this.resetPasswordForm.get('email').setErrors(null);
        } else {
          this.resetPasswordForm.get('email').setErrors({ incorrect: true });
        }
      });
  }

  ngOnInit() {
    if (this.email) {
      this.resetPasswordForm.get('email').setValue(this.email);
      this.resetPasswordForm.get('email').updateValueAndValidity();
    }
  }

  resetPassword({ email }) {
    this.activeRequest = true;
    this.authService.resetPassword(email).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.confirmationMsgService.showConfirmationMessage('forgot-password');
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }
}
