import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardRegistration, CardRegistrationResponse } from '@paperclip/models/payments/card-registration';
import { PaymentAddress } from '@paperclip/models/payments/payment-address';
import { MangoPaymentCard } from '@paperclip/models/payments/payment-card';
import * as mangoPay from 'mangopay-cardregistration-js-kit';
import { Observable, Subject } from 'rxjs';
import { MangoPayService, MpTokenizationResult } from './mangopay.service';

export interface PaymentsAction {
  action: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private paymentsAction: Subject<PaymentsAction> = new Subject<PaymentsAction>();

  paymentsActionCompleted(action: PaymentsAction) {
    this.paymentsAction.next(action);
  }

  getPaymentsActionCompleted(): Observable<PaymentsAction> {
    return this.paymentsAction.asObservable();
  }

  constructor(private http: HttpClient, private mangoPayService: MangoPayService) {}

  addDeliveryAddress(deliveryAddress: PaymentAddress): Observable<any> {
    return this.http.post(`deliveryAddresses`, deliveryAddress);
  }

  editDeliveryAddress(deliveryAddress: PaymentAddress): Observable<any> {
    return this.http.post(`deliveryAddresses/${deliveryAddress.id}`, deliveryAddress);
  }

  deleteDeliveryAddress(deliveryAddressId: string): Observable<any> {
    return this.http.post(`deliveryAddresses/${deliveryAddressId}/delete`, {});
  }

  makeDeliveryAddressDefault(deliveryAddressId: string): Observable<any> {
    return this.http.post(`deliveryAddresses/${deliveryAddressId}/makeDefault`, {});
  }

  getDeliveryAddresses(): Observable<any> {
    return this.http.get(`deliveryAddresses`);
  }

  getPaymentCards(): Observable<any> {
    return this.http.get('paymentCards');
  }

  deletePaymentCard(paymentCardId: string): Observable<any> {
    return this.http.post(`paymentCards/${paymentCardId}/delete`, {});
  }

  makePaymentCardDefault(paymentCardId: string): Observable<any> {
    return this.http.post(`paymentCards/${paymentCardId}/makeDefault`, {});
  }

  getPaymentInfoList(list: string): Observable<any> {
    return this.http.get<any>(`lists/${list}`);
  }

  findAddresses(postCode: string): Observable<any> {
    return this.http.get(`findAddresses?postCode=${postCode}`);
  }

  registerPaymentCardStep1(cardRegistration: CardRegistration): Observable<any> {
    return this.http.post('cardRegistrations', cardRegistration);
  }

  registerPaymentCardGetToken(cardRegistrationResponse: CardRegistrationResponse, mangoPaymentCard: MangoPaymentCard) {
    return new Promise((resolve, reject) => {
      this.mangoPayService
          .tokenizeCard(mangoPaymentCard, cardRegistrationResponse)
          .then(resolve)
          .catch(reject);
    });
  }

  private errorCallback({ xmlhttp, ResultCode, ResultMessage }) {
    console.log('errorCallback', xmlhttp, ResultCode, ResultMessage);
  }

  registerPaymentCardStep3(cardRegistrationId: string, tokenizationResult: MpTokenizationResult): Observable<any> {
    return this.http.post(`cardRegistrations/${cardRegistrationId}`, {'mpCardId': tokenizationResult.mpCardId});
  }
}
