import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SearchState } from '@paperclip/models/search/SearchState';

@Component({
  selector: 'pc-search-filters-all',
  templateUrl: './search-filters-all.component.html',
  styleUrls: ['./search-filters-all.component.scss']
})
export class SearchFiltersAllComponent implements OnChanges {
  @Input() searchState: SearchState;
  @Input() insideFiltersModal = false;
  @Input() clearFilters: boolean;
  @Input() applyFilters: boolean;
  searchStateCopy: SearchState;

  constructor(private router: Router) {}

  ngOnChanges(searchFiltersAllChanges: SimpleChanges): void {
    if (searchFiltersAllChanges.searchState) {
      this.searchStateCopy = JSON.parse(JSON.stringify(this.searchState));
    }

    if (searchFiltersAllChanges.applyFilters?.currentValue) {
      this.applyAllFilters();
    }

    if (searchFiltersAllChanges.clearFilters?.currentValue) {
      this.clearAllFilters();
    }
  }

  public showFilter(filter: string): boolean {
    this.changeSearchType(this.searchStateCopy.filters.type);
    return this.searchStateCopy.filtersBar.left.includes(filter);
  }

  private changeSearchType(type: string) {
    const leftFilters = {
      items: ['search-type', 'location', 'item-category', 'item-price', 'item-condition'],
      users: ['search-type']
    };
    const rightFilters = {
      items: ['sort', 'view'],
      users: []
    };

    if (this.searchStateCopy.isStudentRoostUser) {
      leftFilters.items.splice(1, 0, 'student-roost-location');
    }

    const filtersBarLeft = leftFilters[type];

    this.searchStateCopy.filtersBar = {
      left: filtersBarLeft,
      right: rightFilters[type]
    };
  }

  public applyAllFilters() {
    this.router.navigate(['/search'], {
      queryParams: {
        type: this.searchStateCopy.filters.type,
        useCurrentLocation: this.searchStateCopy.filters.useCurrentLocation,
        latitude: this.searchStateCopy.filters.latitude,
        longitude: this.searchStateCopy.filters.longitude,
        distance: this.searchStateCopy.filters.distance,
        location: this.searchStateCopy.filters.location,
        categoryId: this.searchStateCopy.filters.categoryId,
        priceMin: String(this.searchStateCopy.filters.priceMin).replace(',', '') || null,
        priceMax: String(this.searchStateCopy.filters.priceMax).replace(',', '') || null,
        conditionIds: this.searchStateCopy.filters.conditionIds,
        isPrivate: this.searchStateCopy.filters.isPrivate,
        studentRoostLocation: this.searchStateCopy.filters.studentRoostLocation
      },
      queryParamsHandling: 'merge'
    });
  }

  public clearAllFilters() {
    this.router.navigate(['/search'], {
      queryParams: { term: this.searchState.filters.term, type: this.searchState.filters.type }
    });
  }
}
