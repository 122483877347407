<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>

  <!-- item doesn't have any active offers -->
  <ng-container *ngIf="modalData.activeOffersCount === 0">
    <div class="modal-header">
      <h1>{{ 'modals.delete-item.title' | translate }}</h1>
    </div>

    <p class="modal-body">
      {{ 'modals.delete-item.body' | translate }}
    </p>

    <div class="modal-footer multi-btn-container">
      <button class="btn-large" (click)="dialogRef.close()">{{ 'buttons.cancel' | translate }}</button>
      <button class="btn-large btn-primary" (click)="deleteItem()" [ngClass]="{ 'btn-loading': activeRequest }">
        {{ 'modals.delete-item.delete-item' | translate }}
      </button>
    </div>
  </ng-container>

  <!-- item has active offers, warn user -->
  <ng-container *ngIf="modalData.activeOffersCount > 0">
    <div class="modal-header">
      <h1>{{ 'modals.delete-item-with-offers.title' | translate }}</h1>
    </div>

    <p class="modal-body">
      {{ 'modals.delete-item-with-offers.body' | translate }}
    </p>

    <div class="modal-footer multi-btn-container">
      <button class="btn-large" (click)="deleteItem()" [ngClass]="{ 'btn-loading': activeRequest }">
        {{ 'modals.delete-item.delete-item' | translate }}
      </button>
      <a
        class="btn btn-large btn-primary"
        [routerLink]="['/offers']"
        [queryParams]="{ itemId: modalData.itemId, state: 'new' }"
      >
        {{ 'modals.delete-item-with-offers.view-offers' | translate }}
      </a>
    </div>
  </ng-container>
</div>
