<div class="modal">
  <pc-close-icon (click)="closeModal()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.review-trade.title' | translate }}</h1>
    <p>{{ 'modals.review-trade.subtitle' | translate }}</p>
  </div>

  <form class="modal-body" [formGroup]="reviewForm">
    <!-- item -->
    <div class="review-item">
      <pc-small-item [item]="modalData.order.oldItemModel" metaDetail="price"></pc-small-item>
    </div>

    <!-- choose rating -->
    <ul class="review-stars">
      <li
        *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
        class="review-stars__star review-stars__star--{{ i + 1 }}"
        (click)="setReviewRating(i + 1)"
        (mouseover)="mouseoverStarRating(i + 1)"
        (mouseleave)="mouseleaveStarRating()"
      >
        <pc-review-star-icon [hover]="i < hoverStarRating" [selected]="i < reviewRating"> </pc-review-star-icon>
      </li>
    </ul>

    <!-- review text -->
    <div class="review-text">
      <textarea
        formControlName="reviewText"
        placeholder="{{ 'modals.review-trade.text-placeholder' | translate }}"
      ></textarea>
      <pc-character-counter [currentCount]="reviewForm.get('reviewText').value.length" [countLimit]="500">
      </pc-character-counter>
    </div>
  </form>

  <div class="modal-footer">
    <button
      class="btn-large btn-large--fit-content btn-primary"
      (click)="addReview(reviewForm.value)"
      [ngClass]="{ 'btn-loading': activeRequest }"
      [disabled]="reviewForm.invalid"
    >
      {{ 'modals.review-trade.button' | translate }}
    </button>
  </div>
</div>
