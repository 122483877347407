import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '@paperclip/core/core.service';
import { environment } from '@paperclip/environments/environment';
import * as formatDate from '@paperclip/functions/format-date';
import { DeepLinkData } from 'branch-sdk';
import moment from 'moment';

import { StudentRoostLocation } from './user/StudentRoostLocation';

export interface ApiUser {
  created?: string;
  userId?: string;
  id?: string;
  senderId?: string;
  senderName?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  senderFirstName?: string;
  senderLastName?: string;
  userFirstName?: string;
  userLastName?: string;
  email?: string;
  pictureUrl?: string;
  senderPictureUrl?: string;
  userPictureUrl?: string;
  senderImageUrl?: string;
  thumbnail?: string;
  username?: string;
  nickname?: string;
  senderUsername?: string;
  userNickname?: string;
  locationName?: string;
  senderLocation?: string;
  location?: {
    latitude?: number;
    longitude?: number;
    name?: string;
  };
  phone?: string;
  starRating?: number;
  reviewsCount?: number;
  isFollowed?: boolean;
  isAdmin?: boolean;
  isOwner?: boolean;
  isFriend?: boolean;
  deleted?: boolean;
  followingCount?: number;
  followersCount?: number;
  verifiedbyfacebook?: boolean;
  verifiedbyemail?: boolean;
  verifiedbyphone?: boolean;
  studentRoostLocation?: StudentRoostLocation;
  privateSocialInventory?: boolean;
  forSaleItemsCount?: number;
  socialInventoryItemsCount?: number;
  soldItemsCount?: number;
  socialInventoryTooltipSeen?: boolean;
  bio?: string;
  referralCodeUsed?: string;
  suspended?: boolean;
  verified?: boolean;
  official?: boolean;
}

export class User {
  detail: {
    id?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    username?: string;
    email?: string;
    bio?: string;
    picture?: string;
    location?: string;
    phoneNumber?: string;
    accountLocation?: {
      name: string;
      latitude: number;
      longitude: number;
    };
    isFollowed?: boolean;
    isFriend?: boolean;
    starRating?: string[];
    reviewsCount?: number;
    followingCount?: number;
    followersCount?: number;
    verifications?: {
      facebook: boolean;
      email: boolean;
      phone: boolean;
    };
    verificationIcons?: string[];
    memberSince?: string;
    favouriteCategories?: any;
    privateSocialInventory?: boolean;
    itemCounts?: {
      forSale: number;
      socialInventory: number;
      sold: number;
    };
    socialInventoryTooltipSeen?: boolean;
    accountAgeInDays?: number;
    referralCodeUsed?: string;
    isSuspended?: boolean;
    verifiedBadge?: string;
    verifiedBadgeAlt?: string;
  };
  studentRoostLocation?: StudentRoostLocation;
  isSelected?: boolean;
  deleted?: boolean;

  constructor(user: ApiUser, translateService?: any) {
    this.detail = {
      id: user.userId || user.senderId || user.id,
      firstName: user.firstName || user.senderFirstName || user.userFirstName || '',
      lastName: user.lastName || user.senderLastName || user.userLastName || '',
      username: user.nickname || user.username || user.senderUsername || user.userNickname,
      bio: user.bio,
      email: user.email,
      picture: user.pictureUrl || user.senderPictureUrl || user.userPictureUrl || user.senderImageUrl || user.thumbnail,
      location: user.locationName || user.senderLocation || user.location?.name,
      accountLocation: user.location
        ? {
            name: user.location.name,
            latitude: user.location.latitude,
            longitude: user.location.longitude
          }
        : null,
      phoneNumber: user.phone,
      isFollowed: user.isFollowed,
      isFriend: user.isFriend,
      starRating: user.starRating !== undefined ? setStarRating(user.starRating) : [],
      reviewsCount: user.reviewsCount,
      followingCount: user.followingCount,
      followersCount: user.followersCount,
      verifications: {
        facebook: user.verifiedbyfacebook,
        email: user.verifiedbyemail,
        phone: user.verifiedbyphone
      },
      verificationIcons: [],
      memberSince: translateService
        ? formatDate.formatSingleDateByLocale(user.created, translateService.getBrowserCultureLang())
        : null,
      privateSocialInventory: user.privateSocialInventory,
      itemCounts: {
        forSale: user.forSaleItemsCount || null,
        socialInventory: user.socialInventoryItemsCount || null,
        sold: user.soldItemsCount || null
      },
      socialInventoryTooltipSeen: user.socialInventoryTooltipSeen,
      accountAgeInDays: accountAgeInDays(user.created),
      referralCodeUsed: user.referralCodeUsed || null,
      isSuspended: user.suspended,
      verifiedBadge:
        user.verified && !user.official
          ? 'assets/icons/profile/verified-user.svg'
          : user.verified && user.official
          ? 'assets/icons/profile/verified-paperclip.svg'
          : null,
      verifiedBadgeAlt:
        user.verified && !user.official ? 'verified-user' : user.verified && user.official ? 'verified-official' : ''
    };

    this.detail.fullName = `${this.detail.firstName} ${this.detail.lastName}`;
    this.studentRoostLocation = user.studentRoostLocation
      ? {
          id: user.studentRoostLocation.id,
          city: user.studentRoostLocation.city,
          property: user.studentRoostLocation.property,
          fullLocationName: `${user.studentRoostLocation.city}, ${user.studentRoostLocation.property}`
        }
      : null;
    this.deleted = user.deleted || null;

    Object.keys(this.detail.verifications).forEach((key: any) => {
      if (this.detail.verifications[key] === true) {
        this.detail.verificationIcons.push(key);
      }
    });
  }
}

function accountAgeInDays(created: string): number {
  const dataDate = moment(created, 'YYYYMMDD');
  const currentDate = moment(new Date(), 'YYYYMMDD');
  const dateDifference = currentDate.diff(dataDate, 'days');
  return dateDifference;
}

function setStarRating(starRating: number): string[] {
  if (starRating !== undefined) {
    const reviewStars = [];
    for (let x = 0; x < starRating; x++) {
      reviewStars.push('filled');
    }
    for (let i = starRating; i < 5; i++) {
      reviewStars.push('empty');
    }
    return reviewStars;
  }
}

export async function setUserReferralDeepLink(
  translateService: TranslateService,
  coreService: CoreService
): Promise<string> {
  const referralUrl = environment.production
    ? `https://marketplace.paperclip.co`
    : `https://marketplace.papercliptesting.co.uk`;
  const branchDeepLinkOptions: DeepLinkData = {
    feature: 'referral',
    campaign: 'user-share',
    data: {
      $desktop_url: referralUrl,
      $og_title: translateService.instant('meta.tagline'),
      $og_description: `Check out Paperclip, it is a great way to make cash selling stuff you don’t use anymore!`,
      $og_image_url: 'https://marketplace.paperclip.co/assets/app-icon.png',
      $og_url: referralUrl
    }
  };

  return await coreService.generateBranchDeepLink(branchDeepLinkOptions, false);
}
