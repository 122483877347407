import { AddItemConfirmationModalComponent } from './add-item-confirmation-modal/add-item-confirmation-modal.component';
import { AddItemImagesComponent } from './add-item-images-modal/add-item-images-modal.component';
import { CancelItemCheckoutModalComponent } from './cancel-item-checkout-modal/cancel-item-checkout-modal.component';
import { CancelMakeOfferModalComponent } from './cancel-make-offer-modal/cancel-make-offer-modal.component';
import { DeleteItemModalComponent } from './delete-item-modal/delete-item-modal.component';
import { ItemImageViewerModalComponent } from './item-image-viewer-modal/item-image-viewer-modal.component';
import { MarkAsSoldModalComponent } from './mark-as-sold-modal/mark-as-sold-modal.component';

export * from './add-item-confirmation-modal/add-item-confirmation-modal.component';
export * from './add-item-images-modal/add-item-images-modal.component';
export * from './cancel-item-checkout-modal/cancel-item-checkout-modal.component';
export * from './cancel-make-offer-modal/cancel-make-offer-modal.component';
export * from './delete-item-modal/delete-item-modal.component';
export * from './item-image-viewer-modal/item-image-viewer-modal.component';
export * from './mark-as-sold-modal/mark-as-sold-modal.component';

export const itemModals = [
  AddItemConfirmationModalComponent,
  AddItemImagesComponent,
  CancelItemCheckoutModalComponent,
  CancelMakeOfferModalComponent,
  DeleteItemModalComponent,
  MarkAsSoldModalComponent,
  ItemImageViewerModalComponent
];
