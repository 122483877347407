<form [formGroup]="addItemForm">
  <div formGroupName="itemDetails">
    <textarea
      class="input"
      type="text"
      formControlName="itemDescription"
      placeholder="{{ 'add-item.review-listing.description-placeholder' | translate }}"
      [value]="addItemForm.get('itemDetails').get('itemDescription').value"
    ></textarea>
    <pc-character-counter
      [currentCount]="addItemForm.get('itemDetails').get('itemDescription').value?.length"
      [countLimit]="500"
    >
    </pc-character-counter>
  </div>
</form>
