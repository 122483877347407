import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowResizeService {
  private windowWidth = new BehaviorSubject<number>(window.innerWidth);
  private windowHeight = new BehaviorSubject<number>(window.innerHeight);

  windowWidthDidChange(): Observable<number> {
    return this.windowWidth.asObservable();
  }

  windowHeightDidChange(): Observable<number> {
    return this.windowHeight.asObservable();
  }

  private updateWindowSizes(updatedWidth: number, updatedHeight: number) {
    const previousWindowWidth = this.windowWidth.value;
    const previousWindowHeight = this.windowHeight.value;

    if (previousWindowWidth !== updatedWidth) {
      this.windowWidth.next(updatedWidth);
    }

    if (previousWindowHeight !== updatedHeight) {
      this.windowHeight.next(updatedHeight);
    }
  }

  constructor() {
    fromEvent(window, 'resize').subscribe(() => {
      this.updateWindowSizes(window.innerWidth, window.innerHeight);
    });
  }
}
