/* eslint-disable @typescript-eslint/ban-types */
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { interval, Subscription } from 'rxjs';

import { PayinResult } from '../../../../models/payments/PayinResult';
import { Payment3dsModalService } from './payment-3ds-modal.service';

@Component({
  selector: 'pc-payment-3ds-modal',
  templateUrl: './payment-3ds-modal.component.html'
})
export class Payment3dsModalComponent implements OnInit, OnDestroy {
  public urlSafe: SafeResourceUrl;
  completed: boolean;
  payinStatusIntervalSub: Subscription;
  @ViewChild('iFrame', { static: true }) iFrame: ElementRef;

  constructor(
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<Payment3dsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: {
      secureModeStatusUrl: string;
      iframeSrc: string;
      paymentCompleted: () => {};
      paymentFailed: (payinResult: PayinResult) => {};
    },
    private payment3dsModalService: Payment3dsModalService
  ) {}

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalData.iframeSrc);
    this.payinStatusIntervalSub = interval(2000).subscribe(() => this.getPayinStatus());
  }

  ngOnDestroy(): void {
    this.payinStatusIntervalSub.unsubscribe();
  }

  private getPayinStatus() {
    this.payment3dsModalService.getTransactionStatus(this.modalData.secureModeStatusUrl).subscribe(({ data }) => {
      const payinResult: PayinResult = data;

      if (payinResult.state === 'paid') {
        this.modalData.paymentCompleted();
        this.dialogRef.close();
      } else if (payinResult.state === 'payment-failed') {
        this.modalData.paymentFailed(payinResult);
        this.dialogRef.close();
      }
    });
  }
}
