<div pcClickStopPropagation class="buttons-filter">
  <!-- parent categories -->
  <p>{{ 'search.filters.category.level-1' | translate }}</p>
  <ng-container *ngIf="!parentCategory">
    <button pcClickStopPropagation *ngFor="let category of itemCategories" (click)="selectCategory(category)">
      {{ category.name }}
    </button>
  </ng-container>
  <ng-container *ngIf="parentCategory">
    <button pcClickStopPropagation class="btn-large btn-large--icon btn-stroked" (click)="clearParentCategory()">
      {{ parentCategory.name }}
      <pc-clear-input-btn></pc-clear-input-btn>
    </button>
  </ng-container>

  <!-- child categories -->
  <p *ngIf="parentCategory && parentCategoryChildCategories.length > 0">
    {{ 'search.filters.category.level-2' | translate }}
  </p>
  <ng-container *ngIf="!childCategory">
    <button
      pcClickStopPropagation
      *ngFor="let category of parentCategoryChildCategories"
      (click)="selectCategory(category)"
    >
      {{ category.name }}
    </button>
  </ng-container>
  <ng-container *ngIf="childCategory">
    <button pcClickStopPropagation class="btn-large btn-large--icon btn-stroked" (click)="clearChildCategory()">
      {{ childCategory.name }}
      <pc-clear-input-btn></pc-clear-input-btn>
    </button>
  </ng-container>

  <!-- grandchild categories -->
  <p *ngIf="childCategory && childCategoryChildCategories.length > 0">
    {{ 'search.filters.category.level-3' | translate }}
  </p>
  <ng-container *ngIf="!grandChildCategory">
    <button
      pcClickStopPropagation
      *ngFor="let category of childCategoryChildCategories"
      (click)="selectCategory(category)"
    >
      {{ category.name }}
    </button>
  </ng-container>
  <ng-container *ngIf="grandChildCategory">
    <button pcClickStopPropagation class="btn-large btn-large--icon btn-stroked" (click)="clearGrandChildCategory()">
      {{ grandChildCategory.name }}
      <pc-clear-input-btn></pc-clear-input-btn>
    </button>
  </ng-container>
</div>
