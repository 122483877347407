import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
import { HttpCancelService } from '@paperclip/core/http-cancel.service';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { Item } from '@paperclip/models/item';
import { ApiItem } from '@paperclip/models/item/ApiItem';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { GridViewNewService } from './grid-view-new.service';

export interface GridViewConfig {
  type?: string;
  dataToGet?: string;
  gridData?: {
    userProfileId?: string;
  };
  loadingItems?: any[];
  dataLoading?: boolean;
  dataLoadingMore?: boolean;
  dataLoadingMoreSkip?: number;
  allDataLoaded?: boolean;
}

@Component({
  selector: 'pc-grid-view-new',
  templateUrl: './grid-view-new.component.html'
})
export class GridViewNewComponent implements OnChanges, OnDestroy {
  @Input() gridViewConfig: GridViewConfig;
  items: Item[];
  itemsBeforeSearchCount: number;
  isOwnProfile: boolean;
  windowSize = window.innerWidth < 1200 ? 'mobile' : 'desktop';
  windowWidthResizeSubscription: Subscription;
  navigationSubscription: Subscription;
  searchInputRandomName = 'fugoogle';
  searchItemsForm: UntypedFormGroup = this.formBuilder.group({ term: '' });
  searchItemsFormSubscription: Subscription;
  searchItemsFormFocus: boolean;
  searchingItems: boolean;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private gridViewNewService: GridViewNewService,
    private authService: AuthService,
    private translateService: TranslateService,
    private coreService: CoreService,
    private windowResizeService: WindowResizeService,
    private httpCancelService: HttpCancelService
  ) {
    this.windowWidthResizeSubscription = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 1200 ? 'mobile' : 'desktop';
    });

    // todo: investigate & expand this functionality in future
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.httpCancelService.cancelPendingRequests();
        this.searchItemsForm.reset();
        this.searchingItems = false;
      }
    });

    this.searchItemsFormSubscription = this.searchItemsForm.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(({ term }: { term: string }) => {
        this.randomiseInputName();
        if (this.isOwnProfile) {
          // this.searchItems(null, term);
        }
      });
  }

  ngOnDestroy(): void {
    this.windowWidthResizeSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
    this.searchItemsFormSubscription.unsubscribe();
    this.searchingItems = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.gridViewConfig = {
      ...this.gridViewConfig,
      dataLoading: false,
      dataLoadingMoreSkip: 0
    };
    this.gridViewConfig.loadingItems = Array(8)
      .fill(1)
      .map((x, i) => i);

    if (this.gridViewConfig.gridData?.userProfileId) {
      this.isOwnProfile = this.authService.getAuthedUser()?.detail?.id === this.gridViewConfig.gridData.userProfileId;
    }

    this.gridViewConfig.dataToGet =
      this.isOwnProfile && this.gridViewConfig.dataToGet === 'profile-items'
        ? `${this.gridViewConfig.dataToGet}-own`
        : this.gridViewConfig.dataToGet;
    this.getGridData();
  }

  public getGridData(loadMore = false) {
    if (!this.gridViewConfig.allDataLoaded && !this.gridViewConfig.dataLoading) {
      this.gridViewConfig.dataLoading = !loadMore;
      if (loadMore) {
        this.gridViewConfig.dataLoadingMoreSkip += 30;
        this.gridViewConfig.dataLoadingMore = true;
      } else {
        this.items = [];
        this.gridViewConfig.dataLoadingMoreSkip = 0;
        this.gridViewConfig.dataLoading = true;
        this.gridViewConfig.dataLoadingMore = false;
      }

      const grids: { name: string; requireData?: any; method: () => any }[] = [
        { name: 'profile-items', requireData: 'userProfileId', method: () => this.getUserProfileItems() },
        { name: 'profile-inactive', requireData: 'userProfileId', method: () => this.getUsersSocialInventory() },
        { name: 'profile-favourites', requireData: 'userProfileId', method: () => this.getUserProfileItems() },
        { name: 'profile-sold', requireData: 'userProfileId', method: () => this.getUserProfileItems() }
      ];

      const gridToLoad =
        grids.find((grid: { name: string; method: () => any }) => grid.name === this.gridViewConfig.dataToGet) ||
        grids[0];

      if (!gridToLoad.requireData) {
        gridToLoad.method();
      } else if (this.gridViewConfig.gridData[gridToLoad.requireData]) {
        gridToLoad.method();
      }
    }
  }

  private getUserProfileItems(term: string = null) {
    this.gridViewNewService
      .getUserProfileItems(
        this.gridViewConfig.dataToGet,
        this.gridViewConfig.gridData.userProfileId,
        this.gridViewConfig.dataLoadingMoreSkip,
        term
      )
      .subscribe(
        ({ code, data, message }: PcApiResponse) => {
          if (code === 1) {
            data = this.gridViewConfig.dataToGet.includes('profile-items') ? data.items : data;
            this.modelItems(data);
          } else {
            this.coreService.handleError({ code, message });
          }
        },
        () => this.coreService.handleError({})
      );
  }

  private getUsersSocialInventory(term: string = null) {
    this.gridViewNewService
      .getUsersSocialInventory(
        this.gridViewConfig.gridData.userProfileId,
        this.gridViewConfig.dataLoadingMoreSkip,
        term
      )
      .subscribe(
        ({ code, data, message }: PcApiResponse) => {
          if (code === 1) {
            this.gridViewNewService.sendShowSiOnetimeTooltip(data.length !== 0);
            this.modelItems(data);
          } else {
            this.coreService.handleError({ code, message });
          }
        },
        () => this.coreService.handleError({})
      );
  }

  private modelItems(data: any) {
    this.gridViewConfig.dataLoading = false;
    const items: Item[] = [];
    switch (this.gridViewConfig.type) {
      case 'deals':
        break;

      case 'items':
      default:
        if (data.length > 0) {
          data.forEach((item: ApiItem) =>
            items.push(
              new Item(
                item,
                this.authService.getAuthedUser().detail?.id || '',
                this.translateService.getBrowserCultureLang(),
                this.isOwnProfile ? 'ownItem' : null
              )
            )
          );
        }
        break;
    }

    this.items = this.gridViewConfig.dataLoadingMore ? [...this.items, ...items] : items;
    this.gridViewConfig.allDataLoaded = items.length < 30;
    this.gridViewConfig.dataLoadingMore = false;

    if (!this.searchItemsForm.get('term').value) {
      this.itemsBeforeSearchCount = this.items.length;
    }
  }

  public showEmptyState(): boolean {
    if (this.gridViewConfig.dataLoading) {
      return false;
    }

    return !this.gridViewConfig.dataLoading && this.items.length === 0;
  }

  public gridItemLink(): boolean {
    if (this.gridViewConfig.dataToGet === 'profile-inactive' && !this.isOwnProfile) {
      return false;
    }

    return true;
  }

  public showUserProfileItemsSearch(): boolean {
    if (
      this.gridViewConfig.dataToGet.includes('profile-items') ||
      this.gridViewConfig.dataToGet.includes('profile-inactive')
    ) {
      if (this.itemsBeforeSearchCount > 4) {
        return true;
      }
    }

    return false;
  }

  public searchItems(event: any, term: string) {
    event?.preventDefault();
    this.searchingItems = true;

    if (this.isOwnProfile) {
      this.gridViewConfig.dataLoading = true;
      this.gridViewConfig.dataToGet.includes('profile-items')
        ? this.getUserProfileItems(term)
        : this.getUsersSocialInventory(term);
    } else {
      this.router.navigate(['/search'], {
        queryParams: {
          type: 'items',
          term: term,
          sellerId: this.gridViewConfig.gridData.userProfileId,
          inactive: this.gridViewConfig.dataToGet.includes('profile-inactive')
        }
      });
    }
  }

  public clearItemsSearch() {
    this.gridViewConfig.dataLoading = true;
    this.searchItemsForm.get('term').patchValue('');
    this.searchItems(null, '');
    this.searchingItems = false;
  }

  // randomise the input name because of fucking google
  // https://bugs.chromium.org/p/chromium/issues/detail?id=587466
  private randomiseInputName() {
    let randomString = '';
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      randomString += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    this.searchInputRandomName = randomString;
  }
}
