import { relativeDate } from '@paperclip/functions/format-date';
import { PaymentInfoList } from '@paperclip/models/payments/payment-info-list';

import { ApiPaymentAccount } from '../payments/ApiPaymentAccount';

export class PaymentAccount {
  firstName: string;
  lastName: string;
  fullName: string;
  birthDate: string;
  nationality: string;
  countryOfResidence: string;
  identityVerificationState: 'PENDING' | 'VERIFIED' | 'REFUSED' | 'NONE';
  identityRefusedReason: string;
  userCategory: 'PAYER' | 'OWNER' | 'UNKNOWN';

  constructor(
    apiPaymentAccount: ApiPaymentAccount,
    nationalitiesList: PaymentInfoList[],
    countriesList: PaymentInfoList[],
    locale = 'en-GB'
  ) {
    this.firstName = apiPaymentAccount.firstName;
    this.lastName = apiPaymentAccount.lastName;
    this.fullName = `${apiPaymentAccount.firstName} ${apiPaymentAccount.lastName}`;
    this.birthDate = relativeDate(apiPaymentAccount.birthDate, locale, 'L', true, false);
    this.nationality = nationalitiesList.find(
      (nationality: PaymentInfoList) => nationality.shortcode === apiPaymentAccount.nationality
    ).name;
    this.countryOfResidence = countriesList.find(
      (country: PaymentInfoList) => country.shortcode === apiPaymentAccount.countryOfResidence
    ).name;
    this.identityVerificationState = apiPaymentAccount.kycStatusIdentityProof;
    this.identityRefusedReason = apiPaymentAccount.kycStatusIdentityProofRefusedReasonMessage;
    this.userCategory = apiPaymentAccount.userCategory;
  }
}
