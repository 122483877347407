import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CoreService } from '@paperclip/core/core.service';
import { ModalService } from '@paperclip/modals/modal.service';
import { ShareXModalComponent } from '@paperclip/modals/shared/shared-modals';
import { Item, setItemDeepLink } from '@paperclip/models/item';

@Component({
  selector: 'pc-add-item-confirmation-modal',
  templateUrl: './add-item-confirmation-modal.component.html',
  styleUrls: ['./add-item-confirmation-modal.component.scss']
})
export class AddItemConfirmationModalComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<AddItemConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public item: Item,
    private modalService: ModalService,
    private coreService: CoreService
  ) {}

  public async shareItem() {
    this.item.detail.deepLink = await setItemDeepLink(this.item, this.coreService);
    this.modalService.open(ShareXModalComponent, {
      type: 'left',
      data: { type: 'item', item: this.item, showTags: false },
      needsAuth: false
    });
  }

  public addAnother() {
    window.location.reload();
  }

  public closeModal() {
    this.dialogRef.close();
    this.router.navigate(['/item', this.item.detail.id]);
  }
}
