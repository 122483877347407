import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { User } from '@paperclip/models/user';

@Component({
  selector: 'pc-user-list-menu',
  templateUrl: './user-list-menu.component.html'
})
export class UserListMenuComponent {
  @Input() menuData: {
    user: User;
    userId: string;
    loggedInUserId: string;
    isFollowed: boolean;
  };
  @Input() menuOpened: boolean;
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
}
