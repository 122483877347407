<div class="modal claim-deal-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.claim-deal.title' | translate }}</h1>
    <p>{{ 'modals.claim-deal.body' | translate }}</p>
  </div>

  <div class="modal-body">
    <input type="text" [value]="modalData.dealCode" readonly />
    <button
      class="btn-large btn-secondary"
      [ngxClipboard]
      [cbContent]="modalData.dealUrl"
      (cbOnSuccess)="copyDealCode()"
    >
      {{ 'modals.claim-deal.copy' | translate }}
    </button>
  </div>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large btn-primary" (click)="claimDeal()">
      {{ 'modals.claim-deal.go-to-deal' | translate }}
    </button>
  </div>
</div>
