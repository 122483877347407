<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.dispute-trade.title' | translate }}</h1>
    <p>{{ 'modals.dispute-trade.subtitle' | translate }}</p>
  </div>

  <form class="modal-body" [formGroup]="disputeForm">
    <!-- item -->
    <div class="dispute-item">
      <pc-small-item [item]="modalData.order.oldItemModel" metaDetail="price"></pc-small-item>
    </div>

    <!-- review text -->
    <div class="dispute-text">
      <textarea
        formControlName="disputeText"
        placeholder="{{ 'modals.dispute-trade.text-placeholder' | translate }}"
        required
      ></textarea>
      <pc-character-counter [currentCount]="disputeForm.get('disputeText').value.length" [countLimit]="500">
      </pc-character-counter>
    </div>
  </form>

  <div class="modal-footer">
    <button
      class="btn-large btn-large--fit-content btn-primary"
      (click)="disputeOrder()"
      [ngClass]="{ 'btn-loading': activeRequest }"
      [disabled]="disputeForm.invalid"
    >
      {{ 'modals.dispute-trade.button' | translate }}
    </button>
  </div>
</div>
