import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedModule } from '@paperclip/shared/shared.module';

import { ErrorBannerComponent } from './errors/error-banner/error-banner.component';
// interceptors
import { AddApiUrlInterceptor } from './interceptors/add-api-url.interceptor';
import { AddHeadersInterceptor } from './interceptors/add-headers.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { CancelHttpInterceptor } from './interceptors/cancel-http.interceptor';
import { LogResponseInterceptor } from './interceptors/log-response.interceptor';
import { PostHeadersInterceptor } from './interceptors/post-headers.interceptor';

@NgModule({
  imports: [SharedModule],
  declarations: [ErrorBannerComponent],
  exports: [ErrorBannerComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AddApiUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AddHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PostHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CancelHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogResponseInterceptor, multi: true }
  ]
})
export class CoreModule {}
