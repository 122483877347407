<form
  [formGroup]="resetPasswordForm"
  (ngSubmit)="resetPassword(resetPasswordForm.value)"
  class="forgotten-password modal"
>
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>

  <div class="modal-header">
    <h1>{{ 'modals.forgot-password.title' | translate }}</h1>
  </div>

  <p class="forgotten-password__text">{{ 'modals.forgot-password.body' | translate }}</p>

  <div class="forgotten-password__input">
    <div class="input-group input-group--icon-start input-group--label-end">
      <img class="input-group__icon" src="/assets/icons/modals/auth/email.svg" alt="" />
      <input
        formControlName="email"
        type="email"
        placeholder="{{ 'auth-signup.normal.email-address' | translate }}"
        [ngClass]="{
          'input-valid': resetPasswordForm.get('email').valid && resetPasswordForm.get('email').dirty,
          'input-invalid': resetPasswordForm.get('email').invalid && resetPasswordForm.get('email').dirty
        }"
        required
      />
      <div *ngIf="resetPasswordForm.get('email').dirty" class="input-group__label input-group__label--end">
        <img *ngIf="resetPasswordForm.get('email').valid" src="/assets/icons/forms/input-valid.svg" />
        <img *ngIf="resetPasswordForm.get('email').invalid" src="/assets/icons/forms/input-invalid.svg" />
      </div>
    </div>
    <button
      type="submit"
      class="btn-large btn-primary"
      [disabled]="!resetPasswordForm.valid"
      [ngClass]="{ 'btn-loading': activeRequest }"
    >
      {{ 'buttons.send' | translate }}
    </button>
  </div>

  <div
    *ngIf="resetPasswordForm.get('email').dirty && resetPasswordForm.get('email').invalid"
    class="validation-error-message"
  >
    <p *ngIf="resetPasswordForm.get('email').invalid">
      {{ 'validation-error.email-invalid' | translate }}
    </p>
  </div>

  <div class="forgotten-password__footer multi-btn-container">
    <button
      type="submit"
      class="btn-large btn-primary"
      [disabled]="!resetPasswordForm.valid"
      [ngClass]="{ 'btn-loading': activeRequest }"
    >
      {{ 'modals.forgot-password.button' | translate }}
    </button>
  </div>
</form>
