import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MessagesService } from '@paperclip/page/messages.service';

@Component({
  selector: 'pc-conversation-actions-bs',
  templateUrl: './conversation-actions-bs.component.html'
})
export class ConversationActionsBsComponent {
  constructor(
    public bottomSheetRef: MatBottomSheetRef<ConversationActionsBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public modalData: any,
    private messagesService: MessagesService
  ) {}

  public messageAction(action: string) {
    this.messagesService.sendBottomSheetAction(action);
    this.bottomSheetRef.dismiss();
  }
}
