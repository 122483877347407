<div class="modal select-paperclip-credit">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.select-paperclip-credit.title' | translate }}</h1>
    <p>
      {{
        'modals.select-paperclip-credit.subtitle'
          | translate
            : {
                userCurrency: modalData.itemCheckout.userCurrency,
                availableCredit: modalData.itemCheckout.availablePaperclipCredit.toFixed(2)
              }
      }}
    </p>
  </div>

  <div class="modal-body">
    <div class="data-list">
      <ul
        class="checkbox-list scrollable-content"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300"
        [scrollWindow]="false"
        (scrolled)="loadMoreUserPaperclipCredit()"
      >
        <li
          *ngFor="let credit of modalData.itemCheckout.userAvailableCredits"
          (click)="selectCredit(credit)"
          class="checkbox-list__item"
          [ngClass]="{ 'checkbox-list__item--disabled': credit.disableSelection }"
        >
          <div>
            <p class="colour-secondary">{{ modalData.itemCheckout.userCurrency }}{{ credit.valueAvailable }}</p>
            <p>{{ 'generic.expires' | translate: { expirationDate: credit.expiryDate } }}</p>
          </div>
          <pc-checkmark-icon [checked]="credit.isSelected && !credit.disableSelection" [size]="32"></pc-checkmark-icon>
        </li>
        <div *ngIf="loadingMoreCredits" class="loading-more-spinner"></div>
      </ul>
    </div>
  </div>

  <div class="select-paperclip-credit__footer">
    <div class="order-total">
      <h3>
        <span class="colour-secondary">{{ 'item-checkout.order-summary.order-total' | translate }}:&nbsp;</span
        ><span [ngClass]="{ 'colour-primary': getOrderTotal() <= 0, 'colour-secondary': getOrderTotal() > 0 }"
          ><span *ngIf="getOrderTotal() > 0">{{ modalData.itemCheckout.userCurrency }}</span
          >{{ getOrderTotal() > 0 ? (getOrderTotal() | number: '1.2-2') : ('generic.free' | translate) }}</span
        >
      </h3>
    </div>

    <div class="modal-footer multi-btn-container">
      <button class="btn-large btn-primary" (click)="setSelectedCredits()">{{ 'buttons.continue' | translate }}</button>
    </div>
  </div>
</div>
