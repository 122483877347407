<form
  [formGroup]="!showEnterCodeForm ? verifyPhoneForm : verificationCodeForm"
  (ngSubmit)="verifyPhoneNumber()"
  class="modal verify-phone-modal"
>
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.verify-phone-number.title' | translate }}</h1>
    <p>
      {{
        !showEnterCodeForm
          ? ('modals.verify-phone-number.subtitle' | translate)
          : ('modals.verify-phone-number.subtitle-verify' | translate)
      }}<button type="button" class="btn-link btn-link--inline" (click)="switchForm()">
        {{ 'generic.click-here' | translate }}
      </button>
    </p>
  </div>

  <div class="modal-body">
    <div class="modal-body-1">
      <div *ngIf="!showEnterCodeForm" class="phone-number-input">
        <button
          type="button"
          class="btn-no-styling select-country-btn"
          (click)="showCountrySelection = !showCountrySelection"
        >
          <i class="flag flag--{{ selectedCountry.countryCode }}"></i>
          <img class="select-country-btn__arrow" src="/assets/icons/misc/arrow-down-small.svg" />
        </button>

        <form [formGroup]="verifyPhoneForm" class="input-group input-group--label-start">
          <p class="highlight-text input-group__label">+{{ countryDialCode }}</p>
          <input
            #phoneNumberInput
            formControlName="phoneNumber"
            type="text"
            placeholder="{{ 'modals.verify-phone-number.phone-placeholder' | translate }}"
            (keydown.enter)="$event.preventDefault()"
            required
          />
        </form>
      </div>
      <input
        *ngIf="showEnterCodeForm"
        formControlName="verificationCode"
        type="text"
        placeholder="{{ 'modals.verify-phone-number.verify-placeholder' | translate }}"
        required
      />
      <button
        type="submit"
        class="send-btn btn-large btn-primary"
        [ngClass]="{ 'btn-loading': activeRequest }"
        [disabled]="disableButton"
      >
        {{ !showEnterCodeForm ? ('buttons.send' | translate) : ('buttons.done' | translate) }}
      </button>
    </div>

    <!-- countries menu -->
    <form *ngIf="showCountrySelection && !showEnterCodeForm" [formGroup]="searchCountriesForm" class="countries-menu">
      <div class="input-group input-group--btn-end countries-menu__search">
        <input
          formControlName="countryName"
          type="text"
          placeholder="{{ 'modals.verify-phone-number.country' | translate }}"
          (click)="$event.preventDefault()"
        />
        <pc-clear-input-btn
          *ngIf="searchInputHasValue"
          class="input-group__btn input-group__btn--end"
          (click)="searchCountriesForm.reset()"
        >
        </pc-clear-input-btn>
      </div>
      <ul class="countries-menu__list">
        <li *ngFor="let country of commonCountries" (click)="updateSelectedCountry($event, country.countryCode)">
          <i class="flag flag--{{ country.countryCode }}"></i>
          <p class="highlight-text">
            {{ country.name }} <span class="dial-code">+{{ country.dialCode }}</span>
          </p>
        </li>
        <div class="separator"></div>
        <li *ngFor="let country of countries" (click)="updateSelectedCountry($event, country.countryCode)">
          <i class="flag flag--{{ country.countryCode }}"></i>
          <p class="highlight-text">
            {{ country.name }} <span class="dial-code">+{{ country.dialCode }}</span>
          </p>
        </li>
      </ul>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      class="btn-large btn-primary"
      [ngClass]="{ 'btn-loading': activeRequest }"
      [disabled]="disableButton"
    >
      {{ !showEnterCodeForm ? ('buttons.send' | translate) : ('buttons.done' | translate) }}
    </button>
  </div>
</form>
