<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <img src="/assets/illustrations/payments/protection.svg" alt="" />
    <h1>{{ 'modals.payment-protection.' + modalPov + '.title' | translate }}</h1>
  </div>

  <div class="modal-body">
    <div *ngFor="let section of protectionInfo" class="protection-section">
      <div class="protection-section__icon">
        <img [src]="section.icon" />
      </div>
      <div class="protection-section__detail">
        <p class="highlight-text">{{ section.heading | translate }}</p>
        <p>{{ section.description | translate }}</p>
      </div>
    </div>
  </div>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large btn-primary" (click)="dialogRef.close()">
      {{ 'modals.payment-protection.button' | translate }}
    </button>
  </div>
</div>
