<div class="modal force-email-modal">
  <pc-close-icon *ngIf="stepNumber === 2" (click)="dialogRef.close()"></pc-close-icon>

  <div class="modal-header">
    <h1>{{ 'modals.force-email.step-' + stepNumber + '.title' | translate }}</h1>
  </div>

  <!-- body -->
  <div class="modal-body">
    <p>{{ 'modals.force-email.step-' + stepNumber + '.body' | translate }}</p>
    <form *ngIf="stepNumber === 1" [formGroup]="emailAddressForm">
      <div class="input-group">
        <input type="email" placeholder="Enter your email here" formControlName="emailAddress" />
      </div>
    </form>
  </div>

  <div class="modal-footer multi-btn-container">
    <button
      *ngIf="stepNumber === 1"
      class="btn-large btn-primary"
      [ngClass]="{ 'btn-loading': activeRequest }"
      [disabled]="!emailAddressForm.valid"
      (click)="addEmailToAccount()"
    >
      {{ 'buttons.save-email' | translate }}
    </button>
    <button *ngIf="stepNumber === 2" class="btn-large" (click)="dialogRef.close()">
      {{ 'buttons.close' | translate }}
    </button>
  </div>
</div>
