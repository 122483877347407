import { Injectable } from "@angular/core";
import { ModalService } from "../modal.service";
import { AuthService } from "@paperclip/core/auth/auth.service";
import { UsersService } from "@paperclip/page/users.service";
import { PcApiResponse } from "@paperclip/models/misc/pc-api-response";
import { CoreService } from "@paperclip/core/core.service";
import { AuthedUser } from "@paperclip/models/user/AuthedUser";
import { PaymentAccountModalComponent } from "./payments-modals";

@Injectable({
    providedIn: 'root'
})
export class SellerKycService {

    mpUserCategory: 'PAYER' | 'OWNER' | 'UNKNOWN';
    authedUser: AuthedUser = this.authService.getAuthedUser();

    constructor(
        private authService: AuthService,
        private modalService: ModalService,
        private usersService: UsersService,
        private coreService: CoreService,
    ) {}

    refreshPaymentAccount() {
        this.mpUserCategory = this.authService.getAuthedUser().detail.mpUserCategory;
        if (this.mpUserCategory !== 'OWNER') {
        this.usersService.checkForPaymentAccount(this.authService.getAuthedUser().detail.id).subscribe(
            ({ code, data }: PcApiResponse) => {
            if (code === 1 && data !== null) {
                this.updateMpUserCategory(data.userCategory);
            }
            },
            () => {
            this.coreService.handleError({});
            }
        );
        }
    }

    updateMpUserCategory(userCategory: 'PAYER' | 'OWNER' | 'UNKNOWN') {
        this.mpUserCategory = userCategory;
        this.authedUser.detail.mpUserCategory = userCategory;
        this.authService.updateAuthedUser(this.authedUser);
    }

    public guard(onComplete = () => {}, onCloseWithoutComplete = () => {}) {
        if(this.mpUserCategory !== 'OWNER') {
            this.modalService.open(PaymentAccountModalComponent, {
                disableClose: true,
                type: 'centered',
                data: {
                  pov: 'seller',
                  complete: () => {
                    this.updateMpUserCategory('OWNER');
                    onComplete()
                  },
                  closedWithoutComplete: () => {
                    onCloseWithoutComplete()
                  }
                }
            });
        } else {
            onComplete()
        }

    }

}