import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { Item } from '@paperclip/models/item';
import { ItemCategory } from '@paperclip/models/item/ItemCategory';
import { ItemOverviewService } from '@paperclip/page/item-overview/item-overview.service';

@Component({
  selector: 'pc-add-item-review-listing-bs',
  templateUrl: './add-item-review-listing-bs.component.html',
  styleUrls: ['./add-item-review-listing-bs.component.scss']
})
export class AddItemReviewListingBsComponent implements OnInit {
  @Output() selectedCategoryTree: EventEmitter<ItemCategory[]> = new EventEmitter<ItemCategory[]>();
  activeRequest: boolean;
  addItemForm: UntypedFormGroup = this.formBuilder.group({
    itemDetails: this.formBuilder.group({
      itemCategory: [null, Validators.required]
    })
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    public bottomSheetRef: MatBottomSheetRef<AddItemReviewListingBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public modalData: {
      formControl: string;
      addItemForm: UntypedFormGroup;
      currency: { symbol: string; mask: any };
      categoryTree: ItemCategory[];
      isEditingItem: boolean;
      existingItemId: string;
      itemOverviewItem: Item;
    },
    private updateService: UpdatesService,
    private itemOverviewService: ItemOverviewService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    if (!this.modalData.addItemForm && this.modalData.itemOverviewItem) {
      this.addItemForm.patchValue({
        itemDetails: {
          itemCategory: this.modalData.itemOverviewItem.detail.category
        }
      });

      this.modalData.addItemForm = this.addItemForm;
    }
  }

  clearItemDetail(field: string) {
    if (field === 'itemPrice') {
      this.modalData.addItemForm.get('paymentDetails').get(field).patchValue(null);
      return;
    }
    this.modalData.addItemForm.get('itemDetails').get(field).patchValue(null);
  }

  updateCategoryTree(selectedCategoryTree: ItemCategory[]) {
    this.updateService.sendUpdateData({
      action: 'update-category-tree',
      keyValueData: {
        key: 'selectedCategoryTree',
        value: selectedCategoryTree
      }
    });
  }

  public closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }
}
