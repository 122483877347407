/* eslint-disable @typescript-eslint/ban-types */
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { WalletService } from '@paperclip/page/wallet/wallet.service';

@Component({
  selector: 'pc-wallet-withdraw-balance-modal',
  templateUrl: './wallet-withdraw-balance-modal.component.html',
  styleUrls: ['./wallet-withdraw-balance-modal.component.scss']
})
export class WalletWithdrawBalanceModalComponent {
  withdrawBalanceForm: UntypedFormGroup = this.formBuilder.group({
    amount: [this.modalData.availableBalance.toFixed(2), Validators.required]
  });
  activeRequest: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<WalletWithdrawBalanceModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: {
      accountNumber: string;
      userCurrency: string;
      currencyMask: any;
      availableBalance: number;
      complete: () => {};
      paymentAccountError: () => {};
    },
    private walletService: WalletService,
    private coreService: CoreService
  ) {}

  public withdrawBalance() {
    this.activeRequest = true;
    this.walletService.withdrawBalance(this.withdrawBalanceForm.get('amount').value).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        if (code === 1) {
          this.activeRequest = false;
          this.modalData.complete();
          this.dialogRef.close();
        } else {
          if (code === -206) {
            this.modalData.paymentAccountError();
            this.dialogRef.close();
          } else {
            this.coreService.handleError({ code, message });
          }
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }

  public disableButton(): boolean {
    return (
      this.withdrawBalanceForm.invalid ||
      this.withdrawBalanceForm.get('amount').value === 0 ||
      this.withdrawBalanceForm.get('amount').value > this.modalData.availableBalance
    );
  }
}
