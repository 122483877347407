import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FilterItem } from '@paperclip/models/search/filter-item';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';
import { getSearchSortOptions } from '@paperclip/page/search-sort-options';

@Component({
  selector: 'pc-search-sort-filter',
  templateUrl: './search-sort-filter.component.html',
  styles: [
    `
      .sort-results-filter h2 {
        margin-bottom: 24px;
      }
    `
  ]
})
export class SearchSortFilterComponent implements OnChanges {
  @Input() searchFilters: SearchFilters;
  sortOptions: FilterItem[];
  selectedSortOption: FilterItem;

  constructor(private router: Router) {}

  ngOnChanges(searchFiltersChanges: SimpleChanges): void {
    if (searchFiltersChanges) {
      this.initialiseSortFilter();
    }
  }

  private initialiseSortFilter() {
    this.sortOptions = getSearchSortOptions(this.searchFilters);
    this.selectedSortOption = this.sortOptions.filter(
      (option: FilterItem) => this.searchFilters.sortId === option.apiValue
    )[0];
    this.selectedSortOption.isSelected = true;
  }

  public selectSortOption(sortOption: FilterItem) {
    if (sortOption.label !== this.selectedSortOption.label) {
      this.sortOptions.forEach((option: FilterItem) => {
        option.isSelected = option.label === sortOption.label;
      });
      this.selectedSortOption = this.sortOptions.filter((option: FilterItem) => option.isSelected)[0];
      this.router.navigate(['/search'], {
        queryParams: { sortId: this.selectedSortOption.apiValue },
        queryParamsHandling: 'merge'
      });
    }
  }
}
