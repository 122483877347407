import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';

@Component({
  selector: 'pc-sign-out-modal',
  templateUrl: './sign-out-modal.component.html'
})
export class SignOutModalComponent {
  authedUser: AuthedUser = this.authService.getAuthedUser();

  constructor(
    public dialogRef: MatDialogRef<SignOutModalComponent>,
    private authService: AuthService,
    private coreService: CoreService
  ) {}

  public signOut() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.authService.logout(this.authedUser.detail.id).subscribe(() => {});
  }
}
