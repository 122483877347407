import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '@paperclip/core/app.config';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { environment } from '@paperclip/environments/environment';
import { setUserReferralDeepLink } from '@paperclip/models/user';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';
import * as Bowser from 'bowser';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'pc-invite-friends-modal',
  templateUrl: './invite-friends-modal.component.html',
  styleUrls: ['./invite-friends-modal.component.scss']
})
export class InviteFriendsModalComponent implements OnInit {
  branchDeepLinkLoading: boolean;
  branchDeepLink = '';
  authedUser: AuthedUser = this.authService.getAuthedUser();
  copiedText = 'invite-friends.copy-link';
  userName: string;
  device: Bowser.Parser.ParsedResult;

  constructor(
    public dialogRef: MatDialogRef<InviteFriendsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: { referralDeepLink: string },
    private authService: AuthService,
    private clipboardService: ClipboardService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.branchDeepLink = await this.getBranchDeepLink();
    this.branchDeepLinkLoading = false;
    this.device = Bowser.getParser(window.navigator.userAgent).getResult();
    this.userName = this.authService.getAuthedUser().detail?.fullName || 'Your friend';
  }

  private async getBranchDeepLink(): Promise<string> {
    if (this.modalData.referralDeepLink) {
      return this.modalData.referralDeepLink;
    } else {
      this.branchDeepLinkLoading = true;
      return await setUserReferralDeepLink(this.translateService, this.coreService);
    }
  }

  shareViaEmail() {
    const subject = `${this.userName} has invited you to join Paperclip`;
    const body = `It's your lucky day! ${this.userName} has invited you to join the Paperclip marketplace. Follow the link to buy & sell with the UK's most trusted trading community: ${this.branchDeepLink}\n\nEnjoy!`;

    const mailTo = `mailTo:?Subject=${subject}&Body=${encodeURIComponent(body)}`;

    setTimeout(() => {
      window.open(mailTo, '_self');
    }, 100);
  }

  copyToClipboard() {
    this.clipboardService.copyFromContent(this.branchDeepLink);
    this.confirmationMessageService.showConfirmationMessage('link-copied');
  }

  shareToFacebook() {
    const fbAppId = environment.production ? AppConfig.app.fbAppId : '237645557053105';
    const display: 'async' | 'iframe' | 'page' | 'popup' | 'touch' = 'popup';
    const redirectUri = environment.production
      ? 'https://marketplace.paperclip.co/close-redirect'
      : 'https://marketplace.papercliptesting.co.uk/close-redirect';
    window.open(
      `https://www.facebook.com/dialog/share?app_id=${fbAppId}&display=${display}&href=${this.branchDeepLink}&redirect_uri=${redirectUri}`,
      '_blank'
    );
  }

  shareToMessenger() {
    const fbAppId = environment.production ? AppConfig.app.fbAppId : '237645557053105';
    if (this.device.platform.type !== 'desktop') {
      window.open(
        'fb-messenger://share?link=' +
          encodeURIComponent(this.branchDeepLink) +
          '&app_id=' +
          encodeURIComponent(fbAppId)
      );
    } else {
      const redirectUri = environment.production
        ? 'https://marketplace.paperclip.co/close-redirect'
        : 'https://marketplace.papercliptesting.co.uk/close-redirect';
      window.open(
        `https://www.facebook.com/dialog/send?app_id=${fbAppId}&link=${this.branchDeepLink}&redirect_uri=${redirectUri}`,
        '_blank'
      );
    }
  }

  shareToTwitter() {
    window.open(
      `https://twitter.com/intent/tweet?url=${this.branchDeepLink}&via=PaperclipHQ&text=Check out Paperclip, it is a great way to make cash selling stuff you don’t use anymore!&hashtags=paperclip,marketplace`,
      '_blank'
    );
  }
}
