import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from '@paperclip/core/app.config';
import { environment } from '@paperclip/environments/environment';
import { AppData } from '@paperclip/models/AppData';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { ApiAuthedUser, AuthedUser } from '@paperclip/models/user/AuthedUser';
import { FacebookUser } from '@paperclip/models/user/external-auth-user';
import { FacebookService, InitParams, LoginOptions, LoginResponse } from 'ngx-facebook';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private profilePicture = new Subject<any>();
  private userSuspended = new Subject<any>();

  updateProfilePicture(profilePicture: any) {
    this.profilePicture.next(profilePicture);
  }

  getProfilePicture(): Observable<any> {
    return this.profilePicture.asObservable();
  }

  setUserSuspended(userSuspended: boolean) {
    this.userSuspended.next(userSuspended);
  }

  getUserSuspended(): Observable<boolean> {
    return this.userSuspended.asObservable();
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private fb: FacebookService
  ) {
    let fbAppId = AppConfig.app.fbAppId;
    if (!environment.production) {
      fbAppId = '237645557053105';
    }
    const fbParams: InitParams = {
      appId: fbAppId,
      xfbml: true,
      version: 'v3.3'
    };
    this.fb.init(fbParams);
  }

  /*====================
  paperclip auth
  ====================*/
  login(credentials: any): Observable<any> {
    return this.http.post<any>(`login`, credentials);
  }

  register(credentials: any): Observable<any> {
    return this.http.post<any>(`register`, credentials);
  }

  logout(userId?: string) {
    if (userId) {
      return this.http.post(`logout`, `currentUserId=${userId}`).pipe(
        tap((complete) => {
          localStorage.removeItem('user');
          this.updateAppData({ searchTerm: '', searchType: 'items' });
          window.location.replace('/');
        })
      );
    } else {
      localStorage.removeItem('user');
      this.updateAppData({ searchTerm: '', searchType: 'items' });
      window.location.replace('/');
    }
  }

  /*====================
  social auth
  ====================*/
  getFacebookUser(callback) {
    const loginOptions: LoginOptions = {
      enable_profile_selector: true,
      return_scopes: true,
      scope: 'public_profile,email'
    };

    return this.fb.login(loginOptions).then((fbLoginResponse: LoginResponse) => {
      this.fb
        .api('/me?fields=name,first_name,last_name,email')
        .then((facebookUser: FacebookUser) => {
          return callback(facebookUser);
        })
        .catch((error) => {
          return callback(null, error);
        });
    });
  }

  /*====================
  external jwt auth
  ====================*/
  externalJwtAuth(jwt: string): Observable<any> {
    return this.http.post<any>(`loginExternal`, { gooddollarJwt: jwt });
  }

  /*====================
  auth utilities
  ====================*/
  setAuthedUser(user: ApiAuthedUser, redirectTo: string, source?: 'fb' | 'apple') {
    if (user.userToken) {
      localStorage.setItem('userToken', user.userToken);
    } else {
      user.userToken = localStorage.getItem('userToken');
    }
    const authedUser = new AuthedUser(user);
    localStorage.setItem('user', JSON.stringify(authedUser));

    setTimeout(() => {
      if (authedUser.detail.isNewAccount) {
        if (source) {
          localStorage.setItem('signupMethod', source);
        }
        this.router.navigate(['/onboarding']);
      } else {
        this.router.navigateByUrl(redirectTo).then(() => window.location.reload());
      }
    }, 500);
  }

  getAuthedUser(): AuthedUser {
    return JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
  }

  updateAuthedUser({ detail, verifications, studentRoostLocation }: AuthedUser) {
    let authedUser: AuthedUser = this.getAuthedUser();
    authedUser = {
      ...authedUser,
      detail: {
        ...authedUser.detail,
        ...detail
      },
      verifications: {
        ...authedUser.verifications,
        ...verifications
      },
      studentRoostLocation: {
        ...authedUser.studentRoostLocation,
        ...studentRoostLocation
      }
    };

    if (detail) {
      this.updateProfilePicture(detail.picture);
    }
    localStorage.setItem('user', JSON.stringify(authedUser));
  }

  public removeStudentRoostLocation() {
    let authedUser: AuthedUser = this.getAuthedUser();
    authedUser = {
      ...authedUser,
      studentRoostLocation: null
    };
    localStorage.setItem('user', JSON.stringify(authedUser));
  }

  /*-- store app data that shouldn't ever be cleared --*/
  updateAppData({ currentLocation, recentSearches, recentLocations, searchType, searchTerm, searchViewType }: AppData) {
    let appData: AppData = this.getAppData();
    appData = {
      ...appData,
      currentLocation: {
        ...appData.currentLocation,
        ...currentLocation
      },
      recentSearches: recentSearches ? recentSearches : appData.recentSearches,
      recentLocations: recentLocations ? recentLocations : appData.recentLocations,
      searchType: searchType ? searchType : appData.searchType,
      searchTerm: searchTerm?.length > -1 ? searchTerm : appData.searchTerm,
      searchViewType: searchViewType ? searchViewType : appData.searchViewType || 'grid'
    };

    // update user in localStorage
    localStorage.setItem('appData', JSON.stringify(appData));

    // remove algoliaRecentSearches if exists, todo: eventually remove this
    const lsAppData = JSON.parse(localStorage.appData);
    if (lsAppData.algoliaRecentSearches) {
      delete lsAppData.algoliaRecentSearches;
      localStorage.setItem('appData', JSON.stringify(lsAppData));
    }
  }

  getAppData(): AppData {
    return JSON.parse(localStorage.getItem('appData')) ? JSON.parse(localStorage.getItem('appData')) : {};
  }

  isLoggedIn() {
    const token = this.jwtHelper.tokenGetter();
    if (!token || this.jwtHelper.isTokenExpired()) {
      localStorage.removeItem('user');
      return false;
    }

    return !this.jwtHelper.isTokenExpired();
  }

  resetPassword(email: string): Observable<any> {
    return this.http.get<any>(`users/forgotPassword?email=${email}`);
  }

  getEmailVerification(userId: string): Observable<any> {
    return this.http.get('users/verifications?' + `userId=${userId}`);
  }

  sendVerificationEmail(userId: string, emailAddress: string): Observable<any> {
    return this.http.post(`users/verifyEmail`, { userId: userId, email: emailAddress });
  }

  checkUserIsSuspended(): Observable<PcApiResponse> {
    return this.http.get('users/isSuspended');
  }
}
