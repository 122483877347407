/* eslint-disable no-async-promise-executor */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeUrl } from '@angular/platform-browser';
import { CoreService } from '@paperclip/core/core.service';
import { EditImageModalComponent } from '@paperclip/modals/misc/misc-modals';
import { ModalService } from '@paperclip/modals/modal.service';
import { CompressionConfig } from '@paperclip/models/image-dropzone/compression-config';
import { DroppedImage } from '@paperclip/models/image-dropzone/dropped-image';
import { ExistingImageOptions } from '@paperclip/models/image-dropzone/existing-image-options';
import { ImageDropzoneConfig } from '@paperclip/models/image-dropzone/image-dropzone-config';
import { ImagePreview } from '@paperclip/models/image-dropzone/image-preview';
import imageCompression from 'browser-image-compression';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'pc-image-dropzone',
  templateUrl: 'image-dropzone.component.html',
  styleUrls: ['image-dropzone.component.scss']
})
export class ImageDropzoneComponent implements DoCheck, OnChanges {
  /*-- inputs & outputs --*/
  @Input() config: ImageDropzoneConfig;
  @Input() cropAspectRatio = 1 / 1;
  @Input() loadExistingImage: boolean;
  @Input() existingImages: any[];
  @Input() existingImageOptions: ExistingImageOptions;
  @Input() isOnAdminSettingsEditInfo = false;
  @Output() imageFilesSuccess: EventEmitter<any[]> = new EventEmitter<any[]>();

  /*-- dropzone settings & config --*/
  dragOverActive: boolean;
  defaultConfig: ImageDropzoneConfig = {
    showDropzone: true,
    showPreviews: true,
    previewTemplate: 'item',
    enableReordering: true,
    previewsWrap: true,
    maxImages: 4,
    maxImageSize: 30,
    // todo: duplicates
    allowDuplicates: false,
    compressImages: true,
    compressionMaxSize: 2,
    compressionMaxWidth: 2000
  };
  existingImageDefaultOptions: ExistingImageOptions = {
    allowEditing: true,
    allowDeletion: true
  };
  @ViewChild('filePicker', { static: true }) filePicker: ElementRef;

  /*-- image processing --*/
  droppedImages: DroppedImage[] = [];
  imagePreviews: ImagePreview[] = [];
  sanitizedImageUrls: SafeUrl[];
  imagesProcessing: boolean;
  differ: KeyValueDiffer<any, any>;

  constructor(
    private differs: KeyValueDiffers,
    private sanitizer: DomSanitizer,
    private appService: AppService,
    private modalService: ModalService,
    private coreService: CoreService
  ) {
    this.differ = this.differs.find({}).create();
  }

  /*-- combine any settings from input config with the default --*/
  async ngDoCheck() {
    const changes = this.differ.diff(this.config);
    if (changes) {
      for (const configKey in this.config) {
        if (this.config[configKey] != null) {
          this.defaultConfig[configKey] = this.config[configKey];
        }
      }

      for (let i = this.imagePreviews.length; i < this.defaultConfig.maxImages; i++) {
        this.imagePreviews.push({
          index: i,
          name: '',
          processing: false,
          imageUrl: null,
          btnTranslationKey: this.defaultConfig.previewTemplate === 'user' ? null : 'upload',
          allowEditing: true,
          allowDeletion: true
        });
      }

      for (const optionKey in this.existingImageOptions) {
        if (this.existingImageOptions[optionKey] != null) {
          this.existingImageDefaultOptions[optionKey] = this.existingImageOptions[optionKey];
        }
      }

      this.runChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.existingImages && changes.existingImages.currentValue && this.imagePreviews[0]) {
        if (changes.existingImages.currentValue !== null) {
          this.runChanges();
        }
      }
    }
  }

  private runChanges() {
    if (this.existingImages && this.existingImages[0] !== null) {
      for (let i = 0; i < this.existingImages.length; i++) {
        if (
          typeof this.existingImages[i] === 'string' &&
          this.existingImages[i].includes('empty_profile_picture.png')
        ) {
          this.existingImages[i] = '/assets/default-avatar.svg';
        }
        this.imagePreviews[i].processing = true;
      }
      this.processExistingImages(this.existingImages).then((imageFiles: any[]) => {
        imageFiles.sort((a, b) => {
          return b.index > a.index ? -1 : 0;
        });
        this.droppedImages = imageFiles;
        for (let i = 0; i < imageFiles.length; i++) {
          const existingImage: ImagePreview = {
            index: imageFiles[i].index,
            name: imageFiles[i].name,
            imageUrl: imageFiles[i].sanitizedUrl,
            processing: false,
            btnTranslationKey: this.existingImageDefaultOptions.allowEditing
              ? 'edit'
              : !imageFiles[i].existingImage
              ? 'edit'
              : null,
            allowEditing: this.existingImageDefaultOptions.allowEditing ? true : !imageFiles[i].existingImage,
            allowDeletion: this.existingImageDefaultOptions.allowDeletion ? true : !imageFiles[i].existingImage
          };
          this.imagePreviews[i] = existingImage;
        }
      });
    }
  }

  /*====================
  DROPZONE & IMAGE PICKER
  ====================*/
  public dragOver(event: Event) {
    this.preventEventDefaults(event);
    this.dragOverActive = true;
  }

  public dragLeave(event: any) {
    this.preventEventDefaults(event);
    this.dragOverActive = false;
  }

  /*-- method for both files dropped and files picked --*/
  public async filesDropped(event: any) {
    this.preventEventDefaults(event);
    this.dragOverActive = false;

    // make sure any images previously added have finished processing
    // before we let the user add anymore to avoid any issues
    if (!this.imagesProcessing) {
      // different browsers support different transfer types need to
      // detect which type we have and take the images from there
      let dataTransferType: string;
      let dataTransferImages: FileList;

      if (event && event.dataTransfer) {
        dataTransferType = event.dataTransfer.items ? 'items' : 'files';
        dataTransferImages = event.dataTransfer.items ? event.dataTransfer.items : event.dataTransfer.files;
      } else {
        dataTransferType = 'files';
        dataTransferImages = event.target.files;
      }

      const imagesListCopy: File[] = this.removeDuplicateFilesByName(dataTransferImages);
      if (imagesListCopy && imagesListCopy.length > 0) {
        // check the user hasn't already added the max image count before proceeding
        // if (this.droppedImages.length >= this.defaultConfig.maxImages) {
        if (this.defaultConfig.maxImages !== 1 && this.droppedImages.length >= this.defaultConfig.maxImages) {
          this.coreService.handleError({ customCode: 997 });
        } else {
          // process the image files in getImageFiles dependant on the transfer type and check for
          // any errors e.g. max file size
          await this.getImageFiles(dataTransferType, imagesListCopy)
            .then(async (droppedImages: any) => {
              // we need to add index values to all images in the order that they were
              // added because for some reason when it comes to compressing the files
              // the order gets jumbled, so with indexes we can order them correctly later
              await this.setImageIndex(droppedImages);

              // set images as processing on image previews for some UX feedback
              // especially important for larger files that may take longer to process
              this.imagesProcessing = true;
              for (let i = 0; i < droppedImages.length; i++) {
                this.imagePreviews[droppedImages[i].index].processing = true;
              }

              // compress!
              this.compressImages(droppedImages).then(async (compressedImages: any) => {
                compressedImages.sort((a, b) => {
                  return b.index > a.index ? -1 : 0;
                });
                if (this.defaultConfig.maxImages === 1) {
                  this.droppedImages = compressedImages;
                } else {
                  this.droppedImages.push(...compressedImages);
                }
                this.imageFilesSuccess.emit(this.droppedImages);
                this.imagesProcessing = false;
                for (let i = 0; i < compressedImages.length; i++) {
                  this.imagePreviews[compressedImages[i].index].name = compressedImages[i].name;
                  this.imagePreviews[compressedImages[i].index].imageUrl = compressedImages[i].sanitizedUrl;
                  this.imagePreviews[compressedImages[i].index].processing = false;
                  this.imagePreviews[compressedImages[i].index].btnTranslationKey = 'edit';
                }
              });
            })
            .catch((error) => {
              this.coreService.handleError({});
            });
        }
      }
    }
  }

  private removeDuplicateFilesByName(dataTransferImages: FileList): File[] {
    const existingImageNames = this.droppedImages.map((image: DroppedImage) => image.name);
    const imagesListCopy: File[] = [];
    for (let i = 0; i < dataTransferImages.length; i++) {
      if (!existingImageNames.includes(dataTransferImages[i].name)) {
        imagesListCopy.push(dataTransferImages[i]);
      }
    }

    return imagesListCopy;
  }

  public inputClickEvent(event: any) {
    const target = event.target as HTMLInputElement;
    target.value = null;
  }

  convertBlobToFile(blob: any) {
    return new Promise((resolve) => {
      if (!/Edge/.test(navigator.userAgent)) {
        resolve(new File([blob], blob.name, { type: 'image/jpeg' }));
      } else {
        const edgeBlob = new Blob([blob], { type: blob.type });
        const b: any = edgeBlob;
        b.lastModifiedDate = blob.lastModified;
        b.name = blob.name;
        return resolve(b as File);
      }
    });
  }

  /*====================
  IMAGE PROCESSING
  ====================*/
  private getImageFiles(dataTransferType: string, dataTransfer: any) {
    const imageFiles = [];
    return new Promise((resolve, reject) => {
      // allow user to keep picking images if maxImage is 1
      if (this.defaultConfig.maxImages === 1) {
        if (dataTransferType === 'items') {
          const image: DataTransferItem = dataTransfer[0];
          if (image.kind === 'file' && image.type.includes('image')) {
            const file: File = dataTransfer[0].getAsFile();
            if (file.size / 1024 / 1024 > this.defaultConfig.maxImageSize) {
              this.coreService.handleError({ customCode: 996 });
            } else {
              imageFiles.push(file);
            }
          }
        } else if (dataTransferType === 'files') {
          const image: File = dataTransfer[0];
          if (image.type.includes('image')) {
            if (image.size / 1024 / 1024 > this.defaultConfig.maxImageSize) {
              this.coreService.handleError({ customCode: 996 });
            } else {
              imageFiles.push(image);
            }
          }
        }

        // resolve or reject
        imageFiles.length > 0 ? resolve(imageFiles) : reject(`those weren't images! 😱`);
      } else {
        // if the user drops more images than are allowed
        // only process them up to the point that limit is reached
        if (this.droppedImages.length !== this.defaultConfig.maxImages) {
          for (let i = 0; i < dataTransfer.length; i++) {
            if (i < this.defaultConfig.maxImages - this.droppedImages.length) {
              if (dataTransferType === 'items') {
                const image: DataTransferItem = dataTransfer[i];
                if (image.kind === 'file' && image.type.includes('image')) {
                  const file: File = dataTransfer[i].getAsFile();
                  if (file.size / 1024 / 1024 > this.defaultConfig.maxImageSize) {
                    this.coreService.handleError({ customCode: 996 });
                  } else {
                    imageFiles.push(file);
                  }
                }
              } else if (dataTransferType === 'files') {
                const image: File = dataTransfer[i];
                if (image.type.includes('image')) {
                  if (image.size / 1024 / 1024 > this.defaultConfig.maxImageSize) {
                    this.coreService.handleError({ customCode: 996 });
                  } else {
                    imageFiles.push(image);
                  }
                }
              }
              // resolve or reject
              if (i + 1 === dataTransfer.length || i + 1 === this.defaultConfig.maxImages - this.droppedImages.length) {
                imageFiles.length > 0 ? resolve(imageFiles) : reject(`those weren't images! 😱`);
              }
            }
          }
        } else {
          reject('max images exceeded');
        }
      }
    });
  }

  private setImageIndex(images: any) {
    let imagesCount = images.length;
    let i = this.droppedImages.length;
    return new Promise<void>(async (resolve, reject) => {
      if (this.defaultConfig.maxImages > 1) {
        await images.forEach(async (image: any) => {
          image.index = i;
          i++;

          --imagesCount;
          if (imagesCount <= 0) {
            resolve();
          }
        });
      } else {
        images[0].index = 0;
        resolve();
      }
    });
  }

  private setImageUrls(images: any) {
    let imagesCount = images.length;

    return new Promise<void>(async (resolve, reject) => {
      await images.forEach(async (image: any) => {
        image.processing = true;
        image.src = URL.createObjectURL(image);

        --imagesCount;
        if (imagesCount <= 0) {
          resolve();
        }
      });
    });
  }

  async processExistingImages(images: string[]) {
    let imagesCount = images.length;
    let i = 0;
    const imageFiles = [];
    return new Promise(async (resolve) => {
      await images.forEach(async (imageUrl: any) => {
        if (typeof imageUrl === 'string') {
          // we need to create a new image from the existing image src
          // to avoid cors errors when converting the image to a file
          // see: https://stackoverflow.com/a/43310883/1069719
          const getImage = new Promise(function (resolve2, reject) {
            const image = new Image();
            image.crossOrigin = '*';
            image.id = `${i}`; // add image index as id
            i++;

            /*
              attach a random value to the end of the imageUrl to prevent chromium from using cached image resulting in CORS errors
              see following links for deeper explanations:
              https://stackoverflow.com/questions/49503171/the-image-tag-with-crossorigin-anonymous-cant-load-success-from-s3/49503414#49503414
              https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req/856948#856948
            */
            const rand = '?' + Math.random();
            const fbRandom = imageUrl.includes('facebook.com'); // don't apply random to fb images
            const base64Random = imageUrl.includes('base64');
            let imageSource = `${imageUrl}${rand}`;
            if (fbRandom || base64Random) {
              imageSource = imageUrl;
            }
            image.src = imageSource;
            /* */

            image.onload = function () {
              resolve2(image);
            };
            image.onerror = function () {
              reject(new Error('Could not load image at ' + imageSource));
            };
          });

          getImage.then(async (image: any) => {
            const canvas = await imageCompression.drawImageInCanvas(image);
            let file: any = await imageCompression.canvasToFile(canvas, 'image/jpeg', `${Date.now()}.jpg`, Date.now());
            // let file = await canvas.convertToBlob({
            //   type: 'image/jpeg',
            //   quality: 1
            // });
            file.name = `${Date.now()}.jpg`;
            file.lastModified = Date.now();
            file = await this.convertBlobToFile(file); // convert to file for server
            file.index = Number(image['id']);
            // i++;
            file.src = await URL.createObjectURL(file);
            file.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(file.src);
            file.processing = false;
            file.existingImage = true;
            imageFiles.push(file);

            --imagesCount;
            if (imagesCount <= 0) {
              resolve(imageFiles);
            }
          });
        } else {
          imageUrl.src = await URL.createObjectURL(imageUrl);
          imageUrl.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(imageUrl.src);
          imageFiles.push(imageUrl);
          --imagesCount;
          if (imagesCount <= 0) {
            resolve(imageFiles);
          }
        }
      });
    });
  }

  // we need to sanitize the urls for angulars security
  private sanitizeUrls(images: any) {
    let imagesCount = images.length;
    return new Promise<void>((resolve, reject) => {
      images.forEach((image: any) => {
        image.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(image.src);
        --imagesCount;
        if (imagesCount <= 0) {
          resolve();
        }
      });
    });
  }

  private compressImages(images: any) {
    let imageCount = images.length;
    const compressedImages = [];
    return new Promise((resolve) => {
      images.forEach((imageFile: any) => {
        const options: CompressionConfig = {
          maxSizeMB: this.defaultConfig.compressionMaxSize,
          maxWidthOrHeight: this.defaultConfig.compressionMaxWidth,
          useWebWorker: false
        };
        imageCompression(imageFile, options)
          .then(async (compressedFile: any) => {
            compressedFile = await this.convertBlobToFile(compressedFile); // convert to file for server
            compressedFile.index = imageFile.index;
            compressedFile.src = await URL.createObjectURL(compressedFile);
            compressedFile.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(compressedFile.src);
            compressedFile.processing = false;
            compressedImages.push(compressedFile);
            --imageCount;
            if (imageCount <= 0) {
              resolve(compressedImages);
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      });
    });
  }

  /*====================
  UTILITIES
  ====================*/
  private preventEventDefaults(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  public async previewClicked(event: Event, preview: ImagePreview) {
    event.preventDefault();
    if (!preview.imageUrl) {
      this.filePicker.nativeElement.click();
    } else if (preview.imageUrl && preview.allowEditing) {
      let imageToEdit: DroppedImage | any;
      for (let i = 0; i < this.droppedImages.length; i++) {
        if (this.droppedImages[i].index === preview.index) {
          imageToEdit = this.droppedImages[i];
        }
      }

      this.modalService.open(EditImageModalComponent, {
        type: 'edit-image',
        data: {
          image: imageToEdit,
          saveCroppedImage: (imageFile: DroppedImage) => {
            this.droppedImages[imageFile.index] = imageFile;
            this.imageFilesSuccess.emit(this.droppedImages);
            this.imagePreviews[imageFile.index].imageUrl = imageFile.sanitizedUrl;
          }
        }
      });
    }
  }

  public removeImage(imageIndex: number) {
    // decrease index values of every image after imageIndex
    // on imagePreviews and droppedImages
    for (let i = 0; i < this.imagePreviews.length; i++) {
      if (this.imagePreviews[i].index > imageIndex) {
        this.imagePreviews[i].index -= 1;
      }
    }

    for (let i = 0; i < this.droppedImages.length; i++) {
      if (this.droppedImages[i].index > imageIndex) {
        this.droppedImages[i].index -= 1;
      }
    }

    // now remove the images from both previews and droppedImages
    setTimeout(() => {
      for (let i = 0; i < this.imagePreviews.length; i++) {
        if (this.imagePreviews[i].index === imageIndex) {
          this.imagePreviews.splice(imageIndex, 1);
          this.imagePreviews.push({
            index: this.defaultConfig.maxImages - 1,
            name: '',
            processing: false,
            imageUrl: null,
            btnTranslationKey: 'upload',
            allowEditing: true,
            allowDeletion: true
          });
        }

        if (this.droppedImages[i] && this.droppedImages[i].index === imageIndex) {
          this.droppedImages.splice(imageIndex, 1);
        }
      }

      this.imageFilesSuccess.emit(this.droppedImages);
    }, 100);
  }

  /*--
  need to revokeOjectUrl when images are loaded
  https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications#Using_object_URLs
  --*/
  imageLoaded(image: ImagePreview) {
    for (let i = 0; i < this.droppedImages.length; i++) {
      if (this.droppedImages[i].index === image.index) {
        // URL.revokeObjectURL(this.droppedImages[i].src);
      }
    }
  }

  /*====================
  drag and drop image order
  ====================*/
  cdkDrop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      // we need to loop through the preview elements here so that we can find out
      // if the user has dropped an image after an empty preview (fool) because
      // if they have we want to reset the order to what it was previously
      // if they don't do this then all is good, manipulate the data as needed
      setTimeout(() => {
        const previews = event.container.element.nativeElement.children;
        let disableDrop = false;
        for (let i = 0; i < previews.length; i++) {
          const prev = previews[i - 1];
          const current = previews[i];

          if (prev) {
            const prevClassName = prev.className;
            const currentClassName = current.className;

            if (prevClassName.includes('disabled') && currentClassName.includes('draggable')) {
              disableDrop = true;
            }
          }
        }

        if (disableDrop) {
          moveItemInArray(event.container.data, event.currentIndex, event.previousIndex);
        } else {
          for (let i = 0; i < event.container.data.length; i++) {
            event.container.data[i]['index'] = i;
          }

          this.imagePreviews.forEach((imagePreview: ImagePreview) => {
            this.droppedImages.forEach((droppedImage: DroppedImage) => {
              if (droppedImage.name === imagePreview.name) {
                droppedImage.index = imagePreview.index;
              }
            });
          });

          this.droppedImages.sort((a, b) => {
            return b.index > a.index ? -1 : 0;
          });

          this.imageFilesSuccess.emit(this.droppedImages);
        }
      }, 100);
    }
  }
}
