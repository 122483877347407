<form [formGroup]="paymentAccountForm" class="payment-account-form">
  <!-- first & last name -->
  <div class="form-row form-row--group">
    <div class="form-group" [ngClass]="{ 'form-group--force-inline': windowSize !== 'mobile' }">
      <label for="first-name">{{ 'modals.payment-account.form.first-name' | translate }}</label>
      <input
        id="first-name"
        formControlName="firstName"
        type="text"
        placeholder="{{ 'modals.payment-account.form.first-name' | translate }}"
        required
      />
    </div>
    <div class="form-group" [ngClass]="{ 'form-group--force-inline': windowSize !== 'mobile' }">
      <label for="last-name">{{ 'modals.payment-account.form.last-name' | translate }}</label>
      <input
        id="last-name"
        formControlName="lastName"
        type="text"
        placeholder="{{ 'modals.payment-account.form.last-name' | translate }}"
        required
      />
    </div>
  </div>

  <!-- date of birth & nationality -->
  <div class="form-row form-row--group">
    <div class="form-group" [ngClass]="{ 'form-group--force-inline': windowSize !== 'mobile' }">
      <label for="date-of-birth">{{ 'modals.payment-account.form.date-of-birth' | translate }}</label>
      <input
        id="date-of-birth"
        formControlName="birthDate"
        type="text"
        placeholder="{{ 'modals.payment-account.form.date-of-birth-placeholder' | translate }}"
        [textMask]="{ mask: dobMask, guide: false, pipe: autoCorrectedDatePipe }"
        required
      />
    </div>

    <div
      [formGroup]="nationalityFormGroup"
      class="form-group"
      [ngClass]="{ 'form-group--force-inline': windowSize !== 'mobile' }"
    >
      <label for="nationality">{{ 'modals.payment-account.form.nationality' | translate }}</label>
      <span class="custom-select">
        <select id="nationality" formControlName="nationality" (change)="changeNationality()" required>
          <option *ngFor="let nationality of nationalitiesList" [ngValue]="nationality">{{ nationality.name }}</option>
        </select>
      </span>
    </div>
  </div>

  <!-- country of residence -->
  <div class="form-row">
    <div [formGroup]="countryFormGroup" class="form-group">
      <label for="country">{{ 'modals.payment-account.form.country' | translate }}</label>
      <span class="custom-select">
        <select id="country" formControlName="country" (change)="changeCountry()" required>
          <option *ngFor="let country of countriesList" [ngValue]="country">{{ country.name }}</option>
        </select>
      </span>
    </div>
  </div>
</form>
