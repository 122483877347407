import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { ApiUser, User } from '@paperclip/models/user';
import { MessagesService } from '@paperclip/page/messages.service';

@Component({
  selector: 'pc-new-message-modal',
  templateUrl: './new-message-modal.component.html',
  styleUrls: ['./new-message-modal.component.scss']
})
export class NewMessageModalComponent implements OnInit {
  users: User[] = [];
  selectedUser: User;
  dataLoading: boolean;
  loadingMore: boolean;
  loadMoreSkipValue = 0;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<NewMessageModalComponent>,
    private messagesService: MessagesService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  public getUsers(loadMore = false) {
    if (loadMore) {
      this.loadMoreSkipValue += 30;
      this.loadingMore = true;
    } else {
      this.loadMoreSkipValue = 0;
      this.dataLoading = true;
      this.loadingMore = false;
    }

    this.messagesService.getNewConversationFriend(this.loadMoreSkipValue).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.loadingMore = false;
        if (code === 1) {
          const users: User[] = [];
          if (data.length > 0) {
            data.forEach((user: ApiUser) => users.push(new User(user)));
          }
          this.users = loadMore ? [...this.users, ...users] : users;
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  public selectUser(selectedUser: User) {
    this.users.forEach((user: User) => (user.isSelected = false));
    selectedUser.isSelected = true;
    this.selectedUser = this.users.filter((user: User) => user.isSelected)[0];
  }

  public startMessage() {
    this.dialogRef.close();
    this.router.navigate(['/messages', this.selectedUser.detail.id]);
  }
}
