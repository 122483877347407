import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { PostcodeMatch } from '@paperclip/models/payments/postcode-match';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { PaymentsService } from '../payments.service';

@Component({
  selector: 'pc-find-payment-address',
  templateUrl: './find-payment-address.component.html',
  // todo: move the shared styles somewhere better
  styleUrls: ['./find-payment-address.component.scss', '../../../../item/item-checkout/item-checkout-form-groups.scss']
})
export class FindPaymentAddressComponent implements OnInit, OnDestroy {
  @Input() page: 'wallet' | 'checkout' = 'wallet';
  @Input() addressForm: UntypedFormGroup;
  @Input() addressType = 'delivery';
  @ViewChild('postCodeInput') postCodeInput: ElementRef;
  postCodeChange$: Subscription;
  loadingAddressSuggestions: boolean;
  postCodeMatches: PostcodeMatch[] = [];
  showPostCodeMatches: boolean;
  selectedMatch: PostcodeMatch;

  constructor(private paymentsService: PaymentsService) {}

  ngOnInit() {
    this.postCodeChange$ = this.addressForm
      .get('findAddress')
      .get('postCodeSuggestion')
      .valueChanges.pipe(
        distinctUntilChanged(), // wait for the searchTerm to be unique
        debounceTime(1000) // wait 1s so we're not searching every keypress
      )
      .subscribe((postCodeSuggestion: string) => {
        this.findMatches(postCodeSuggestion);
      });
  }

  ngOnDestroy(): void {
    this.addressForm.get('findAddress').get('postCodeSuggestion').patchValue('');
    this.postCodeChange$.unsubscribe();
  }

  public inputFocus() {
    if (this.addressForm.get('findAddress').get('postCodeSuggestion').value) {
      this.findMatches(this.addressForm.get('findAddress').get('postCodeSuggestion').value);
    }
  }

  public enterKeydown(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  public clearInput() {
    this.addressForm.get('findAddress').get('postCodeSuggestion').patchValue('');
    this.selectedMatch = null;
  }

  // prevent input blur from firing when selecting a match
  public preventDefault(event: any) {
    event.preventDefault();
  }

  private findMatches(postCode: string) {
    if (postCode) {
      this.loadingAddressSuggestions = true;
      this.showPostCodeMatches = true;
      this.paymentsService.findAddresses(postCode).subscribe(
        ({ code, data, message }: PcApiResponse) => {
          this.loadingAddressSuggestions = false;
          if (code === 1) {
            this.postCodeMatches = data;
          }
        },
        (error) => {
          console.error('error:', error);
        }
      );
    } else {
      this.postCodeMatches = [];
      this.showPostCodeMatches = false;
    }
  }

  public selectPostCodeMatch(match: PostcodeMatch) {
    this.selectedMatch = match;
    this.addressForm.get('findAddress').patchValue(
      {
        postCode: this.addressForm.get('findAddress').get('postCodeSuggestion').value,
        postCodeSuggestion: match.suggestion,
        suggestion: match.suggestion,
        addressLine1: match.addressLine1,
        addressLine2: match.addressLine2,
        city: match.city,
        county: match.county,
        country: match.country,
        countryCode: match.countryCode
      },
      { emitEvent: false }
    );
    this.postCodeInput.nativeElement.blur();
    this.postCodeMatches = [];
  }

  public enterAddressManually() {
    this.paymentsService.paymentsActionCompleted({
      action: 'enter-address-manually',
      data: this.addressType + '-address-form'
    });
  }

  public useAddress() {
    this.paymentsService.paymentsActionCompleted({
      action: 'use-found-address',
      data: this.addressType
    });
  }

  public getButtonLabel(): string {
    if (this.page === 'wallet') {
      return this.addressType === 'delivery' ? 'buttons.use-this-address' : 'buttons.use-this-address';
    }

    return this.addressType === 'delivery' ? 'buttons.use-this-address' : 'item-checkout.button.choose-payment-method';
  }

  public useAddressButtonDisabled(): boolean {
    if (this.addressType === 'delivery') {
      return !this.selectedMatch;
    } else {
      return !this.selectedMatch || !this.addressForm.get('paymentCard').valid;
    }
  }
}
