<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.trading-two-day-delay.title' | translate }}</h1>
  </div>

  <p
    class="modal-body"
    [innerHTML]="'modals.trading-two-day-delay.body-' + deliveryType | translate: { link: externalLinks.faq.payments }"
  ></p>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large btn-primary" (click)="dialogRef.close()">{{ 'buttons.okay' | translate }}</button>
  </div>
</div>
