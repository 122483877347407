import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Order } from '@paperclip/models/order';
import { TradingService } from '@paperclip/page/trading.service';

@Component({
  selector: 'pc-mark-dispatched-modal',
  templateUrl: './mark-dispatched-modal.component.html'
})
export class MarkDispatchedModalComponent {
  activeRequest: boolean;

  constructor(
    public dialogRef: MatDialogRef<MarkDispatchedModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { orderId: string; confirmed: (data: Order) => {} },
    private tradingService: TradingService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService
  ) {}

  markAsDispatched() {
    this.activeRequest = true;
    this.tradingService.markAsDispatched(this.modalData.orderId).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.activeRequest = false;
        if (code === 1) {
          this.confirmationMessageService.showConfirmationMessage('item-dispatched');
          this.modalData.confirmed(data);
          this.dialogRef.close();
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.activeRequest = false;
        this.coreService.handleError({});
      }
    );
  }
}
