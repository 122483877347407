import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSwiperConfig } from 'ngx-image-swiper';

@Component({
  selector: 'pc-image-viewer-modal',
  templateUrl: './image-viewer-modal.component.html'
})
export class ImageViewerModalComponent implements OnInit {
  ngxSwiperConfig: NgxSwiperConfig = {
    navigationPlacement: 'inside',
    navigationLeftIcon: '/assets/icons/misc/swipe-left.svg',
    navigationRightIcon: '/assets/icons/misc/swipe-right.svg',
    imgBackgroundSize: 'contain'
  };

  constructor(
    public dialogRef: MatDialogRef<ImageViewerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public images: [string]
  ) {}

  ngOnInit() {
    this.ngxSwiperConfig.navigation = this.images.length > 1 ? true : false;
    this.ngxSwiperConfig.pagination = this.images.length > 1 ? true : false;
  }
}
