import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DroppedImage } from '@paperclip/models/image-dropzone/dropped-image';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'pc-edit-image-modal',
  templateUrl: './edit-image-modal.component.html',
  styleUrls: ['./edit-image-modal.component.scss']
})
export class EditImageModalComponent {
  croppedImage: string;
  canvasRotation = 0;
  transform: ImageTransform = {};

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<EditImageModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: {
      image: DroppedImage;
      // eslint-disable-next-line @typescript-eslint/ban-types
      saveCroppedImage: (imageFile: DroppedImage) => {};
    }
  ) {}

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public rotateImage(direction: 'left' | 'right') {
    if (direction === 'left') {
      this.canvasRotation--;
    } else {
      this.canvasRotation++;
    }
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  public async saveCroppedImage() {
    const imageFile = await this.createImageFile();
    this.modalData.saveCroppedImage(imageFile);
    this.dialogRef.close();
  }

  private async createImageFile(): Promise<DroppedImage> {
    const imageFile: DroppedImage = this.convertBlobToFile(base64ToFile(this.croppedImage));
    imageFile.src = await URL.createObjectURL(imageFile);
    imageFile.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(imageFile.src);
    imageFile.index = this.modalData.image.index || 0;
    return imageFile;
  }

  private convertBlobToFile(blob: Blob) {
    if (!/Edge/.test(navigator.userAgent)) {
      return new File([blob], this.modalData.image.name, { type: 'image/png' });
    } else {
      const edgeBlob = new Blob([blob], { type: 'image/png' });
      const b: any = edgeBlob;
      b.lastModifiedDate = this.modalData.image.lastModified;
      b.name = this.modalData.image.name;
      return b as File;
    }
  }
}
