<form class="location-filter" pcClickStopPropagation>
  <!-- location search & use current location -->
  <!-- <pc-new-location-search [locationFormValue]="locationForm.value" [clearLocationSearch]="clearLocationSearch"
    [insideSideFilters]="insideSideFilters" [forceFullWidth]="!insideModal"
    (updateLocationForm)="updateLocation($event)" (emitLocationInputFocus)="showSeparator = $event">
  </pc-new-location-search> -->
  <pc-search-location-input [locationForm]="locationForm" (emitLocationInputFocus)="showSeparator = $event">
  </pc-search-location-input>

  <div *ngIf="showSeparator" class="separator"></div>
  <div [formGroup]="locationForm" class="grid-row grid-row--no-margin location-distance">
    <div class="location-distance__header">
      <p class="highlight-text">{{ 'search.filters.location.distance-from' | translate }}</p>
      <span class="highlight-text location-distance__header__label">
        {{ distanceLabelTranslation | translate: { distance: locationForm.get('distance').value } }}
      </span>
    </div>
    <mat-slider
      formControlName="distance"
      [min]="distanceSliderConfig.min"
      [max]="distanceSliderConfig.max"
      [step]="distanceSliderConfig.step"
      (input)="distanceChanged($event)"
    >
    </mat-slider>
    <p class="sub-text">{{ 'search.filters.location.distance-instruction' | translate }}</p>
  </div>
</form>
