export const paperclipErrors = [
  { code: 999, message: 'CONNECTION_LOST' },
  { code: -1, message: 'GENERAL_ERROR' },
  { code: -2, message: 'AUTH_TOKEN_INVALID' },
  { code: -3, message: 'AUTH_TOKEN_ABSENT' },
  { code: -4, message: 'USER_NOT_FOUND' },
  { code: -5, message: 'AUTH_TOKEN_EXPIRED' },
  { code: -666, message: 'ERROR_CODE_UNKNOWN' },
  { code: -100, message: 'ERROR_CODE_USER_REPORT_DUPLICATE' },
  { code: -101, message: 'ERROR_CODE_UPDATE_PROFILE_PASSWORDS_NOT_MATCH' },
  { code: -102, message: 'ERROR_CODE_UPDATE_PROFILE_WRONG_PASSWORD' },
  { code: -103, message: 'ERROR_CODE_UPDATE_PROFILE_USER_WITH_EMAIL_NOT_FOUND' },
  { code: -104, message: 'ERROR_CODE_UPDATE_PROFILE_EMAIL_ALREADY_EXISTS' },
  { code: -105, message: 'ERROR_CODE_EXISTING_OFFER' },
  { code: -106, message: 'ERROR_CODE_ITEM_ALREADY_REPORTED' },
  { code: -107, message: 'ERROR_CODE_REFERRAL_CODE_DUPLICATE' },
  { code: -108, message: 'ERROR_CODE_REFERRAL_CODE_IS_INVALID' },
  { code: -109, message: 'ERROR_CODE_PHONE_NUMBER_VERIFICATION_FAILED' },
  { code: -110, message: 'ERROR_CODE_UKAMF_SIGNIN_GENERAL_ERROR' },
  { code: -111, message: 'ERROR_CODE_UKAMF_SIGNIN_UNAPPROVED_LOGIN' },
  { code: -112, message: 'ERROR_CODE_USERNAME_IS_NOT_AVAILABLE' },
  { code: -113, message: 'ERROR_CODE_UNABLE_TO_CREATE_NEW_USER' },
  { code: -114, message: 'ERROR_CODE_EMAIL_IS_NOT_AVAILABLE' },
  { code: -115, message: 'ERROR_CODE_OFFER_WAS_RECENTLY_UPDATED' },
  { code: -116, message: 'ERROR_CODE_ITEM_IS_ALREADY_DELETED' },
  { code: -117, message: 'ERROR_CODE_USER_IS_ALREADY_DELETED' },
  { code: -118, message: 'INVALID_EMAIL' },
  { code: -119, message: 'INVALID_PASSWORD' },
  { code: -120, message: 'ERROR_AUTHORIZATION_ERROR' },
  { code: -122, message: 'USER_AGE_LESS_THAN_7_DAYS' },
  { code: -123, message: 'INVALID_NAME' },
  { code: -130, message: 'INVALID_PAPERCLIP_CREDIT_CODE' },
  { code: -200, message: 'EMAIL_VERIFICATION_REQUIRED' },
  { code: -201, message: 'MANGO_UNSUFFICIENT_WALLET_BALANCE' },
  { code: -202, message: 'MANGO_CARD_ERROR' },
  { code: -203, message: 'MANGO_TRANSACTION_REFUSED' },
  { code: -204, message: 'MANGO_CARD_OR_PAYMENT_PERIOD_EXPIRED' },
  { code: -205, message: 'MANGO_CARD_NOT_ACTIVE' },
  { code: -206, message: 'USER_DOES_NOT_HAVE_ANY_BANK_ACCOUNT_REGISTERED' },
  { code: -207, message: 'CHECKOUT_PROCESS_ITEM_ALREADY_BOUGHT' },
  { code: -208, message: 'MANGO_ERROR_UPLOADING_KYC' },
  { code: -211, message: 'ACCOUNT_AUTOMATICALLY_SUSPENDED' },
  { code: -212, message: 'ACCOUNT_SUSPENDED' },
  { code: -213, message: 'ACCOUNT_PERMANENTLY_SUSPENDED' }
];
