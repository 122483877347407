<!-- facebook -->
<svg *ngIf="icon === 'facebook'" xmlns="http://www.w3.org/2000/svg" width="7.535" height="16" viewBox="0 0 7.535 16">
  <path
    id="path"
    d="M1055.733,672.63h-3v-8h-2.005v-2.755h2.005V660.25c0-2.248.612-3.62,3.272-3.62h2.2v2.755h-1.372c-1.055,0-1.055.391-1.055,1.055v1.383h2.491l-.3,2.755h-2.185Z"
    transform="translate(-1050.73 -656.63)"
    [attr.fill]="socialColour ? '#fff' : '#444141'"
  />
</svg>

<!-- messenger -->
<svg
  *ngIf="icon === 'messenger'"
  xmlns="http://www.w3.org/2000/svg"
  width="18.667"
  height="18.793"
  viewBox="0 0 18.667 18.793"
>
  <path
    d="M9.337,0C4.177,0,0,3.894,0,8.7a8.489,8.489,0,0,0,3.477,6.773v3.32l3.178-1.754a9.979,9.979,0,0,0,2.675.362c5.16,0,9.337-3.894,9.337-8.7S14.5,0,9.337,0Zm.928,11.713L7.89,9.164,3.249,11.713,8.354,6.262,10.793,8.81l4.578-2.549Z"
    transform="translate(0 0.001)"
    [attr.fill]="socialColour ? '#fff' : '#444141'"
  />
</svg>

<!-- twitter -->
<svg *ngIf="icon === 'twitter'" xmlns="http://www.w3.org/2000/svg" width="16" height="13.123" viewBox="0 0 16 13.123">
  <path
    id="path"
    d="M1094.79,658.358a6.454,6.454,0,0,1-1.895.524,3.338,3.338,0,0,0,1.457-1.807,6.73,6.73,0,0,1-2.084.8,3.293,3.293,0,0,0-5.7,2.244,3.653,3.653,0,0,0,.088.743,9.312,9.312,0,0,1-6.776-3.439,3.279,3.279,0,0,0,1.02,4.371,3.321,3.321,0,0,1-1.457-.408h0a3.279,3.279,0,0,0,2.638,3.22,3.2,3.2,0,0,1-.875.117,3.106,3.106,0,0,1-.612,0,3.292,3.292,0,0,0,3.06,2.288,6.57,6.57,0,0,1-4.08,1.457,6.291,6.291,0,0,1-.787,0,9.34,9.34,0,0,0,14.4-7.883v-.422a6.855,6.855,0,0,0,1.6-1.807Z"
    transform="translate(-1078.79 -656.834)"
    [attr.fill]="socialColour ? '#fff' : '#444141'"
  />
</svg>

<!-- instagram -->
<svg *ngIf="icon === 'instagram'" xmlns="http://www.w3.org/2000/svg" width="15.899" height="16" viewBox="0 0 15.899 16">
  <g id="Group_3556" data-name="Group 3556" transform="translate(-7.696 -7.6)">
    <g id="Group_3555" data-name="Group 3555" transform="translate(7.696 7.6)">
      <path
        id="Path_4142"
        data-name="Path 4142"
        d="M8.05,1.409h3.22a3.83,3.83,0,0,1,1.509.3,1.81,1.81,0,0,1,.906.6,1.81,1.81,0,0,1,.6.906,7.563,7.563,0,0,1,.3,1.509v6.44a3.83,3.83,0,0,1-.3,1.509,1.81,1.81,0,0,1-.6.906,1.81,1.81,0,0,1-.906.6,7.563,7.563,0,0,1-1.509.3H4.83a3.83,3.83,0,0,1-1.509-.3,1.81,1.81,0,0,1-.906-.6,1.81,1.81,0,0,1-.6-.906,7.563,7.563,0,0,1-.3-1.509V4.73a3.83,3.83,0,0,1,.3-1.509,1.81,1.81,0,0,1,.6-.906,1.81,1.81,0,0,1,.906-.6,10.937,10.937,0,0,1,1.509-.2,22.237,22.237,0,0,1,3.22-.1M8.05,0H4.73A4.518,4.518,0,0,0,2.818.4a4.221,4.221,0,0,0-1.409.906A3.627,3.627,0,0,0,.4,2.818,7.863,7.863,0,0,0,0,4.73v6.642a4.518,4.518,0,0,0,.4,1.912,4.221,4.221,0,0,0,.906,1.409,4.221,4.221,0,0,0,1.409.906,7.863,7.863,0,0,0,1.912.4H11.27a4.518,4.518,0,0,0,1.912-.4A3.942,3.942,0,0,0,15.5,13.283a7.863,7.863,0,0,0,.4-1.912V4.73a4.518,4.518,0,0,0-.4-1.912,4.221,4.221,0,0,0-.906-1.409A4.221,4.221,0,0,0,13.182.5,7.863,7.863,0,0,0,11.27.1,21.4,21.4,0,0,0,8.05,0Z"
        [attr.fill]="socialColour ? '#fff' : '#444141'"
      />
      <path
        id="Path_4143"
        data-name="Path 4143"
        d="M8.026,3.9A4.081,4.081,0,0,0,3.9,8.026a4.081,4.081,0,0,0,4.126,4.126,4.081,4.081,0,0,0,4.126-4.126A4.081,4.081,0,0,0,8.026,3.9Zm0,6.843a2.717,2.717,0,1,1,2.717-2.717A2.706,2.706,0,0,1,8.026,10.743Z"
        transform="translate(0.025 0.025)"
        [attr.fill]="socialColour ? '#fff' : '#444141'"
      />
      <circle
        id="Ellipse_479"
        data-name="Ellipse 479"
        cx="1.006"
        cy="1.006"
        r="1.006"
        transform="translate(11.371 2.717)"
        [attr.fill]="socialColour ? '#fff' : '#444141'"
      />
    </g>
  </g>
</svg>
