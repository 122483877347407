import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'pc-claim-deal-modal',
  templateUrl: './claim-deal-modal.component.html',
  styleUrls: ['./claim-deal-modal.component.scss']
})
export class ClaimDealModalComponent {
  userId = this.authService.getAuthedUser().detail?.id || '';

  constructor(
    public dialogRef: MatDialogRef<ClaimDealModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: { dealId: string; dealCode: string; dealUrl: string },
    private authService: AuthService,
    private clipboardService: ClipboardService,
    private confirmationMessageService: ConfirmationMessageService
  ) {}

  copyDealCode() {
    this.clipboardService.copyFromContent(this.modalData.dealCode);
    this.confirmationMessageService.showConfirmationMessage('deal-code-copied');
  }

  claimDeal() {
    window.open(this.modalData.dealUrl, '_blank');
    this.dialogRef.close();
  }
}
