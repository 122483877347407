<li>
  <button
    *ngIf="menuData?.dataCount > 0"
    class="btn-link"
    (click)="menuAction.emit({ action: 'mark-all-as-read', data: menuData?.contentType })"
  >
    {{ 'menus.clear-delete-all.mark-all-as-read' | translate }}
  </button>
</li>
<li>
  <button
    class="btn-link btn-link--red"
    (click)="menuAction.emit({ action: 'delete-all', data: menuData?.contentType })"
  >
    {{ 'menus.clear-delete-all.delete-all' | translate }}
  </button>
</li>
