<div pcClickStopPropagation>
  <h2>{{ 'menus.wallet-filter-transactions.title' | translate }}</h2>
  <span class="custom-select">
    <select id="transactions-filter" (change)="setTransactionFilter($any($event.target).value)">
      <option *ngFor="let option of filterOptions" [selected]="option === 'all'">
        {{ 'menus.wallet-filter-transactions.' + option | translate }}
      </option>
    </select>
  </span>
</div>
