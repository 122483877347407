import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pc-payment-details-item-price',
  templateUrl: './payment-details-item-price.component.html',
  styleUrls: ['./payment-details-item-price.component.scss']
})
export class PaymentDetailsItemPriceComponent {
  @Input() addItemForm: UntypedFormGroup;
  @Input() largeInput = true;
  @Input() currency: { symbol: string; mask: any };
  @Input() forceFullWidth = false;
  @Output() clearItemDetail: EventEmitter<string> = new EventEmitter<string>();
}
