import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DisableFilterAction } from '@paperclip/models/search/DisableFilterAction';
import { SearchFilters } from '@paperclip/models/search/SearchFilters';

interface SearchType {
  label: 'items' | 'users';
  isSelected: boolean;
  apiValue?: any;
}

@Component({
  selector: 'pc-search-search-type-filter',
  templateUrl: './search-search-type-filter.component.html'
})
export class SearchTypeFilterComponent implements OnChanges {
  @Input() searchFilters: SearchFilters;
  @Input() applyFilter: boolean;
  @Input() clearFilter: boolean;
  @Input() insideFiltersModal = false;
  @Output() disableFilterButton: EventEmitter<DisableFilterAction> = new EventEmitter<DisableFilterAction>();
  searchTypes: SearchType[] = [];
  selectedType: 'items' | 'users';

  constructor(private router: Router) {}

  ngOnChanges(searchFiltersChanges: SimpleChanges): void {
    if (searchFiltersChanges) {
      if (searchFiltersChanges.searchFilters) {
        this.searchTypes = [
          { label: 'items', isSelected: this.searchFilters.type.includes('items') },
          { label: 'users', isSelected: this.searchFilters.type.includes('users') }
        ];

        this.selectedType = this.searchTypes.filter((type: SearchType) => type.isSelected)[0].label;
      }

      if (searchFiltersChanges.applyFilter?.currentValue) {
        this.applySearchTypeFilter();
      }

      if (searchFiltersChanges.clearFilter?.currentValue) {
        this.clearSearchTypeFilter();
      }
    }
  }

  public selectSearchType(searchType: SearchType) {
    this.disableFilterButton.emit({ button: 'clear', disable: false });
    this.disableFilterButton.emit({ button: 'apply', disable: false });
    if (searchType.label !== this.selectedType) {
      this.searchTypes.forEach((type: SearchType) => {
        type.isSelected = type.label === searchType.label;
      });
      this.selectedType = this.searchTypes.filter((type: SearchType) => type.isSelected)[0].label;
      if (this.searchFilters.view === 'list' || this.insideFiltersModal) {
        this.setFilterOnAllComponent(this.selectedType);
      }
    }
  }

  private applySearchTypeFilter() {
    this.router.navigate(['/search'], {
      queryParams: { type: this.selectedType, term: this.searchFilters.term }
    });
  }

  private clearSearchTypeFilter() {
    this.selectedType = 'items';
    this.router.navigate(['/search'], {
      queryParams: { type: 'items', term: this.searchFilters.term }
    });
  }

  private setFilterOnAllComponent(type: string) {
    this.searchFilters.type = type;
  }
}
