<div pcClickStopPropagation>
  <h2>{{ 'menus.filter-trades.title' | translate }}</h2>
  <span class="custom-select">
    <select id="transactions-filter" (change)="setTradesFilter($any($event.target).value)">
      <option *ngFor="let option of filterOptions" [selected]="option === menuData.selectedOption">
        {{ 'menus.filter-trades.' + option | translate }}
      </option>
    </select>
  </span>
</div>
