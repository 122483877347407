<div class="modal block-user-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.block-user.title' | translate }}</h1>
  </div>

  <p class="modal-body">
    {{ 'modals.block-user.body' | translate }}
  </p>

  <div class="modal-footer multi-btn-container">
    <button class="btn-stroked" (click)="dialogRef.close()">{{ 'buttons.cancel' | translate }}</button>
    <button class="btn-primary" (click)="blockUser()">{{ 'buttons.block' | translate }}</button>
  </div>
</div>
