import { formatNumber } from '@angular/common';
import { AppConfig } from '@paperclip/core/app.config';
import { CoreService } from '@paperclip/core/core.service';
import { environment } from '@paperclip/environments/environment';
import { relativeDate } from '@paperclip/functions/format-date';
import { priceWithoutComma } from '@paperclip/functions/price-without-comma';
import { DeepLinkData } from 'branch-sdk';

import { ApiItem } from './item/ApiItem';
import { ItemDetailTable } from './item/item-detail-table';
import { ItemCategory } from './item/ItemCategory';
import { FilterItem } from './search/filter-item';
import { User } from './user';
import { Order } from './order';

interface ItemStats {
  activeOrdersCount: number;
  ordersReceived: number;
  favouritesCount: number;
  displaysCount: number;
}

interface ItemPaymentInformation {
  acceptOffers: boolean;
  acceptBuyNow: boolean;
}

interface ItemDeliveryInformation {
  postageAvailable: boolean;
  postageCost: number;
  meetInPerson: boolean;
  deliveryString?: string;
}

export class Item {
  detail: {
    id?: string;
    shortToken?: string;
    dateAdded?: string;
    name?: string;
    description?: string;
    price?: string;
    priceItem?: number;
    priceDelivery?: number;
    priceFee?: number;
    priceTotal?: number;    
    priceClass?: string;
    location?: string;
    images?: string[];
    thumbnail?: string;
    thumbnails?: string[];
    isSold?: boolean;
    isTraded?: boolean;
    isFavourite?: boolean;
    includedInOffer?: boolean;
    offerMade?: boolean;
    isOwnItem?: boolean;
    tag?: string;
    category?: ItemCategory;
    categoryTree?: ItemCategory[];
    conditionTypeObject?: FilterItem;
    stats?: ItemStats;
    statsTable?: ItemDetailTable[];
    detailTable?: ItemDetailTable[];
    paymentInformation?: ItemPaymentInformation;
    deliveryInformation?: ItemDeliveryInformation;
    sellerId?: string;
    reuseImages?: boolean;
    isInSocialInventory?: boolean;
    canMakeOffer?: boolean;
    deepLink?: string;     
  };
  order?: Order;
  actions?: {
    buyNow: boolean;
    payNow: boolean;
  };
  seller?: User;
  isSelected?: boolean;

  constructor(
    item: ApiItem,
    loggedInUserId: string,
    locale = 'en-GB',
    dontShowTag: 'ownItem' | 'featuredItem' | 'newItem' = null
  ) {
    this.detail = {
      id: item.id,
      shortToken: item.shortToken,
      dateAdded: relativeDate(item.created, locale, 'L', true, true),
      name: item.name,
      description: item.description || 'items.no-description',
      price: setItemPrice(item),
      priceItem: item.priceItem,
      priceDelivery: item.priceDelivery,
      priceFee: item.priceFee,
      priceTotal: item.priceTotal,
      priceClass: 'colour-secondary',
      location: item.location ? item.location.name : null,
      images: item.images || [item.thumbnail] || [item.thumbnails[0]],
      thumbnail: item.thumbnail || item.thumbnails[0],
      isSold: item.isSold,
      isTraded: item.isTraded,
      isFavourite: item.isFavourite,
      includedInOffer: item.includedInOffer,
      offerMade: !!item.offerDate || item.offerMade,
      tag: setItemTag(item, dontShowTag),
      category: item.category,
      categoryTree: item.categoryTree,
      conditionTypeObject: setConditionTypeObject(item),
      paymentInformation: setItemPaymentInformation(item),
      deliveryInformation: setItemDeliveryInformation(item),
      sellerId: item.sellerId,
      reuseImages: item.reuseImages,
      isInSocialInventory: item.isInSocialInventory,
      canMakeOffer: item.canMakeOffer
    };
    this.order = item.order ? new Order(item.order) : null
    this.actions = item.actions;

    this.detail.detailTable = setItemDetailTable(item, this.detail.deliveryInformation.deliveryString, locale);

    this.seller = item.user ? new User(item.user) : null;

    // Check if the item belongs to the seller
    if (item.isOwnItem) {
      this.detail.isOwnItem = item.isOwnItem;
    } else if (this.seller) {
      this.detail.isOwnItem = this.seller.detail.id === loggedInUserId;
    } else if (this.detail.sellerId === loggedInUserId) {
      this.detail.isOwnItem = true;
    }

    this.detail.stats = setItemStats(this, item, loggedInUserId);
    if (this.detail.stats) {
      this.detail.statsTable = setItemStatsTable(this);
    }
  }
}

function setItemPrice(item): string {
  return formatNumber(item.price, 'en-GB', '1.2-2');
  // return item.price % 1 === 0 ? item.price.toLocaleString('en-GB') + '.00' : item.price.toLocaleString('en-GB');
}

function setItemTag(item: ApiItem, dontShowTag: 'ownItem' | 'featuredItem' | 'newItem'): string {
  const isOwnItem = item.isOwnItem && dontShowTag !== 'ownItem';
  const isFeaturedItem = item.isFeatured && dontShowTag !== 'featuredItem';
  const isNewItem = item.isNew && dontShowTag !== 'newItem';
  if (isOwnItem) return 'own-item';
  if (isFeaturedItem) return 'featured-item';
  if (isNewItem) return 'new-item';
  return null;
}

function setConditionTypeObject(item: ApiItem): FilterItem {
  const itemConditions: FilterItem[] = [];
  AppConfig.app['lists'].conditionType.forEach((condition: any) => {
    const itemCondition: FilterItem = {
      apiValue: condition.id,
      label: condition.name,
      isSelected: false
    };
    itemConditions.push(itemCondition);
  });

  return itemConditions.filter((itemCondition: FilterItem) => {
    return itemCondition.apiValue === item.conditionType;
  })[0];
}

function setItemStats(item: Item, apiItem: ApiItem, loggedInUserId: string): ItemStats {
  if (!item.seller) {
    return null;
  }

  return item.seller.detail.id !== loggedInUserId
    ? null
    : {
        activeOrdersCount: apiItem.activeOffersCount,
        ordersReceived: apiItem.offersReceived,
        favouritesCount: apiItem.favouritesCount,
        displaysCount: apiItem.displaysCount
      };
}

function setItemStatsTable(item: Item): ItemDetailTable[] {
  return [
    {
      icon: '/assets/icons/item/active-offers.svg',
      key: 'active-offers',
      count: item.detail.stats.activeOrdersCount,
      class: item.detail.stats.activeOrdersCount > 0 ? 'red-text' : ''
    },
    {
      icon: '/assets/icons/item/offers-received.svg',
      key: 'total-offers',
      count: item.detail.stats.ordersReceived
    },
    {
      icon: '/assets/icons/item/save.svg',
      key: 'favourited',
      count: item.detail.stats.favouritesCount
    },
    {
      icon: '/assets/icons/item/views-count.svg',
      key: 'views',
      count: item.detail.stats.displaysCount
    },
    {
      icon: '/assets/icons/misc/date.svg',
      key: 'uploaded',
      count: item.detail.dateAdded
    }
  ];
}

function setItemDetailTable(item: ApiItem, deliveryString: string, locale = 'en-GB'): ItemDetailTable[] {
  return [
    {
      icon: '/assets/icons/item/condition.svg',
      key: 'item-overview.condition',
      count: item.conditionTypeName
    },
    {
      icon: '/assets/icons/profile/location.svg',
      key: 'item-overview.location',
      count: item.location ? item.location.name : null
    },
    {
      icon: '/assets/icons/item/delivery.svg',
      key: 'item-overview.delivery.delivery',
      count: 'item-overview.delivery.' + deliveryString
    },
    {
      icon: '/assets/icons/misc/date.svg',
      key: 'trading.card.item-stats.added',
      count: relativeDate(item.created, locale, 'L', true, true)
    }
  ];
}

function setItemPaymentInformation(item: ApiItem): ItemPaymentInformation {
  return {
    acceptOffers: item.purchaseMethodAcceptOffers,
    acceptBuyNow: item.purchaseMethodBuyNow
  };
}

function setItemDeliveryInformation(item: ApiItem): ItemDeliveryInformation {
  let postageCost: any = null;
  if (item.deliveryMethodPostageCost) {
    postageCost =
      item.deliveryMethodPostageCost % 1 === 0
        ? priceWithoutComma(item.deliveryMethodPostageCost) + '.00'
        : priceWithoutComma(item.deliveryMethodPostageCost);
  }

  const deliveryInformation: ItemDeliveryInformation = {
    postageAvailable: item.deliveryMethodPostage ? item.deliveryMethodPostage : false,
    postageCost: postageCost,
    meetInPerson: item.deliveryMethodMeetInPerson ? item.deliveryMethodMeetInPerson : false,
    deliveryString: ''
  };
  deliveryInformation.deliveryString = setDeliveryString(deliveryInformation);

  return deliveryInformation;
}

function setDeliveryString(deliveryInformation: ItemDeliveryInformation): string {
  if (deliveryInformation.postageAvailable && deliveryInformation.meetInPerson) {
    if (Number(deliveryInformation.postageCost) === 0) {
      return 'both-accepted-free';
    } else {
      return 'both-accepted-cost';
    }
  } else if (deliveryInformation.postageAvailable) {
    if (Number(deliveryInformation.postageCost) === 0) {
      return 'postage-only-free';
    } else {
      return 'postage-only-cost';
    }
  } else if (deliveryInformation.meetInPerson) {
    return 'collection-only';
  }
}

export async function setItemDeepLink(item: Item, coreService: CoreService): Promise<string> {
  const itemRoute = `/item/${item.detail.id}`;
  const itemUrl = environment.production
    ? `https://marketplace.paperclip.co${itemRoute}`
    : `https://marketplace.papercliptesting.co.uk${itemRoute}`;
  const branchDeepLinkOptions: DeepLinkData = {
    feature: 'referral',
    campaign: 'user-share',
    data: {
      $desktop_url: itemUrl,
      $og_title: item.detail.name,
      $og_description: item.detail.description,
      $og_image_url: item.detail.images[0],
      $og_url: itemUrl
    }
  };

  return await coreService.generateBranchDeepLink(branchDeepLinkOptions);
}
