import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  constructor(private http: HttpClient) {}

  getWalletBalance(): Observable<any> {
    return this.http.get(`wallet/balance`);
  }

  withdrawBalance(amount: number): Observable<any> {
    return this.http.post('withdraw', { amount: Number(amount) });
  }

  getUserCredits(skip = 0, limit = 0): Observable<any> {
    return this.http.get(`paperclipCredit/available?skip=${skip}&limit=${limit}`);
  }

  getUserTransactions(getPendingTransactions: boolean, skip = 0, limit = 0): Observable<any> {
    return getPendingTransactions
      ? this.http.get(`transactions/pending?skip=${skip}&limit=${limit}`)
      : this.http.get(`transactions/available?skip=${skip}&limit=${limit}&ignoreFees=true`);
  }

  getUserBankDetails(): Observable<any> {
    return this.http.get(`bankAccounts`);
  }

  saveUserBankDetails(formData: FormData): Observable<any> {
    return this.http.post(`bankAccounts`, formData);
  }

  redeemPaperclipCredit(code: string): Observable<any> {
    return this.http.post(`paperclipCredit/${code}/redeem`, {});
  }

  uploadIdVerification(userId: string, formData: FormData): Observable<any> {
    return this.http.post(`paymentsAccounts/${userId}/uploadIdentityProof`, formData);
  }
}
