import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CoreService } from '@paperclip/core/core.service';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { DroppedImage } from '@paperclip/models/image-dropzone/dropped-image';
import { AddItemService } from '@paperclip/page/add-item/add-item.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-add-item-images-modal',
  templateUrl: './add-item-images-modal.component.html',
  styleUrls: ['./add-item-images-modal.component.scss']
})
export class AddItemImagesComponent implements OnInit, OnDestroy {
  windowSize = window.innerWidth < 1200 ? 'mobile' : 'desktop';
  windowWidthResizeSubscription: Subscription;
  closeTip: boolean;
  itemImages: DroppedImage[] = [];

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<AddItemImagesComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { onAddItem: boolean; complete: (images: DroppedImage[]) => {} },
    private addItemService: AddItemService,
    private windowResizeService: WindowResizeService,
    private coreService: CoreService
  ) {
    this.windowWidthResizeSubscription = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 1200 ? 'mobile' : 'desktop';
    });
  }

  ngOnInit(): void {
    this.coreService.trackPaperclipEvent({ name: 'ADD_ITEM_VIEWED', data: null }).subscribe();
  }

  ngOnDestroy(): void {
    this.windowWidthResizeSubscription.unsubscribe();
  }

  imageFilesSuccess(imageFiles: DroppedImage[] | any[]) {
    this.itemImages = imageFiles;
  }

  goToAddItem() {
    this.dialogRef.close();

    if (this.modalData?.onAddItem) {
      this.modalData.complete(this.itemImages);
    } else {
      this.addItemService.storeItemImages(this.itemImages);
      this.router.navigate(['/add-item']);
    }
  }
}
