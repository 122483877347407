import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '@paperclip/core/app.config';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { Item } from '@paperclip/models/item';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { PaperclipEvent } from '@paperclip/models/PaperclipEvent';
import { ItemOverviewService } from '@paperclip/page/item-overview/item-overview.service';

import { GridViewConfig } from '../grid-view-new.component';

@Component({
  selector: 'pc-grid-view-new-item',
  templateUrl: './grid-view-new-item.component.html'
})
export class GridViewNewItemComponent {
  @Input() gridViewConfig: GridViewConfig;
  @Input() item: Item;
  @Input() isOwnProfile: boolean;
  @Input() windowSize: string;
  userCurrency = AppConfig.web.currency.symbol || '£';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService,
    private itemOverviewService: ItemOverviewService,
    private confirmationMessageService: ConfirmationMessageService
  ) {}

  private preventDefault(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  public gridItemAction(event, action: 'like' | 'unlike', gridItem: Item) {
    // prevent link to gridItem if clicking on action
    let target = event.target;
    while (target && target.nodeName !== 'A') {
      target = target.parentNode;
    }

    if (target) {
      this.preventDefault(event);
    }

    switch (action) {
      case 'unlike':
        this.unfavouriteItem(gridItem);
        break;

      case 'like':
      default:
        this.favouriteItem(gridItem);
        break;
    }
  }

  private favouriteItem(item: Item) {
    this.itemOverviewService.favouriteItem(item.detail.id).subscribe(
      ({ code, message }: PcApiResponse) => {
        if (code === 1) {
          this.trackPaperclipEvent('save-item', item.detail.id);
          this.confirmationMessageService.showConfirmationMessage('saved-item');
          item.detail.isFavourite = true;
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  private unfavouriteItem(item: Item) {
    this.itemOverviewService.unfavouriteItem(item.detail.id).subscribe(
      ({ code, message }: PcApiResponse) => {
        if (code === 1) {
          this.trackPaperclipEvent('unsave-item', item.detail.id);
          this.confirmationMessageService.showConfirmationMessage('unsaved-item');
          item.detail.isFavourite = false;
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  public showFavouriteItemAction(item: Item): boolean {
    if (
      this.gridViewConfig.dataLoading ||
      item?.detail?.isOwnItem ||
      this.gridViewConfig.dataToGet === 'profile-sold' ||
      this.gridViewConfig.dataToGet === 'profile-inactive'
    ) {
      return false;
    }

    return true;
  }

  public socialInventoryBtnAction(event: any, item: Item) {
    this.preventDefault(event);
    this.isOwnProfile
      ? this.router.navigate(['/relist-item', item.detail.id])
      : this.router.navigate(['/messages', this.gridViewConfig.gridData.userProfileId]);
  }

  private trackPaperclipEvent(action: string, itemId: string) {
    const paperclipEvent: PaperclipEvent = { name: null, data: { objectIds: [itemId] } };
    switch (action) {
      case 'save-item':
        paperclipEvent.name = 'ITEM_SAVED';
        break;

      case 'unsave-item':
        paperclipEvent.name = 'ITEM_UNSAVED';
        break;

      default:
        console.error('unknown from type');
        break;
    }

    if (this.activatedRoute.snapshot.queryParams?.queryId) {
      paperclipEvent.data.queryId = this.activatedRoute.snapshot.queryParams?.queryId || null;
    }

    this.coreService.trackPaperclipEvent({ name: paperclipEvent.name, data: paperclipEvent.data }).subscribe();
  }
}
