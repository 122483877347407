import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '@paperclip/core/app.config';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';
import { AccountSettingsService } from '@paperclip/page/account-settings/account-settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-social-inventory-one-time-modal',
  templateUrl: './social-inventory-one-time-modal.component.html',
  styles: [
    `
      .social-inventory-one-time-modal img {
        margin: 16px 0;
      }
    `
  ]
})
export class SocialInventoryOneTimeModalComponent implements OnInit, OnDestroy {
  authedUser: AuthedUser = this.authService.getAuthedUser();
  windowSize: string;
  windowWidthResizeSubscription: Subscription;
  externalLinks = AppConfig.externalLinks;

  constructor(
    public dialogRef: MatDialogRef<SocialInventoryOneTimeModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { closed: () => {} },
    private accountSettingsService: AccountSettingsService,
    private coreService: CoreService,
    private authService: AuthService,
    private windowResizeService: WindowResizeService
  ) {
    this.windowWidthResizeSubscription = this.windowResizeService.windowWidthDidChange().subscribe((updatedWidth) => {
      this.windowSize = updatedWidth < 750 ? 'mobile' : updatedWidth < 1200 ? 'tablet' : 'desktop';
    });
  }

  ngOnInit() {
    this.windowSize = window.innerWidth < 750 ? 'mobile' : window.innerWidth < 1200 ? 'tablet' : 'desktop';
    this.accountSettingsService.setSocialInventoryModalSeen().subscribe(
      ({ code, message }: PcApiResponse) => {
        if (code !== 1) {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  ngOnDestroy(): void {
    this.windowWidthResizeSubscription.unsubscribe();
  }

  public closeModal() {
    this.modalData.closed();
    this.dialogRef.close();
  }
}
