import { Component, Input } from '@angular/core';

@Component({
  selector: 'pc-notification-dot',
  templateUrl: './notification-dot.component.html',
  styleUrls: ['./notification-dot.component.scss']
})
export class NotificationDotComponent {
  @Input() placement:
    | 'notifications-menu'
    | 'button'
    | 'profile'
    | 'inline'
    | 'inline-right'
    | 'inline-left'
    | 'inside-button'
    | 'notifications'
    | 'notifications-inside-menu' = 'button';
  @Input() notificationCount: number;
  @Input() showCount = true;
  @Input() size = 16;
  userOS: string;

  constructor() {
    if (navigator.appVersion.indexOf('Win') !== -1) {
      this.userOS = 'windows';
    }
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      this.userOS = 'mac';
    }
  }
}
