<div class="modal share-x-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.share.title' | translate }}</h1>
  </div>

  <div class="modal-body">
    <p>{{ 'modals.share.body-' + modalData.type | translate }}</p>
    <div class="share-x-modal__data">
      <pc-small-item [item]="modalData.item" metaDetail="price" [link]="false">
      </pc-small-item>
    </div>

    <div class="share-x-modal__buttons">
      <button class="btn-large btn-facebook" [ngClass]="{ 'btn-loading': branchDeepLinkLoading }"
        (click)="shareToFacebook()">
        {{ 'modals.share.facebook' | translate }}
      </button>
      <button class="btn-large btn-twitter" [ngClass]="{ 'btn-loading': branchDeepLinkLoading }"
        (click)="shareToTwitter()">
        {{ 'modals.share.twitter' | translate }}
      </button>
      <button class="btn-large btn-messenger" [ngClass]="{ 'btn-loading': branchDeepLinkLoading }"
        (click)="shareToMessenger()">
        {{ 'modals.share.messenger' | translate }}
      </button>
      <button class="btn-large" [ngClass]="{ 'btn-loading': branchDeepLinkLoading }" (click)="shareByEmail()">
        {{ 'modals.share.email' | translate }}
      </button>
      <button class="btn-large" [ngClass]="{ 'btn-loading': branchDeepLinkLoading }" [ngxClipboard]
        [cbContent]="branchDeepLink" (cbOnSuccess)="copyLink()">
        {{ copiedText | translate }}
      </button>
    </div>
  </div>
</div>
