<div class="no-data-view" [ngClass]="{ 'no-data-view--dropdown': isInDropdown }">
  <div></div>
  <div class="no-data-view__content">
    <!-- full page content only -->
    <ng-container *ngIf="!isInDropdown">
      <img
        *ngIf="needsDisplayImage && showImage"
        src="{{ 'no-data.' + noDataType + '.image.url' | translate }}"
        [ngStyle]="{ width: imageWidth, height: imageHeight }"
      />
      <h3>{{ 'no-data.' + noDataType + '.title' | translate }}</h3>
    </ng-container>

    <!-- all page content - can be either displaying message or title depending on whether we are in dropdown or not-->
    <p [ngClass]="{ 'p-reg': isInDropdown }">{{ getAppropriateNoDataContent() | translate }}</p>

    <!-- all page content - determined by needsButton input -->
    <button
      *ngIf="needsButton"
      [ngClass]="{ 'btn-primary': !isButtonBlue, 'btn-secondary': isButtonBlue, 'btn-no-click': disableButton }"
      (click)="handleClick()"
      [disabled]="disableButton"
    >
      {{ 'no-data.' + noDataType + '.button-title' | translate }}
    </button>
  </div>
  <div></div>
</div>
