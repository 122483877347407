<div class="modal messages-add-item-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.messages-add-item.title' | translate }}</h1>
    <p>{{ 'modals.messages-add-item.subtitle' | translate }}</p>
  </div>

  <div class="modal-body">
    <ul class="item-tabs">
      <li
        *ngFor="let tab of itemTabs"
        class="item-tabs__tab"
        [ngClass]="{ 'item-tabs__tab--active': tab.isSelected }"
        (click)="selectItemTab(tab)"
      >
        <h3>{{ 'modals.messages-add-item.' + tab.label | translate }}</h3>
      </li>
    </ul>
    <div class="data-list">
      <ul
        class="checkbox-list scrollable-content"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300"
        [scrollWindow]="false"
        (scrolled)="getItems(true)"
      >
        <ng-container *ngIf="dataLoading">
          <li *ngFor="let data of [0, 1, 2]" class="checkbox-list__item">
            <pc-small-item [item]="null" [link]="false"></pc-small-item>
          </li>
        </ng-container>
        <ng-container *ngIf="!dataLoading && items.length > 0">
          <li *ngFor="let item of items" (click)="selectItem(item)" class="checkbox-list__item">
            <pc-small-item [item]="item" [link]="false"></pc-small-item>
            <pc-checkmark-icon [checked]="item.isSelected" [size]="32"></pc-checkmark-icon>
          </li>
        </ng-container>
        <pc-no-data-view
          *ngIf="!dataLoading && items.length === 0"
          [pageToShow]="selectedTab.label === 'my-items' ? 'user-items' : 'user-items'"
          [isOwnProfile]="selectedTab.label === 'my-items'"
          [isInDropdown]="true"
          (buttonWasClicked)="goToAddItem()"
        >
        </pc-no-data-view>
        <div *ngIf="!dataLoading && loadingMore" class="loading-more-spinner"></div>
      </ul>
    </div>
  </div>

  <div class="modal-footer multi-btn-container">
    <button class="btn-large btn-primary" (click)="addItemToMessage()" [disabled]="!selectedItem">
      {{ 'modals.messages-add-item.button' | translate }}
    </button>
  </div>
</div>
