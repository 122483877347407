import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuAction } from '@paperclip/models/component/menu-action';

@Component({
  selector: 'pc-filter-trades-menu',
  templateUrl: './filter-trades-menu.component.html'
})
export class FilterTradesMenuComponent implements OnInit {
  @Input() menuData: { page: string; selectedOption: string };
  @Input() menuOpened: boolean;
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
  filterOptions = ['all'];

  constructor(private router: Router) {}

  ngOnInit() {
    switch (this.menuData.page) {
      case 'selling-history':
        this.filterOptions.push('completed', 'declined', 'refunded', 'dispute-resolved');
        break;

      case 'buying-history':
        this.filterOptions.push('completed', 'declined', 'refunded', 'dispute-resolved');
        break;

      case 'buying-active':
      case 'selling-active':
      default:
        this.filterOptions.push(
          'waiting-for-response',
          'awaiting-payment',
          'awaiting-dispatch',
          'awaiting-delivery',
          'requires-collection',
          'payment-failed',
          'active-dispute'
        );
        break;
    }

    if (this.menuData.page === 'selling-active') {
      this.filterOptions.push('mark-as-completed');
    }
  }

  public setTradesFilter(filter: string) {
    filter = filter.replace(/\s/g, '-').toLowerCase();
    this.menuAction.emit({ action: 'close-menu' });
    this.router.navigate([], { queryParams: { filter: filter } });
  }
}
