import { AppConfig } from '@paperclip/core/app.config';
import { relativeDate } from '@paperclip/functions/format-date';

import { Item } from './item';
import { ApiItem } from './item/ApiItem';
import { Order } from './order';
import { ApiOrder } from './order/ApiOrder';
import { ApiUser, User } from './user';

const notificationTypes: { id: number; type: string }[] = [
  { id: 0, type: 'LikedYourItem' },
  { id: 1, type: 'FollowedYou' },
  { id: 2, type: 'FollowedUserAddedAnItem' },
  { id: 3, type: 'ReviewedATrade' },
  { id: 4, type: 'MadeAnOffer' },
  { id: 5, type: 'DeclinedAnOffer' },
  { id: 6, type: 'AcceptedAnOffer' },
  { id: 12, type: 'SentYouAMessage' },
  { id: 19, type: 'PaymentReceived' },
  { id: 20, type: 'PaymentFailed' },
  { id: 21, type: 'SellerInactivityFirst' },
  { id: 22, type: 'SellerInactivitySecond' },
  { id: 23, type: 'SellerInactivityRefundedSeller' },
  { id: 24, type: 'SellerInactivityRefundedBuyer' },
  { id: 25, type: 'BuyerInactivityFirst' },
  { id: 26, type: 'BuyerInactivitySecond' },
  { id: 27, type: 'BuyerInactivityThird' },
  { id: 28, type: 'BuyerInactivityFourth' },
  { id: 29, type: 'BuyerInactivityReleasedSeller' },
  { id: 30, type: 'BuyerInactivityReleasedBuyer' },
  { id: 31, type: 'OfferDisputed' },
  { id: 32, type: 'OfferDispatched' },
  { id: 33, type: 'OfferDelivered' },
  { id: 34, type: 'PayOutSent' },
  { id: 35, type: 'KycRefused' },
  { id: 36, type: 'AcceptedAnOfferPayments' },
  { id: 37, type: 'BuyerCollectionInactivityFirst' },
  { id: 38, type: 'PaymentReceivedCollectionBuyer' },
  { id: 39, type: 'PaymentReceivedCollectionSeller' },
  { id: 40, type: 'BuyNowCashConfirmedBuyer' },
  { id: 41, type: 'BuyNowCashConfirmedSeller' },
  { id: 42, type: 'PaperclipCredit3DayReminder' },
  { id: 43, type: 'InactiveCardRemoved' },
  { id: 44, type: 'PaperclipCredit1DayReminder' },
  { id: 45, type: 'SocialInventoryAfter30days' },
  { id: 46, type: 'SocialInventoryAfter60days' },
  { id: 47, type: 'SocialInventoryAfter90days' },
  { id: 48, type: 'SocialInventoryAfter180days' },
  { id: 49, type: 'SocialInventoryAfter365days' },
  { id: 50, type: 'SocialInventoryAfter1yearEvery3months' },
  { id: 51, type: 'SocialInventoryExternalLinkNotification' },
  { id: 52, type: 'SuspendedTradeExternalLinkNotification' }
];

export interface ApiNotification {
  id?: string;
  senderId?: string;
  receiverId?: string;
  message?: string;
  notificationType?: number;
  created?: string;
  isFollowed?: boolean;
  senderName?: string;
  senderUsername?: string;
  senderPictureUrl?: string;
  senderImageUrl?: string;
  senderLocation?: string;
  order?: Order;
  itemEntity?: ApiItem;
  userEntity?: ApiUser;
  cardEntity?: {
    id: string;
    alias: string;
  };
  creditExpiring?: number;
  isSeen?: boolean;
}

interface NotificationContent {
  message: string;
  linkId?: string;
  linkText?: string;
}

export class Notification {
  detail: {
    id: string;
    date: string;
    type: string;
    content?: NotificationContent;
    isRead?: boolean;
  };
  sender: User;
  fromUser?: User;
  item?: Item;
  order?: Order;
  cardAlias?: string;
  creditExpiring?: string;
  linkUser?: boolean;

  constructor(notification: ApiNotification, loggedInUserId: string, locale = 'en-GB') {
    this.detail = {
      id: notification.id,
      date: relativeDate(notification.created, locale, 'L', true, true),
      type: notificationTypes.find(
        (notificationType: { id: number; type: string }) => notificationType.id === notification.notificationType
      ).type,
      isRead: notification.isSeen
    };
    this.sender = new User(notification);

    if (notification.notificationType === 38 || notification.notificationType === 39) {
      const user =
        notification.notificationType === 38 ? notification.order.seller : notification.order.buyer;
      this.fromUser = new User(user);
    } else if (notification.userEntity) {
      this.fromUser = new User(notification.userEntity);
    }

    this.linkUser = !this.sender.detail.fullName?.toLowerCase().includes('paperclip');
    this.item = notification.itemEntity ? new Item(notification.itemEntity, loggedInUserId, locale) : null;
    this.order = notification.order;
    this.cardAlias = notification.cardEntity?.alias;
    if (notification?.creditExpiring) {
      this.creditExpiring =
        notification?.creditExpiring % 1 === 0
          ? notification?.creditExpiring.toLocaleString('en-GB') + '.00'
          : notification?.creditExpiring.toLocaleString('en-GB');
    } else {
      this.creditExpiring = null;
    }

    this.detail.content = this.setNotificationContent(this.detail.type);
  }

  setNotificationContent(notificationType: string) {
    let link: { id: string; text: string };

    switch (notificationType) {
      case 'LikedYourItem':
      case 'FollowedUserAddedAnItem':
      case 'PaymentFailed':
      case 'SocialInventoryAfter30days':
      case 'SocialInventoryAfter60days':
      case 'SocialInventoryAfter90days':
      case 'SocialInventoryAfter180days':
      case 'SocialInventoryAfter365days':
      case 'SocialInventoryAfter1yearEvery3months':
        link = { id: this.item.detail.id, text: this.item.detail.name };
        break;

      case 'FollowedYou':
        link = { id: this.sender.detail.id, text: this.sender.detail.fullName };
        break;

      case 'PaymentReceivedCollectionBuyer':
      case 'PaymentReceivedCollectionSeller':
        link = { id: this.fromUser.detail.id, text: this.fromUser.detail.fullName };
        break;

      case 'ReviewedATrade':
        link = {
          id: JSON.parse(localStorage.getItem('user')).detail.username,
          text: null
        };
        break;

      case 'MadeAnOffer':
      case 'DeclinedAnOffer':
      case 'AcceptedAnOffer':
      case 'AcceptedAnOfferPayments':
      case 'SellerInactivityFirst':
      case 'SellerInactivitySecond':
      case 'SellerInactivityRefundedSeller':
      case 'SellerInactivityRefundedBuyer':
      case 'BuyerInactivityFirst':
      case 'BuyerInactivitySecond':
      case 'BuyerInactivityThird':
      case 'BuyerInactivityFourth':
      case 'BuyerInactivityReleasedSeller':
      case 'BuyerInactivityReleasedBuyer':
      case 'OfferDisputed':
      case 'OfferDispatched':
      case 'OfferDelivered':
      case 'PaymentReceived':
      case 'BuyerCollectionInactivityFirst':
        link = { id: this.order.item.detail.id, text: this.order.item.detail.name };
        break;

      case 'SocialInventoryFeatureNotification':
        link = { id: AppConfig.externalLinks.faq.socialInventory, text: null };
        break;

      case 'SuspendedTradeExternalLinkNotification':
        link = { id: AppConfig.externalLinks.faq.accountSuspension, text: null };
        break;

      case 'PayOutSent':
      case 'KycRefused':
      case 'BuyNowCashConfirmedBuyer':
      case 'BuyNowCashConfirmedSeller':
      case 'PaperclipCredit3DayReminder':
      case 'PaperclipCredit1DayReminder':
      case 'InactiveCardRemoved':
      default:
        link = { id: null, text: null };
        break;
    }

    const notificationContent: NotificationContent = {
      message: `user-notifications.${notificationType}`,
      linkId: link.id,
      linkText: link.text
    };

    if (notificationType === 'FollowedYou') {
      notificationContent.message = this.sender.detail.isFollowed
        ? 'user-notifications.FollowedYou'
        : 'user-notifications.UnfollowedFollowedYou';
    }

    return notificationContent;
  }
}

export class NotificationsByDate {
  today: Notification[] = [];
  previous: Notification[] = [];
  all: Notification[] = [];

  constructor(apiNotifications: ApiNotification[], loggedInUserId: string, locale = 'en-GB') {
    const notifications: Notification[] = [];
    apiNotifications.forEach((notification: ApiNotification) =>
      notifications.push(new Notification(notification, loggedInUserId, locale))
    );

    this.today = notifications.filter((notification: Notification) => notification.detail.date === 'Today');
    this.previous = notifications.filter((notification: Notification) => notification.detail.date !== 'Today');
    this.all = notifications;
  }
}
