import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-verified-badge-modal',
  templateUrl: './verified-badge-modal.component.html',
  styles: [
    `
      .verified-badge {
        padding-left: 8px;
      }
    `
  ]
})
export class VerifiedBadgeModalComponent implements OnInit {
  verifiedType: 'user' | 'official';

  constructor(
    public dialogRef: MatDialogRef<VerifiedBadgeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public verification: { verifiedBadge: string; verifiedBadgeAlt: string }
  ) {}

  ngOnInit(): void {
    this.verifiedType = this.verification.verifiedBadge.includes('verified-paperclip') ? 'official' : 'user';
  }
}
