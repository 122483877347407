import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[pcTabStopPropagation]'
})
export class TabStopPropagationDirective {
  @HostListener('keydown', ['$event'])
  public keyDown(event: KeyboardEvent): void {
    if (event.key.toLowerCase() === 'tab') {
      event.stopPropagation();
    }
  }
}
