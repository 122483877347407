import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AppConfig } from '@paperclip/core/app.config';
import { AppStateService } from '@paperclip/core/app-state.service';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { MenuAction } from '@paperclip/models/component/menu-action';
import { WalletBalance } from '@paperclip/models/payments/WalletBalance';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';
import { AccountSettingsService } from '@paperclip/page/account-settings/account-settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-header-profile-menu',
  templateUrl: './header-profile-menu.component.html',
  styleUrls: ['./header-profile-menu.component.scss']
})
export class HeaderProfileMenuComponent implements OnChanges {
  @Input() menuOpened: boolean;
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter<MenuAction>();
  authedUser: AuthedUser = this.authService.getAuthedUser();
  walletBalance: WalletBalance;
  userCurrency = AppConfig.web.currency.symbol || '£';
  externalLinks = AppConfig.externalLinks;
  updateAuthedUserSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private appStateService: AppStateService,
    private accountSettingsService: AccountSettingsService
  ) {
    this.updateAuthedUserSubscription = this.accountSettingsService
      .getUpdateAuthedUser()
      .subscribe((update: boolean) => {
        if (update) {
          this.authedUser = this.authService.getAuthedUser();
        }
      });
  }

  ngOnChanges(): void {
    this.walletBalance = this.appStateService.appState.walletBalance;
  }
}
