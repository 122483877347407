<div class="image-dropzone-container" [ngClass]="{
    'image-dropzone-container--user-settings': defaultConfig.previewTemplate.includes('user-settings'),
    'loading-data': loadExistingImage && !this.existingImages,
    'image-dropzone-container--id-verification': defaultConfig.previewTemplate === 'id-verification'
  }">
  <input #filePicker type="file" style="display: none" (change)="filesDropped($event)" (click)="inputClickEvent($event)"
    [multiple]="defaultConfig.maxImages > 1" />
  <!-- dropzone -->
  <div *ngIf="defaultConfig.showDropzone" class="dropzone"
    [ngClass]="{ 'dropzone--dragover': dragOverActive, 'dropzone--disabled': imagesProcessing }"
    (dragover)="dragOver($event)" (dragleave)="dragLeave($event)" (drop)="filesDropped($event)">
    <h3>{{ 'image-uploader.drop-images' | translate }}</h3>
    <p class="p-small">{{ 'image-uploader.or' | translate }}</p>
    <button (click)="filePicker.click()">
      {{ 'image-uploader.upload' | translate }}
    </button>
  </div>

  <div class="previews-drop-container" (dragover)="dragOver($event)" (dragleave)="dragLeave($event)"
    (drop)="filesDropped($event)">
    <!-- every other template -->
    <div *ngIf="defaultConfig.showPreviews" class="previews" [ngClass]="{
        'previews--user': defaultConfig.previewTemplate.includes('user'),
        'previews--no-wrap': !defaultConfig.previewsWrap
      }" cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="imagePreviews"
      (cdkDropListDropped)="cdkDrop($event)">
      <div *ngFor="let preview of imagePreviews" class="preview" [ngClass]="{
          'preview--processing': preview.processing,
          'preview--draggable': preview.imageUrl && droppedImages.length > 1,
          'preview--user':
            defaultConfig.previewTemplate.includes('user'),
          'remove-bottom-margin': isOnAdminSettingsEditInfo
        }" cdkDrag cdkDragBoundary=".previews" cdkDragLockAxis="x"
        [cdkDragDisabled]="!defaultConfig.enableReordering || !preview.imageUrl || droppedImages.length < 2">
        <div class="preview__image"
          [ngClass]="{ 'preview__image--clickable': !preview.imageUrl && !preview.processing }">
          <!-- item preview icon -->
          <div *ngIf="
              !preview.imageUrl &&
              (defaultConfig.previewTemplate === 'item' || defaultConfig.previewTemplate === 'id-verification')
            " class="preview-icon" (click)="previewClicked($event, preview)">
            <img src="/assets/icons/item/camera.svg" />
          </div>

          <!-- user preview button -->
          <button *ngIf="!preview.imageUrl && defaultConfig.previewTemplate === 'user'" class="preview-button"
            (click)="previewClicked($event, preview)">
            {{ 'buttons.add-image' | translate }}
          </button>

          <!-- delete image -->
          <img *ngIf="
              preview.imageUrl &&
              preview.allowDeletion &&
              defaultConfig.previewTemplate !== 'user-settings'
            " class="preview-remove-icon" (click)="removeImage(preview.index)"
            src="/assets/icons/forms/input-invalid.svg" />

          <!-- the actual image -->
          <img *ngIf="preview.imageUrl" class="bg-image" [src]="preview.imageUrl" (load)="imageLoaded(preview)" />
        </div>

        <button *ngIf="
            preview.btnTranslationKey !== null &&
            defaultConfig.previewTemplate !== 'user-settings'
          " class="btn btn-small" [ngClass]="{
            'btn-primary': preview.btnTranslationKey === 'upload',
            'push-to-top-right': isOnAdminSettingsEditInfo
          }" (click)="previewClicked($event, preview)" [disabled]="preview.processing">
          {{ 'image-uploader.' + preview.btnTranslationKey | translate }}
        </button>

        <div *ngIf="
            defaultConfig.previewTemplate === 'user-settings'
          " class="user-settings-buttons">
          <button (click)="filePicker.click()" [disabled]="preview.processing">
            {{ 'image-uploader.upload-new' | translate }}
          </button>

          <button class="btn-stroked" (click)="previewClicked($event, preview)" [disabled]="preview.processing">
            {{ 'image-uploader.edit' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
