import { Component, Input } from '@angular/core';

@Component({
  selector: 'pc-arrow-icon',
  templateUrl: './arrow-icon.svg',
  styleUrls: ['./arrow-icon.component.scss']
})
export class ArrowIconComponent {
  @Input() public orientation = 'left';
  @Input() public disabled = false;
}
