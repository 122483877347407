import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pc-redeem-paperclip-credit-modal',
  templateUrl: './redeem-paperclip-credit-modal.component.html'
})
export class RedeemPaperclipCreditModalComponent implements OnInit {
  modalContent: { title: string; body: string };
  constructor(
    public dialogRef: MatDialogRef<RedeemPaperclipCreditModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: { apiCode: number }
  ) {}

  ngOnInit() {
    this.modalContent = {
      title: this.modalData.apiCode === 1 ? 'success' : `failure`,
      body: this.modalData.apiCode === 1 ? 'success' : `failure${this.modalData.apiCode}`
    };
  }
}
