<ul class="item-category-breadcrumb" [ngClass]="{ 'item-category-breadcrumb--loading loading-data': showLoading }">
  <li *ngFor="let category of categoryBreadcrumb; let last = last">
    <a
      class="p-small"
      [routerLink]="['/search']"
      [queryParams]="{ type: 'items', categoryId: category.id }"
      [queryParamsHandling]="'merge'"
      >{{ category.name }}</a
    >
  </li>
</ul>
