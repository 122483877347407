/* eslint-disable prefer-spread */
import { DecimalPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NoDataType } from '@paperclip/constants/no-data-type';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import * as formatDate from '@paperclip/functions/format-date';
import * as modifyItemsData from '@paperclip/functions/modify-items';
import { ComponentUpdate } from '@paperclip/models/component-update';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Subscription } from 'rxjs';

import { GridViewService } from './grid-view.service';

@Component({
  selector: 'pc-grid-view',
  templateUrl: './grid-view.component.html',
  providers: [DecimalPipe]
})
export class GridComponent implements OnInit, OnChanges, OnDestroy {
  /*====================
  GRID INPUTS
  gridLocation: whether it is on the feed or not for special layouts
  gridType: items, users, deals, collections
  dataToGet: what data we need for the grid, e.g. featured items
  gridRows & gridColumns are for the home feed only
  gridData: gives us any data required for API calls
  swipeGrid: this is a specific type of grid where the content overflows and the user can
  use controls to scroll through or scroll with their mouse
  overflowGrid: similar to swipeGrid but with no navigation controls & different sizing
  showItemTags: whether to show item tags on item grids
  enableLoadMore: whether to enable infinite scrolling, true by default
  ====================*/
  @Input() gridLocation: string;
  @Input() gridType: string;
  @Input() dataToGet: string;
  @Input() gridRows: number;
  @Input() gridColumns: number;
  @Input() gridData: any;
  @Input() swipeGrid: boolean;
  @Input() swipeGridConfig: { title?: string; titleStyle?: string; routerLink?: any[]; queryParams?: any };
  @Input() overflowGrid: boolean;
  @Input() showItemTags = { ownItem: null, featuredItem: null, newItem: null };
  @Input() enableLoadMore = true;
  @Input() isFriend = false;
  @Input() forceUpdate = false;

  /*======================
  Dyanmic inputs below are used specifically for the configurable homepage
  =====================*/
  @Input() dynamicApi: string;
  @Input() dynamicApiQueryParams: any;
  @Input() dynamicApiLimit: number;

  /*====================
  SUBRCRIPTIONS
  ====================*/
  forceReloadGridViewSubscription: Subscription;

  /*====================
  GRID OUTPUTS
  emitTotalItemsCount: for the feed only to display total number of items
  ====================*/
  @Output() emitTotalItemsCount: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitNoDataClickEvent: EventEmitter<NoDataType> = new EventEmitter<NoDataType>();
  @Output() emitFeedSectionFinishedLoadingState: EventEmitter<void> = new EventEmitter<void>();

  /*====================
  GRID ITEMS & LOADING
  ====================*/
  gridItems: any = [];
  numberOfItems: number;
  loadingItems: Array<number>;
  dataLoading: boolean;
  dataLoadingMore: boolean;
  dataLoadingMoreSkip = 0;
  dataAllLoaded: boolean;

  /*====================
  GRID TYPE SPECIFIC DATA
  ====================*/
  isOwnProfile: boolean;

  /*====================
  SCROLLABLE GRID PROPERTIES
  ====================*/
  @ViewChild('gridElementRef') gridElementRef: ElementRef;
  scrollLeftValue: number;
  disableScrollLeft = true;
  disableScrollRight = false;

  /*====================
  MISCELLANEOUS
  ====================*/
  isLoggedIn = this.authService.isLoggedIn();
  loggedInUserId: string = this.authService.getAuthedUser().detail?.id || '';

  constructor(
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService,
    private authService: AuthService,
    private gridViewService: GridViewService,
    private coreService: CoreService,
    private updatesService: UpdatesService
  ) {
    /*-- this is for the add item modal, force reloading the items --*/
    this.forceReloadGridViewSubscription = this.gridViewService.needsForceUploadRequestSent().subscribe(() => {
      if (this.gridType.length !== 0 && this.gridData.length !== 0 && this.dataToGet.length !== 0) {
        this.loadGridType(this.gridType, this.gridData, this.dataToGet, false);
      }
    });
  }

  ngOnInit() {
    // these item tag controls need to be passed into the component
    // if they are not set to false they are true by default
    this.showItemTags = {
      ownItem: this.showItemTags.ownItem === false ? false : true,
      featuredItem: this.showItemTags.featuredItem === false ? false : true,
      newItem: this.showItemTags.newItem === false ? false : true
    };
  }

  ngOnDestroy() {
    this.forceReloadGridViewSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.numberOfItems = this.gridRows * this.gridColumns || 0;

    // create an array of loading items to show
    this.loadingItems = Array(this.numberOfItems > 0 ? this.numberOfItems : 8)
      .fill(1)
      .map((x, i) => i);

    this.loadGridType(this.gridType, this.gridData, this.dataToGet, false);
  }

  loadGridType(gridType: string, gridData: any, dataToGet: string, loadMore = false) {
    // only set initial loading state when not loading more
    this.dataLoading = loadMore ? false : true;

    const limit = this.numberOfItems > 0 ? this.numberOfItems : 0;

    // load more controls
    if (loadMore) {
      this.dataLoadingMoreSkip += 30;
      this.dataLoadingMore = true;
    } else {
      this.gridItems = [];
      this.dataLoadingMoreSkip = 0;
      this.dataLoading = true;
      this.dataLoadingMore = false;
    }

    // if data is all loaded turn off loading more
    if (this.dataAllLoaded) {
      this.dataLoadingMore = false;
    }

    if (this.dynamicApi) {
      this.getDynamicHomeSection(this.dynamicApi, this.dynamicApiQueryParams, this.dynamicApiLimit);
    } else {
      switch (gridType) {
        case 'items':
          this.getItems(dataToGet, gridData, loadMore, limit);
          break;

        case 'users':
          this.getUsers(dataToGet, gridData, loadMore, limit);
          break;

        case 'deals':
          this.getDeals(dataToGet, gridData, loadMore, limit);
          break;

        case 'collections':
          break;

        default:
          this.getItems(dataToGet, gridData, loadMore, limit);
          break;
      }
    }
  }

  loadMoreItems() {
    // don't load more on the feed or if all data has been loaded
    if (this.enableLoadMore && !this.dataAllLoaded) {
      this.loadGridType(this.gridType, this.gridData, this.dataToGet, true);
    }
  }

  /*====================
  dynamic home page sections
  ====================*/
  getDynamicHomeSection(query: string, queryParams: any, limit = 4) {
    this.gridViewService.getDynamicHomePageSection(query, queryParams, limit).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.dataLoadingMore = false;
        this.emitFeedSectionFinishedLoadingState.emit();
        if (code === 1) {
          switch (this.gridType) {
            case 'items': {
              // modify items data
              const items = data.items ? data.items : data;
              this.emitTotalItemsCount.emit(data.total || 0);
              modifyItemsData.modifyItemsData(items, this.decimalPipe, this.loggedInUserId);
              this.gridItems = items;
              break;
            }

            case 'deals':
              // modify deals data
              data.forEach((deal: any) => {
                deal.endDate = formatDate.relativeDate(deal.endDate, this.translateService.getBrowserCultureLang());
              });
              this.gridItems = data;
              break;
          }
        }
      },
      () => {
        // do something with error here
        this.coreService.handleError({});
      }
    );
  }

  /*====================
  items
  ====================*/
  getItems(itemsToGet: string, gridData: any, loadMore = false, limit: number) {
    switch (itemsToGet) {
      case 'featured-items':
        this.getFeaturedItems(limit, loadMore);
        break;

      case 'similar-items':
        if (gridData && gridData.itemId) {
          this.getSimilarItems(limit, loadMore, gridData);
        }
        break;

      case 'feed-categorised':
        if (gridData.length !== 0) {
          this.getFeedCategorisedItems(gridData);
        }
        break;

      case 'user-items':
        if (gridData.userProfileId) {
          this.getUserItems(limit, loadMore, gridData);
        }
        break;

      case 'user-sold-items':
        if (gridData.userProfileId) {
          this.getUserSoldItems(limit, loadMore, gridData);
        }
        break;

      case 'user-favourites':
        if (gridData.userProfileId) {
          this.getUserFavourites(limit, loadMore, gridData);
        }
        break;
    }
  }

  getFeaturedItems(limit: number, loadMore: boolean) {
    this.gridViewService.getFeaturedItems(this.dataLoadingMoreSkip, limit).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.dataLoadingMore = false;

        if (code === 1) {
          // modify items data
          modifyItemsData.modifyItemsData(data, this.decimalPipe, this.loggedInUserId);

          if (loadMore) {
            this.gridItems.push.apply(this.gridItems, data);
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          } else {
            this.gridItems = data;

            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          }
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  getSimilarItems(limit: number, loadMore: boolean, { itemId }) {
    this.gridViewService.getSimilarItems(itemId, this.dataLoadingMoreSkip, limit).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.dataLoadingMore = false;

        if (code === 1) {
          // modify items data
          modifyItemsData.modifyItemsData(data, this.decimalPipe, this.loggedInUserId);

          // get items count to show or hide 'show all' link
          this.emitTotalItemsCount.emit({ totalCount: this.decimalPipe.transform(data.length) });

          if (loadMore) {
            this.gridItems.push.apply(this.gridItems, data);
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          } else {
            this.gridItems = data;
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          }
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  getFeedCategorisedItems(gridData: any) {
    this.dataLoading = false;
    this.dataLoadingMore = false;
    modifyItemsData.modifyItemsData(gridData.items, this.decimalPipe, this.loggedInUserId);
    this.gridItems = gridData.items;
  }

  getUserItems(limit: number, loadMore: boolean, { userProfileId }) {
    // check if own profile for no data state
    this.isOwnProfile = userProfileId === this.loggedInUserId;

    this.gridViewService.getUserItems(userProfileId, this.dataLoadingMoreSkip).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.dataLoadingMore = false;
        if (code === 1) {
          // modify items data
          modifyItemsData.modifyItemsData(data.items, this.decimalPipe, this.loggedInUserId);

          if (loadMore) {
            this.gridItems.push.apply(this.gridItems, data.items);
            // check if all items have been loaded
            this.dataAllLoaded = data.items.length < 30;
          } else {
            this.gridItems = data.items;
            // check if all items have been loaded
            this.dataAllLoaded = data.items.length < 30;
          }
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  getUserSoldItems(limit: number, loadMore: boolean, { userProfileId }) {
    // check if own profile for no data state
    this.isOwnProfile = userProfileId === this.loggedInUserId;

    this.gridViewService.getUserSoldItems(userProfileId, this.dataLoadingMoreSkip).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.dataLoadingMore = false;
        if (code === 1) {
          // modify items data
          modifyItemsData.modifyItemsData(data, this.decimalPipe, this.loggedInUserId);

          if (loadMore) {
            this.gridItems.push.apply(this.gridItems, data);
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          } else {
            this.gridItems = data;
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          }
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  getUserFavourites(limit: number, loadMore: boolean, { userProfileId }) {
    this.gridViewService
      .getUserFavourites(userProfileId, this.dataLoadingMoreSkip)
      .subscribe(({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.dataLoadingMore = false;
        if (code === 1) {
          // modify items data
          modifyItemsData.modifyItemsData(data, this.decimalPipe);

          if (loadMore) {
            this.gridItems.push.apply(this.gridItems, data);
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          } else {
            this.gridItems = data;
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          }
        }
      });
  }

  /*====================
  users
  ====================*/
  getUsers(usersToGet: string, gridData: any, loadMore = false, limit: number) {
    switch (usersToGet) {
      case 'user-followers':
        if (gridData.length !== 0) {
          this.getUserFollowers(limit, loadMore, gridData);
        }
        break;

      case 'user-following':
        if (gridData.length !== 0) {
          this.getUserFollowing(limit, loadMore, gridData);
        }
        break;
    }
  }

  getUserFollowers(limit: number, loadMore: boolean, { userProfileId }) {
    // check if own profile for no data state
    this.isOwnProfile = userProfileId === this.loggedInUserId;

    this.gridViewService.getUsersFollowers(userProfileId, this.dataLoadingMoreSkip).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.dataLoadingMore = false;

        if (code === 1) {
          if (loadMore) {
            this.gridItems.push.apply(this.gridItems, data);
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          } else {
            this.gridItems = data;
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          }
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  getUserFollowing(limit: number, loadMore: boolean, { userProfileId }) {
    // check if own profile for no data state
    this.isOwnProfile = userProfileId === this.loggedInUserId;

    this.gridViewService.getUsersFollowing(userProfileId, this.dataLoadingMoreSkip).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.dataLoadingMore = false;

        if (code === 1) {
          if (loadMore) {
            this.gridItems.push.apply(this.gridItems, data);
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          } else {
            this.gridItems = data;
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          }
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  /*====================
  deals
  ====================*/
  getDeals(dealsToGet: string, gridData: any, loadMore = false, limit: number) {
    switch (dealsToGet) {
      case 'all-deals':
        this.getAllDeals(limit, loadMore, gridData);
        break;
    }
  }

  getAllDeals(limit: number, loadMore: boolean, gridData: any) {
    this.gridViewService.getDealsAndDiscounts(this.dataLoadingMoreSkip, limit).subscribe(
      ({ code, data, message }: PcApiResponse) => {
        this.dataLoading = false;
        this.dataLoadingMore = false;

        if (code === 1) {
          data.forEach((deal: any) => {
            deal.endDate = formatDate.relativeDate(deal.endDate, this.translateService.getBrowserCultureLang());
          });
          // set total count -- TODO: future

          if (loadMore) {
            this.gridItems.push.apply(this.gridItems, data);
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          } else {
            this.gridItems = data;
            // check if all items have been loaded
            this.dataAllLoaded = data.length < 30;
          }
        } else {
          this.coreService.handleError({ code, message });
        }
      },
      () => {
        this.coreService.handleError({});
      }
    );
  }

  /*====================
  utilities
  ====================*/
  scrollGridLeft() {
    if (!this.disableScrollLeft) {
      this.gridElementRef.nativeElement.scrollLeft -= 1076;
      this.disableScrollRight = false;

      // disable scroll left?
      setTimeout(() => {
        const scrollLeftValue = this.gridElementRef.nativeElement.scrollLeft;
        this.disableScrollLeft = scrollLeftValue <= 0 ? true : false;
      }, 500);
    }
  }

  scrollGridRight(distance = 1076) {
    if (!this.disableScrollRight || this.gridItems.length > 0) {
      this.gridElementRef.nativeElement.scrollLeft += distance;
      this.disableScrollLeft = false;

      // disable scroll right?
      const scrollWidth = this.gridElementRef.nativeElement.scrollWidth;
      const offsetWidth = this.gridElementRef.nativeElement.offsetWidth;
      const totalGridWidth = scrollWidth - offsetWidth;

      setTimeout(() => {
        const scrollLeftValue = this.gridElementRef.nativeElement.scrollLeft;
        this.disableScrollRight = scrollLeftValue >= totalGridWidth ? true : false;
      }, 500);
    }
  }

  // disable / enable swipeGrid navigation when using mouse to scroll
  getScrollPosition() {
    const scrollWidth = this.gridElementRef.nativeElement.scrollWidth;
    const offsetWidth = this.gridElementRef.nativeElement.offsetWidth;
    const totalGridWidth = scrollWidth - offsetWidth;

    this.disableScrollLeft = this.gridElementRef.nativeElement.scrollLeft <= 0 ? true : false;
    this.disableScrollRight = this.gridElementRef.nativeElement.scrollLeft >= totalGridWidth ? true : false;
  }

  showNoDataState(): boolean {
    return !this.dataLoading && this.gridItems.length === 0;
  }

  getCorrectNoDataPageToShow(): string {
    switch (this.dataToGet) {
      default:
        return this.dataToGet;
    }
  }

  /*====================
  no data button click event - pass it on up to the parent
  ====================*/
  noDataButtonWasClicked(noDataType: NoDataType) {
    this.emitNoDataClickEvent.emit(noDataType);
  }
}
