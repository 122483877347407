<div class="modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <img src="/assets/illustrations/payments/activate-account.svg" alt="" />
    <h1>{{ 'modals.activate-payment-account.title' | translate }}</h1>
  </div>

  <div class="modal-body">
    <div *ngFor="let section of activateAccountInfo" class="protection-section">
      <div class="protection-section__icon">
        <img [src]="section.icon" />
      </div>
      <div class="protection-section__detail">
        <p class="highlight-text">{{ section.heading | translate }}</p>
        <p>{{ section.description | translate }}</p>
      </div>
    </div>
  </div>

  <div class="modal-footer multi-btn-container">
    <a class="btn btn-large btn-primary" routerLink="/wallet/payment-setup">{{
      'modals.activate-payment-account.button' | translate
    }}</a>
  </div>
</div>
