import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userIsLoggedIn = this.authService.isLoggedIn();
    const jsonReq: HttpRequest<any> = userIsLoggedIn
      ? req.clone({
          setHeaders: { Authorization: `Bearer ${this.authService.getAuthedUser().authToken}` }
        })
      : req.clone({ setHeaders: { Authorization: '' } });

    return next.handle(jsonReq);
  }
}
