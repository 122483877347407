<div class="modal ie-warning-modal">
  <pc-close-icon (click)="dialogRef.close()"></pc-close-icon>
  <div class="modal-header">
    <h1>{{ 'modals.ie-warning.title' | translate }}</h1>
  </div>

  <div class="modal-body">
    <p>{{ 'modals.ie-warning.body' | translate }}</p>
    <br />
    <a href="https://www.google.com/chrome/" target="_blank">{{ 'modals.ie-warning.body-2' | translate }}</a>
  </div>
</div>
