<ul
  [ngClass]="{
    'content-card content-card--extra-padding': !pendingTransactionsModal,
    'scrollable-content': pendingTransactionsModal,
    'no-pending-transactions': pendingTransactionsModal && transactionsList?.length === 0,
    'loading-data': !transactionsList
  }"
  infiniteScroll
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="300"
  [scrollWindow]="!pendingTransactionsModal"
  (scrolled)="getUserTransactions(pendingTransactionsModal, true)"
>
  <ng-container *ngFor="let transaction of transactionsList ? transactionsList : [null, null, null]; let last = last">
    <li class="wallet-transaction">
      <div class="wallet-transaction__detail">
        <div class="wallet-transaction__detail__left">
          <p class="highlight-text">
            {{ 'wallet.history.type.' + transaction?.type | translate: { buyer: transaction?.buyerUsername } }}&nbsp;<a
              *ngIf="transaction?.type === 'transfer'"
              [routerLink]="['/u', transaction?.buyerUsername]"
              >{{ transaction?.buyerUsername }}</a
            ><img
              *ngIf="transaction?.type === 'credit-code-redeemed' || transaction?.type === 'credit-code-expired'"
              class="info-icon"
              src="/assets/icons/misc/info.svg"
              alt=""
              matTooltip="{{ 'tooltip.wallet-paperclip-credit.text' | translate }}"
              matTooltipPosition="above"
              [matTooltipDisabled]="windowSize !== 'desktop'"
              (click)="displayTooltipAsBottomSheet('wallet-paperclip-credit')"
            />
          </p>

          <p class="colour-secondary">
            <span *ngIf="transaction?.minusValue">-</span>{{ userCurrency }}{{ transaction?.value | number: '1.2-2'
            }}<span
              *ngIf="transaction?.type === 'credit-code-redeemed' || transaction?.type === 'credit-code-expired'"
              class="wallet-transaction__detail__right__credit"
            >
              {{ 'generic.paperclip-credit' | translate }}</span
            >
          </p>

          <a *ngIf="transaction?.itemId" class="btn" [routerLink]="['/item', transaction?.itemId]">{{
            'wallet.history.view-trade' | translate
          }}</a>
          <div
            *ngIf="transaction?.type === 'credit-code-redeemed' || transaction?.type === 'credit-code-expired'"
            class="credit-detail"
          >
            <p class="credit-detail__code">
              {{ 'wallet.history.code-used' | translate }}<span>{{ transaction?.creditCode }}</span>
            </p>
            <p class="credit-detail__expiry">
              {{
                transaction?.type === 'credit-code-redeemed'
                  ? ('generic.expires' | translate: { expirationDate: transaction?.creditCodeExpiryDate })
                  : ('generic.expired' | translate: { expirationDate: transaction?.creditCodeExpiryDate })
              }}
            </p>
          </div>
        </div>
        <div class="wallet-transaction__detail__right">
          <p class="colour-secondary">
            <span *ngIf="transaction?.minusValue">-</span>{{ userCurrency }}{{ transaction?.value | number: '1.2-2'
            }}<span
              *ngIf="transaction?.type === 'credit-code-redeemed' || transaction?.type === 'credit-code-expired'"
              class="wallet-transaction__detail__right__credit"
            >
              {{ 'generic.paperclip-credit' | translate }}</span
            >
          </p>
          <p>{{ transaction?.date }}</p>
        </div>
      </div>
      <a *ngIf="transaction?.itemId" class="btn" [routerLink]="['/item', transaction?.itemId]">{{
        'wallet.history.view-trade' | translate
      }}</a>
    </li>
    <div *ngIf="!last" class="separator"></div>
  </ng-container>
  <div *ngIf="transactionsList && loadingMore" class="loading-more-spinner"></div>

  <div *ngIf="transactionsList?.length === 0" class="no-transaction-history">
    <h3>
      {{
        pendingTransactionsModal
          ? ('wallet.history.no-data.title-pending' | translate)
          : ('wallet.history.no-data.title' | translate)
      }}
    </h3>
    <p>
      {{
        pendingTransactionsModal
          ? ('wallet.history.no-data.message-pending' | translate)
          : ('wallet.history.no-data.message' | translate: { transactionType: selectedFilterAction.replace('-', ' ') })
      }}
    </p>
  </div>
</ul>
