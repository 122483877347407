<div class="error-banner" [ngClass]="{ 'error-banner--visible': error }">
  <div *ngIf="error" class="grid-container error-banner__content">
    <p>{{ 'error.' + error.message | translate }}</p>
    <button class="btn-icon" (click)="error = null">
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAADqADAAQAAAABAAAADgAAAACeOBvAAAAAsElEQVQoFYWSwQ3CMBAELSrIjy9ffpSWTkgJfqUd8iRVQAdm1tjBWHfJSpvozju5k5WQUrrhOx7Cgf6yFBOWHtiFOdOAF5bGwGPAiypkwvRbKG6LceDCLlRpCz6EHPgJ+MbSb70a7t+EtPaqdNHcZ1SfjOaF3rnpX/mAe9s5R6C9vZl6KVPN27agqKYm7cIctpMyJFBy4T3oizow4ISlWIPWm/N27e2XG61w3ytwzn4AX2lmm53QfgEAAAAASUVORK5CYII="
        alt=""
      />
    </button>
  </div>
</div>
